import * as actionTypes from '../../types/User'
import { LOGOUT } from '../../types/Auth'

const initialState = null

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SUCCESS:
      return {
        ...state,
        ...action.payload.value
      }
    case actionTypes.UPDATE_IS_SAAS_USER:
      return {
        ...state,
        isSaasUser: action.payload.value
      }
    case LOGOUT:
      return initialState
    default: {
      return state
    }
  }
}
export default userReducer
