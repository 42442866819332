import React, { useEffect, useState } from 'react'

const LazyBackgroundImage = ({ src, backgroundClass, hover }) => {
  const [imageSrc, setImageSrc] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setImageSrc(src)
  }, [src])

  return (
    <div
      className={`absolute w-full h-full bg-no-repeat bg-center transition-all duration-300
      ${hover ? 'bottom-5' : 'bottom-0'}
      ${imageSrc ? 'opacity-100 bg-transparent' : 'opacity-0 bg-gray-100 animate-pulse'} ${backgroundClass}`}
      style={{
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: backgroundClass?.includes('bg-contain') ? 'contain' : ''
      }}
    ></div>
  )
}

export default LazyBackgroundImage
