import React from 'react'
import Button from 'components/Button'
import Logo from 'components/Logo'
import usePartnersHook from 'components/Partners/hook'
import configs from 'config/configs'

const PartnersApply = ({ partnerData }) => {
  const { handleApply } = usePartnersHook()

  return (
    <div className="flex flex-col items-center justify-between min-h-screen p-8">
      <div className="flex mb-7">
        <img
          src={configs.images.bloomLogoWithHeart}
          className={'m-2 w-32 md:w-auto'}
        />
        <Logo
          className={'w-32 md:w-48 h-16 ml-2 bg-no-repeat bg-center'}
          logo={`url(${partnerData?.logo})`}
        />
      </div>
      <div className="font-black text-5xl leading-48px lg:leading-67_2px mb-4 lg:mb-1 text-center">
        Capital to grow your business
      </div>
      <div className="flex flex-col items-center mb-10">
        <div className="text-xl leading-6 text-center">
          Do you need capital for marketing or inventory purchases?
        </div>
        <div className="text-xl leading-6 text-center">
          Bloom Capital is designed for eCommerce founders just like you.
        </div>
      </div>
      <Button
        type="button"
        loading={false}
        variant="button-apply"
        className="font-medium mb-12"
        affixIcon={configs.icons.arrowForward}
        children={'Apply'}
        onClick={() => handleApply(partnerData)}
      />
      <div className="mb-8">
        <img src={configs.images.partnerApply} className={'m-2'} />
      </div>
    </div>
  )
}

export default PartnersApply
