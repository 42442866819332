import React from 'react'
import { Link } from 'react-router-dom'
import PageTitle from 'components/PageTitle'
import useRouterHook from 'routes/hooks/useRouter'
import RegistrationStepper from '../RegistrationStepper'
import ButtonAction from './ButtonAction'
import FormAndTerms from './FormAndTerms'

const PersonalInformation = ({
  slug,
  formik,
  shouldShowError,
  passwordBar,
  onPersonalNext,
  handleChange,
  onClickBack,
  loading
}) => {
  const { isSubdomainPermitted, isSubdomainWithoutBusinessType } =
    useRouterHook()

  const steps =
    !isSubdomainPermitted() || isSubdomainWithoutBusinessType()
      ? [true, true, false]
      : [true, false]

  return (
    <form>
      <div className="justify-between ">
        <div className="flex justify-center text-xl leading-8">
          Welcome to Bloom,
        </div>
        <RegistrationStepper steps={steps} />
        <PageTitle title="Let's create an account" />
        <FormAndTerms
          formik={formik}
          shouldShowError={shouldShowError}
          passwordBar={passwordBar}
          handleChange={handleChange}
        />
      </div>
      <div className="flex">
        {slug && (
          <Link
            to="/login"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center w-16"
          >
            <span className="text-pink-color font-bold border-pink-color border-b hover:opacity-80 transition-opacity">
              Sign in
            </span>
          </Link>
        )}
        <ButtonAction
          loading={loading}
          onClickBack={onClickBack}
          onClickNext={onPersonalNext}
          removeBackButton={steps.length === 2}
        />
      </div>
    </form>
  )
}

export default PersonalInformation
