import React from 'react'
import configs from 'config/configs'
import Button from '../Button'

const ButtonAction = ({
  onClickNext,
  onClickBack,
  loading,
  disabled,
  removeBackButton
}) => {
  return (
    <div className="flex flex-row justify-between mt-4 3xl:mt-12 mb-8 w-full">
      <Button
        className={removeBackButton && 'invisible'}
        variant={'button-back'}
        onClick={onClickBack}
        loading={loading}
      >
        Back
      </Button>
      <Button
        variant={'button-next-pink'}
        affixIcon={configs.icons.arrowForward}
        onClick={onClickNext}
        type="submit"
        loading={loading}
        disabled={disabled}
      >
        Next
      </Button>
    </div>
  )
}

export default ButtonAction
