import React from 'react'
import ForgotPasswordForm from 'components/ForgotPasswordForm'
import { UnauthenticatedLayout } from '../../../layouts'

const ResetPassword = ({ name }) => {
  return (
    <UnauthenticatedLayout
      formContent={<ForgotPasswordForm />}
      screenName={name}
      hasHeader={true}
      hasFooter={false}
    />
  )
}

export default ResetPassword
