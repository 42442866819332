import React from 'react'
import { NavLink } from 'react-router-dom'
import useSidebarHook from 'layouts/components/Sidebar/hook'
import useOnboardingHook from '../../../components/Onboarding/hook'
import useHeaderHook from '../Header/useHeaderHook'

const Sidebar = ({ steps, options, currentStep, setCurrentStep }) => {
  const { handleTick, isOfferExpired, offerExpiresAt } = useSidebarHook()
  const { toggleMobileExpand } = useHeaderHook({})
  const { onboardingMeta } = useOnboardingHook()

  return (
    <div className="flex flex-col mb-3 mt-3">
      {steps?.map((step, index, arr) => {
        const isActive = index === currentStep
        const isLast = index === arr.length - 1

        // Check if this step is the first uncompleted one
        const isFirstUncompletedStep =
          steps.indexOf(steps.find((s) => !s.isDone)) === index
        const isNavEnabled =
          offerExpiresAt && !!isOfferExpired && onboardingMeta?.offer
            ? false
            : options.directNavigation ||
              step.isDone ||
              !!isFirstUncompletedStep

        return (
          <div
            key={index}
            className={`flex relative border-divider-dark-blue h-60px
                  ${isLast || isActive ? 'border-0' : 'border-b'}
                  ${isActive && '-left-2.5 w-active-step bg-teal-color rounded-lg  px-2.5 shadow-active-step'}`}
          >
            <NavLink
              to={step.url}
              onClick={(e) =>
                isNavEnabled ? setCurrentStep(index) : e.preventDefault()
              }
              className={`flex w-full border-0 outline-none`}
            >
              <span
                className={`flex items-center w-full py-18px px-38px ${isActive && 'text-dark-blue'}`}
                onClick={toggleMobileExpand}
              >
                <span
                  className={`flex h-6 w-6 mr-3.5 justify-center items-center bg-transparent`}
                >
                  {step.icon ? (
                    <img
                      src={step.icon}
                      className={`h-6 w-6 object-cover`}
                      style={{
                        filter:
                          isActive &&
                          'brightness(0) saturate(100%) invert(7%) sepia(34%) saturate(2203%) hue-rotate(209deg) brightness(89%) contrast(92%)'
                      }}
                    />
                  ) : null}
                </span>
                <span className="flex">{step.title}</span>
                {options.showTick && (
                  <img
                    className={'flex ml-auto w-6'}
                    src={handleTick(isActive, step.isDone, step.status)}
                  />
                )}
              </span>
            </NavLink>
          </div>
        )
      })}
    </div>
  )
}

export default Sidebar
