import React from 'react'
import { useQuery } from 'react-query'
import companyApi from 'config/api/company'
import Skeleton from '../Skeleton'

const PaymentDetails = () => {
  const paymentDetailsQuery = useQuery(
    'accountDetails',
    companyApi.getCurrentBankAccount
  )

  if (paymentDetailsQuery.isLoading) {
    return <Skeleton height="274px" />
  }

  return (
    <div class="">
      <div className="mt-5">
        <p className="text-l text-text-color-grey-bloom font-normal">Bank</p>
        <p className="mt-1 text-l font-normal">
          {paymentDetailsQuery.data?.bankName || 'Not available'}
        </p>
      </div>
      <div className="mt-8">
        <p className="text-l text-text-color-grey-bloom font-normal">
          Sort code
        </p>
        <p className="mt-1 text-l font-normal">
          {paymentDetailsQuery.data?.sortCode || 'Not available'}
        </p>
      </div>
      <div className="mt-8">
        <p className="text-l text-text-color-grey-bloom font-normal">
          Account number
        </p>
        <p className="mt-1 text-l font-normal">
          {paymentDetailsQuery.data?.account || 'Not available'}
        </p>
      </div>
    </div>
  )
}

export default PaymentDetails
