import { useFormik } from 'formik'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import onboardingApi from 'config/api/onboarding'
import {
  updateBankDetails,
  updateIsClickedIntegrationsDone
} from 'redux/actions/Onboarding'
import { getErrorMessage } from 'utils/getErrorMessage'
import bankDetailschema from './schema'

const useBankDetailsHook = ({ onNext }) => {
  const [options, setOptions] = useState([])
  const [shouldShowError] = useState(false)
  const dispatch = useDispatch()

  const dataOnboarding = useSelector((state) => state.dataOnboarding)
  const { currentStep, config } = dataOnboarding

  const step = config[currentStep]
  const textButton = step?.nextStep === 'success' ? 'Done' : 'Next'

  const { isLoading } = useQuery(
    'bankAccountOptions',
    onboardingApi.getBankAccountOptions,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (opts) => {
        setOptions(opts)
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  const formik = useFormik({
    initialValues: {
      id: options?.find((o) => o.selected)?.id || ''
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: bankDetailschema(),
    onSubmit: () => {
      if (onNext) onNext()
    }
  })

  const handleChange = (field, value) => {
    onboardingApi.setSelectedBankAccount(value)
    dispatch(updateBankDetails(field, value))
    formik.setFieldValue(field, value)

    const newOptions = [...options]
    newOptions.forEach((opt) => (opt.selected = opt.id === value))
    setOptions([...newOptions])
  }

  const onBankDetailsNext = () => {
    dispatch(updateIsClickedIntegrationsDone(true))
    formik.handleSubmit()
  }

  return {
    formik,
    isLoading,
    shouldShowError,
    onBankDetailsNext,
    handleChange,
    options,
    textButton
  }
}

export default useBankDetailsHook
