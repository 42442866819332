import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROOT_ROUTES } from 'config/configs/routes'
import { setSignupStep } from 'redux/actions/Auth'

const SignupAction = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const onClick = () => {
    dispatch(setSignupStep({ signupStep: 0 }))
    history.push(ROOT_ROUTES.SIGNUP)
  }

  return (
    <div className="flex items-center">
      <span className="text-dark-grey font-bold">
        Don’t have an account? &nbsp;
      </span>
      <div onClick={onClick} className="cursor-pointer">
        <span className="text-pink-color font-bold border-pink-color border-b hover:opacity-80 transition-opacity">
          Sign up
        </span>
      </div>
    </div>
  )
}

export default SignupAction
