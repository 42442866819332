import * as actionTypes from '../../types/Onboarding'

export const updatePercentage = (value) => {
  return {
    type: actionTypes.UPDATE_PERCENTAGE,
    payload: {
      value
    }
  }
}

export const updateIsClickedIntegrationsDone = (value) => {
  return {
    type: actionTypes.UPDATE_IS_CLICKED_INTEGRATIONS_DONE,
    payload: {
      value
    }
  }
}

export const updateIsRefetchIntegrations = (value) => {
  return {
    type: actionTypes.UPDATE_IS_REFETCH_INTEGRATIONS,
    payload: {
      value
    }
  }
}

export const updateOnboardingConfig = (value) => {
  return {
    type: actionTypes.UPDATE_ONBOARDING_CONFIG,
    payload: {
      value
    }
  }
}

export const updateOnboardingMeta = (value) => {
  return {
    type: actionTypes.UPDATE_ONBOARDING_META,
    payload: {
      value
    }
  }
}

export const updateBankDetails = (field, value) => {
  return {
    type: actionTypes.UPDATE_BANK_DETAILS,
    payload: {
      field,
      value
    }
  }
}

export const updateCompanyDirector = (value) => {
  return {
    type: actionTypes.UPDATE_COMPANY_DIRECTOR,
    payload: {
      value
    }
  }
}

export const updateCompanyDirectorDetails = (value, formIndex) => {
  return {
    type: actionTypes.UPDATE_COMPANY_DIRECTOR_DETAILS,
    payload: {
      value,
      formIndex
    }
  }
}

export const addCompanyDirectorDetails = (formIndex) => {
  return {
    type: actionTypes.ADD_COMPANY_DIRECTOR_DETAILS,
    payload: {
      formIndex
    }
  }
}

export const removeCompanyDirectorDetails = (formIndex) => {
  return {
    type: actionTypes.REMOVE_COMPANY_DIRECTOR_DETAILS,
    payload: {
      formIndex
    }
  }
}

export const setCurrentStepOnboarding = (value) => {
  return {
    type: actionTypes.SET_CURRENT_STEP,
    payload: {
      value
    }
  }
}

export const resetOnboardingState = () => {
  return {
    type: actionTypes.RESET_ONBOARDING_STATE
  }
}

export const updateDocusignSigningStatus = (value) => {
  return {
    type: actionTypes.UPDATE_DOCUSIGN_SIGNING_STATUS,
    payload: {
      value
    }
  }
}

export const updateGocardlessStatus = (value) => {
  return {
    type: actionTypes.UPDATE_GOCARDLESS_STATUS,
    payload: {
      value
    }
  }
}

export const setIntegrations = (value) => {
  return {
    type: actionTypes.SET_INTEGRATIONS,
    payload: {
      value
    }
  }
}

export const setIntegrationStatus = (value) => {
  return {
    type: actionTypes.SET_INTEGRATION_STATUS,
    payload: {
      value
    }
  }
}

export const setOfferExpiration = (value) => {
  return {
    type: actionTypes.SET_OFFER_EXPIRATION,
    payload: {
      value
    }
  }
}
