import React from 'react'

const FormikInputTextGrey = ({
  placeholder,
  values,
  errors,
  shouldValid,
  field,
  type,
  iconPrefixSrc,
  iconPostfixSrc,
  containerClassname,
  inputClassname,
  backgroundColor = 'bg-light-grey',
  content,
  handleChange,
  setFieldValue,
  disabled,
  autoComplete,
  autoFocus,
  noWhiteSpace
}) => {
  const borderColor =
    shouldValid && errors[field]
      ? 'border-error-message'
      : 'border-b-placeholder'
  const inputPadding = iconPrefixSrc ? 'pl-11' : 'pl-4'

  return (
    <div
      className={`flex flex-col justify-center align-center w-full mx-auto ${containerClassname}`}
    >
      <div className="relative flex justify-between align-center">
        {content}
        {iconPrefixSrc && (
          <div className={`absolute left-3 top-1/2 transform -translate-y-1/2`}>
            <img className="w-6 h-6" src={iconPrefixSrc} />
          </div>
        )}
        <input
          id={field}
          className={`flex-grow flex flex-row items-center w-full h-14 rounded-lg text-base ${backgroundColor} 
                      border-1/2 outline-none transition-all placeholder:text-base placeholder:text-placeholder-grey 
                      focus:border-dark-grey focus:border-2 ${inputPadding} ${inputClassname} ${borderColor} ${
                        content ? 'pr-40' : 'pr-8'
                      }`}
          value={
            noWhiteSpace ? values?.[field]?.replace(/\s+/g, '') : values[field]
          }
          placeholder={placeholder}
          onChange={(e) => {
            if (handleChange) handleChange(field, e.target.value)
            if (setFieldValue) setFieldValue(field, e.target.value)
          }}
          type={type}
          disabled={!!disabled}
          autoComplete={autoComplete}
          autoFocus={!!autoFocus}
        />
        {iconPostfixSrc && (
          <img
            className="absolute right-3 top-1/2 w-6 h-6 transform -translate-y-1/2"
            src={iconPostfixSrc}
          />
        )}
      </div>
      {shouldValid && errors[field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {errors[field]}
        </p>
      )}
    </div>
  )
}

export default FormikInputTextGrey
