import zxcvbnAsync from 'zxcvbn-async'

const makePasswordStrengthBar = async (value, initialItems) => {
  return zxcvbnAsync.load({}).then((zxcvbn) => {
    const {
      score,
      feedback: { warning }
    } = zxcvbn(value)

    let passwordBar = {
      message: '',
      score,
      items: initialItems
    }

    if (score === 0 || score === 1) {
      passwordBar = {
        message: warning,
        score,
        items: [
          {
            1: 'bg-pink-color'
          },
          {
            2: 'bg-light-grey-200'
          },
          {
            3: 'bg-light-grey-200'
          },
          {
            4: 'bg-light-grey-200'
          }
        ]
      }
    } else if (score === 2) {
      passwordBar = {
        message: warning,
        score,
        items: [
          {
            1: 'bg-yellow-color'
          },
          {
            2: 'bg-yellow-color'
          },
          {
            3: 'bg-light-grey-200'
          },
          {
            4: 'bg-light-grey-200'
          }
        ]
      }
    } else if (score === 3) {
      passwordBar = {
        message: warning,
        score,
        items: [
          {
            1: 'bg-yellow-color'
          },
          {
            2: 'bg-yellow-color'
          },
          {
            3: 'bg-yellow-color'
          },
          {
            4: 'bg-light-grey-200'
          }
        ]
      }
    } else if (score === 4) {
      passwordBar = {
        message: warning,
        score,
        items: [
          {
            1: 'bg-teal-color'
          },
          {
            2: 'bg-teal-color'
          },
          {
            3: 'bg-teal-color'
          },
          {
            4: 'bg-teal-color'
          }
        ]
      }
    }
    return passwordBar
  })
}

export { makePasswordStrengthBar }
