import arrowDarkGrey from 'config/images/bloom_icons/arrowDarkGrey.svg'
import arrowDatepickerDropdown from 'config/images/bloom_icons/arrowDatePicker.svg'
import arrowDropdown from 'config/images/bloom_icons/arrowDropdown.svg'
import arrowForward from 'config/images/bloom_icons/arrowForward.svg'
import arrowsSort from 'config/images/bloom_icons/arrowsSort.svg'
import calculator from 'config/images/bloom_icons/calculator.svg'
import calendar from 'config/images/bloom_icons/calendar.svg'
import calendarGrey from 'config/images/bloom_icons/calendarGrey.svg'
import cart from 'config/images/bloom_icons/cart.svg'
import checkDarkBlue from 'config/images/bloom_icons/checkDarkBlue.svg'
import circleCheckGreen from 'config/images/bloom_icons/circleCheckGreen.svg'
import circleCheckGrey from 'config/images/bloom_icons/circleCheckGrey.svg'
import circleCheckOrange from 'config/images/bloom_icons/circleCheckOrange.svg'
import circleCheckPink from 'config/images/bloom_icons/circleCheckPink.svg'
import circleCheckPurple from 'config/images/bloom_icons/circleCheckPurple.svg'
import circleCrossRed from 'config/images/bloom_icons/circleCrossRed.svg'
import closeDarkGrey from 'config/images/bloom_icons/closeDarkGrey.svg'
import closeRed from 'config/images/bloom_icons/closeRed.svg'
import connections from 'config/images/bloom_icons/connections.svg'
import currencyPound from 'config/images/bloom_icons/currencyPound.svg'
import debit from 'config/images/bloom_icons/debit.png'
import document from 'config/images/bloom_icons/document.svg'
import docusign from 'config/images/bloom_icons/docusignLogo.svg'
import download from 'config/images/bloom_icons/download.svg'
import email from 'config/images/bloom_icons/email.svg'
import funds from 'config/images/bloom_icons/funds.png'
import globe from 'config/images/bloom_icons/globe.svg'
import gocardless from 'config/images/bloom_icons/gocardlessLogo.svg'
import greenLineChart from 'config/images/bloom_icons/greenLineChart.svg'
import hamburgerMobile from 'config/images/bloom_icons/hamburgerMobile.svg'
import home from 'config/images/bloom_icons/home.svg'
import library from 'config/images/bloom_icons/library.svg'
import lockClosed from 'config/images/bloom_icons/lockClosed.svg'
import lockClosedTeal from 'config/images/bloom_icons/lockClosedTeal.svg'
import logoBloomName from 'config/images/bloom_icons/logoBloomName.svg'
import logoBloomSmall from 'config/images/bloom_icons/logoBloomSmall.svg'
import logoDotAndO from 'config/images/bloom_icons/logoDotAndO.svg'
import logoDotShield from 'config/images/bloom_icons/logoDotShield.svg'
import minus from 'config/images/bloom_icons/minus.svg'
import phone from 'config/images/bloom_icons/phone.svg'
import plus from 'config/images/bloom_icons/plus.svg'
import redBasket from 'config/images/bloom_icons/redBasket.svg'
import redClose from 'config/images/bloom_icons/redClose.svg'
import redColumnChart from 'config/images/bloom_icons/redColumnChart.svg'
import search from 'config/images/bloom_icons/search.svg'
import searchGrey from 'config/images/bloom_icons/searchGrey.svg'
import settings from 'config/images/bloom_icons/settings.svg'
import speakerphone from 'config/images/bloom_icons/speakerphone.svg'
import user from 'config/images/bloom_icons/user.svg'
import chat from 'config/images/header/chat.svg'
import headerClose from 'config/images/header/headerClose.svg'
import headerLogoMobile from 'config/images/header/headerLogoMobile.png'
import heartGreyHeader from 'config/images/header/heartGrey.svg'
import logoBloomAndGoCardless from 'config/images/header/logoBloomAndGoCardless.svg'
import logoBloomAndSeko from 'config/images/header/logoBloomAndSeko.svg'
import logoBloomAndXapix from 'config/images/header/logoBloomAndXapix.svg'
import logoHeaderDestkopDarkGrey from 'config/images/header/logoHeaderDestkopDarkGrey.png'
import bank from 'config/images/onboarding/sidebar/bank.svg'
import directors from 'config/images/onboarding/sidebar/directors.svg'

const icons = {
  headerLogoMobile,
  chat,
  closeRed,
  closeDarkGrey,
  arrowsSort,
  download,
  redBasket,
  redColumnChart,
  greenLineChart,
  bank,
  funds,
  directors,
  debit,
  docusign,
  gocardless,
  circleCheckGrey,
  circleCheckOrange,
  circleCheckPurple,
  circleCheckPink,
  circleCheckGreen,
  circleCrossRed,
  redClose,
  arrowDropdown,
  arrowForward,
  calendar,
  calendarGrey,
  arrowDatepickerDropdown,
  hamburgerMobile,
  headerClose,
  logoHeaderDestkopDarkGrey,
  heartGreyHeader,
  search,
  user,
  email,
  phone,
  lockClosed,
  lockClosedTeal,
  globe,
  currencyPound,
  searchGrey,
  calculator,
  library,
  cart,
  speakerphone,
  checkDarkBlue,
  arrowDarkGrey,
  logoBloomSmall,
  logoBloomName,
  logoDotShield,
  logoDotAndO,
  settings,
  document,
  home,
  connections,
  plus,
  minus,
  logoBloomAndGoCardless,
  logoBloomAndSeko,
  logoBloomAndXapix
}

export default icons
