import * as actionTypes from '../../types/Partner'

const initialState = {}

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PARTNER_DATA:
      return {
        ...state,
        partnerData: action.payload.value
      }
    default: {
      return state
    }
  }
}

export default partnerReducer
