import React from 'react'
import PageTitle from 'components/PageTitle'
import SearchInputGrey from '../../SearchInputGrey'

const CompanyInformationStep1 = ({
  StepTitle,
  StepSubtitle,
  companyBusinessType,
  companyName,
  setCompanyName,
  steps
}) => {
  return (
    <div className="flex flex-col w-full lg:h-full">
      <PageTitle
        stepper={steps}
        isSubtitleTop={true}
        title={<StepTitle companyBusinessType={companyBusinessType} />}
        subtitle={<StepSubtitle companyBusinessType={companyBusinessType} />}
      />

      <SearchInputGrey
        inputProps={{
          value: companyName,
          autoFocus: true,
          placeholder: 'Enter your company name',
          onChange: (e) => setCompanyName(e.target.value)
        }}
      />
    </div>
  )
}

export default CompanyInformationStep1
