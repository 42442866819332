import { useFormik } from 'formik'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import authApi from 'config/api/auth'
import { ROOT_ROUTES } from 'config/configs/routes'
import { getErrorMessage } from 'utils/getErrorMessage'
import forgotPasswordSchema from './schema'

const { LOGIN } = ROOT_ROUTES

const useForgotPasswordFormHook = () => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const history = useHistory()

  const handleForgotPassword = useMutation(authApi.forgotPassword, {
    onSuccess: (res) => {
      toast.success(res.message)
      history.push(LOGIN)
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  //form
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotPasswordSchema(),
    onSubmit: (values) => {
      handleForgotPassword.mutate(values)
    }
  })

  const onForgotPassword = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  return {
    formik,
    shouldShowError,
    onForgotPassword,
    loading: handleForgotPassword.isLoading
  }
}

export default useForgotPasswordFormHook
