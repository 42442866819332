import React from 'react'
import configs from 'config/configs'
import formatCurrency from 'utils/formatCurrency'
import { maybePluralize } from 'utils/textUtil'
import Button from '../Button'

const BestLabel = () => {
  return (
    <div className="absolute top-2.5 z-50 w-full flex justify-center">
      <div className="text-sm bg-purple-bloom text-white px-5 rounded-best font-bold">
        BEST
      </div>
    </div>
  )
}

const RecurringOfferCard = ({
  offer,
  index,
  isActive,
  onClick,
  onNext,
  isLoading
}) => {
  const currentOffer = offer?.totalFee?.months?.[offer?.months]
  return (
    <div
      className={
        `flex flex-col overflow-hidden w-full lg:${
          isActive ? 'w-330px' : 'w-240px'
        } cursor-pointer ${offer?.isBest && isActive && 'pt-5'}`
        //   `lg:absolute ${handlePosition(index, isActive)} flex flex-col overflow-hidden w-full lg:${
        //   isActive ? 'w-330px' : 'w-240px'
        // } cursor-pointer ${offer?.isBest && isActive && 'pt-5'}`
      }
      onClick={() => onClick(index)}
      //onClick is used on mobile
      onMouseOver={() => onClick(index)}
      style={{
        transition: 'top 0.2s ease-in-out 0s',
        filter: `${isActive || index === 1 ? 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2))' : ''}`
      }}
    >
      <div>
        {offer?.isBest && isActive && <BestLabel />}
        <div
          className={`${isActive ? 'h-260px' : 'h-165px'}
            ${(!offer?.isBest || (offer?.isBest && !isActive)) && 'mt-4'} lg:mt-0 rounded-lg border
            ${
              isActive ? 'border-teal-color' : 'border-dark-blue'
            }  transition-all ease-out duration-200 bg-white pb-5 overflow-hidden`}
        >
          <div
            className={`${
              isActive
                ? 'bg-teal-color text-dark-grey '
                : 'bg-dark-grey text-white'
            } flex justify-center items-center h-60px text-2xl font-bold relative`}
          >
            {/* <span>
              Option {index + 1}
              </span> */}
            <span>Immediate Payout</span>
          </div>
          <div className={`py-2.5 px-5`}>
            {isActive ? (
              <>
                <div className="flex justify-center text-4xl font-bold text-dark-grey opacity-80">
                  {formatCurrency(currentOffer?.payoutAmount)}
                </div>
                <div className="flex justify-center text-2xl leading-6 text-dark-grey opacity-80">
                  {maybePluralize(offer?.months, 'month')}
                </div>
                <div className={'flex justify-between mt-3'}>
                  <div className="opacity-80">Bloom Fee</div>
                  {/* <div className="opacity-80">{`Bloom Fee (${currentOffer?.discountAmount})`}</div> */}
                  <div>{formatCurrency(currentOffer?.discountFee)}</div>
                </div>
                {/* <div className={'flex justify-between'}>
                  <div className="opacity-80">Payout Amount</div>
                  <div>{formatCurrency(currentOffer?.payoutAmount)}</div>
                </div> */}
                {/* <div className={`flex justify-between ${!isActive && 'mt-1'} `}>
                  <div className="opacity-80">Monthly Repayment</div>
                  <div>{formatCurrency(currentOffer?.monthlyFee)}</div>
                </div> */}
              </>
            ) : (
              <>
                <div className="flex justify-center text-4xl font-bold text-dark-grey opacity-80">
                  {formatCurrency(offer?.amount)}
                </div>
                <div className="flex justify-center text-2xl leading-6 text-dark-grey opacity-70 mb-2">
                  {maybePluralize(offer?.months, 'month')}
                </div>
              </>
            )}
          </div>
          <div className={`flex justify-center`}>
            <Button
              type="button"
              loading={isLoading}
              variant="button-next"
              className="font-medium"
              affixIcon={configs.icons.arrowForward}
              children={'Next'}
              onClick={onNext}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecurringOfferCard
