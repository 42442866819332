import React, { useEffect, useState } from 'react'
import Button from 'components/Button'
import ImageCard from 'components/ImageCard'
import SearchInputGrey from 'components/SearchInputGrey'
import CommonModal from 'modals/components/CommonModal'

const AddAccountModal = ({
  close,
  rutter,
  getTitle,
  isOpened,
  isEscapePressed,
  extraAccountList,
  openRequestModal,
  getClickedIntegrationProps,
  onSwitchModalsToIntegration
}) => {
  const [search, setSearch] = useState('')
  const [accountList, setAccountList] = useState(extraAccountList)

  useEffect(() => {
    if (search) {
      const newList = extraAccountList?.filter((account) =>
        account?.label?.toLowerCase()?.includes(search.toLowerCase())
      )
      setAccountList(newList)
    } else {
      setAccountList(extraAccountList)
    }
  }, [extraAccountList, search])

  useEffect(() => {
    isEscapePressed && setSearch('')
  }, [isEscapePressed])

  return (
    <CommonModal
      title={' '}
      isOpened={isOpened}
      close={() => {
        setSearch('')
        close()
      }}
      customAddAccountModal
      customStyle="overflow-hidden relative h-full"
    >
      <div>
        <div className="flex flex-col text-center">
          <div className="border rounded-lg p-5">
            <div className="mb-6">
              <SearchInputGrey
                inputProps={{
                  value: search,
                  placeholder: 'Search',
                  onChange: (e) => setSearch(e.target.value)
                }}
                iconRight
              />
            </div>
            <div className="min-h-full">
              {accountList.map((account, i) => (
                <ImageCard
                  key={i}
                  extraAccount
                  rutter={rutter}
                  onClose={close}
                  img={account.img}
                  url={account.url}
                  getTitle={getTitle}
                  title={account.label}
                  platform={account.platform}
                  accountType={'sales accounts'}
                  status={account.connected ?? account.status}
                  getClickedIntegrationProps={getClickedIntegrationProps}
                  onSwitchModalsToIntegration={onSwitchModalsToIntegration}
                />
              ))}
            </div>
          </div>
          <div className="flex absolute bottom-0 left-0 w-full bg-white h-20">
            <Button
              className="m-auto"
              onClick={openRequestModal}
              variant="button-next-pink"
              children={'Don’t see my account'}
            />
          </div>
        </div>
      </div>
    </CommonModal>
  )
}

export default AddAccountModal
