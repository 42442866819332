import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import configs from 'config/configs'
import useWindowSize from 'hooks/useWindowSize'
import { startCaseKeys } from 'utils/startCaseKeys'

const CustomSymbolShape = ({ x, y, fill, borderWidth, borderColor }) => {
  const xOffset = 8
  return (
    <rect
      x={x - xOffset}
      y={y}
      fill={fill}
      strokeWidth={borderWidth}
      stroke={borderColor}
      width={16}
      height={4}
      style={{ marginRight: 8 }}
    />
  )
}

const CategoryDetailChart = ({ data }) => {
  const mobileSize = 640
  const { width } = useWindowSize()
  const maxValue = Math.max(...data.map((item) => item.monthlyAmount))
  const formattedData = data.map((obj) => startCaseKeys(obj))

  return (
    <ResponsiveBar
      data={formattedData}
      indexBy="Month"
      groupMode="grouped"
      keys={['Monthly Amount']}
      colors={[configs.colors.lightBlueBloom]}
      margin={{ top: 80, right: 32, bottom: 56, left: 56 }}
      animate={true}
      minValue={0}
      maxValue={maxValue + 1}
      gridYValues={[0, 20, 40, 60, 80, 100]}
      padding={0.4}
      enableGridX={false}
      enableLabel={false}
      yFormat=" >-.2f"
      axisLeft={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: width < mobileSize ? 90 : 0
      }}
      useMesh={true}
      legends={[
        {
          anchor: 'top-left',
          dataFrom: 'keys',
          direction: 'row',
          justify: false,
          translateX: -16,
          translateY: -48,
          itemsSpacing: 20,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 24,
          itemOpacity: 1,
          symbolSize: 4,
          symbolShape: CustomSymbolShape,
          symbolBorderColor: 'rgba(0, 0, 0, .5)'
        }
      ]}
    />
  )
}

export default CategoryDetailChart
