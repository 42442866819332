import * as Yup from 'yup'

const signatoryDesignationSchema = () => {
  return Yup.object().shape({
    selected_director: Yup.string()
      .trim()
      .transform((value) => value ?? '')
      .required('Director is required'),

    line1: Yup.string().trim().required('Address Line 1 is required'),

    line2: Yup.string().trim().required('Address Line 2 is required'),

    flat_no: Yup.string().trim().nullable(),

    postal_code: Yup.string().trim().required('Postal Code is required')
  })
}

export default signatoryDesignationSchema
