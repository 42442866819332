import React from 'react'
import images from 'config/configs/images'
import Footer from 'layouts/components/Footer'
import Header from 'layouts/components/Header'

const UnauthenticatedLayout = ({
  formContent,
  hasFooter,
  hasHeader,
  screenName
}) => {
  return (
    <>
      {hasHeader && <Header screenName={screenName} />}
      <div className="bg-b-lite h-content-fit-mobile lg:h-content-fit-desktop px-6 md:px-0">
        <div className="container xl:mx-auto flex relative w-full h-full">
          <div className="flex mt-88px sm:mt-0 sm:ml-8 lg:ml-232px xl:ml-0 sm:items-center w-full">
            {formContent}
          </div>
          <img
            src={images.logoLogin}
            className="absolute hidden md:block md:left-57px xl:left-596px top-61px h-login-logo-desktop"
            alt="logoLogin"
          ></img>
          <img
            src={images.logoLoginMobile}
            className="absolute block md:hidden left-0 right-0 ml-auto mr-auto top-450px"
            alt="logoLoginMobile"
          ></img>
        </div>
      </div>
      {hasFooter && <Footer />}
    </>
  )
}

export default UnauthenticatedLayout
