import * as actionTypes from '../../types/App'

export const openSidebar = () => {
  return {
    type: actionTypes.EXPAND_SIDEBAR
  }
}

export const closeSidebar = () => {
  return {
    type: actionTypes.CLOSE_SIDEBAR
  }
}

export const openHeader = () => {
  return {
    type: actionTypes.EXPAND_HEADER
  }
}

export const closeHeader = () => {
  return {
    type: actionTypes.CLOSE_HEADER
  }
}

export const setOnboardingConnections = (payload) => {
  return {
    type: actionTypes.SET_ONBOARDING_CONNECTIONS,
    payload
  }
}
