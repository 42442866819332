import React from 'react'

const Skeleton = ({ height = '5' }) => {
  return (
    <div className="animate-pulse flex">
      <div className="flex-1 space-y-4 py-1">
        <div className={`h-24 lg:h-${height} bg-gray-200 rounded`}></div>
      </div>
    </div>
  )
}

export default Skeleton
