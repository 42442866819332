const estimatedARR = [
  { title: 'Business does not have recurring revenue', value: '0' },
  { title: '£0-£50k ARR', value: '50000' },
  { title: '£50-£100k ARR', value: '100000' },
  { title: '£100k-£500k ARR', value: '500000' },
  { title: '£500k-£1M ARR', value: '1000000' },
  { title: '£1M-£10M ARR', value: '10000000' },
  { title: '£10M+', value: '20000000' }
]

export default {
  estimatedARR
}
