export const INITIALIZE_APP = 'INITIALIZE_APP'
export const INITIALIZE_APP_SUCCESS = 'INITIALIZE_APP_SUCCESS'
export const INITIALIZE_APP_FAILURE = 'INITIALIZE_APP_FAILURE'

export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR'
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'

export const EXPAND_HEADER = 'EXPAND_HEADER'
export const CLOSE_HEADER = 'CLOSE_HEADER'
export const SET_ONBOARDING_CONNECTIONS = 'SET_ONBOARDING_CONNECTIONS'
