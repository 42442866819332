import React from 'react'
import RegistrationStepper from 'components/RegistrationStepper'

const PageTitle = ({ title, subtitle, isSubtitleTop = false, stepper }) => (
  <div className="flex flex-col w-full mb-4 lg:mb-8">
    {isSubtitleTop && (
      <p className="flex justify-center mb-2 lg:text-xl text-center">
        {subtitle}
      </p>
    )}
    {stepper && <RegistrationStepper steps={stepper} />}
    <h1 className="text-xl mb-2 lg:text-40px lg:leading-56px font-black text-center">
      {title}
    </h1>
    {!isSubtitleTop && (
      <span className="lg:text-xl text-center" style={{ lineHeight: '32px' }}>
        {subtitle}
      </span>
    )}
  </div>
)
export default PageTitle
