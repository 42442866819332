import React from 'react'
import Card from 'components/Card'
import CategoriesTable from 'components/Categories/CategoriesTable'
import CategoriesChart from 'components/Charts/CategoriesChart'
import CategoryDetailChart from 'components/Charts/CategoryDetailChart'
import HeaderFilter from 'components/HeaderFilter'
import configs from 'config/configs'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'
import SubNav from 'layouts/components/SubNav'
import formatCurrency from 'utils/formatCurrency'
import useCategoriesHook from './hook'

const AccountAndBalancesCategories = () => {
  const {
    isLoading,
    allCategoriesInflowsGraphData,
    allCategoriesOutflowsGraphData,
    categoryInflowData,
    categoryOutflowData,
    transactionInflowData,
    transactionOutflowData,
    categoryInflowsOptions,
    categoryOutflowsOptions,
    onCategoriesInflowsChange,
    onCategoriesOutflowsChange,
    filterByCategoryInflows,
    filterByCategoryOutflows,
    loadMoreInflowTransactions,
    loadMoreOutflowTransactions
  } = useCategoriesHook()

  if (isLoading) {
    return <AppLayoutSkeleton />
  }

  return (
    <>
      <SubNav />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col bg-white rounded-lg p-6">
          <HeaderFilter
            title="Outflows by category"
            onChange={onCategoriesOutflowsChange}
            defaultValue="12m"
            filterOptions={['3m', '6m', '12m']}
          />
          <div className="mt-6 h-596px sm:h-508px bg-body-grey">
            <CategoriesChart data={allCategoriesOutflowsGraphData} />
          </div>

          <div className="mt-6 relative inline-flex w-full md:w-1/2 lg:w-1/3">
            <img
              src={configs.icons.arrowDropdown}
              className="w-3 h-3 absolute top-0 right-0 m-22px pointer-events-none"
            ></img>

            <select
              onChange={(e) => filterByCategoryOutflows(e.target.value)}
              className="border-1/2 border-grey-button rounded-lg text-gray-600 w-full h-14 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
            >
              {categoryOutflowsOptions.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>

          <div className="mt-6 h-450px bg-body-grey">
            <CategoryDetailChart data={categoryOutflowData.chart} />
          </div>

          <div className="flex flex-row mt-6 justify-between flex-wrap">
            {categoryOutflowData.averages?.map((item, index) => (
              <div className="flex flex-col space-y-1" key={index}>
                <span className="text-sm">{item.title}</span>
                <span className="font-bold text-light-blue-bloom">
                  {formatCurrency(item.value, 2)}
                </span>
              </div>
            ))}
          </div>
          <span className="border border-grey-blue-bloom mt-4" />
          <div className="mt-6">
            <Card
              headerTitle={`${transactionOutflowData.meta.totalCount} transactions`}
              headerClassname="text-text-color-bloom"
              cardClassname="px-0"
              children={
                <CategoriesTable
                  transactions={transactionOutflowData.data.transactions}
                />
              }
            />
            {transactionOutflowData.hasMore && (
              <div
                className="flex justify-center mb-6 items-center bg-white cursor-pointer text-header-table-gray font-medium"
                onClick={() =>
                  loadMoreOutflowTransactions(transactionOutflowData.lastDate)
                }
              >
                Load more
              </div>
            )}
          </div>
        </div>

        <div className="col-span-12 flex flex-col bg-white rounded-lg p-6">
          <HeaderFilter
            title="Inflows by category"
            onChange={onCategoriesInflowsChange}
            defaultValue="12m"
            filterOptions={['3m', '6m', '12m']}
          />
          <div className="mt-6 h-508px bg-body-grey">
            <CategoriesChart data={allCategoriesInflowsGraphData} />
          </div>

          <div className="mt-6 relative inline-flex w-full md:w-1/2 lg:w-1/3">
            <img
              src={configs.icons.arrowDropdown}
              className="w-3 h-3 absolute top-0 right-0 m-22px pointer-events-none"
            ></img>

            <select
              onChange={(e) => filterByCategoryInflows(e.target.value)}
              className="border-1/2 border-grey-button rounded-lg text-gray-600 w-full h-14 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
            >
              {categoryInflowsOptions.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>

          <div className="mt-6 h-450px bg-body-grey">
            <CategoryDetailChart data={categoryInflowData.chart} />
          </div>

          <div className="flex flex-row mt-6 justify-between flex-wrap">
            {categoryInflowData.averages?.map((item, index) => (
              <div className="flex flex-col space-y-1" key={index}>
                <span className="text-sm">{item.title}</span>
                <span className="font-bold text-light-blue-bloom">
                  {formatCurrency(item.value, 2)}
                </span>
              </div>
            ))}
          </div>
          <span className="border border-grey-blue-bloom mt-4" />
          <div className="mt-6">
            <Card
              headerTitle={`${transactionInflowData.meta.totalCount} transactions`}
              headerClassname="text-text-color-bloom"
              cardClassname="px-0"
              children={
                <CategoriesTable
                  transactions={transactionInflowData.data.transactions}
                />
              }
            />
            {transactionInflowData.hasMore && (
              <div
                className="flex justify-center mb-6 items-center bg-white cursor-pointer text-header-table-gray font-medium"
                onClick={() =>
                  loadMoreInflowTransactions(transactionInflowData.lastDate)
                }
              >
                Load more
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountAndBalancesCategories
