import { useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAuthHook from 'components/Auth/hook'
import authApi from 'config/api/auth'
import { REGISTRATION_ROUTES } from 'config/configs/routes'
import { updateCurrentStep } from 'redux/actions/Register'
import { getErrorMessage } from 'utils/getErrorMessage'

const { SUCCESS_REGISTRATION } = REGISTRATION_ROUTES

const useSignupHook = () => {
  const [companyCurrentStep, setCompanyCurrentStep] = useState(0)
  const { eligibility, handleGetEligibility, handleGetCompanyInfo } =
    useAuthHook()
  const dataRegister = useSelector((state) => state.dataRegister)
  const { currentStep } = dataRegister
  const history = useHistory()
  const dispatch = useDispatch()

  const handleCurrentStep = (step) => {
    dispatch(updateCurrentStep(step))
  }

  const register = useMutation(authApi.register, {
    onSuccess: () => {
      handleGetEligibility()
      handleGetCompanyInfo()
      history.push(SUCCESS_REGISTRATION)
    },
    onError: (err) => {
      const errorMsg = getErrorMessage(err)
      toast.error(errorMsg)
      dispatch(errorSavingCompany(true))
    }
  })

  const handleCreateCompanyInfo = (data) => {
    register.mutate(data)
  }

  return {
    currentStep,
    handleCurrentStep,
    handleCreateCompanyInfo,
    eligibility,
    companyCurrentStep,
    setCompanyCurrentStep,
    loading: register.isLoading
  }
}

export default useSignupHook
