import * as Yup from 'yup'

const selectedCompanySchema = () => {
  const companyNameRegex = /[a-zA-Z0-9]+/

  return Yup.object().shape({
    companyName: Yup.string()
      .matches(companyNameRegex, 'Company name is required')
      .required('Company name is required'),
    companyNumber: Yup.string().required('Company number is required'),
    country: Yup.string().required('Country is required'),
    companyType: Yup.string().required('Company type is required'),
    incorporatedOn: Yup.string().required('Incorporated date is required'),
    avgMonthlyIncome: Yup.string().required('Monthly income is required')
  })
}

export default selectedCompanySchema
