import { useState } from 'react'

const useRecurringTableHook = () => {
  let [indexsShow, setIndexsShow] = useState([])

  const handleOnclick = (index) => {
    if (indexsShow.includes(index)) {
      let indexs = indexsShow.filter((item) => item !== index)
      setIndexsShow(indexs)
    } else {
      let indexs = [...indexsShow]
      indexs.push(index)
      setIndexsShow(indexs)
    }
  }

  return {
    indexsShow,
    handleOnclick
  }
}

export default useRecurringTableHook
