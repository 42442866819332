import React from 'react'
import Button from '../Button'
import FormikTextInput from '../FormikInputText'
import useLoginFormHook from './hook'

const LoginForm = () => {
  const { formik, shouldShowError, onLogin, loading, onForgotPassword } =
    useLoginFormHook()

  return (
    <div className="flex flex-col justify-between items-start w-full max-w-496px xl:ml-103px z-10 shadow-login-form">
      <form
        className="flex flex-col justify-end space-y-6 w-full rounded-2xl p-12 pb-16 md:pb-12 bg-white"
        onSubmit={onLogin}
      >
        <span className="text-32px md:text-2xl font-semibold static">
          Log in
        </span>
        <div className="flex flex-col space-y-4">
          <FormikTextInput
            autoFocus
            field="email"
            id="email"
            placeholder="Email"
            shouldValid={shouldShowError}
            {...formik}
          />
          <div className="w-full relative">
            <FormikTextInput
              field="password"
              id="password"
              placeholder="Password"
              inputClassname="w-full md:pr-184px"
              containerClassname="relative"
              shouldValid={shouldShowError}
              type="password"
              {...formik}
            />
            <button
              type="button"
              className="right-0 md:right-4 top-32 lg:top-2 absolute text-b-dark ml-2 font-medium text-sm underline"
              onClick={onForgotPassword}
            >
              Forgot your password?
            </button>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <Button
            type="submit"
            variant="button-login-pink"
            className="w-full md:w-auto"
            loading={loading}
            disabled={!(formik.isValid && formik.dirty)}
          >
            Login
          </Button>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
