import React from 'react'
import PersonalInformation from 'components/PersonalInformation'
import usePersonalFormHook from 'components/PersonalInformation/hook'
import EmailVerification from 'pages/authorisation/EmailVerification'
import { PartnersLayout } from '../../../../layouts'

const PartnersSignUp = ({ partnerData }) => {
  const {
    step,
    userData,
    formik,
    shouldShowError,
    passwordBar,
    onPersonalNext,
    handleChange,
    loading
  } = usePersonalFormHook(partnerData.slug)

  return step === 0 ? (
    <PartnersLayout logo={partnerData?.logo}>
      <div className="container mx-auto px-6 md:px-0">
        <div className="flex justify-center">
          <div className={`flex flex-col w-full md:w-570px relative`}>
            <PersonalInformation
              slug={partnerData?.slug}
              onPersonalNext={onPersonalNext}
              formik={formik}
              shouldShowError={shouldShowError}
              passwordBar={passwordBar}
              handleChange={handleChange}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </PartnersLayout>
  ) : (
    <EmailVerification
      firstName={userData.firstName}
      email={userData.email}
      confirmationSentAt={userData.confirmationSentAt}
      partners={partnerData}
    />
  )
}

export default PartnersSignUp
