import styled, { css } from 'styled-components'

const Logo = styled.div`
  ${(props) => css`
    background-image: ${props.logo};
    background-size: contain;
  `}
`

export default Logo
