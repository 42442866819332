import * as actionTypes from '../../types/Auth'

const initialState = {
  isLoggedIn: false,
  accessToken: null,
  expiresAt: null,
  user: null,
  signupStep: 0,
  accountValidationData: null,
  companyName: null,
  impersonating: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        accessToken: action.payload.token,
        expiresAt: action.payload.expiresAt,
        impersonating: action.payload.impersonating,
        user: action.payload.user
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        accessToken: null,
        expiresAt: null,
        user: null,
        companyName: null
      }
    case actionTypes.UPDATE_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload.companyName
      }
    case actionTypes.SET_SIGNUP_STEP:
      return {
        ...state,
        signupStep: action.payload.signupStep
      }
    default: {
      return state
    }
  }
}
export default authReducer
