import { keys } from 'ramda'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import configs from 'config/configs'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import formatCurrency from 'utils/formatCurrency'
import Button from '../Button'
import ButtonGroup from '../ButtonGroup'
import Card from '../Card'
import useCountdown from '../CountdownTimer/hook'
import ErrorMessage from '../ErrorMessage'
import RecurringOfferCard from './RecurringOfferCard'
import RecurringRepayCard from './RecurringRepayCard'
import SkeletonAvailableFunds from './SkeletonAvailableFunds'
import useAvailableFundsHook from './hook'

const RecurringAvailableFunds = ({ onNext, target }) => {
  const {
    availableFundsData,
    submitOfferForm,
    offerData,
    setOfferData,
    isAccepted,
    error,
    isLoading,
    trackOfferAdjusted
  } = useAvailableFundsHook({ onNext })

  const [viewPage, setViewPage] = useState('offerCards')
  const [activeCard, setActiveCard] = useState(null)
  const [activeRepayCard, setRepayActiveCard] = useState(null)
  const [activeOffer, setActiveOffer] = useState(null)
  const [lastAmount, setLastAmount] = useState()
  const [lastMonths, setLastMonths] = useState()

  const { expired } = useCountdown(target)
  const user = useSelector((state) => state.dataUser)

  useEffect(() => {
    //set best offer as initial offer
    const bestOffer = availableFundsData?.defaults?.find(
      (offer) => offer.isBest
    )
    setActiveOffer(bestOffer)
  }, [availableFundsData])

  useEffect(() => {
    target && expired && setViewPage('offerCards')
  }, [expired, target])

  const repayOptions = offerData?.repayMonthOptions?.slice(-3)
  const repayMonthOptions = offerData?.currentPricing?.months
  const threeRepayOptions = repayOptions?.length === 3

  const saveLastValues = () => {
    setLastAmount(offerData.currentAmount)
    setLastMonths(offerData.currentMonth)
  }

  const handleAmountChange = (value) => {
    let data = {}

    if (availableFundsData?.amountDetails[value]) {
      data.currentAmount = value
      data.currentPricing = availableFundsData.amountDetails[value]
      data.repayMonthOptions = keys(data?.currentPricing?.months)
      let eligibleMonths = []
      Object.keys(data?.currentPricing?.months).forEach((key) => {
        if (data?.currentPricing?.months[key]?.isEligible)
          eligibleMonths.push(key)
      })
      data.currentMonth = parseInt(eligibleMonths[0])

      setOfferData({ ...offerData, ...data })
    }
  }

  const handleMonthChange = (value) => {
    setOfferData({ ...offerData, currentMonth: value })
  }

  const handleTrackEvent = () => {
    if (
      offerData.currentAmount !== lastAmount ||
      offerData.currentMonth !== lastMonths
    ) {
      trackOfferAdjusted(offerData.currentAmount, offerData.currentMonth)
    }
  }

  const handleBackButton = () => {
    setViewPage('offerCards')
    let data = {}
    data.currentAmount = activeOffer?.amount
    data.repayMonthOptions = keys(activeOffer?.totalFee?.months)
    data.currentPricing =
      availableFundsData?.amountDetails?.[activeOffer?.amount]
    data.currentMonth = activeOffer?.months
    //set last active offer as current offer
    setOfferData({ ...offerData, ...data })
  }

  const handleOfferCardClick = (i, offer) => {
    setActiveCard(i)
    setActiveOffer(offer)
    let data = {}

    data.currentAmount = offer?.amount
    data.repayMonthOptions = keys(offer?.totalFee?.months)
    data.currentPricing = availableFundsData?.amountDetails?.[offer?.amount]
    data.currentMonth = offer?.months
    //set active offer as current offer
    setOfferData({ ...offerData, ...data })
  }

  const handleRepayCardClick = (type) => {
    setRepayActiveCard(type)

    setOfferData({ ...offerData, repaymentOption: type })
  }

  const handleRepayBackButton = () => {
    setViewPage('offerCards')
    setRepayActiveCard('')
  }

  if (isLoading || !availableFundsData || !offerData) {
    return <SkeletonAvailableFunds />
  }

  if (error) {
    return (
      <div className="flex flex-col justify-between container pt-6 mb-16">
        <Card headerClassname="text-b-dark font-medium">
          <ErrorMessage error={error}></ErrorMessage>
        </Card>
      </div>
    )
  }
  const renderCustomizeOffer = () => {
    const currentOffer =
      offerData?.currentPricing?.months?.[offerData?.currentMonth]
    return (
      <>
        <div className="flex flex-col">
          <div className="flex flex-col justify-center text-xl lg:text-40px lg:leading-56px font-black text-center">
            {/* changes until the next commented code */}
            {target && !!expired ? (
              <>
                <div>
                  {capitalizeFirstLetter(user?.firstName)}, your offer expired!
                </div>
                {/* Removed as per https://bloomfin.atlassian.net/browse/EN-60 */}
                {/*<div className="flex justify-center mb-2 text-xl leading-26px font-normal">*/}
                {/*  Call us on*/}
                {/*  <a*/}
                {/*    href={`tel:${getPhoneNumber().href}`}*/}
                {/*    className="hover:opacity-80 transition-opacity font-semibold ml-1"*/}
                {/*  >*/}
                {/*    {getPhoneNumber().text}*/}
                {/*  </a>*/}
                {/*</div>*/}
              </>
            ) : (
              <>
                <p>{`You can trade up to ${formatCurrency(
                  availableFundsData?.maxAmount
                    ? availableFundsData.maxAmount
                    : '0'
                )}`}</p>
                <p> of ARR against an immediate payout</p>
              </>
            )}
            {
              // `You have been approved for ${formatCurrency(
              //  availableFundsData?.maxAmount ? availableFundsData.maxAmount : '0'
              //)}`
            }
          </div>
        </div>
        <div className="flex justify-center mt-7 mb-2 text-xl leading-56px">
          {/* We have approved the following trading limits: */}
        </div>
        {offerData ? (
          <>
            <div
              className={`flex flex-col rounded-lg overflow-hidden mb-2 ${
                target && !!expired && 'pointer-events-none'
              }`}
              style={
                target && !!expired
                  ? { filter: 'grayscale(1)' }
                  : { filter: 'grayscale(0)' }
              }
            >
              <div className="flex flex-col lg:flex-row bg-dark-grey text-white py-22px pl-8 pr-6">
                <div className="flex flex-1 flex-col">
                  <div className="flex items-center leading-6">
                    Select Tradable Income
                    <span className="text-32px leading-10 font-black ml-2.5 ">
                      {formatCurrency(
                        offerData.currentAmount &&
                          parseInt(offerData.currentAmount)
                      )}
                    </span>
                  </div>
                  <div className="my-3 lg:w-11/12">
                    {offerData.pricingSteps && offerData.currentAmount ? (
                      <Slider
                        style={{
                          background: isAccepted && '#374151',
                          opacity: isAccepted && '0.8'
                        }}
                        railStyle={{ backgroundColor: '#5D5E6E', height: 4 }}
                        trackStyle={{
                          backgroundColor: isAccepted ? '#FAFAFA' : '#00FFC2',
                          height: 4
                        }}
                        disabled={isAccepted}
                        handleStyle={{
                          width: '24px',
                          height: '24px',
                          top: 0,
                          background: isAccepted ? '#FAFAFA' : '#44D7B6',
                          border: '2px solid #374151',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)'
                        }}
                        value={offerData.currentAmount}
                        min={parseInt(offerData.pricingSteps[0])}
                        max={parseInt(
                          offerData.pricingSteps[
                            offerData.pricingSteps.length - 1
                          ]
                        )}
                        step={
                          offerData?.pricingSteps?.length > 1
                            ? parseInt(
                                offerData.pricingSteps[1] -
                                  offerData.pricingSteps[0]
                              )
                            : 0
                        }
                        dotStyle={{ display: 'none' }}
                        onBeforeChange={saveLastValues}
                        onChange={(e) => handleAmountChange(e)}
                        onAfterChange={(e) => handleTrackEvent(e)}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  className={`flex flex-1 justify-start lg:${
                    threeRepayOptions ? 'justify-between' : 'justify-end'
                  } items-end`}
                >
                  <div
                    className={`h-full pt-4 mr-4 ${!threeRepayOptions && 'pr-5'} mb-2 lg:mr-0`}
                  >
                    Repay over
                  </div>
                  <ButtonGroup
                    isRecurring
                    disabled={isAccepted}
                    options={repayOptions}
                    value={offerData.currentMonth}
                    repayMonthOptions={repayMonthOptions}
                    onChange={(e) => !isAccepted && handleMonthChange(e)}
                  />
                </div>
              </div>
              {/* changes until the next commented code */}
              <div className="bg-white px-8 pb-6 pt-14px">
                {offerData &&
                  offerData.currentPricing &&
                  offerData.currentMonth && (
                    <>
                      <div className={'flex justify-between pb-3px'}>
                        <div>Payout</div>
                        <div>{formatCurrency(currentOffer?.payoutAmount)}</div>
                      </div>
                      <div className={'flex justify-between pb-3px'}>
                        <div>Bloom Fee</div>
                        {formatCurrency(currentOffer?.discountFee)}
                      </div>
                    </>
                  )}
                {/* <div className="bg-white px-8 pb-6 pt-14px">
                {offerData && offerData.currentPricing && offerData.currentMonth && (
                  <>
                    <div className={'flex justify-between pb-3px'}>
                      <div>{`Bloom Fee (${currentOffer?.discountAmount})`}</div>
                      {formatCurrency(currentOffer?.discountFee)}
                    </div>
                    <div className={'flex justify-between pb-3px'}>
                      <div>Payment Amount</div>
                      <div>{formatCurrency(currentOffer?.payoutAmount)}</div>
                    </div>
                  </>
                )} */}
                <div className="flex justify-between flex-col-reverse space-y-4 space-y-reverse mt-8 md:space-x-4 md:flex-row  md:space-y-0">
                  <Button
                    disabled={isAccepted}
                    variant="button-back-grey"
                    onClick={handleBackButton}
                    children="Back"
                    className="font-medium"
                  />
                  <Button
                    variant="button-next"
                    affixIcon={configs.icons.arrowForward}
                    children={'Next'}
                    className="font-medium"
                    type="button"
                    loading={isLoading}
                    onClick={
                      isAccepted ? onNext : () => setViewPage('repayCards')
                    }
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    )
  }
  const renderOfferCards = () => {
    const arraySortedByAmount = availableFundsData?.defaults?.sort(
      (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
    )
    const bestItemIndex = availableFundsData?.defaults?.findIndex(
      (el) => el.isBest
    )
    return (
      <>
        <div className="flex flex-col">
          <div className="flex flex-col text-xl mb-2 lg:text-40px lg:leading-56px font-black justify-center text-center">
            {/* changes until the next commented code */}
            {target && !!expired ? (
              `${capitalizeFirstLetter(user?.firstName)}, your offer expired! `
            ) : (
              <>
                <p>{`You can trade up to ${formatCurrency(
                  availableFundsData?.maxAmount
                    ? availableFundsData.maxAmount
                    : '0'
                )}`}</p>
                <p> of ARR against an immediate payout</p>
              </>
            )}
            {
              // `You have been approved for ${formatCurrency(
              //   availableFundsData?.maxAmount ? availableFundsData.maxAmount : '0'
              // )}`
            }
          </div>
        </div>

        {!isAccepted && (
          <>
            {/* <div className="flex justify-center mb-2 text-xl leading-26px">
              Congratulations! Please select an offer below:
            </div> */}
            {target && !!expired ? (
              <></>
            ) : (
              //   Removed as per https://bloomfin.atlassian.net/browse/EN-60
              // <div className="flex justify-center mb-2 text-xl leading-26px font-normal">
              //   Call us on
              //   <a
              //     href={`tel:${getPhoneNumber().href}`}
              //     className="hover:opacity-80 transition-opacity font-semibold ml-1"
              //   >
              //     {getPhoneNumber().text}
              //   </a>
              // </div>
              <div className="mb-5 text-sm leading-26px lext-left lg:text-center">
                <span
                  className="mb-8 mr-1.5 text-sm text-pink-color underline underline-offset-4 leading-26px hover:cursor-pointer"
                  onClick={() => {
                    setViewPage('customizeOffer')
                  }}
                >
                  Click here
                </span>
                &nbsp;if you want more control over your offer & repayment
                options.
              </div>
            )}
            {arraySortedByAmount && (
              <div
                // className="flex relative mb-64 mt-4 lg:mt-14 flex-col lg:flex-row"
                className={`flex relative mb-64 lg:mb-0 mt-4 lg:mt-0 flex-col lg:flex-row lg:justify-center ${
                  target && !!expired && 'pointer-events-none'
                }`}
                style={
                  target && !!expired
                    ? { filter: 'grayscale(1)' }
                    : { filter: 'grayscale(0)' }
                }
              >
                {/* changes until the next commented code */}
                <RecurringOfferCard
                  key={bestItemIndex}
                  offer={arraySortedByAmount[bestItemIndex]}
                  index={bestItemIndex}
                  isActive={
                    activeCard === bestItemIndex ||
                    (activeCard === null &&
                      arraySortedByAmount[bestItemIndex].isBest)
                  }
                  onClick={(i) =>
                    handleOfferCardClick(i, arraySortedByAmount[bestItemIndex])
                  }
                  onNext={isAccepted ? onNext : () => setViewPage('repayCards')}
                  isLoading={isLoading}
                  isAccepted={isAccepted}
                  activeCard={activeCard}
                />
                {/* {arraySortedByAmount.map((offer, index) => {
                  return (
                    <RecurringOfferCard
                      key={index}
                      offer={offer}
                      index={index}
                      isActive={activeCard === index || (activeCard === null && offer.isBest)}
                      onClick={(i) => handleOfferCardClick(i, offer)}
                      onNext={isAccepted ? onNext : () => setViewPage('repayCards')}
                      isLoading={isLoading}
                      isAccepted={isAccepted}
                      activeCard={activeCard}
                    />
                  );
                })} */}
              </div>
            )}
          </>
        )}
      </>
    )
  }
  const renderRepayCards = () => {
    const monthlyFee =
      offerData?.currentPricing?.months?.[offerData?.currentMonth]?.monthlyFee
    const weeklyFee =
      offerData?.currentPricing?.months?.[offerData?.currentMonth]?.weeklyFee
    return (
      <>
        <div className="flex flex-col">
          <div className="flex text-xl mb-2 lg:text-40px lg:leading-56px font-black justify-center">
            {`You have selected ${formatCurrency(offerData?.currentAmount)}`}
          </div>
        </div>

        <>
          <div className="flex justify-center mb-2 text-xl leading-26px">
            Do you want to repay weekly or monthly?
          </div>
          <div className="flex mb-6 mt-2 flex-col lg:flex-row justify-center gap-7">
            <RecurringRepayCard
              repayAmount={weeklyFee}
              repaymentOption={'weekly'}
              isActive={activeRepayCard === 'weekly'}
              onClick={() => handleRepayCardClick('weekly')}
              isLoading={isLoading}
            />
            <RecurringRepayCard
              repayAmount={monthlyFee}
              repaymentOption={'monthly'}
              isActive={activeRepayCard === 'monthly'}
              onClick={() => handleRepayCardClick('monthly')}
              isLoading={isLoading}
            />
          </div>
          <div className="flex justify-between flex-col-reverse space-y-4 space-y-reverse mt-8 mb-2 md:space-x-4 md:flex-row  md:space-y-0">
            <Button
              variant="button-back-grey"
              onClick={handleRepayBackButton}
              children="Back"
              className="font-medium"
            />
            <Button
              variant="button-next"
              affixIcon={configs.icons.arrowForward}
              children={'Next'}
              className="font-medium"
              type="button"
              disabled={!activeRepayCard}
              loading={isLoading}
              onClick={submitOfferForm}
            />
          </div>
        </>
      </>
    )
  }
  return (
    <div className="flex flex-col justify-between container mb-10">
      <div className={`flex flex-col`}>
        {viewPage === 'customizeOffer' || isAccepted
          ? renderCustomizeOffer()
          : viewPage === 'offerCards'
            ? renderOfferCards()
            : renderRepayCards()}
      </div>
    </div>
  )
}
export default RecurringAvailableFunds
