import React from 'react'
import Footer from 'layouts/components/Footer'
import Header from 'layouts/components/Header'

const CommonLayout = ({
  hasHeader = true,
  hasFooter = true,
  screenName,
  children
}) => {
  return (
    <div>
      {hasHeader && <Header screenName={screenName} />}
      {children}
      {hasFooter && <Footer />}
    </div>
  )
}

export default CommonLayout
