import * as Yup from 'yup'
import passwordValidation from 'utils/passwordValidation'

const changePasswordSchema = () => {
  passwordValidation.customPasswordValidationFunction()
  return Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, passwordValidation.passwordMessageMin)
      .required('Current password is required')
      .password(),
    ...passwordValidation.validationPasswordSchema()
  })
}

export default changePasswordSchema
