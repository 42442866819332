import axios from '../../services/axios'

const getIntegrations = async (type = '') => {
  const category = type.split(' ')[0]
  const response = await axios.get(`/api/integrations/${category}`)

  return response.data
}

const getOnboardingConfig = async () => {
  const response = await axios.get('/api/onboarding/steps')

  return response.data
}

const getOnboardingConnections = async () => {
  const response = await axios.get('/api/onboarding/connections')

  return response.data
}

const getOnboardingOffers = async (isSaaSUser) => {
  const response = await axios.get(
    isSaaSUser ? '/api/onboarding/recurring/offer' : '/api/onboarding/offer'
  )

  return response.data
}

const getGocardlessToken = async () => {
  const response = await axios.get('/api/onboarding/gocardless')

  return response.data
}

const getSignedDocuments = async () => {
  const response = await axios.get('/api/onboarding/signed_documents')

  return response.data
}

const getDocumentsSigningUrl = async (isSaasUser) => {
  const response = await axios.get(
    isSaasUser
      ? '/api/onboarding/recurring/sign-docs'
      : '/api/onboarding/sign-docs'
  )

  return response.data
}

const setSelectedBankAccount = async (bank_account_id) => {
  const response = await axios.put(
    `/api/onboarding/bank-account?id=${bank_account_id}`
  )

  return response.data
}

const finalizeOnboarding = async () => {
  const response = await axios.post(`/api/onboarding/finalize`)

  return response.data
}

const getSelectedBankAccount = async () => {
  const response = await axios.get(`/api/onboarding/bank-account`)

  return response.data
}

const getBankAccountOptions = async () => {
  const response = await axios.get('/api/onboarding/bank-account-options')

  return response.data
}

const getGoCardlessStatus = async () => {
  const response = await axios.get('/api/onboarding/gocardless-status')

  return response.data
}

const setGoCardlessStatus = async (status) => {
  const response = await axios.put(
    `/api/onboarding/set-gocardless-status?status=${status}`
  )

  return response.data
}

const createOffer = async ({ data, isSaaSUser }) => {
  const response = await axios.post(
    isSaaSUser
      ? '/api/onboarding/recurring/accept-offer'
      : '/api/onboarding/accept-offer',
    data
  )
  return response.data
}

const rollback = async () => {
  const response = await axios.post('/api/onboarding/rollback', {})

  return response.data
}

const submitApplication = async () => {
  const response = await axios.post('/api/onboarding/submit-application')

  return response.data
}

const submitRutterIntegrationData = async (data) => {
  const response = await axios.post('/inlr/rtr/connection', data)

  return response.data
}

const submitZuoraData = async (data) => {
  const response = await axios.post('/inlr/zuora/auth', data)

  return response.data
}

const getPublicKeyRutter = async () => {
  const response = await axios.get('/inlr/rtr/public_key')

  return response.data
}

const integrationsRutter = async (data) => {
  const response = await axios.post('/inlr/rtr/user_token', data)

  return response.data
}

const getPlaidLinkToken = async (institutionId) => {
  const response = await axios.get('/inlr/plaid/link_token', {
    params: {
      institution_id: institutionId
    }
  })

  return { ...response.data, institutionId }
}

const exchangePlaidToken = async (data) => {
  const response = await axios.post('/inlr/plaid/public_token', data)

  return response.data
}

export default {
  getIntegrations,
  getOnboardingConfig,
  getGocardlessToken,
  getBankAccountOptions,
  getOnboardingOffers,
  getGoCardlessStatus,
  setGoCardlessStatus,
  finalizeOnboarding,
  getSignedDocuments,
  getDocumentsSigningUrl,
  getOnboardingConnections,
  setSelectedBankAccount,
  getSelectedBankAccount,
  createOffer,
  rollback,
  getPublicKeyRutter,
  integrationsRutter,
  submitApplication,
  submitZuoraData,
  submitRutterIntegrationData,
  getPlaidLinkToken,
  exchangePlaidToken
}
