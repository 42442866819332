import { useFormik } from 'formik'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { requestIntegrationApi } from 'config/api/requestIntegration'
import { getErrorMessage } from 'utils/getErrorMessage'
import { requestIntegrationSchema } from './schema'

export const useRequestIntegrationFormHook = ({ close }) => {
  const requestIntegrationMutation = useMutation(requestIntegrationApi, {
    onSuccess: (res) => {
      toast.success(res.message || 'Your request has been successfully sent')
      if (!!close) {
        close()
      }
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      website: ''
    },
    validateOnChange: false,
    validationSchema: requestIntegrationSchema(),
    onSubmit: (values) => {
      requestIntegrationMutation.mutate({
        platform_name: values.name,
        platform_url: values.website
      })
    }
  })

  return {
    formik,
    submitting: requestIntegrationMutation.isLoading
  }
}
