import * as actionTypes from '../../types/Auth'

export const loginSuccess = (payload) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload
  }
}

export const logout = () => {
  return {
    type: actionTypes.LOGOUT
  }
}

export const updateCompanyName = (payload) => {
  return {
    type: actionTypes.UPDATE_COMPANY_NAME,
    payload
  }
}

export const setSignupStep = (payload) => {
  return {
    type: actionTypes.SET_SIGNUP_STEP,
    payload
  }
}
