import { useCallback, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import onboardingApi from 'config/api/onboarding'
import { MERCHANT_ONBOARDING_ROUTES } from 'config/configs/routes'
import { INTEGRATION_CONNECTED, INTEGRATION_WARNING } from 'enums/Integrations'
import {
  setCurrentStepOnboarding,
  updateIsRefetchIntegrations,
  updateOnboardingConfig,
  updateOnboardingMeta,
  updatePercentage
} from 'redux/actions/Onboarding'
import { getErrorMessage } from 'utils/getErrorMessage'
import { useAnalytics } from '../../hooks/useAnalytics'
import useCountdown from '../CountdownTimer/hook'
import { resolveIcon, resolveUrl } from './util'

const { SUCCESS } = MERCHANT_ONBOARDING_ROUTES

const useOnboardingHook = () => {
  const analytics = useAnalytics()
  const user = useSelector((state) => state.dataUser)

  const dataOnboarding = useSelector((state) => state.dataOnboarding)
  const { currentStep, config, meta, offerExpiration } = dataOnboarding
  const { expired } = useCountdown(offerExpiration?.offerExpiresAt)
  const dispatch = useDispatch()
  const history = useHistory()

  const setCurrentStep = (value) => {
    dispatch(setCurrentStepOnboarding(value))
  }

  const submitApplication = useMutation(
    'submitApplication',
    onboardingApi.submitApplication,
    {
      onSuccess: () => {
        window.location.reload()
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  const handleOnSuccessPage = () => {
    analytics.track(user?.id, 'Application Submitted')
    submitApplication.mutate()
  }

  const redirectToMissingIntegrations = () => {
    const unfinishedIntegration = config
      .map((step, index) => ({
        isDone: [INTEGRATION_CONNECTED, INTEGRATION_WARNING].includes(
          step.status
        ),
        optional: step.optional,
        url: resolveUrl(step.type),
        index
      }))
      .find((item) => !item.isDone && !item.optional)
    setCurrentStep(unfinishedIntegration?.index)
    history.push(unfinishedIntegration?.url)
    return !!unfinishedIntegration
  }

  const onNext = useCallback(() => {
    const current = config[currentStep]
    const nextStep = config.find((step) => step.type === current.nextStep)
    if (nextStep) {
      const index = config.indexOf(nextStep)
      const nextUrl = nextStep.url
      analytics.track(user.id, 'Clicked Button', {
        page: window.location.href,
        nextUrl
      })
      setCurrentStep(index)
      history.push(nextUrl)
    } else {
      const redirected = redirectToMissingIntegrations()
      if (!redirected) {
        const nextUrl = resolveUrl(current.nextStep)
        if (nextUrl === SUCCESS) {
          handleOnSuccessPage()
        } else {
          history.push(nextUrl)
        }
      }
    }
  }, [config, currentStep])

  const onBack = useCallback(() => {
    const current = config[currentStep]
    const previousStep = config.find((step) => step.nextStep === current.type)
    if (!previousStep) return
    const nextUrl = previousStep.url

    analytics.track(user.id, 'Clicked Button', {
      page: window.location.href,
      nextUrl
    })

    const index = config.indexOf(previousStep)
    setCurrentStep(index)
    history.push(nextUrl)
  }, [config, currentStep])

  const onboardingStepConfig = useQuery(
    'config',
    () => onboardingApi.getOnboardingConfig(),
    {
      onSuccess: (res) => {
        const steps = res.steps.map((step) => ({
          type: step.kind,
          title: step.name,
          isDone: step.isDone,
          status: step.status,
          nextStep: step.nextStep,
          optional: step.optional,
          url: resolveUrl(step.kind),
          icon: resolveIcon(step.kind)
        }))

        batch(() => {
          dispatch(updateOnboardingConfig(steps))
          dispatch(updateOnboardingMeta(res.meta))
          dispatch(updatePercentage(res.percentage))
        })
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  useEffect(() => {
    let isRefetching = currentStep < 4
    dispatch(updateIsRefetchIntegrations(isRefetching))
  }, [currentStep])

  return {
    onBack,
    onNext,
    setCurrentStep,
    onboardingSteps: config,
    onboardingMeta: meta,
    isOfferExpired: !!expired,
    offerExpiresAt: offerExpiration?.offerExpiresAt,
    loadingOnboardingSteps: onboardingStepConfig.isLoading
  }
}

export default useOnboardingHook
