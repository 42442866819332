import axiosDefault from 'axios'
import { jwtDecode } from 'utils/jwtDecode'
import axios from '../../services/axios'

const login = async (user) => {
  const response = await axios.post(`/api/login`, user)

  if (typeof woopra !== 'undefined' && response.status === 200) {
    const id = jwtDecode(response.data.token).sub
    woopra.identify({
      id: id,
      email: user.email
    })

    woopra.track('signin', {
      email: user.email
    })
  }

  return response.data
}

const forgotPassword = async (data) => {
  const response = await axios.post(`/api/auth/forgot_password`, data)
  return response.data
}

const resetPassword = async (data) => {
  const { password, passwordConfirmation, token } = data
  const config = { headers: { Authorization: `Bearer ${token}` } }
  const response = await axiosDefault.post(
    `/api/auth/reset_password`,
    {
      password,
      password_confirmation: passwordConfirmation
    },
    config
  )
  return response.data
}

const changePassword = async (data) => {
  const response = await axios.post(`/api/user/change_password`, data)
  return response.data
}

const logout = async () => {
  const response = await axios.post(`/api/logout`)
  return response.data
}

const register = async (data) => {
  const response = await axios.post('/api/register/company-information', data)

  if (typeof woopra !== 'undefined' && response === 200) {
    woopra.track('registered_company', {
      name: data.companyName,
      company_no: data.companyNumber,
      inc_date: data.incorporatedOn,
      country: data.country,
      type: data.companyType,
      revenue: data.avgMonthlyIncome,
      website: data.website
    })
  }

  return response.data
}

const createPersonalInfo = async (data = '') => {
  const response = await axios.post('/api/register/user', data)

  if (typeof woopra !== 'undefined' && response.status === 200) {
    const id = jwtDecode(response.data.token).sub

    woopra.identify({
      id: id,
      email: data.email,
      name: data.firstName + ' ' + data.lastName,
      phone: data.phone,
      keep_me_informed: data.keepInformed
    })

    woopra.track('signup', {
      email: data.email,
      name: data.firstName + ' ' + data.lastName,
      phone: data.phone,
      keep_me_informed: data.keepInformed
    })
  }

  return response.data
}

const getEligibility = async () => {
  const response = await axios.get('/api/register/validate')
  return response.data
}

const refreshToken = async () => {
  const response = await axios.get('/api/refresh-token')
  return response.data
}

const getCompanyInfo = async () => {
  const response = await axios.get('/api/company-information')
  return response.data
}

const resendVerificationEmail = async (email) => {
  const response = await axios.post('/api/resend_confirmation_email', { email })
  return response.data
}

export default {
  login,
  logout,
  register,
  refreshToken,
  getEligibility,
  createPersonalInfo,
  getCompanyInfo,
  forgotPassword,
  resetPassword,
  changePassword,
  resendVerificationEmail
}
