import _ from 'lodash'
import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { accountsToInstitutions } from 'components/Integrations/Plaid/helper'
import onboardingApi from 'config/api/onboarding'
import { useAnalytics } from 'hooks/useAnalytics'

export const usePlaid = () => {
  const analytics = useAnalytics()
  const user = useSelector((state) => state.dataUser)

  const [selectedBankToken, setSelectedBankToken] = useState(null)
  const [institutions, setInstitutions] = useState({})
  const [loading, setLoading] = useState(true)

  const requestToken = _.debounce((institutionId) => {
    !!selectedBankToken && setSelectedBankToken(null)
    setSelectedBankToken(institutions[institutionId ?? 'default'].token)
  }, 500)

  const getBankAccounts = useQuery(
    'bankAccountOptions',
    onboardingApi.getBankAccountOptions,
    {
      refetchOnWindowFocus: false,
      onSuccess: (accounts) => {
        const institutions = accountsToInstitutions(accounts)
        Promise.all([
          ...Object.keys(institutions).map((institutionId) =>
            onboardingApi.getPlaidLinkToken(institutionId)
          ),
          onboardingApi.getPlaidLinkToken(null)
        ]).then((response) => {
          if (!response.length) return

          response.forEach((tokenResponse) => {
            if (!tokenResponse.institutionId) {
              institutions['default'] = {
                token: tokenResponse.linkToken,
                status: null
              }
            } else {
              institutions[tokenResponse.institutionId].token =
                tokenResponse.linkToken
              institutions[tokenResponse.institutionId].status =
                tokenResponse.status
            }
          })

          setInstitutions(institutions)
          setLoading(false)
        })
      },
      cacheTime: 0
    }
  )

  const exchangePlaidToken = useMutation(onboardingApi.exchangePlaidToken, {
    onSuccess: () => {
      getBankAccounts.refetch()
    }
  })

  const onSuccess = useCallback((error, metadata) => {
    setLoading(true)
    analytics.track(user.id, 'Integration Status', {
      category: 'banking',
      status: 'start',
      name: 'Plaid'
    })
    exchangePlaidToken.mutate(metadata)
  }, [])

  const onExit = () => {
    setSelectedBankToken(null)
  }

  return {
    onExit,
    loading,
    onSuccess,
    requestToken,
    institutions,
    selectedBankToken
  }
}
