import React from 'react'
import InfoCard from 'components/InfoCard'
import Skeleton from 'components/Skeleton'
import configs from 'config/configs'
import Sidebar from 'layouts/components/Sidebar'
import YourBusinessProgressLinear from './YourBusinessProgressLinear'
import useSidebarOnboardingHook from './hook'

const SidebarOnboarding = ({
  shouldShowOnboardingSidebar,
  loadingOnboardingSteps,
  setCurrentStep
}) => {
  const { currentStep, sidebarSteps, meta, cardDescription } =
    useSidebarOnboardingHook()
  const headerChildClass = !shouldShowOnboardingSidebar ? 'hidden' : ''
  const doneSteps = sidebarSteps.map(({ isDone, status }) => status ?? isDone)

  return (
    <div className={`${headerChildClass} flex flex-col w-full lg:w-1/3`}>
      <div className={`flex flex-col bg-dark-blue text-white rounded-lg`}>
        <div className="pt-38px">
          <YourBusinessProgressLinear
            title="Your progress"
            doneSteps={doneSteps}
          />
        </div>

        {loadingOnboardingSteps ? (
          <Skeleton height="96" />
        ) : (
          <Sidebar
            steps={sidebarSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            options={{ ...meta, showTick: true }}
          />
        )}
      </div>

      {meta?.showInfoCard && (
        <InfoCard
          image={configs.icons.logoDotShield}
          description={cardDescription()}
        />
      )}
    </div>
  )
}

export default SidebarOnboarding
