export const ROOT_URL = 'https://www.letsbloom.com'
export const HELP_URL = 'https://help.letsbloom.com/kb/en'
export const XERO_URL = 'https://go.xero.com/GeneralLedger/ChartOfAccounts.aspx'

export const PHONE_NUMBER = {
  href: '+442045794867',
  text: '+44 204 579 4867',
  gocardless: {
    href: '+442039368922',
    text: '+44 203 936 8922'
  },
  seko: {
    href: '+442045794867',
    text: '0204 579 4867'
  }
}

export const SAAS_BUSINESS_TYPE = 'recurring'
export const ECOMMERCE_BUSINESS_TYPE = 'ecommerce'

export const GOCARDLESS = 'gocardless'
export const XAPIX = 'xapix'
export const SEKO = 'seko'
export const XERO = 'xero' // for POC

export const SUBDOMAINS_WITHOUT_BUSINESS_TYPE = [XAPIX]

export const websiteRegex =
  /((https?):\/\/)?(www.)?([a-zA-Z0-9-\.]+)?[a-zA-Z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
