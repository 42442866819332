import React from 'react'
import { CommonLayout } from '../../layouts'

const NotFoundPage = ({ name }) => {
  return (
    <CommonLayout hasHeader={true} hasFooter={false} screenName={name}>
      <div className="flex flex-col w-full h-full justify-center items-center">
        <p className="text-3xl mt-232px text-b-purple font-bold">404</p>
        <p className="text-xl font-bold text-pink-bloom">Page not found</p>
        <p className="mt-5">Where do you want to go next?</p>
      </div>
    </CommonLayout>
  )
}

export default NotFoundPage
