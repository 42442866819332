import React, { useState } from 'react'

const HeaderFilter = ({ title, onChange, filterOptions, defaultValue }) => {
  const [itemActive, setItemActive] = useState(defaultValue)

  const handleClick = (item) => {
    setItemActive(item)
    if (onChange) onChange(item)
  }

  return (
    <div className="flex flex-row justify-between h-14 shadow-card-header-white">
      {title && <span className="font-semibold py-2">{title}</span>}
      <div className="flex flex-row space-x-2 h-10 overflow-x-auto w-2/3 sm:w-auto">
        {filterOptions.map((item, index) => (
          <span
            className={`px-4 py-2 cursor-pointer ${itemActive === item && 'bg-grey-blue-bloom rounded-lg'}`}
            key={index}
            onClick={() => handleClick(item)}
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  )
}

export default HeaderFilter
