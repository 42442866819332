import axios from '../../services/axios'

const getTotalInflowsMonthly = async () => {
  const response = await axios.get('/api/accounts-balances/inflows/predicted')

  return response.data
}

const getRecurringInflows = async (lastDate) => {
  const response = await axios.get(
    `/api/accounts-balances/inflows/recurring${!lastDate ? '' : `?last_date=${lastDate}`}`
  )

  return response.data
}

const getRegularInflows = async (lastDate) => {
  const response = await axios.get(
    `/api/accounts-balances/inflows/regular${!lastDate ? '' : `?last_date=${lastDate}`}`
  )

  return response.data
}

const getTotalOutflowsMonthly = async () => {
  const response = await axios.get('/api/accounts-balances/outflows/predicted')

  return response.data
}

const getRecurringOutflows = async (lastDate) => {
  const response = await axios.get(
    `/api/accounts-balances/outflows/recurring${!lastDate ? '' : `?last_date=${lastDate}`}`
  )

  return response.data
}

const getRegularOutflows = async (lastDate) => {
  const response = await axios.get(
    `/api/accounts-balances/outflows/regular${!lastDate ? '' : `?last_date=${lastDate}`}`
  )

  return response.data
}

const getBalancesOverview = async () => {
  const response = await axios.get('/api/accounts-balances/overview')

  return response.data
}

const getBalancesOverviewByPeriod = async (period) => {
  const response = await axios.get(
    `/api/accounts-balances/overview/balances?period=${period}`
  )

  return response.data
}

const getTransactions = async (
  account = '',
  category = 'all',
  search = '',
  lastDate = ''
) => {
  const response = await axios.get(
    `/api/accounts-balances/transactions?account_number=${account}&category=${category}&search=${search}&last_date=${lastDate}`
  )

  return response.data
}

const getTransactionCategories = async () => {
  const response = await axios.get(
    `/api/accounts-balances/transactions/categories`
  )

  return response.data
}

const getAccounts = async () => {
  const response = await axios.get(`/api/accounts-balances/accounts`)

  return response.data
}

const getInflowsVsOutflows = async (period = 3) => {
  const response = await axios.get(
    `/api/accounts-balances/overview/balances?period=${period}`
  )

  return response.data
}

const getCategoryFlows = async (type, period) => {
  const response = await axios.get(
    `/api/accounts-balances/categories/flows?type=${type}&period=${period}`
  )

  return response.data
}

const getCategoryInformation = async (type, category) => {
  const categoryEncoded = encodeURIComponent(category)
  const response = await axios.get(
    `/api/accounts-balances/categories?type=${type}&category=${categoryEncoded}`
  )

  return response.data
}

const getTransactionFlows = async (category, type, lastDate = '') => {
  const categoryEncoded = encodeURIComponent(category)
  const url = `/api/accounts-balances/transactions/flows?category=${categoryEncoded}&direction=${type}&last_date=${lastDate}`
  const response = await axios.get(url)

  return response.data
}

export default {
  getTotalInflowsMonthly,
  getRecurringInflows,
  getRegularInflows,
  getTotalOutflowsMonthly,
  getRecurringOutflows,
  getRegularOutflows,
  getCategoryFlows,
  getCategoryInformation,
  getBalancesOverview,
  getBalancesOverviewByPeriod,
  getTransactions,
  getTransactionCategories,
  getAccounts,
  getInflowsVsOutflows,
  getTransactionFlows
}
