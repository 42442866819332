import React from 'react'

const FormikInputCheckbox = ({
  values,
  errors,
  field,
  handleChange,
  inputClassname,
  shouldValid,
  content
}) => {
  return (
    <React.Fragment>
      <div className="flex flex-row space-x-4 items-center">
        <input
          className={`w-6 h-6 ${inputClassname}`}
          type="checkbox"
          value={values[field]}
          onChange={(e) => {
            const valueBool = e.target.value === 'true'
            handleChange(field, !valueBool)
          }}
          checked={values[field]}
        />
        {content}
      </div>
      {shouldValid && errors[field] && (
        <p className="text-error-message text-sm mt-2">{errors[field]}</p>
      )}
    </React.Fragment>
  )
}

export default FormikInputCheckbox
