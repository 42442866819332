import useAuthHook from '../../../components/Auth/hook';

const useHeaderDropdownHook = () => {
  const { handleLogout } = useAuthHook();

  const onClickLogout = () => {
    handleLogout();
  };

  // Actions for header dropdown
  // If action is "navigate", then it will generate an a tag with "to" as href
  // If action is "click", then it will trigger property "onClick"
  const dropdownItems = [
    {
      title: 'Log out',
      action: 'click',
      onClick: onClickLogout,
    },
  ];

  return {
    dropdownItems,
    handleLogout,
  };
};

export default useHeaderDropdownHook;
