import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { keys } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import onboardingApi from 'config/api/onboarding'
import { useAnalytics } from 'hooks/useAnalytics'
import { setOfferExpiration } from 'redux/actions/Onboarding'
import { getErrorMessage } from 'utils/getErrorMessage'
import SignatorySchema from './schema'

const useAvailableFundsHook = ({ onNext }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const [isAuthorised, setIsAuthorised] = useState(true)
  // this was part of an authorised signatory thing
  // const submitButtonName = isAuthorised ? 'ACCEPT AND SIGN' : 'DONE';
  const [isAccepted, setIsAccepted] = useState(false)
  const user = useSelector((state) => state.dataUser)
  const persistedDataRegister = useSelector(
    (state) => state.persistedDataRegister
  )
  const { isSaaSUser } = persistedDataRegister

  const analytics = useAnalytics()
  const dispatch = useDispatch()

  const [offerData, setOfferData] = useState({
    pricingSteps: [],
    currentPricing: {},
    currentAmount: 0,
    currentMonth: 0,
    repayMonthOptions: [],
    repaymentOption: ''
  })

  const [availableFundsData, setAvailableFundsData] = useState(null)

  const offerExpiresAt =
    availableFundsData?.expiresAt &&
    dayjs(availableFundsData?.expiresAt).format()
  const offerDateFormated = dayjs(offerExpiresAt)
  const isOfferExpired = offerDateFormated.diff(dayjs()) < 0

  const setExpirationInfo = (value) => {
    dispatch(setOfferExpiration(value))
  }

  useEffect(() => {
    if (availableFundsData?.expiresAt) {
      const value = {
        isOfferExpired,
        offerExpiresAt
      }
      setExpirationInfo(value)
    }
  }, [offerExpiresAt, isOfferExpired])

  const onboardingData = useQuery(
    'availableFunds',
    () => onboardingApi.getOnboardingOffers(isSaaSUser),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (res) {
          setAvailableFundsData(res)
          setIsAccepted(res.amount && res.duration)
        }
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
      }
    }
  )

  const createOffer = useMutation(onboardingApi.createOffer, {
    onSuccess: () => {
      if (onNext) onNext()
    },
    onError: (err) => {
      const errorMsg = getErrorMessage(err)
      toast.error(errorMsg)
    }
  })

  //form
  const formik = useFormik({
    initialValues: {
      name: '',
      position: '',
      email: '',
      confirmPerson: false
    },
    validationSchema: SignatorySchema(),
    onSubmit: (values) => {
      let data = {
        amount: offerData.currentAmount,
        duration: offerData.currentMonth,
        repaymentOption: offerData.repaymentOption,
        is_authorized: false,
        signatory: {
          name: values.name,
          position: values.position,
          email: values.email
        }
      }

      createOffer.mutate({ data, isSaaSUser })
    }
  })

  const submitOfferForm = () => {
    let formData = {
      amount: offerData.currentAmount,
      duration: offerData.currentMonth,
      repaymentOption: offerData.repaymentOption,
      is_authorized: isAuthorised
    }
    if (isAuthorised === false) {
      setShouldShowError(true)
      formik.handleSubmit()
    } else {
      createOffer.mutate({ data: formData, isSaaSUser })
    }
  }

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const makeInitialOfferData = useCallback(() => {
    let data = {}
    const bestOffer = availableFundsData?.defaults?.find(
      (offer) => offer.isBest
    )
    data.pricingSteps = keys(availableFundsData?.amountDetails)
    data.currentPricing =
      availableFundsData?.amountDetails[
        availableFundsData?.amount || availableFundsData?.defaultAmount
      ]
    data.repayMonthOptions = availableFundsData?.amount
      ? keys(
          availableFundsData?.amountDetails?.[availableFundsData.amount]?.months
        )
      : keys(availableFundsData?.amountDetails?.[bestOffer?.amount]?.months) ||
        keys(data?.currentPricing?.months)
    data.currentAmount =
      availableFundsData?.amount ||
      bestOffer?.amount ||
      availableFundsData?.defaultAmount
    data.currentMonth =
      availableFundsData?.duration ||
      bestOffer?.months ||
      parseInt(keys(data?.currentPricing?.months)[0])

    setOfferData(data)
  }, [availableFundsData])

  useEffect(() => {
    if (availableFundsData) {
      makeInitialOfferData()
      if (user) {
        analytics.track(user.id, 'Viewed Offer', {
          minAmount: availableFundsData?.minAmount,
          maxAmount: availableFundsData?.maxAmount
        })
      }
    }
  }, [availableFundsData, user])

  const trackOfferAdjusted = (amount, months) => {
    analytics.track(user.id, 'Adjusted Offer', {
      amount,
      months
    })
  }

  return {
    formik,
    onNext,
    offerData,
    isAccepted,
    isAuthorised,
    setOfferData,
    handleChange,
    offerExpiresAt,
    isOfferExpired,
    shouldShowError,
    submitOfferForm,
    setIsAuthorised,
    availableFundsData,
    trackOfferAdjusted,
    error: onboardingData.error || createOffer.error,
    isLoading: onboardingData.isLoading || createOffer.isLoading
  }
}

export default useAvailableFundsHook
