import { T, always, cond, propEq } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import configs from 'config/configs'
import { XERO } from 'config/configs/constant'
import { INTEGRATION_CONNECTED, INTEGRATION_WARNING } from 'enums/Integrations'
import useModal from 'hooks/useModal'
import AddAccountModal from '../../modals/AddAccountModal/AddAccountModal'
import { RequestIntegrationModal } from '../../modals/AddAccountModal/RequestIntegrationModal'
import Button from '../Button'
import ErrorMessage from '../ErrorMessage'
import ImageCard, { IntegrationModal } from '../ImageCard'
import OpenBanking from './OpenBanking'
import SkeletonOnboardingLayout from './SkeletonIntegrations'
import useIntegrationsHook from './hook'
import { useConnectedActions } from './useConnectedActions'

const SalesContent = always(
  'Securely reading your sales data helps us predict your future revenue. The more data we have about your business performance, the more capital we can provide.'
)

const MaketingContent = always(
  'Securely reading your marketing data helps us predict your future potential. The more data we have about your business, the more capital we can provide.'
)

const PlatformsContent = always(
  'Securely reading your financial data helps us confirm your eligibility. The more data we have about your business, the more capital we can provide.'
)

const Content = cond([
  [propEq('accountType', 'sales accounts'), SalesContent],
  [propEq('accountType', 'marketing accounts'), MaketingContent],
  [propEq('accountType', 'accounting platforms'), PlatformsContent],
  [T, '']
])

// TODO: Refactor modal stacking mechanism.
const Integrations = ({
  type,
  accountType,
  currentStep,
  onNext,
  onBack,
  nextText = 'Next',
  skipText = 'Skip',
  hasTitle = true,
  hasNext = true,
  hasContent = true
}) => {
  const {
    rutter,
    isLoading,
    accountList,
    integrationsError,
    extraAccountList,
    showIntegration
  } = useIntegrationsHook(accountType)

  const {
    isOpened: isAddAccountModalOpened,
    open: openAddAccountModal,
    close: closeAddAccountModal,
    isEscapePressed: isEscapePressed
  } = useModal()

  const {
    isOpened: isIntegrationModalOpened,
    open: openIntegrationModal,
    close: closeIntegrationModal
  } = useModal()
  const {
    isOpened: isRequestModalOpened,
    open: openRequestModal,
    close: closeRequestModal
  } = useModal()
  // TODO: POC only! make it more extendable.
  const { track, getComponent } = useConnectedActions()
  const [integrationProps, setIntegrationProps] = useState({
    img: '',
    title: ''
  })
  const dataOnboarding = useSelector((state) => state.dataOnboarding)
  const { config } = dataOnboarding
  useEffect(() => {
    if (accountList.length) {
      track(accountList)
    }
  }, [accountList])

  const handleOpenRequestIntegrationModal = () => {
    openRequestModal()
    closeAddAccountModal()
  }

  const onSwitchModalsToIntegration = () => {
    closeAddAccountModal()
    openIntegrationModal()
  }
  const step = config?.find((item) => item.type === type)
  const isOptional = step?.optional
  const isDone = [INTEGRATION_CONNECTED, INTEGRATION_WARNING].includes(
    step?.status
  )

  // TODO: Remove eslint-disable-line in the future
  const [openXeroModal, closeXeroModal, isXeroOpened, XeroModal] = // eslint-disable-line
    getComponent(XERO)
  const isNextButtonDisabled =
    (accountType === 'bank accounts' &&
      accountList &&
      !accountList[0]?.connected) ||
    isLoading

  return (
    <div className="flex flex-col">
      <AddAccountModal
        rutter={rutter}
        close={closeAddAccountModal}
        isOpened={isAddAccountModalOpened}
        extraAccountList={extraAccountList}
        onSwitchModalsToIntegration={onSwitchModalsToIntegration}
        getClickedIntegrationProps={(img, title) => {
          setIntegrationProps({ img, title })
        }}
        openRequestModal={handleOpenRequestIntegrationModal}
        isEscapePressed={isEscapePressed}
      />
      <RequestIntegrationModal
        isOpened={isRequestModalOpened}
        close={closeRequestModal}
      />
      <IntegrationModal
        rutter={rutter}
        image={integrationProps?.img}
        close={closeIntegrationModal}
        isOpened={isIntegrationModalOpened}
        integration={integrationProps?.title}
      />
      <XeroModal />
      {integrationsError && <ErrorMessage error={integrationsError} />}
      {accountList && (
        <div className="flex flex-col">
          {hasTitle && (
            <h1 className="text-xl mb-2 lg:text-40px lg:leading-56px font-black">
              Connect your{' '}
              {accountType?.includes('accounting')
                ? 'finance accounts'
                : accountType}
            </h1>
          )}
          {accountType !== 'bank accounts' ? (
            <>
              {hasContent && (
                <div className="mb-8">
                  <span className="lg:text-xl lg:leading-8">
                    <Content accountType={accountType} />
                  </span>
                </div>
              )}
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
                {isLoading ? (
                  <SkeletonOnboardingLayout />
                ) : (
                  accountList
                    .filter(showIntegration)
                    .map((account) => (
                      <ImageCard
                        key={account.label}
                        accountType={accountType}
                        title={account.label}
                        rutter={rutter}
                        platform={account.platform}
                        img={account.img}
                        url={account.url}
                        status={account.connected ?? account.status}
                        onConnectedAction={
                          account.label.toLowerCase() === XERO && openXeroModal
                        }
                      />
                    ))
                )}
              </div>
            </>
          ) : (
            accountList && (
              <OpenBanking accountList={accountList} isLoading={isLoading} />
            )
          )}
        </div>
      )}
      {hasNext && (
        <div className="flex flex-row justify-between mt-35px">
          {currentStep === 0 ? (
            <Button
              onClick={openAddAccountModal}
              className="truncate"
              variant="button-add-account"
              children="Add Sales Account"
            />
          ) : (
            <Button
              variant="button-back-grey"
              onClick={onBack}
              children="Back"
            />
          )}
          <Button
            variant="button-next-pink"
            affixIcon={configs.icons.arrowForward}
            onClick={onNext}
            children={isOptional && !isDone ? skipText : nextText}
            className="ml-auto"
            disabled={isNextButtonDisabled}
          />
        </div>
      )}
    </div>
  )
}

export default Integrations
