import { useQuery } from 'react-query'
import accountsAndBalancesApi from 'config/api/accountsAndBalances'

export const useTransactionFiltersHook = () => {
  const getTransactionCategories = useQuery('getTransactionCategories', () =>
    accountsAndBalancesApi.getTransactionCategories()
  )

  const getAccounts = useQuery('getAccounts', () =>
    accountsAndBalancesApi.getAccounts()
  )

  return {
    categories: getTransactionCategories.data,
    categoriesLoading: getTransactionCategories.isLoading,
    categoriesError: getTransactionCategories.isError,
    accounts: getAccounts.data ? getAccounts.data.data.accounts : [],
    accountsLoading: getAccounts.isLoading,
    accountsError: getAccounts.isError
  }
}
