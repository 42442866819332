import { useQuery } from 'react-query'
import { v4 as uuid } from 'uuid'
import Card from 'components/Card'
import DocumentDetails from 'components/DocumentDetails'
import ErrorMessage from 'components/ErrorMessage'
import Skeleton from 'components/Skeleton'
import documentsApi from 'config/api/documents'

const Documents = () => {
  const getDocumentsQuery = useQuery('documents', () =>
    documentsApi.getDocuments()
  )

  return (
    <>
      <div className="text-3xl font-bold">Documents</div>
      {getDocumentsQuery.isError ? (
        <div className="flex flex-col justify-between container pt-6 mb-16">
          <Card headerClassname="text-b-dark font-medium">
            <ErrorMessage error={getDocumentsQuery.isError}></ErrorMessage>
          </Card>
        </div>
      ) : (
        <>
          {getDocumentsQuery.isLoading && (
            <div className="mt-5">
              <Skeleton height="274px" />
            </div>
          )}
          {!getDocumentsQuery.isLoading &&
            getDocumentsQuery.data.length === 0 && (
              <div className="mt-5">You have no documents.</div>
            )}
          {getDocumentsQuery.data?.map((document) => (
            <DocumentDetails key={uuid()} document={document} />
          ))}
        </>
      )}
    </>
  )
}

export default Documents
