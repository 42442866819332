import React from 'react'
import { useSelector } from 'react-redux'
import Footer from 'layouts/components/Footer'
import Header from 'layouts/components/Header'

const AuthorisationLayout = ({
  hasHeader = true,
  hasFooter = true,
  screenName,
  headerBackground = 'bg-white',
  children
}) => {
  const { headerExpanded } = useSelector((state) => state.dataApp)
  const headerExpandedClass = headerExpanded && 'h-screen'

  return (
    <div
      className={`min-h-screen text-dark-grey ${headerExpandedClass} flex flex-col`}
    >
      {hasHeader && (
        <Header screenName={screenName} backgroundColor={headerBackground} />
      )}
      <div className="bg-light-grey-100" style={{ flexGrow: '1' }}>
        {children}
      </div>
      {hasFooter && <Footer />}
    </div>
  )
}

export default AuthorisationLayout
