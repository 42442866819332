import React, { useEffect, useState } from 'react'
import CommonModal from '../../../../modals/components/CommonModal'
import { StepOne } from './StepOne'
import { StepThree } from './StepThree'
import { StepTwo } from './StepTwo'
import { useXeroData } from './useXeroData'

const MODAL_STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3
}

export function XeroConnectedModal(props) {
  const { isOpened, close, url } = props
  const [step, setStep] = useState(MODAL_STEPS.FIRST)
  const {
    selectedOrganization,
    selectOrganization,
    refetchConnections,
    refetchCurrentWriteback,
    connections,
    areConnectionsLoading,
    isWritebackLoading,
    currentWritebackData,
    currentConnection,
    postWriteback,
    deleteConnection,
    refetchConnection,
    triggerRefetch,
    setTriggerRefetch,
    isRefetchLoading
  } = useXeroData()

  useEffect(() => {
    if (isOpened || triggerRefetch) {
      refetchConnections()
      refetchCurrentWriteback()
      if (triggerRefetch) setTriggerRefetch(false)
    }
  }, [isOpened, triggerRefetch])

  const handleReconnect = () => {
    if (url) {
      window.open(url, '', 'height=700,width=800')
    }
    close()
  }

  const handleDisconnectStep = () => {
    setStep(MODAL_STEPS.THIRD)
  }

  const handleNextStep = () => {
    setStep(MODAL_STEPS.SECOND)
  }

  const handleBack = () => {
    setStep(MODAL_STEPS.FIRST)
  }

  const handleAfterActionRedirect = () => {
    handleBack()
  }

  const handleDisconnect = () => {
    deleteConnection.mutate()
    handleAfterActionRedirect()
  }

  let content
  if (step === 3) {
    content = (
      <StepThree
        handleBack={handleBack}
        handleDisconnect={handleDisconnect}
        selectedOrganization={selectedOrganization}
      />
    )
  }
  if (step === 2) {
    content = (
      <StepTwo
        handleAfterActionRedirect={handleAfterActionRedirect}
        selectedOrganization={selectedOrganization}
        handleBack={handleBack}
        currentWritebackData={currentWritebackData}
        currentConnection={currentConnection}
        refetch={refetchConnection}
        postWriteback={postWriteback}
      />
    )
  }
  if (step === 1) {
    content = (
      <StepOne
        currentWritebackData={currentWritebackData}
        isLoading={areConnectionsLoading || isRefetchLoading}
        organizations={connections}
        handleReconnect={handleReconnect}
        handleNextStep={handleNextStep}
        handleDisconnectStep={handleDisconnectStep}
        selectedOrganization={selectedOrganization}
        selectOrganization={selectOrganization}
      />
    )
  }
  if (areConnectionsLoading || isWritebackLoading || isRefetchLoading) {
    content = (
      <div className="flex w-full h-64px animate-pulse flex bg-gray-200 rounded"></div>
    )
  }

  return (
    <CommonModal
      customStyle={'lg:max-w-496px'}
      title={'Configure your Xero integration'}
      isOpened={isOpened}
      close={close}
      noFooter
    >
      <div className="flex flex-col justify-center w-full">{content}</div>
    </CommonModal>
  )
}
