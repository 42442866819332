import React from 'react'

const SkeletonAvailableFunds = () => {
  return (
    <div className="flex flex-col space-y-3">
      <div className="flex w-full h-36 animate-pulse bg-gray-200 rounded"></div>
      <div className="flex w-full h-350px animate-pulse bg-gray-200 rounded"></div>
      <div className="flex w-full h-36 animate-pulse bg-gray-200 rounded"></div>
    </div>
  )
}

export default SkeletonAvailableFunds
