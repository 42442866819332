import axios from '../../../services/axios'

const shops = async (platform) => {
  const response = await axios.get(
    `/inlr/rtr/store_unique_names?platform=${platform}`
  )

  return response.data
}

export default {
  shops
}
