import arrowCompareDown from '../images/bloom_icons/arrowCompareDown.svg'
import arrowCompareUp from '../images/bloom_icons/arrowCompareUp.svg'
import logoDot from '../images/bloom_icons/logo-dot.png'
import logoSingleO from '../images/bloom_icons/logo-single-o.png'
import logoLoginMobile from '../images/login/logo-login-mobile.png'
import logoLogin from '../images/login/logo-login.png'
import check from '../images/onboarding/check.svg'
import connectFb from '../images/onboarding/connectFb2.svg'
import connectGoogle from '../images/onboarding/connectGoogle.svg'
import doneCheck from '../images/onboarding/doneCheck.svg'
import doneCheckActive from '../images/onboarding/doneCheckActive.svg'
import emptyCheck from '../images/onboarding/emptyCheck.svg'
import emptyCheckActive from '../images/onboarding/emptyCheckActive.svg'
import fca from '../images/onboarding/fca.svg'
import founder from '../images/onboarding/founder.svg'
import onboardingSuccess from '../images/onboarding/onboardingSuccess.png'
import partyPopper from '../images/onboarding/partyPopper.svg'
import plaidLogo from '../images/onboarding/plaid-logo-horizontal-RGB.png'
import bloomLogoWithHeart from '../images/partners/bloomLogoWithHeart.svg'
import partnerApply from '../images/partners/partnersApply.svg'

const images = {
  founder,
  onboardingSuccess,
  check,
  fca,
  logoLogin,
  logoLoginMobile,
  logoSingleO,
  logoDot,
  arrowCompareUp,
  arrowCompareDown,
  emptyCheck,
  emptyCheckActive,
  doneCheck,
  doneCheckActive,
  connectFb,
  connectGoogle,
  partyPopper,
  partnerApply,
  bloomLogoWithHeart,
  plaidLogo
}

export default images
