import React, { useEffect, useState } from 'react'
import icons from 'config/configs/icons'
import images from 'config/configs/images'
import {
  digitRegExp,
  lowercaseRegExp,
  specialRegExp,
  uppercaseRegExp
} from 'utils/passwordValidation'

const PasswordStrengthRequirements = ({ password, showTooltip }) => {
  const [passwordStatus, setPasswordStatus] = useState({
    oneDigit: false,
    eightCharacters: false,
    specialCharacter: false,
    lowercaseCharacter: false,
    uppercaseCharacter: false
  })

  useEffect(() => {
    setPasswordStatus({
      oneDigit: digitRegExp.test(password),
      eightCharacters: password.length >= 8,
      lowercaseCharacter: lowercaseRegExp.test(password),
      uppercaseCharacter: uppercaseRegExp.test(password),
      specialCharacter: specialRegExp.test(password)
    })
  }, [password])

  return showTooltip ? (
    <div class="absolute left-50% w-75% transform -translate-x-50% -translate-y-75% flex-col items-center">
      <div class="absolute bottom-0 w-3 h-3 left-50% -mb-1 transform rotate-45 bg-light-grey-200"></div>
      <span class="relative flex flex-col rounded-lg p-4 whitespace-no-wrap text-sm bg-light-grey-200 z-10">
        <div className="relative flex">
          {passwordStatus.eightCharacters ? (
            <img src={images.check} alt="checkmark" />
          ) : (
            <img src={icons.closeRed} alt="close-red" />
          )}
          <span className="ml-2">At least 8 characters are required</span>
        </div>
        <div className="relative flex">
          {passwordStatus.oneDigit ? (
            <img src={images.check} alt="checkmark" />
          ) : (
            <img src={icons.closeRed} alt="close-red" />
          )}
          <span className="ml-2">At least one digit is required</span>
        </div>
        <div className="relative flex">
          {passwordStatus.specialCharacter ? (
            <img src={images.check} alt="checkmark" />
          ) : (
            <img src={icons.closeRed} alt="close-red" />
          )}
          <span className="ml-2">
            At least one special character is required
          </span>
        </div>
        <div className="relative flex">
          {passwordStatus.lowercaseCharacter ? (
            <img src={images.check} alt="checkmark" />
          ) : (
            <img src={icons.closeRed} alt="close-red" />
          )}
          <span className="ml-2">
            At least one lowercase character is required
          </span>
        </div>
        <div className="relative flex">
          {passwordStatus.uppercaseCharacter ? (
            <img src={images.check} alt="checkmark" />
          ) : (
            <img src={icons.closeRed} alt="close-red" />
          )}
          <span className="ml-2">
            At least one uppercase character is required
          </span>
        </div>
      </span>
    </div>
  ) : (
    <></>
  )
}

export default PasswordStrengthRequirements
