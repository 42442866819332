import axios from '../../services/axios'

const getSales = async () => {
  const response = await axios.get('/api/sales')

  return response.data
}

export default {
  getSales
}
