import { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import { REGISTRATION_ROUTES, ROOT_ROUTES } from 'config/configs/routes'
import NotFoundPage from 'pages/NotFoundPage'
import NotEligible from 'pages/authorisation/NotEligible'
import useCompanyInformation from 'routes/hooks/useCompanyInformation'
import useRouterHook from 'routes/hooks/useRouter'
import CompanyAuthRouter from '../AuthRouter/CompanyAuthRouter'
import useUser from '../hooks/useUser'
import MerchantDashboardRouter from './dashboard/MerchantDashboardRouter'
import MerchantOnboardingRouter from './onboarding/MerchantOnboardingRouter'

const { COMPANY_REGISTRATION, NOT_ELIGIBLE } = REGISTRATION_ROUTES
const { REGISTRATION, ONBOARDING, DASHBOARD, LOGOUT } = ROOT_ROUTES

const AppRouter = () => {
  const { userData, handleGetUser, isUserLoading } = useUser()
  const {
    isSubdomainPermitted,
    getBaseDomain,
    getSubdomain,
    redirectSubdomain
  } = useRouterHook()
  const {
    getCompanyInfo,
    companyLoading,
    isCompanyRegistered,
    isCompanyConfirming,
    eligibilityLoading,
    handleGetEligibility,
    eligibility
  } = useCompanyInformation()

  useEffect(() => {
    getCompanyInfo.refetch()
    handleGetUser()
  }, [])

  useEffect(() => {
    if (isCompanyRegistered) {
      handleGetEligibility()
    }
  }, [isCompanyRegistered])

  if (companyLoading || eligibilityLoading || isUserLoading) {
    return <LoadingScreen />
  }

  // If a regular user tries to connect to a embedded partner URL must be redirect to base URL
  if (!userData?.embeddedPartner && isSubdomainPermitted()) {
    window.location.assign(`${getBaseDomain()}${window.location.pathname}`)
  }

  // If you're a partner but you're not in the correct subdomain
  // you'll be redirected to the correct subdomain
  // only if you've not finished submitting the application
  if (
    userData?.embeddedPartner &&
    getSubdomain() !== userData?.embeddedPartner &&
    (!isCompanyRegistered || userData?.onboarding)
  ) {
    window.location.assign(
      `${redirectSubdomain(userData?.embeddedPartner)}${window.location.pathname}`
    )
  }

  if (!isCompanyRegistered || isCompanyConfirming) {
    return (
      <Switch>
        <Route path={REGISTRATION} component={CompanyAuthRouter} />
        <Redirect to={COMPANY_REGISTRATION} />
      </Switch>
    )
  }

  // this switch contains the logic for company eligibility
  // if an registered company gets eligibility code 0 or 1, will continue with normal flow
  // if an registered company gets any other code, will be redirect to default template

  if (isCompanyRegistered) {
    switch (parseInt(eligibility)) {
      case -1:
      case 0:
      case 1:
        if (userData?.onboarding) {
          return (
            <>
              <Route path={ONBOARDING} component={MerchantOnboardingRouter} />
              <Redirect to={ONBOARDING} />
            </>
          )
        } else {
          if (isSubdomainPermitted()) {
            window.location.assign(
              `${getBaseDomain()}${window.location.pathname}`
            )
          }
          return (
            <>
              <Route path={DASHBOARD} component={MerchantDashboardRouter} />;
              <Redirect to={DASHBOARD} />
            </>
          )
        }

      default:
        return (
          <>
            <Route path={NOT_ELIGIBLE} component={NotEligible} />
            <Redirect
              to={eligibility || eligibility === 0 ? NOT_ELIGIBLE : ONBOARDING}
            />
          </>
        )
    }
  }

  return (
    <Switch>
      <Route exact path={LOGOUT} />
      <Route path={'*'} component={NotFoundPage} />
    </Switch>
  )
}

export default AppRouter
