import FormikInputTextGrey from 'components/FormikInputTextGrey'
import FormikPasswordInput from 'components/FormikPasswordInput'
import Button from '../Button'
import PasswordStrengthBar from '../PasswordStrengthBar'
import useResetPasswordHook from './hook'

const ResetPasswordForm = () => {
  const {
    formik,
    shouldShowError,
    passwordBar,
    handleChange,
    onResetPassword,
    loading
  } = useResetPasswordHook()

  return (
    <div className="flex flex-col justify-between items-start w-full md:w-596px xl:w-624px xl:ml-103px z-10 shadow-login-form">
      <form
        className="flex flex-col justify-end space-y-6 w-full rounded-2xl p-12 bg-white"
        onSubmit={onResetPassword}
      >
        <span className="text-32px md:text-2xl font-semibold static">
          Reset password
        </span>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col w-full relative">
            <FormikPasswordInput
              field="password"
              id="password"
              placeholder="New password"
              inputClassname="w-full"
              containerClassname={'mb-3'}
              shouldValid={shouldShowError}
              type="password"
              {...formik}
              handleChange={handleChange}
              autoFocus
            />
            <PasswordStrengthBar passwordBar={passwordBar} />
          </div>
          <div className="w-full relative">
            <FormikInputTextGrey
              field="passwordConfirmation"
              id="passwordConfirmation"
              placeholder="Confirm new password"
              type="password"
              containerClassname={'mb-3'}
              shouldValid={shouldShowError}
              {...formik}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col items-end">
          <Button type="submit" variant="onboard" loading={loading}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ResetPasswordForm
