import * as Yup from 'yup'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import FormikInputTextGrey from 'components/FormikInputTextGrey'
import Spinner from 'components/Spinner'
import shopifyApi from 'config/api/connections/shopify'
import CommonModal from 'modals/components/CommonModal'
import { getErrorMessage } from 'utils/getErrorMessage'

const SHOPIFY_URL = '.myshopify.com'

const storeUrlRegex = /^[a-zA-Z0-9-_]+$/i

const ShopifyInputContent = () => (
  <span className="absolute top-0 right-0 h-full flex items-center pl-4 pr-8 overflow-hidden z-10">
    <span className="absolute top-2 left-0 h-10 flex items-center border-l border-light-grey-400 "></span>
    {SHOPIFY_URL}
  </span>
)

const ShopifyModal = ({ isOpened, close }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const accountsQuery = useQuery('shopifyShops', shopifyApi.shops, {
    onError: (err) => {
      toast.error(getErrorMessage(err))
    },
    enabled: false,
    retry: false
  })

  useEffect(() => {
    if (isOpened) {
      accountsQuery.refetch()
    }
  }, [isOpened])

  const formik = useFormik({
    initialValues: {
      shop_url: ''
    },
    validationSchema: Yup.object().shape({
      shop_url: Yup.string()
        .matches(storeUrlRegex, 'Please enter a valid store name.')
        .required('Store name is required.')
    }),
    onSubmit: (values, { resetForm }) => {
      const shopUrl = `${values.shop_url + SHOPIFY_URL}`
      resetForm()
      close()

      //open pop-up
      window.open(
        `${window.location.origin}/inlr/shopify/start?shopUrl=${encodeURIComponent(shopUrl)}`,
        '',
        'height=700,width=800'
      )
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  return (
    <CommonModal
      title={'Connect your Shopify store'}
      isOpened={isOpened}
      close={close}
    >
      <div className="flex flex-col justify-center w-full">
        <div>
          <div className="w-full">
            <div className="flex flex-col w-full mb-6">
              <div className="flex flex-col w-full mb-3 pb-3 border-light-grey border-b">
                <p className="pb-3">Connected shops:</p>
                {accountsQuery.isLoading && (
                  <div className="flex flex-col self-center w-full">
                    <Spinner />
                  </div>
                )}
                {accountsQuery.data?.map((account) => (
                  <div key={account} className="flex">
                    {account}
                  </div>
                ))}
                {!accountsQuery.isLoading && !accountsQuery.data?.length && (
                  <div>No shops yet.</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full mb-10 pb-6 border-light-grey border-b text-center">
          <p className="text-sm md:text-base text-placeholder-grey">
            To connect your Shopify store URL, login to your Shopify admin.{' '}
            <br />
            Your store name will be included in the URL
          </p>
        </div>

        <div className="flex items-center justify-center">
          <form className="w-full">
            <div className="flex w-full items-start text-left mb-6">
              <FormikInputTextGrey
                field="shop_url"
                id="shop_url"
                placeholder="Your Store Name"
                shouldValid={shouldShowError}
                {...formik}
                handleChange={handleChange}
                containerClassname={'mb-3 overflow-hidden'}
                autoFocus
                content={<ShopifyInputContent />}
              />
            </div>

            <div className="flex justify-between items-center mb-12">
              <Button
                type="submit"
                variant={'button-next-pink'}
                className="ml-auto normal-case"
                disabled={!(formik.isValid && formik.dirty)}
                onClick={onSubmit}
                loading={formik.isSubmitting}
              >
                Connect Shopify
              </Button>
            </div>
          </form>
        </div>

        <div className="flex justify-center mb-10">
          <span className="text-sm text-placeholder-grey">
            Having trouble with this step? Please contact our support.
          </span>
        </div>
      </div>
    </CommonModal>
  )
}

export default ShopifyModal
