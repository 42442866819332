import { useState } from 'react'
import React from 'react'
import DirectorsForm from './DirectorsForm'
import SignatoryDesignation from './SignatoryDesignation'

const useCompanyDirectorHook = ({ onBack, onNext }) => {
  const [currentDirectorStep, setCurrentDirectorStep] = useState(0)

  const directorSteps = [
    {
      name: 'Directors',
      component: (
        <DirectorsForm
          onBack={onBack}
          onRedirect={() => setCurrentDirectorStep(1)}
        />
      )
    },
    {
      name: 'Your Details',
      component: (
        <SignatoryDesignation
          onBack={() => setCurrentDirectorStep(0)}
          onRedirect={onNext}
        />
      )
    }
  ]

  return {
    currentDirectorStep,
    directorSteps
  }
}

export default useCompanyDirectorHook
