import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import configs from 'config/configs'
import useWindowSize from 'hooks/useWindowSize'
import { startCaseKeys } from 'utils/startCaseKeys'

const CustomSymbolShape = ({ x, y, fill, borderWidth, borderColor }) => {
  const xOffset = 8
  return (
    <rect
      x={x - xOffset}
      y={y}
      fill={fill}
      strokeWidth={borderWidth}
      stroke={borderColor}
      width={16}
      height={4}
      style={{ marginRight: 8 }}
    />
  )
}

const CategoriesChart = ({ data }) => {
  const mobileSize = 640
  const { width } = useWindowSize()
  const condition = width < mobileSize
  const maxValue = Math.max(
    ...data.map((item) =>
      Math.max(item.predicted_monthly_amount, item.average_monthly_amount)
    )
  )
  const formattedData = data.map((obj) => startCaseKeys(obj))

  return (
    <ResponsiveBar
      data={formattedData}
      indexBy="Category"
      groupMode="grouped"
      layout={width < mobileSize ? 'vertical' : 'horizontal'}
      keys={['Predicted Monthly Amount', 'Average Monthly Amount']}
      colors={[configs.colors.lightBlueBloom, configs.colors.indingoBloom]}
      margin={{
        top: 80,
        right: 32,
        bottom: condition ? 172 : 56,
        left: condition ? 48 : 204
      }}
      animate={true}
      minValue={0}
      maxValue={maxValue + 1}
      innerPadding={2}
      padding={0.5}
      enableGridX={true}
      enableGridY={false}
      enableLabel={false}
      yFormat=" >-.2f"
      axisLeft={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: condition ? 90 : 0
      }}
      useMesh={true}
      legends={[
        {
          anchor: 'top-left',
          dataFrom: 'keys',
          direction: condition ? 'column' : 'row',
          justify: false,
          translateX: condition ? -16 : -186,
          translateY: -64,
          itemsSpacing: 5,
          itemDirection: 'left-to-right',
          itemWidth: 180,
          itemHeight: 24,
          itemOpacity: 1,
          symbolSize: 4,
          symbolShape: CustomSymbolShape,
          symbolBorderColor: 'rgba(0, 0, 0, .5)'
        }
      ]}
    />
  )
}

export default CategoriesChart
