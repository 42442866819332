import * as actionTypes from '../../types/Company'
import { LOGOUT } from '../../types/Auth'

const initialState = {
  companyConfirming: false,
  companyRegistered: false,
  companyInformation: {},
  registerCompanyLoading: false
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_REGISTERED:
      return {
        ...state,
        companyRegistered: action.payload.value
      }
    case actionTypes.COMPANY_CONFIRMING:
      return { ...state, companyConfirming: action.payload.value }
    case actionTypes.REGISTER_COMPANY_LOADING:
      return { ...state, registerCompanyLoading: action.payload.value }
    case LOGOUT:
      return initialState
    default: {
      return state
    }
  }
}
export default companyReducer
