import { useFormik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useAuthHook from '../Auth/hook'
import loginSchema from './schema'

const useLoginFormHook = () => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const { handleLogin, loginLoading } = useAuthHook()
  const history = useHistory()

  //form
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema(),
    onSubmit: (values) => {
      handleLogin(values)
    }
  })

  const onLogin = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  const onForgotPassword = () => {
    history.push('/forgot-password')
  }

  return {
    formik,
    shouldShowError,
    onLogin,
    loading: loginLoading,
    onForgotPassword
  }
}

export default useLoginFormHook
