import { T, always, cond, propEq } from 'ramda'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LazyBackgroundImage from 'components/LazyBackgroundImage'
import configs from 'config/configs'
import { INTEGRATION_CRITICAL } from 'enums/Integrations'
import { useAnalytics } from 'hooks/useAnalytics'
import { useConnectionStatus } from 'hooks/useConnectionStatus'
import useModal from 'hooks/useModal'
import useWindowSize from 'hooks/useWindowSize'
import AmazonModal from 'modals/AmazonModal'
import BigCommerceModal from 'modals/BigCommerceModal'
import ChargeBeeModal from 'modals/ChargeBeeModal'
import { FacebookModal } from 'modals/FacebookModal'
import MagentoModal from 'modals/MagentoModal'
import PrestaShopModal from 'modals/PrestaShopModal'
import RecurlyModal from 'modals/RecurlyModal'
import ShopifyModal from 'modals/ShopifyModal'
import WooCommerceModal from 'modals/WooCommerceModal'
import ZuoraModal from 'modals/ZuoraModal'

const renderConnectButton = (title, hover) => {
  const fb = title?.toLowerCase().includes('facebook')
  const ggl = title?.toLowerCase().includes('google')
  return fb || ggl ? (
    <img
      src={fb ? configs.images.connectFb : configs.images.connectGoogle}
      className={`absolute bottom-4 px-3 transition-all duration-300  ${hover ? 'opacity-100' : 'opacity-0'}`}
    />
  ) : (
    <div
      className={`absolute bottom-5 group-hover:flex items-center justify-center text-white text-sm leading-4 py-6.5px px-61px rounded-btn group-hover:bg-pink-color transition-all duration-300  ${
        hover ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div>Connect</div>
    </div>
  )
}

const HAS_MODAL = [
  'Shopify',
  'prestashop',
  'woo_commerce',
  'magento',
  'zuora',
  'amazon',
  'Chargebee',
  'Recurly',
  'Facebook',
  'big_commerce'
]

export const IntegrationModal = cond([
  [propEq('integration', 'Shopify'), ShopifyModal],
  [propEq('integration', 'amazon'), AmazonModal],
  [propEq('integration', 'prestashop'), PrestaShopModal],
  [propEq('integration', 'woo_commerce'), WooCommerceModal],
  [propEq('integration', 'magento'), MagentoModal],
  [propEq('integration', 'Recurly'), RecurlyModal],
  [propEq('integration', 'zuora'), ZuoraModal],
  [propEq('integration', 'Chargebee'), ChargeBeeModal],
  [propEq('integration', 'Facebook'), FacebookModal],
  [propEq('integration', 'big_commerce'), BigCommerceModal],
  [T, always(null)]
])

const ImageCard = ({
  url,
  img,
  title,
  rutter,
  onClose,
  platform,
  accountType,
  extraAccount,
  status,
  onSwitchModalsToIntegration,
  getClickedIntegrationProps,
  onConnectedAction
}) => {
  const analytics = useAnalytics()
  const { width } = useWindowSize()
  const [hover, setHover] = useState(false)
  const user = useSelector((state) => state.dataUser)
  const {
    isOpened: isModalOpened,
    open: openModal,
    close: closeModal
  } = useModal()
  const {
    isInactive,
    isConnected,
    bgColorClass,
    borderColorClass,
    statusIcon
  } = useConnectionStatus(status)

  const handleClickConnection = () => {
    if (isConnected && onConnectedAction) {
      return onConnectedAction()
    }
    if (HAS_MODAL.includes(title) && width > 640) {
      extraAccount && onSwitchModalsToIntegration()
      !extraAccount && openModal()
    } else if (platform && rutter.ready) {
      extraAccount && onClose()
      analytics.track(user.id, 'Integration Status', {
        status: 'start',
        name: platform,
        category: accountType.split(' ')[0] || ''
      })

      rutter.exit()
      setTimeout(() => {
        rutter.open({ platform })
      }, 500)
    } else {
      extraAccount && onClose()
      window.open(url, '', 'height=700,width=800')
    }
  }

  // render different card for 'add sales account'
  if (extraAccount) {
    return (
      <React.Fragment>
        <div
          id={`connect-${title}`}
          onClick={() => {
            getClickedIntegrationProps(img, title)
            handleClickConnection()
          }}
          className={`flex justify-center items-center group relative h-60px my-2.5 rounded-lg overflow-hidden bg-white border cursor-pointer ${bgColorClass}`}
        >
          <LazyBackgroundImage src={img} backgroundClass="bg-auto" />
        </div>
      </React.Fragment>
    )
  }

  return (
    <>
      <IntegrationModal
        image={img}
        integration={title}
        isOpened={isModalOpened}
        close={closeModal}
        rutter={rutter}
        url={url}
      />
      <div
        id={`connect-${title}`}
        onClick={handleClickConnection}
        onMouseEnter={() => isInactive && setHover(true)}
        onMouseLeave={() => isInactive && setHover(false)}
        className={`flex justify-center items-center group relative h-28 rounded-lg overflow-hidden bg-white ${
          !isInactive ? 'border-2' : 'border'
        } cursor-pointer ${borderColorClass}`}
      >
        <LazyBackgroundImage
          src={img}
          hover={hover}
          backgroundClass="bg-contain"
        />
        {!isInactive ? (
          <>
            <div className="absolute right-0 top-0 m-2">
              <img src={statusIcon} className="w-18px h-18px" />
            </div>
            <div
              className={`absolute left-0 bottom-0 flex items-center justify-center w-full h-8 ${bgColorClass} text-white text-xs font-bold`}
            >
              <span id={`connected-${title}`}>
                {status === INTEGRATION_CRITICAL
                  ? 'Connection Error'
                  : 'Connected'}
              </span>
            </div>
          </>
        ) : (
          <>{renderConnectButton(title, hover)}</>
        )}
      </div>
    </>
  )
}

export default ImageCard
