export const accountsToInstitutions = (accounts) => {
  return accounts.reduce((institutions, account) => {
    institutions[account.institutionId] ||= {}
    institutions[account.institutionId].accounts ||= []

    institutions[account.institutionId].accounts.push({
      name: account.accountName,
      number: account.accountNumber,
      type: account.accountType,
      currency: account.currency
    })
    institutions[account.institutionId].bankName = account.bankName
    institutions[account.institutionId].sortCode = account.sortCode
    institutions[account.institutionId].accountHolder = account.accountHolder
    institutions[account.institutionId].status = account.connected

    return institutions
  }, {})
}
