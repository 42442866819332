import * as actionTypes from '../../types/Partner'

export const updatePartnerData = (value) => {
  return {
    type: actionTypes.UPDATE_PARTNER_DATA,
    payload: {
      value
    }
  }
}
