const integrations = {
  stripe: {
    title: 'Stripe',
    img: 'stripe.png'
  },

  shopify: {
    title: 'Shopify',
    path: 'shopify',
    img: 'shopify.png'
  },

  shopify_redirect: {
    path: 'shopify_redirect'
  },

  amazon: {
    title: 'Amazon',
    img: 'amazon.png'
  },

  ebay: {
    title: 'eBay',
    img: 'ebay.png'
  },

  magento: {
    title: 'Magento',
    img: 'magento.png'
  },

  woocommerce: {
    title: 'WooCommerce',
    img: 'woocommerce.png'
  },
  recurly: {
    title: 'Recurly',
    img: 'recurly.png'
  },

  chargebee: {
    title: 'Chargebee',
    img: 'chargebee.png'
  },

  klarna: {
    title: 'Klarna',
    img: 'klarna.png'
  },

  square: {
    title: 'Square',
    img: 'square.png'
  },

  braintree: {
    title: 'Braintree',
    img: 'braintree.png'
  },

  checkout: {
    title: 'Checkout.com',
    img: 'checkout.com.png'
  },

  adyen: {
    title: 'Adyen',
    img: 'adyen.png'
  },

  zencart: {
    title: 'ZenCart',
    img: 'zencart.png'
  },

  prestashop: {
    title: 'PrestaShop',
    img: 'prestashop.png'
  },

  wix: {
    title: 'Wix',
    img: 'prestashop-logo-small.png'
  },

  facebook: {
    title: 'Facebook',
    path: 'facebook',
    accountsPath: 'accounts_index',
    img: 'Facebook-Logo-small.png'
  },

  google_ads: {
    title: 'Google Ads',
    path: 'google-ads',
    accountsPath: 'customers_index',
    img: 'Google-Ads-Logo-Small.png'
  },

  bing_ads: {
    title: 'Bing Ads',
    img: 'bing-ads-logo-small.png'
  },

  google_analytics: {
    title: 'Google Analytics',
    path: 'google-analytics',
    accountsPath: 'accounts_selection',
    img: 'Google-Analytics-Logo-Small.png'
  },

  xero: {
    title: 'Xero',
    img: 'Xero-Logo.png'
  },

  quickbooks: {
    title: 'Quick Books',
    img: 'QuickBooks-Logo.png'
  },

  sage: {
    title: 'Sage',
    img: 'Sage-Logo.png'
  },

  credit_kudos: {
    title: 'Credit Kudos',
    img: 'credit-kudos.png'
  },
  docusign: {
    title: 'DocuSign',
    img: 'docusign.png'
  },
  gocardless: {
    title: 'GoCardless',
    path: 'gocardless',
    img: 'gocardless.svg'
  },
  snapchat: {
    title: 'SnapChat',
    img: 'snapchat-logo.png'
  },
  zuora: {
    title: 'Zuora',
    img: 'zuora.png'
  },
  freshbooks: {
    title: 'FreshBooks',
    path: 'freshbooks',
    accountsPath: 'customers_index',
    img: 'freshbooks-logo.png'
  }
}

export default integrations
