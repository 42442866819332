import axios from '../../services/axios'

const sendHelpEmail = async ({ name, subject, details, email }) => {
  const url = email
    ? '/api/support/support_unauthenticated_email'
    : '/api/support/support_email'
  const payload = {
    ...(email && { email }),
    ...{ name, subject, details }
  }
  const response = await axios.post(url, payload)
  return response.data
}

export default {
  sendHelpEmail
}
