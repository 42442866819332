import { useLocation } from 'react-router'
import { ACCOUNTS_BALANCES_ROUTES } from 'config/configs/routes'

const { OVERVIEW, INFLOWS, OUTFLOWS, CATEGORIES, TRANSACTIONS } =
  ACCOUNTS_BALANCES_ROUTES

const AccountsAndBalancesSubNav = [
  {
    name: 'Overview',
    to: OVERVIEW
  },
  {
    name: 'Inflows',
    to: INFLOWS
  },
  {
    name: 'Outflows',
    to: OUTFLOWS
  },
  {
    name: 'Transactions',
    to: TRANSACTIONS
  },
  {
    name: 'Categories',
    to: CATEGORIES
  }
]

const useSubnavHook = () => {
  const location = useLocation()
  const pathname = location.pathname

  return {
    subnavList: AccountsAndBalancesSubNav,
    pathname
  }
}

export default useSubnavHook
