import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

const useCountdown = (target) => {
  const countdownDate = dayjs(target)

  const [countdown, setCountdown] = useState(countdownDate.diff(dayjs()))

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(countdownDate.diff(dayjs()))
    }, 1000)
    return () => clearInterval(interval)
  }, [countdownDate])

  const days = Math.floor(countdown / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000)
  const expired = target && countdownDate.diff(dayjs()) < 0

  const twoDigitTimeFormat = (time) => {
    return ('0' + time).slice(-2)
  }

  return { days, hours, minutes, seconds, twoDigitTimeFormat, expired }
}

export default useCountdown
