import { useMemo } from 'react'
import Card from 'components/Card'
import BalancesOverviewChart from 'components/Charts/BalancesOverviewChart'
import InflowsAndOutflowsChart from 'components/Charts/InflowsAndOutflowsChart'
import CircularProgressWithMeta from 'components/CircularProgressWithMeta'
import ErrorMessage from 'components/ErrorMessage'
import HeaderFilter from 'components/HeaderFilter'
import configs from 'config/configs'
import SubNav from 'layouts/components/SubNav'
import formatCurrency from 'utils/formatCurrency'
import AppLayoutSkeleton from '../../../../layouts/AppLayout/AppLayoutSkeleton'
import useOverviewHook from './hook'

const AccountsAndBalancesOverview = () => {
  const {
    error,
    accountMeta,
    balances,
    inflowsVsOutflows,
    breakdownData,
    isFetching,
    setBalancesPeriod,
    setInflowsVsOutflowsPeriod
  } = useOverviewHook()

  const inflowsVsOutflowsMax = useMemo(() => {
    return (
      inflowsVsOutflows &&
      inflowsVsOutflows.reduce((acc, it) => {
        if (it.inflow > acc) return it.inflow
        if (Math.abs(it.outflow) > acc) return Math.abs(it.outflow)

        return acc
      }, 0)
    )
  }, [inflowsVsOutflows])

  const inflowsVsOutflowsMin = useMemo(() => {
    return (
      inflowsVsOutflows &&
      inflowsVsOutflows.reduce((acc, it) => {
        if (it.inflow <= acc) return it.inflow
        if (Math.abs(it.outflow) <= acc) return Math.abs(it.outflow)

        return acc
      }, inflowsVsOutflowsMax)
    )
  }, [inflowsVsOutflows])

  if (isFetching) {
    return <AppLayoutSkeleton />
  }

  return (
    <>
      <SubNav />
      {Boolean(error) ? (
        <Card headerClassname="text-b-dark font-medium">
          <ErrorMessage error={error}></ErrorMessage>
        </Card>
      ) : (
        <div className="grid grid-cols-12 gap-4">
          {accountMeta ? (
            <div className="col-span-12 flex flex-col bg-white rounded-lg p-2 md:p-6">
              <div
                className="flex flex-row space-x-3 pb-4 mb-8 text-color-bloom"
                style={{ boxShadow: 'inset 0px -2px 0px #FAF8F8' }}
              >
                <span className="font-semibold">{accountMeta.title}</span>
                <span className="w-px h-6 bg-grey-rectangle hidden md:block"></span>
                <span className="font-semibold">{accountMeta.number}</span>
              </div>
              <div className="flex flex-col space-x-0 md:flex-row md:space-x-8">
                <div className="flex flex-row justify-between space-x-2 md:space-x-8">
                  <div className="flex flex-col">
                    <span className="text-sm text-text-color-grey-bloom">
                      Balance
                    </span>
                    <span className="text-green-bloom font-bold text-2xl">
                      {formatCurrency(accountMeta.balance, 2)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-text-color-grey-bloom">
                      Overdraft limit
                    </span>
                    <span className="font-bold text-2xl">
                      {formatCurrency(accountMeta.overdraftLimit, 2)}
                    </span>
                  </div>
                </div>
                <span className="w-px h-14 bg-grey-rectangle hidden md:block"></span>
                <div className="flex flex-row space-x-4 justify-center mt-8 md:mt-0 md:space-x-8">
                  <CircularProgressWithMeta
                    days={'30'}
                    value={accountMeta['30DLiquidity']}
                    className="h-14 font-semibold"
                    styles={{
                      path: {
                        stroke: configs.colors.indingoBloom
                      },
                      trail: {
                        stroke: configs.colors.lightBDark
                      },
                      text: {
                        fill: configs.colors.bDark
                      }
                    }}
                  />
                  <CircularProgressWithMeta
                    days={'90'}
                    value={accountMeta['90DLiquidity']}
                    className="h-14 font-semibold"
                    styles={{
                      path: {
                        stroke: configs.colors.indingoBloom
                      },
                      trail: {
                        stroke: configs.colors.lightBDark
                      },
                      text: {
                        fill: configs.colors.bDark
                      }
                    }}
                  />
                  <CircularProgressWithMeta
                    days={'180'}
                    value={accountMeta['180DLiquidity']}
                    className="h-14 font-semibold"
                    styles={{
                      path: {
                        stroke: configs.colors.indingoBloom
                      },
                      trail: {
                        stroke: configs.colors.lightBDark
                      },
                      text: {
                        fill: configs.colors.bDark
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {balances && (
            <div className="col-span-12 flex flex-col bg-white rounded-lg p-6">
              <HeaderFilter
                title="Balances"
                onChange={(value) => setBalancesPeriod(value)}
                defaultValue="12m"
                filterOptions={['3m', '6m', '12m']}
              />
              <div className="mt-6 h-96 bg-body-grey">
                <BalancesOverviewChart data={balances} />
              </div>
            </div>
          )}

          {inflowsVsOutflows ? (
            <div className="col-span-12 flex flex-col bg-white rounded-lg p-6 mb-12">
              <HeaderFilter
                title="Inflows vs outflows"
                defaultValue="12m"
                filterOptions={['3m', '6m', '12m']}
                onChange={(value) => setInflowsVsOutflowsPeriod(value)}
              />
              <div className="mt-6 h-96 bg-body-grey">
                <InflowsAndOutflowsChart
                  data={inflowsVsOutflows}
                  min={inflowsVsOutflowsMin}
                  max={inflowsVsOutflowsMax}
                />
              </div>
              <div className="flex flex-col space-y-12 mt-6 md:flex-row md:space-x-12 md:space-y-0">
                <div className="flex flex-col md:w-1/2">
                  <span className="">Inflows Breakdown</span>
                  <span className="border border-grey-blue-bloom mt-4" />
                  <div className="flex flex-row mt-4 space-x-2 md:space-x-12">
                    {breakdownData.inflow.map((item, index) => (
                      <div className="flex flex-col space-y-1" key={index}>
                        <span className="font-semibold text-sm">
                          {item.label}
                        </span>
                        <span className="font-bold text-light-blue-bloom">
                          {formatCurrency(item.value, 2)}
                        </span>
                        <span className="text-sm">{item.date}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col md:w-1/2">
                  <span className="">Outflows Breakdown</span>
                  <span className="border border-grey-blue-bloom mt-4" />
                  <div className="flex flex-row mt-4 space-x-2 md:space-x-12">
                    {breakdownData.outflow.map((item, index) => (
                      <div className="flex flex-col space-y-1" key={index}>
                        <span className="font-semibold text-sm">
                          {item.label}
                        </span>
                        <span className="font-bold text-indingo-bloom">
                          {formatCurrency(item.value, 2)}
                        </span>
                        <span className="text-sm">{item.date}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export default AccountsAndBalancesOverview
