import { Field, FormikProvider } from 'formik'
import configs from 'config/configs'
import Button from '../../Button'
import FormikSelectGrey from '../../FormikSelectGrey'
import Skeleton from '../../Skeleton'
import useSignatoryDesignationHook from './hook'

const SignatoryDesignation = ({ onBack, onRedirect }) => {
  const { formik, onSubmit, loading, options, selectedDirector } =
    useSignatoryDesignationHook({ onRedirect })

  if (loading) {
    return <Skeleton height="48" />
  }

  return (
    <div>
      <p className="font-bold text-4xl">Your details</p>
      <p className="text-xl mt-2">
        Please select your name from the dropdown list and provide us with your
        residential address. We need this information to verify your identity
        and conduct a AML check.
      </p>

      <form onSubmit={onSubmit}>
        <FormikProvider value={formik}>
          <div className="rounded-2xl xs:bg-none lg:bg-white mt-8 lg:p-6">
            <Field name={`selected_director`}>
              {({ meta }) => (
                <div className="w-full">
                  <FormikSelectGrey
                    field="selected_director"
                    id="selected_director"
                    placeholder="Director"
                    initialValue={selectedDirector}
                    options={options}
                    {...formik}
                    handleChange={(field, value) =>
                      formik.setFieldValue(field, value)
                    }
                  />
                  {meta.error && (
                    <p className="text-b-pink text-sm mt-2 font-normal pl-4">
                      {meta.error}
                    </p>
                  )}
                </div>
              )}
            </Field>

            <p className="mt-3">ADDRESS INFORMATION</p>

            <div className="mt-3">
              <div className="flex row gap-4 w-full content-between">
                <Field name={`line1`}>
                  {({ field, meta }) => (
                    <div className="w-full">
                      <input
                        id={`line1`}
                        placeholder="Address Line 1"
                        type="text"
                        {...field}
                        className={`flex-grow font-normal flex flex-row items-center rounded-lg 
                        bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                        border-b-placeholder ${meta.touched && meta.error && 'border-b-pink'}`}
                      />
                      {meta.error && (
                        <p className="text-b-pink text-sm mt-2 font-normal pl-4">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <Field name={`line2`}>
                  {({ field, meta }) => (
                    <div className="w-full">
                      <input
                        id={`line2`}
                        placeholder="Address Line 2"
                        type="text"
                        {...field}
                        className={`flex-grow font-normal flex flex-row items-center rounded-lg 
                        bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                        border-b-placeholder ${meta.touched && meta.error && 'border-b-pink'}`}
                      />
                      {meta.error && (
                        <p className="text-b-pink text-sm mt-2 font-normal pl-4">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div className="flex row gap-4 w-full content-between mt-4">
                <Field name={`flat_no`}>
                  {({ field, meta }) => (
                    <div className="w-full">
                      <input
                        id={`flat_no`}
                        placeholder="Flat no (if available)"
                        type="text"
                        {...field}
                        className={`flex-grow font-normal flex flex-row items-center rounded-lg 
                        bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                        border-b-placeholder ${meta.touched && meta.error && 'border-b-pink'}`}
                      />
                      {meta.error && (
                        <p className="text-b-pink text-sm mt-2 font-normal pl-4">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <Field name={`postal_code`}>
                  {({ field, meta }) => (
                    <div className="w-full">
                      <input
                        id={`postal_code`}
                        placeholder="Postal Code"
                        type="text"
                        {...field}
                        className={`flex-grow font-normal flex flex-row items-center rounded-lg 
                        bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                        border-b-placeholder ${meta.touched && meta.error && 'border-b-pink'}`}
                      />
                      {meta.error && (
                        <p className="text-b-pink text-sm mt-2 font-normal pl-4">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>

          <div className="mt-35px flex justify-between items-center">
            <Button
              variant="button-back-grey"
              type="button"
              onClick={onBack}
              children="Back"
            />
            <Button
              variant="button-next-pink"
              affixIcon={configs.icons.arrowForward}
              type="submit"
              children="Next"
              disabled={!formik.values.selected_director}
            />
          </div>
        </FormikProvider>
      </form>
    </div>
  )
}

export default SignatoryDesignation
