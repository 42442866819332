import axios from '../../../services/axios'

const shops = async () => {
  const response = await axios.get(
    `/inlr/rtr/store_unique_names?platform=BIG_COMMERCE`
  )

  return response.data
}

export default {
  shops
}
