import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import api from 'config/api/header/index'
import { closeHeader, openHeader } from 'redux/actions/App'
import { getErrorMessage } from 'utils/getErrorMessage'
import LoggedInAction from './LoggedInAction'
import SigninAction from './SignInAction'
import SignupAction from './SignupAction'

const useHeaderHook = ({ screenName }) => {
  const [showHeaderDropdown, setShowHeaderDropdown] = useState(false)
  const { isLoggedIn, companyName } = useSelector((state) => state.dataAuth)
  const user = useSelector((state) => state.dataUser)
  const [mobileMenu] = useState(true)
  const [helpModalDetails, setHelpModalDetails] = useState(null)
  const { headerExpanded } = useSelector((state) => state.dataApp)
  const dispatch = useDispatch()

  const sendEmailQuery = useMutation('sendHelpEmail', api.sendHelpEmail, {
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  useEffect(() => {
    if (helpModalDetails) {
      sendEmailQuery.mutate(helpModalDetails)
    }
  }, [helpModalDetails])

  let ActionComponent = SignupAction

  if (screenName === 'Login') {
    ActionComponent = SignupAction
  }

  if (screenName === 'Signup') {
    ActionComponent = SigninAction
  }

  if (user) {
    ActionComponent = LoggedInAction
  }

  const handleFocusDropdown = () => {
    setShowHeaderDropdown(true)
  }

  const handleBlurDropdown = () => {
    setTimeout(() => {
      setShowHeaderDropdown(false)
    }, 200)
  }

  const toggleMobileExpand = () => {
    if (headerExpanded) return dispatch(closeHeader())

    dispatch(openHeader())
  }

  const companyNameTextScale = () => {
    let fontSize = 'text-2xl'
    if (companyName?.length > 20) {
      fontSize = 'text-base'
    }
    return fontSize
  }

  const submitEmail = (values) => {
    setHelpModalDetails(values)
  }

  return {
    isLoggedIn,
    user,
    companyName,
    submitEmail,
    showHeaderDropdown,
    ActionComponent,
    mobileMenu,
    mobileExpand: headerExpanded,
    companyNameTextScale: companyNameTextScale(),
    handleFocusDropdown,
    handleBlurDropdown,
    toggleMobileExpand
  }
}

export default useHeaderHook
