import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { batch, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import authApi from 'config/api/auth'
import { SAAS_BUSINESS_TYPE } from 'config/configs/constant'
import { updateCompanyName } from 'redux/actions/Auth'
import {
  companyConfirming,
  companyRegistered,
  registerCompanyLoading
} from 'redux/actions/Company'
import { updateBusinessType } from 'redux/actions/PersistRegister'
import { errorSavingCompany } from 'redux/actions/Register'
import { updateIsSaasUser } from 'redux/actions/User'
import { getErrorMessage } from 'utils/getErrorMessage'

const useCompanyInformation = () => {
  const [eligibility, setEligibility] = useState('')
  const [companyLoading, setCompanyLoading] = useState(true)
  const [eligibilityLoading, setEligibilityLoading] = useState(false)
  const dispatch = useDispatch()
  const isCompanyConfirming = useSelector(
    (state) => state.dataCompany.companyConfirming
  )
  const isCompanyRegistered = useSelector(
    (state) => state.dataCompany.companyRegistered
  )

  const getCompanyInfo = useQuery('getCompanyInfo', authApi.getCompanyInfo, {
    enabled: false,
    retry: false,
    onSuccess: (res) => {
      batch(() => {
        dispatch(updateIsSaasUser(res.businessType === SAAS_BUSINESS_TYPE))
        dispatch(updateCompanyName(res))
        dispatch(companyRegistered(true))
        res?.businessType && dispatch(updateBusinessType(res?.businessType))
      })
      setCompanyLoading(false)
    },
    onError: () => {
      dispatch(companyRegistered(false))
      setCompanyLoading(false)
    }
  })

  const register = useMutation(authApi.register, {
    onSuccess: () => {
      getCompanyInfo.refetch()
      batch(() => {
        dispatch(registerCompanyLoading(false))
        dispatch(companyRegistered(true))
      })
    },
    onError: (err) => {
      batch(() => {
        dispatch(registerCompanyLoading(false))
        dispatch(companyRegistered(false))
        dispatch(companyConfirming(false))
        dispatch(errorSavingCompany(true))
      })
      const errorMsg = getErrorMessage(err)
      toast.error(errorMsg)
    }
  })

  const handleCreateCompanyInfo = (data) => {
    dispatch(registerCompanyLoading(true))
    register.mutate(data)
  }

  const handleGetEligibility = () => {
    setEligibilityLoading(true)
    getEligibility.refetch()
  }

  const getEligibility = useQuery('eligibility', authApi.getEligibility, {
    retry: false,
    onSuccess: (res) => {
      setEligibility(res.eligibility)
      setEligibilityLoading(false)
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
      setEligibilityLoading(false)
    },
    enabled: false
  })

  return {
    isCompanyConfirming,
    isCompanyRegistered,
    handleGetEligibility,
    eligibility,
    getEligibility,
    eligibilityLoading,
    handleCreateCompanyInfo,
    getCompanyInfo,
    companyLoading
  }
}

export default useCompanyInformation
