import { validateDataPresence } from './validateDataPresenceUtil'

const formatCurrency = (
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  currency = 'GBP'
) => {
  if (isNaN(value)) return validateDataPresence(value)

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits,
    maximumFractionDigits
  })

  return formatter.format(Number(value))
}

export default formatCurrency
