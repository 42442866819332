import React from 'react'
import { useSelector } from 'react-redux'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import { AuthorisationLayout } from '../../../layouts'

const OnboardingNotEligible = () => {
  const user = useSelector((state) => state.dataUser)
  return (
    <AuthorisationLayout hasFooter={false}>
      <div className="flex flex-col items-center justify-center min-h-content-fit-desktop">
        <div className="flex text-40px leading-56px">
          <div className="font-bold">
            {capitalizeFirstLetter(user?.firstName)}
          </div>
          , thank you for applying to Bloom.
        </div>
        <div className="text-xl w-680px text-center mt-4 leading-8">
          Unfortunately you do not meet our criteria at this time. Please refer
          to the{' '}
          <a
            href="https://www.letsbloom.com/faq/"
            className="font-bold text-pink-color"
            target={'_blank'}
          >
            {' '}
            FAQs
          </a>{' '}
          on our website for more information.
        </div>
      </div>
    </AuthorisationLayout>
  )
}

export default OnboardingNotEligible
