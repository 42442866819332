import * as Yup from 'yup'

export const xeroFormSchema = () => {
  return Yup.object().shape({
    writeBack: Yup.boolean(),
    accounts: Yup.object()
      .typeError('Account is required')
      .nullable()
      .optional()
      .shape({
        id: Yup.string(),
        name: Yup.string()
      })
      .when('writeBack', {
        is: true,
        then: Yup.object()
          .required(`Account is required`)
          .typeError('Account is required')
      }),
    bankAccounts: Yup.object()
      .typeError('Account is required')
      .nullable()
      .optional()
      .shape({
        id: Yup.string(),
        name: Yup.string()
      })
      .when('writeBack', {
        is: true,
        then: Yup.object()
          .required(`Bank account is required`)
          .typeError('Account is required')
      }),
    expenseAccounts: Yup.object()
      .typeError('Account is required')
      .nullable()
      .optional()
      .shape({
        id: Yup.string(),
        name: Yup.string()
      })
      .when('writeBack', {
        is: true,
        then: Yup.object()
          .required(`Expense account is required`)
          .typeError('Account is required')
      })
  })
}
