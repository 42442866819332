import Button from 'components/Button'
import CommonModal from 'modals/components/CommonModal'

const CantFindCompanyModal = ({ isOpened, close }) => {
  return (
    <CommonModal title="Sorry" isOpened={isOpened} close={close}>
      <p>Thank you for your interest in Bloom funding. </p>
      <p>
        Unfortunately, we are unable to support Non-Limited Business
        applications at this time.{' '}
      </p>

      <Button
        type="submit"
        variant="button-next-pink"
        className="ml-auto normal-case"
        onClick={close}
      >
        Ok
      </Button>
    </CommonModal>
  )
}

export default CantFindCompanyModal
