import React from 'react'
import configs from 'config/configs'
import formatCurrency from 'utils/formatCurrency'
import OutflowsAccordion from './OutflowsAccordion'
import useRecurringTableHook from './hook'

const OutflowsRecurringTable = ({ dataGrouped, loadMore, hasMore }) => {
  const { indexsShow, handleOnclick } = useRecurringTableHook()

  return (
    <div className="mt-6">
      <table className="w-full text-sm leading-24px text-text-color-bloom">
        <thead>
          <tr className="shadow-card-header-white text-header-table-gray">
            <td className="pb-4 pr-6">Date</td>
            <td className="pb-4 pr-6">Description</td>
            <td className="pb-4 pr-12 text-right">Amount</td>
          </tr>
        </thead>
        <tbody>
          {dataGrouped &&
            dataGrouped.map((data, index) => {
              let d
              if (data instanceof Array) {
                d = data
              } else {
                d = Object.values(data)
              }

              const firstRow = d[0]
              const accordionData = d.slice(1, d.length)
              return (
                <React.Fragment key={index}>
                  {firstRow && (
                    <tr className="shadow-card-header-white">
                      <td className="py-4 pr-6">
                        {new Date(firstRow.date).toLocaleDateString('en-UK')}
                      </td>
                      <td className="py-4 pr-6">{firstRow.description}</td>
                      <td className="py-4 pr-12 text-right">
                        <div className="flex flex-col">
                          {formatCurrency(firstRow.amount, 2)}
                          <span className="text-header-table-gray font-normal text-xs">
                            Predicted monthly
                          </span>
                        </div>
                      </td>
                      <td>
                        <img
                          src={configs.icons.arrowDropdown}
                          onClick={() => handleOnclick(index)}
                          className={`${indexsShow.includes(index) && 'transform rotate-180'}`}
                        />
                      </td>
                    </tr>
                  )}

                  {/* Grouped row */}
                  {indexsShow.includes(index) && (
                    <OutflowsAccordion data={accordionData} />
                  )}
                </React.Fragment>
              )
            })}
        </tbody>
      </table>
      {hasMore && (
        <div className="flex items-center justify-center mt-6 text-header-table-gray font-medium text-base leading-48px">
          <button onClick={loadMore}>Load more</button>
        </div>
      )}
    </div>
  )
}

export default OutflowsRecurringTable
