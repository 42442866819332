import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { batch, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import authApi from 'config/api/auth'
import consumer from 'config/channels/consumer'
import { ROOT_ROUTES } from 'config/configs/routes'
import { useAnalytics } from 'hooks/useAnalytics'
import { loginSuccess, logout, updateCompanyName } from 'redux/actions/Auth'
import { resetOnboardingState } from 'redux/actions/Onboarding'
import { resetPersistState } from 'redux/actions/PersistRegister'
import { resetRegisterState } from 'redux/actions/Register'
import { getErrorMessage } from 'utils/getErrorMessage'
import { jwtDecode } from 'utils/jwtDecode'

// Provide hooks utility for authentication
const useAuthHook = () => {
  const [eligibility, setEligibility] = useState('1')
  const [isInitDone, setIsInitDone] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const analytics = useAnalytics()

  const refreshTokenQuery = useQuery(
    'refreshToken',
    () => authApi.refreshToken(),
    {
      onSuccess: (res) => {
        handleRefreshSuccess(res.token)
      },
      onError: () => {
        handleRefreshError()
      },
      enabled: false,
      retry: false
    }
  )

  const loginMutation = useMutation(authApi.login, {
    onSuccess: (res) => {
      if (res.token) {
        handleGetCompanyInfo()
      }
      handleLoginSuccess(res.token)
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  const handleLogin = (data) => {
    loginMutation.mutate(data)
  }

  const getCompanyInfo = useQuery('getCompanyInfo', authApi.getCompanyInfo, {
    onSuccess: (res) => {
      dispatch(updateCompanyName(res))
    },
    retry: false,
    enabled: false
  })

  const fetchEligibility = useQuery('eligibility', authApi.getEligibility, {
    onSuccess: (res) => {
      setEligibility(res.action)
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    },
    enabled: false
  })

  const handleGetEligibility = () => {
    fetchEligibility.refetch()
  }

  const handleGetCompanyInfo = () => {
    getCompanyInfo.refetch()
  }

  const handleLoginSuccess = (token) => {
    const decodedToken = jwtDecode(token)
    analytics.identify(decodedToken.sub)

    dispatch(
      loginSuccess({
        token,
        expiresAt: decodedToken.exp,
        impersonating: decodedToken.impersonating
      })
    )

    // This is needed in order to connect successfully to WS.
    // Right now, only logged in users can connect to websocket (because we don't want to broadcast messages to everyone).
    // If we don't reload the page upon successful login, the WS connection will not be established
    consumer.subscriptions.consumer.connect()
  }

  // LOGOUT
  const handleLogoutSuccess = () => {
    batch(() => {
      dispatch(resetOnboardingState())
      dispatch(resetRegisterState())
      dispatch(resetPersistState())
      dispatch(logout())
    })
    return history.push(ROOT_ROUTES.SIGNUP)
  }

  const logoutMutation = useMutation(authApi.logout, {
    onSuccess: () => {
      handleLogoutSuccess()
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  // REFRESH TOKENS
  const handleRefreshSuccess = (token) => {
    const decodedToken = jwtDecode(token)
    dispatch(
      loginSuccess({
        token,
        expiresAt: decodedToken.exp,
        impersonating: decodedToken.impersonating
      })
    )
  }

  const handleRefreshError = () => {
    batch(() => {
      dispatch(resetOnboardingState())
      dispatch(resetRegisterState())
      dispatch(logout())
    })
  }

  const initAuth = async () => {
    setIsInitDone(false)
    const { isSuccess } = await refreshTokenQuery.refetch()
    if (isSuccess) {
      const company = await getCompanyInfo.refetch()

      if (company.isSuccess) return setIsInitDone(true)
    }
    return null
  }

  return {
    initAuth,
    handleLogin,
    loginLoading: loginMutation.isLoading,
    logoutLoading: logoutMutation.isLoading,
    isInitAuthLoading: refreshTokenQuery.isLoading || getCompanyInfo.isLoading,
    isInitDone,
    handleLoginSuccess,
    handleLogout,
    handleGetEligibility,
    handleGetCompanyInfo,
    eligibility,
    refreshTokenLoading: refreshTokenQuery.isLoading
  }
}

export default useAuthHook
