import React from 'react'

const CategoriesTable = ({ transactions }) => {
  return (
    <div className="mt-6 overflow-x-auto text-sm leading-24px text-text-color-bloom">
      <table className="w-full">
        <thead>
          <tr className="shadow-card-header-white text-header-table-gray">
            <td className="pb-4 pr-6">Date</td>
            <td className="pb-4 pr-6">Description</td>
            <td className="pb-4 pr-6">Recurrence</td>
            <td className="pb-4 pr-6">Amount</td>
            <td className="pb-4 pr-12 text-right">Closing balance</td>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr className="shadow-card-header-white space-x-6 py-4" key={index}>
              <td className="py-4 pr-6">
                {new Date(transaction.date).toLocaleString('en-UK')}
              </td>
              <td className="pb-4 pr-6">{transaction.description}</td>
              <td className="py-4 pr-6">{transaction.category}</td>
              <td className="py-4 pr-6">
                {Number(transaction.in) !== 0
                  ? transaction.in
                  : transaction.out}
              </td>
              <td className="pb-4 pr-12 text-right">{transaction.balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CategoriesTable
