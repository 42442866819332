import moment from 'moment'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import configs from 'config/configs'

const FormikDatePickerGrey = ({
  placeholder,
  values,
  errors,
  field,
  handleChange,
  inputClassname,
  shouldValid,
  containerClassname,
  content,
  disabled
}) => {
  const borderColor =
    shouldValid && errors[field]
      ? 'border-error-message'
      : 'border-b-placeholder'

  const [pickedDate, setPickedDate] = useState(new Date())

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div
        className={`flex flex-row items-center w-full h-14 px-4 rounded-lg bg-white border-1/2 outline-none transition-all placeholder:text-base placeholder:text-placeholder-grey focus:border-2 focus:border-dark-grey focus:border-input-focus ${inputClassname} ${borderColor}`}
      >
        <label
          className={`w-full cursor-pointer ${props.value ? '' : 'text-placeholder-grey'}`}
          onClick={props.onClick}
          ref={ref}
        >
          {props.value || props.placeholder}
        </label>
        <img src={configs.icons.calendarGrey} onClick={props.onClick} />
      </div>
    )
  })

  return (
    <div
      className={`flex flex-col justify-center w-full mx-auto ${containerClassname}`}
    >
      <div className="flex justify-between align-center max-w-400px relative">
        {content}
        <DatePicker
          value={values[field]}
          placeholderText={placeholder}
          disabled={!!disabled}
          selected={pickedDate}
          onChange={(date) => {
            setPickedDate(date)
            if (handleChange)
              handleChange(field, moment(date).format('DD-MM-YYYY'))
          }}
          dateFormat="dd-MM-yyyy"
          customInput={<CustomInput />}
        />
      </div>
      {shouldValid && errors[field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {errors[field]}
        </p>
      )}
    </div>
  )
}

export default FormikDatePickerGrey
