import React from 'react'
import useHeaderDropdownHook from './hook'

const LinkItem = ({ item }) => {
  return (
    <div className="py-4 w-full">
      <a href={item.to} className="font-poppins text-white font-medium">
        {item.title}
      </a>
    </div>
  )
}

const ActionItem = ({ item }) => {
  return (
    <button className="py-4 w-full font-medium" onClick={item.onClick}>
      <p className="font-poppins text-left text-white">{item.title}</p>
    </button>
  )
}

const HeaderDropdown = ({ show = false, offsetY = '0' }) => {
  const { dropdownItems } = useHeaderDropdownHook()

  if (!show) {
    return <React.Fragment></React.Fragment>
  }

  return (
    <div
      className={`px-6 py-2 w-168px rounded-lg shadow-header-dropdown animate-dropdownAppear origin-top bg-b-purple absolute z-50 right-0 top-${offsetY} divide-y divide-white divide-opacity-24`}
    >
      {dropdownItems.map((item, index) => {
        if (item.action === 'navigate') {
          return <LinkItem item={item} key={index} />
        }
        if (item.action === 'click') {
          return <ActionItem item={item} key={index} />
        }
      })}
    </div>
  )
}

export default HeaderDropdown
