import React from 'react';
import { Link } from 'react-router-dom';
import useSubnavHook from './hook';

const SubNav = () => {
  const { subnavList, pathname } = useSubnavHook();

  return (
    <div className="flex mb-4 overflow-x-auto">
      {subnavList.map((sub, index) => {
        const isActive = sub.to === pathname;
        const activeClassName = isActive ? 'bg-grey-blue-bloom text-indingo-bloom font-semibold' : '';
        return (
          <Link to={sub.to} key={index} className={`px-4 py-3 rounded-lg ${activeClassName}`}>
            {sub.name}
          </Link>
        );
      })}
    </div>
  );
};

export default SubNav;
