import React from 'react'
import ExpiredMessage from './ExpiredMessage'
import ShowCounter from './ShowCounter'
import useCountdown from './hook'

const CountdownTimer = ({ target }) => {
  const { days, hours, minutes, seconds, expired } = useCountdown(target)

  return !!expired ? (
    <ExpiredMessage target={target} />
  ) : (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      target={target}
    />
  )
}

export default CountdownTimer
