import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import ConnectionSuccessful from 'components/ConnectionSuccessful'
import FormikInputTextGrey from 'components/FormikInputTextGrey'
import api from 'config/api/onboarding'
import configs from 'config/configs'
import { websiteRegex } from 'config/configs/constant'
import CommonModal from 'modals/components/CommonModal'
import { getErrorMessage } from 'utils/getErrorMessage'

const WooCommerceModal = ({ image, isOpened, close }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const createConnectionMut = useMutation(api.submitRutterIntegrationData, {
    onSuccess: () => {
      setShowSuccess(true)
    },
    onError: (err) => {
      const errorMsg = getErrorMessage(err)
      toast.error(errorMsg)
      close()
      setShowSuccess(false)
    }
  })

  const formik = useFormik({
    initialValues: {
      store_url: '',
      basic_username: '',
      basic_password: ''
    },
    validationSchema: Yup.object().shape({
      store_url: Yup.string()
        .matches(websiteRegex, 'Please enter a valid store URL.')
        .required('Store name is required.'),
      basic_username: Yup.string().required('Basic Username is required.'),
      basic_password: Yup.string().required('Basic Password is required.')
    }),
    onSubmit: (values) => {
      createConnectionMut.mutate({
        platform: 'woo_commerce',
        connection_parameters: values
      })
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  const onCloseSuccessModal = () => {
    setShowSuccess(false)
    close()
  }

  return (
    <CommonModal
      title={'Connect your WooCommerce store'}
      isOpened={isOpened}
      close={close}
    >
      {showSuccess ? (
        <ConnectionSuccessful
          integration="woocommerce"
          onClick={onCloseSuccessModal}
        />
      ) : (
        <>
          <div className="flex flex-col justify-center w-full border-light-grey-200 border-b mb-4">
            <div className="flex justify-center items-center">
              <img src={image} alt="woocommerce-logo" className="w-auto h-20" />
            </div>

            <div className="flex flex-col w-full text-left gap-y-2 mb-2">
              <p className="text-sm md:text-base text-black font-semibold">
                Store URL
              </p>
              <p className="text-sm md:text-base text-placeholder-grey">
                Please enter in your WooCommerce domain. If your store is at
                www.mystore.com, then enter in "www.mystore.com".
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left">
                  <FormikInputTextGrey
                    field="store_url"
                    id="store_url"
                    placeholder="www.mystore.com"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                    autoFocus
                  />
                </div>
              </form>
            </div>

            <div className="flex flex-col w-full text-left gap-y-2 mb-2">
              <p className="text-sm md:text-base text-black font-semibold">
                Rest API Key
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left">
                  <FormikInputTextGrey
                    field="basic_username"
                    id="basic_username"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                    autoFocus
                  />
                </div>
              </form>
            </div>

            <div className="flex flex-col w-full text-left gap-y-2 mb-2">
              <p className="text-sm md:text-base text-black font-semibold">
                Rest API Secret
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left">
                  <FormikInputTextGrey
                    field="basic_password"
                    id="basic_password"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                    autoFocus
                  />
                </div>
              </form>
            </div>

            <div className="flex justify-between items-center mb-4">
              <Button
                type="submit"
                variant={'button-next-pink'}
                className="ml-auto normal-case"
                disabled={!(formik.isValid && formik.dirty)}
                onClick={onSubmit}
                loading={createConnectionMut.isLoading}
              >
                Connect WooCommerce
              </Button>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full pt-2">
            <div className="flex justify-center gap-x-2 items-center">
              <img src={configs.icons.lockClosed} />
              <span className="text-sm text-placeholder-grey">
                Secured with 256-bit encryption
              </span>
            </div>
          </div>
        </>
      )}
    </CommonModal>
  )
}

export default WooCommerceModal
