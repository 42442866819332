import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import api from 'config/api/accountsAndBalances/index'

const useCategoriesHook = () => {
  const initialState = {
    data: {
      transactions: []
    },
    meta: {
      totalCount: 0
    },
    hasMore: false
  }

  const [allCategoriesInflowsGraphData, setAllCategoriesInflowsGraphData] =
    useState([])
  const [allCategoriesOutflowsGraphData, setAllCategoriesOutflowsGraphData] =
    useState([])
  const [transactionInflowData, setTransactionInflowData] =
    useState(initialState)
  const [transactionOutflowData, setTransactionOutflowData] =
    useState(initialState)
  const [selectedCategoriesInflowsPeriod, setSelectedCategoriesInflowsPeriod] =
    useState('12m')
  const [
    selectedCategoriesOutflowsPeriod,
    setSelectedCategoriesOutflowsPeriod
  ] = useState('12m')
  const [selectedInflowCategory, setSelectedInflowCategory] = useState('')
  const [selectedOutflowCategory, setSelectedOutflowCategory] = useState('')
  const [lastDateInflowTransactions, setLastDateInflowTransactions] =
    useState('')
  const [lastDateOutflowTransactions, setLastDateOutflowTransactions] =
    useState('')
  const [categoryInflowData, setCategoryInflowData] = useState({
    chart: [],
    averages: []
  })
  const [categoryOutflowData, setCategoryOutflowData] = useState({
    chart: [],
    averages: []
  })

  const categoryInflowsOptions = allCategoriesInflowsGraphData.map(
    (item) => item.category
  )
  const categoryOutflowsOptions = allCategoriesOutflowsGraphData.map(
    (item) => item.category
  )

  useEffect(() => {
    if (
      allCategoriesInflowsGraphData.length > 0 &&
      selectedInflowCategory.length === 0
    ) {
      setSelectedInflowCategory(allCategoriesInflowsGraphData[0].category)
    }
  }, [allCategoriesInflowsGraphData])

  useEffect(() => {
    if (
      allCategoriesOutflowsGraphData.length > 0 &&
      selectedOutflowCategory.length === 0
    ) {
      setSelectedOutflowCategory(allCategoriesOutflowsGraphData[0].category)
    }
  }, [allCategoriesOutflowsGraphData])

  const categoryInflowsGraphQuery = useQuery(
    ['getCategoryInflows', selectedCategoriesInflowsPeriod],
    () => api.getCategoryFlows('in', selectedCategoriesInflowsPeriod),
    {
      retry: true,
      onSuccess: (res) => {
        setAllCategoriesInflowsGraphData(res)
      }
    }
  )

  const categoryOutflowsGraphQuery = useQuery(
    ['getCategoryOutflows', selectedCategoriesOutflowsPeriod],
    () => api.getCategoryFlows('out', selectedCategoriesOutflowsPeriod),
    {
      retry: true,
      onSuccess: (res) => {
        setAllCategoriesOutflowsGraphData(res)
      }
    }
  )

  const inflowPerCategoryQuery = useQuery(
    ['inflowPerCategoryQuery', selectedInflowCategory],
    () => api.getCategoryInformation('in', selectedInflowCategory),
    {
      enabled: false,
      onSuccess: (res) => {
        setCategoryInflowData(res)
      }
    }
  )

  const outflowPerCategoryQuery = useQuery(
    ['outflowPerCategoryQuery', selectedOutflowCategory],
    () => api.getCategoryInformation('out', selectedOutflowCategory),
    {
      enabled: false,
      onSuccess: (res) => {
        setCategoryOutflowData(res)
      }
    }
  )

  const inflowTransactionsQuery = useQuery(
    [
      'getTransactionInflows',
      selectedInflowCategory,
      lastDateInflowTransactions
    ],
    () =>
      api.getTransactionFlows(
        selectedInflowCategory,
        'in',
        lastDateInflowTransactions
      ),
    {
      enabled: false,
      onSuccess: (res) => {
        setTransactionInflowData({
          ...transactionInflowData,
          data: {
            transactions: [
              ...transactionInflowData.data.transactions,
              ...res.data.transactions
            ]
          },
          meta: res.meta,
          hasMore: res.hasMore
        })
      }
    }
  )

  const outflowTransactionsQuery = useQuery(
    [
      'getTransactionOutflows',
      selectedOutflowCategory,
      lastDateOutflowTransactions
    ],
    () =>
      api.getTransactionFlows(
        selectedOutflowCategory,
        'out',
        lastDateOutflowTransactions
      ),
    {
      enabled: false,
      onSuccess: (res) => {
        setTransactionOutflowData({
          ...transactionOutflowData,
          data: {
            transactions: [
              ...transactionOutflowData.data.transactions,
              ...res.data.transactions
            ]
          },
          meta: res.meta,
          hasMore: res.hasMore
        })
      }
    }
  )

  useEffect(() => {
    if (selectedOutflowCategory) {
      outflowTransactionsQuery.refetch()
    }
  }, [selectedOutflowCategory, lastDateOutflowTransactions])

  useEffect(() => {
    if (selectedInflowCategory) {
      inflowTransactionsQuery.refetch()
    }
  }, [selectedInflowCategory, lastDateInflowTransactions])

  useEffect(() => {
    if (selectedInflowCategory) {
      inflowPerCategoryQuery.refetch()
    }
  }, [selectedInflowCategory])

  useEffect(() => {
    if (selectedOutflowCategory) {
      outflowPerCategoryQuery.refetch()
    }
  }, [selectedOutflowCategory])

  const onCategoriesOutflowsChange = (value) => {
    setSelectedCategoriesOutflowsPeriod(value)
  }

  const onCategoriesInflowsChange = (value) => {
    setSelectedCategoriesInflowsPeriod(value)
  }

  const filterByCategoryInflows = (value) => {
    setTransactionInflowData(initialState)
    setSelectedInflowCategory(value)
  }

  const filterByCategoryOutflows = (value) => {
    setTransactionOutflowData(initialState)
    setSelectedOutflowCategory(value)
  }

  const loadMoreInflowTransactions = (value) => {
    setLastDateInflowTransactions(value)
  }

  const loadMoreOutflowTransactions = (value) => {
    setLastDateOutflowTransactions(value)
  }

  return {
    isLoading:
      categoryOutflowsGraphQuery.isLoading ||
      categoryInflowsGraphQuery.isLoading,
    allCategoriesOutflowsGraphData,
    allCategoriesInflowsGraphData,
    categoryInflowData,
    categoryOutflowData,
    transactionInflowData,
    transactionOutflowData,
    categoryInflowsOptions,
    categoryOutflowsOptions,
    onCategoriesOutflowsChange,
    onCategoriesInflowsChange,
    filterByCategoryInflows,
    filterByCategoryOutflows,
    loadMoreInflowTransactions,
    loadMoreOutflowTransactions
  }
}

export default useCategoriesHook
