import * as actionTypes from '../../types/User'

export const userSuccess = (value) => {
  return {
    type: actionTypes.USER_SUCCESS,
    payload: {
      value
    }
  }
}

export const updateIsSaasUser = (value) => {
  return {
    type: actionTypes.UPDATE_IS_SAAS_USER,
    payload: {
      value
    }
  }
}
