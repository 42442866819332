import PersonalInformation from 'components/PersonalInformation'
import usePersonalFormHook from 'components/PersonalInformation/hook'
import { AuthorisationLayout } from '../../../layouts'

const SignUp = () => {
  const {
    formik,
    shouldShowError,
    passwordBar,
    onPersonalNext,
    handleChange,
    loading,
    onClickBack
  } = usePersonalFormHook(undefined)
  return (
    <AuthorisationLayout
      screenName={'Signup'}
      hasHeader={true}
      hasFooter={false}
      headerBackground={'bg-light-grey-100'}
    >
      <div className="container mx-auto px-6 md:px-0">
        <div className="flex justify-center mt-6 md:mt-16 3xl:mt-8">
          <div
            className={`flex flex-col w-full md:max-w-2xl relative mt-8 xl:mt-0`}
          >
            <PersonalInformation
              onPersonalNext={onPersonalNext}
              onClickBack={onClickBack}
              formik={formik}
              shouldShowError={shouldShowError}
              passwordBar={passwordBar}
              handleChange={handleChange}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </AuthorisationLayout>
  )
}

export default SignUp
