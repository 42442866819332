import React from 'react'
import { useSelector } from 'react-redux'
import Footer from 'layouts/components/Footer'
import Header from 'layouts/components/Header'
import SidebarDashboardEcommerce from 'layouts/components/SidebarDashboardEcommerce'
import SidebarDashboardSaaS from 'layouts/components/SidebarDashboardSaaS'

const AppLayout = ({
  screenName,
  hasHeader = true,
  hasFooter = false,
  hasSidebar = true,
  SidebarBottomWidget,
  children
}) => {
  // If header is expanded, disable scroll on body
  const { headerExpanded } = useSelector((state) => state.dataApp)
  const headerExpandedClass = headerExpanded ? 'h-screen' : ''
  const persistedDataRegister = useSelector(
    (state) => state.persistedDataRegister
  )
  const { isSaaSUser } = persistedDataRegister
  const { impersonating } = useSelector((state) => state.dataAuth)

  return (
    <div className={`text-dark-grey ${headerExpandedClass}`}>
      {impersonating && (
        <div
          class="h-14 w-100 bg-red-600	text-white flex items-center pl-4 gap-3"
          style={{ background: '#171930' }}
        >
          <span>You are in impersonation mode for user</span>
          <span>
            <a style={{ color: '#50e3c2' }} href="/impersonate/finish">
              Click here to stop impersonating
            </a>
          </span>
        </div>
      )}
      {hasHeader && (
        <Header
          screenName={screenName}
          shouldShowSaasDashboardSidebar={isSaaSUser}
          shouldShowEcommerceDashboardSidebar={!isSaaSUser}
        />
      )}
      <div className="bg-body-grey px-2 md:pt-12 xl:px-0 min-h-content-fit-mobile md:min-h-content-fit-desktop h-fit-content">
        <div className="container mx-auto grid grid-cols-12 md:gap-16">
          {hasSidebar && (
            <div className="hidden col-span-12 md:col-span-3 md:row-span-2 lg:col-span-3 lg:block">
              {/* sidebar for desktop view */}
              {isSaaSUser ? (
                <SidebarDashboardSaaS>
                  {SidebarBottomWidget}
                </SidebarDashboardSaaS>
              ) : (
                <SidebarDashboardEcommerce>
                  {SidebarBottomWidget}
                </SidebarDashboardEcommerce>
              )}
            </div>
          )}

          <div className="col-span-12 md:col-span-12 lg:col-span-9 mt-4 lg:mt-0">
            {children}
          </div>
        </div>
      </div>
      {hasFooter && <Footer />}
    </div>
  )
}

export default AppLayout
