import React from 'react'
import formatCurrency from 'utils/formatCurrency'
import Button from '../Button'

const ButtonGroup = ({
  value,
  options,
  onChange,
  disabled,
  isRecurring,
  repayMonthOptions
}) => {
  if (isRecurring) {
    return (
      <div className="flex flex-col rounded-group border border-grey-group">
        <div className="flex flex-row justify-center bg-grey-group py-1 px-1">
          {options?.map((option, index) => {
            const type =
              option == value ? 'button-group-active' : 'button-group'
            const isEligible = repayMonthOptions?.[option]?.isEligible
            return (
              <Button
                disabled={!isEligible || disabled}
                key={index}
                variant={type}
                onClick={() => option && onChange(option)}
                children={`${option} months`}
              />
            )
          })}
        </div>
        <div className="flex flex-row mt-2 justify-center">
          {options?.map((option, index) => {
            const isEligible = repayMonthOptions?.[option]?.isEligible
            return (
              <div className="flex flex-col justify-center w-1/3" key={index}>
                <div
                  className={`flex justify-center text-xs ${!isEligible && 'opacity-20'}`}
                >
                  {isEligible
                    ? formatCurrency(repayMonthOptions?.[option]?.monthlyFee)
                    : '-'}
                </div>
              </div>
            )
          })}
        </div>
        <div className="flex justify-center mb-1 text-xs">
          Estimated Monthly Repayment
        </div>
        {/* <div className="flex justify-center mb-1 text-xs">Monthly Repayment Amount</div> */}
      </div>
    )
  }
  return (
    <div className="flex bg-grey-group rounded-group py-1 px-1 border border-grey-group">
      {options?.map((option, index) => {
        const type = option == value ? 'button-group-active' : 'button-group'
        return (
          <Button
            disabled={disabled}
            key={index}
            variant={type}
            onClick={() => option && onChange(option)}
            children={`${option} months`}
          />
        )
      })}
    </div>
  )
}

export default ButtonGroup
