import React, { Suspense, lazy } from 'react'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'

const OverviewCreditLimit = lazy(
  () => import('../../../pages/app/OverviewCreditLimit')
)

export const FeatureDashboardRouter = () => {
  return (
    <Suspense fallback={<AppLayoutSkeleton />}>
      <OverviewCreditLimit />
    </Suspense>
  )
}
