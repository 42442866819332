import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const CircularProgressWithMeta = ({ days, value, className, styles }) => {
  return (
    <div className="flex flex-row space-x-3">
      <div
        className="min-w-6"
        data-tip={`The percentage probability the company will not exceed their credit limit during the next ${days} days`}
        data-for={`circleTip-${value}`}
      >
        <CircularProgressbar
          value={value}
          className={className}
          styles={styles}
        />
      </div>
      <ReactTooltip id={`circleTip-${value}`} place="top" effect="solid" />
      <div className="flex flex-col justify-center">
        <span className="text-sm text-text-color-grey-bloom">{`${days}d liquidity`}</span>
        <span className="text-2xl font-semibold text-indingo-bloom">
          {value}
        </span>
      </div>
    </div>
  )
}

export default CircularProgressWithMeta
