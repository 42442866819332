import React from 'react'
import { Link } from 'react-router-dom'

const PartnersMaintenance = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-content-fit-desktop">
      <div className="flex text-40px leading-56px">We'll Be Back Soon</div>
      <div className="text-xl w-680px text-center mt-4 leading-8">
        We are under maintenance. To apply for funding please visit
        <Link
          to="/login"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2"
        >
          <span className="text-pink-color font-bold border-pink-color border-b hover:opacity-80 transition-opacity">
            Bloom
          </span>
        </Link>
      </div>
    </div>
  )
}

export default PartnersMaintenance
