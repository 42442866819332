import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import userApi from 'config/api/user'
import { updateEmbeddedPartner } from 'redux/actions/PersistRegister'
import { userSuccess } from 'redux/actions/User'

// Provide hooks utility for authentication
const useUser = () => {
  const userData = useSelector((state) => state.dataUser)
  const dispatch = useDispatch()
  const [isUserLoading, setIsUserLoading] = useState(false)

  const getUser = useQuery('user', () => userApi.getUser(), {
    onSuccess: (res) => {
      dispatch(userSuccess(res))
      res.embeddedPartner &&
        dispatch(updateEmbeddedPartner(res.embeddedPartner))
      setIsUserLoading(false)

      if (typeof woopra !== 'undefined') {
        woopra.identify({
          id: res.id,
          email: res.email,
          name: res.firstName + ' ' + res.lastName,
          phone: res.phone,
          keep_me_informed: res.keepInformed
        })
      }
    },
    onError: () => {
      setIsUserLoading(false)
    },
    enabled: false,
    retry: false
  })

  const handleGetUser = () => {
    setIsUserLoading(true)
    return getUser.refetch()
  }

  return {
    handleGetUser,
    isUserLoading,
    userData
  }
}

export default useUser
