import React from 'react'

const Card = ({
  headerTitle,
  headerClassname,
  headerActionComponent: HeaderActionComponent,
  headerActionProps,
  children,
  cardClassname,
  bgColor = 'white'
}) => {
  return (
    <div
      className={`rounded-lg p-6 bg-${bgColor} ${cardClassname} overflow-x-auto`}
    >
      <div
        className={`flex justify-between pb-6 shadow-card-header-${bgColor}`}
      >
        {headerTitle && (
          <p
            className={`flex items-center text-dark-grey font-bold text-xl ${headerClassname}`}
          >
            {headerTitle}
          </p>
        )}
        {HeaderActionComponent && (
          <HeaderActionComponent {...headerActionProps}></HeaderActionComponent>
        )}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default Card
