import dayjs from 'dayjs'
import React from 'react'

const ExpiredMessage = ({ target }) => {
  return (
    <div className="flex justify-center my-4">
      Your offer expired on the
      <span className="font-bold ml-1">
        {dayjs(target).format('DD MMM YYYY HH:mm')}
      </span>
    </div>
  )
}

export default ExpiredMessage
