import React from 'react'
import { useSelector } from 'react-redux'
import configs from 'config/configs'

const useSidebarOnboardingHook = () => {
  const onboardingData = useSelector((state) => state.dataOnboarding)
  const { currentStep, percentage, config, meta } = onboardingData

  const cardDescription = () => {
    return (
      <div>
        <img
          className={'h-14px relative top-4'}
          src={configs.icons.logoBloomName}
        />
        <p style={{ textIndent: '50px' }}>
          uses bank-level encryption to ensure your information remains safe and
          secure.
        </p>
      </div>
    )
  }

  return {
    sidebarSteps: config,
    currentStep,
    percentage,
    meta,
    cardDescription
  }
}

export default useSidebarOnboardingHook
