import React from 'react'
import Button from 'components/Button'
import CommonModal from 'modals/components/CommonModal'

const HelpThankYouModal = ({ isOpened, close }) => {
  return (
    <CommonModal title={'Thank you'} isOpened={isOpened} close={close}>
      <div>
        <div className="mb-6">
          <p className="text-xl">
            Your request has been submitted. The team will get back to you soon.
          </p>
        </div>

        {/* Phone removed as per https://bloomfin.atlassian.net/browse/EN-60 */}
        {/*<div className="mb-6">*/}
        {/*  <p className="text-xl">*/}
        {/*    Call{' '}*/}
        {/*    <a href={`tel:${getPhoneNumber().href}`} className="hover:opacity-80 transition-opacity font-semibold">*/}
        {/*      {getPhoneNumber().text}*/}
        {/*    </a>{' '}*/}
        {/*    if you have any questions in the meantime.*/}
        {/*  </p>*/}
        {/*</div>*/}

        <div className="flex flex-col">
          <Button
            type="button"
            variant="bordered"
            className="text-placeholder-grey border-placeholder-grey font-bold"
            onClick={close}
          >
            OK
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default HelpThankYouModal
