import React from 'react'

const Spinner = ({ bgColor, color, height = '5' }) => {
  return (
    <div
      className={`rounded-full flex items-center relative bg-${bgColor} mx-2`}
    >
      <div
        className={`absolute animate-spin rounded-full h-${height} w-${height} border-b-2 border-${color}`}
      ></div>
    </div>
  )
}

export default Spinner
