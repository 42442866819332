import { PartnersLayout } from 'layouts'
import React from 'react'
import Button from 'components/Button'
import LoadingScreen from 'components/LoadingScreen'
import configs from 'config/configs'
import { useEmailVerification } from 'pages/authorisation/EmailVerification/hook'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import AuthorisationLayout from '../../../layouts/AuthorisationLayout'

const EmailVerification = ({
  firstName,
  email,
  confirmationSentAt,
  partners
}) => {
  const { shouldResend, waitingTimeRemainingSec, showResend, onClick } =
    useEmailVerification({
      email,
      confirmationSentAt
    })

  if (!firstName || !email || !confirmationSentAt) {
    return <LoadingScreen />
  }

  const pageContent = (
    <div className="flex flex-col items-center justify-center min-h-content-fit-desktop">
      <div className="text-center text-40px leading-56px">
        <div className="font-bold">
          Hi {capitalizeFirstLetter(firstName)}! &nbsp;{' '}
        </div>
        Let's verify your email address
      </div>
      <div className="text-xl w-680px text-center mt-4 leading-8">
        Welcome to Bloom! Check the inbox of the email address you added &
        verify your email to continue to setting up your account.
      </div>
      {showResend && (
        <div className="mt-20">
          <Button
            variant="button-next-pink"
            className="w-full md:w-auto"
            affixIcon={configs.icons.arrowForward}
            type="button"
            onClick={onClick}
            disabled={!shouldResend}
          >
            {`Resend Email ${!shouldResend ? `(${waitingTimeRemainingSec})` : ''}`}
          </Button>
        </div>
      )}
    </div>
  )

  return partners?.logo ? (
    <PartnersLayout hasFooter={false} logo={partners?.logo}>
      {pageContent}
    </PartnersLayout>
  ) : (
    <AuthorisationLayout hasFooter={false}>{pageContent}</AuthorisationLayout>
  )
}

export default EmailVerification
