import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import configs from 'config/configs'

const FormikCurrencyInputGrey = ({
  placeholder,
  values,
  errors,
  field,
  handleChange,
  inputClassname,
  prefix,
  shouldValid,
  containerClassname,
  disabled
}) => {
  const borderColor =
    shouldValid && errors[field]
      ? 'border-error-message'
      : 'border-b-placeholder'

  return (
    <div
      className={`flex flex-col justify-center w-full mx-auto ${containerClassname}`}
    >
      <div className="relative flex justify-between align-center">
        <div className={`absolute left-3 top-1/2 transform -translate-y-1/2`}>
          <img src={configs.icons.currencyPound} className="w-6 h-6" />
        </div>
        <CurrencyInput
          value={values[field]}
          className={`flex-grow flex flex-row items-center w-full h-14 pl-11 pr-4 rounded-lg bg-white border-1/2 outline-none transition-all placeholder:text-base placeholder:text-placeholder-grey focus:border-2 focus:border-dark-grey outline-none ${inputClassname} ${borderColor}`}
          placeholder={placeholder}
          decimalsLimit={2}
          disabled={!!disabled}
          prefix={prefix || '£'}
          onValueChange={(value) => {
            if (handleChange) handleChange(field, value)
          }}
        />
      </div>
      {shouldValid && errors[field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {errors[field]}
        </p>
      )}
    </div>
  )
}

export default FormikCurrencyInputGrey
