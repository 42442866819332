import configs from 'config/configs'
import { ROOT_ROUTES } from 'config/configs/routes'

const { DASHBOARD, CONNECTIONS, DOCUMENTS, SETTINGS } = ROOT_ROUTES

export const steps = [
  {
    title: 'Dashboard',
    url: DASHBOARD,
    routeMatch: DASHBOARD,
    icon: configs.icons.home
  },
  {
    title: 'Connections',
    url: CONNECTIONS,
    routeMatch: CONNECTIONS,
    icon: configs.icons.connections
  },
  {
    title: 'Documents',
    url: DOCUMENTS,
    routeMatch: DOCUMENTS,
    icon: configs.icons.document
  },
  {
    title: 'Settings',
    url: SETTINGS,
    routeMatch: SETTINGS,
    icon: configs.icons.settings
  }
]

export const options = {
  directNavigation: true,
  showTick: false
}
