import * as actionTypes from '../../types/Register'

export const resetRegisterState = () => {
  return {
    type: actionTypes.RESET_REGISTER_STATE
  }
}

export const updateCurrentStep = (value) => {
  return {
    type: actionTypes.UPDATE_CURRENT_STEP,
    payload: {
      value
    }
  }
}

export const errorSavingCompany = (value) => {
  return {
    type: actionTypes.SAVE_COMPANY_ERROR,
    payload: {
      value
    }
  }
}

export const selectCompany = (company) => {
  return {
    type: actionTypes.SELECT_COMPANY,
    payload: { company }
  }
}

export const removeSelectedCompany = () => {
  return {
    type: actionTypes.REMOVE_SELECT_COMPANY
  }
}

export const updateSelectedCompanyForm = (field, value) => {
  return {
    type: actionTypes.UPDATE_SELECT_COMPANY_FORM,
    payload: {
      field,
      value
    }
  }
}

export const updateNewCompanyForm = (field, value) => {
  return {
    type: actionTypes.UPDATE_NEW_COMPANY_FORM,
    payload: {
      field,
      value
    }
  }
}

export const setCompletedStep1 = (value = true) => {
  return {
    type: actionTypes.COMPLETED_STEP_1,
    payload: {
      value
    }
  }
}

export const updatePersonalInfoValues = (values) => {
  return {
    type: actionTypes.UPDATE_PERSONAL_INFO_VALUES,
    payload: {
      ...values
    }
  }
}

export const setIsNewCompany = (value = true) => {
  return {
    type: actionTypes.SET_IS_NEW_COMPANY,
    payload: {
      value
    }
  }
}
