import axios from '../../../services/axios'

const shops = async () => {
  const response = await axios.get(`/inlr/shopify/shops`)

  return response.data
}

export default {
  shops
}
