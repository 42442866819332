import React, { useMemo } from 'react'
import icons from 'config/configs/icons'
import {
  INTEGRATION_CONNECTED,
  INTEGRATION_CRITICAL,
  INTEGRATION_WARNING
} from 'enums/Integrations'

const ConnectedBank = ({
  institutionId,
  bankName,
  sortCode,
  accountHolder,
  accounts,
  status,
  requestToken
}) => {
  const buttonBackground = useMemo(() => {
    switch (status) {
      case INTEGRATION_CONNECTED:
        return 'teal-color'
      case INTEGRATION_WARNING:
        return 'orange-warning'
      default:
        return 'red-600'
    }
  }, [status])

  return (
    <div
      className={`flex flex-col py-4 px-4 bg-white rounded-lg border w-full border-1 border-b-grey max-md:border-2 max-md:border-${buttonBackground}`}
    >
      {/* Mobile view */}
      <div className="hidden max-md:flex justify-end w-full">
        <button onClick={() => requestToken(institutionId)}>
          <div
            className={`flex items-center justify-center gap-x-2 bg-${buttonBackground} text-dark-blue font-lato font-bold rounded-2xl h-8 text-sm px-8`}
          >
            {status === INTEGRATION_CONNECTED ? (
              <>
                <img src={icons.checkDarkBlue} alt="checkmark" />
                <span className="text-xs">CONNECTED</span>
              </>
            ) : status === INTEGRATION_WARNING ? (
              <span className="text-xs">UPDATE</span>
            ) : status === INTEGRATION_CRITICAL ? (
              <div className="text-white">
                <span className="text-xs">RECONNECT</span>
              </div>
            ) : null}
          </div>
        </button>
      </div>
      <div className={`flex items-start justify-between mb-2`}>
        <div className="flex flex-col w-6/12">
          <div className="font-bold">{bankName ?? '...'}</div>
          <div className="w-full">
            <span className="font-bold">Sort Code: </span>
            {sortCode ?? '...'}
          </div>
        </div>
        {accountHolder && (
          <div className="flex flex-col w-3/12 max-md:w-6/12">
            <div className="font-bold">Account Holder:</div>
            <div>{accountHolder}</div>
          </div>
        )}
        <div className="flex justify-end w-3/12 max-md:hidden">
          <button onClick={() => requestToken(institutionId)}>
            <div
              className={`flex items-center justify-center gap-x-2 bg-${buttonBackground} text-dark-blue font-lato font-bold rounded-2xl h-8 text-sm px-8`}
            >
              {status === INTEGRATION_CONNECTED ? (
                <>
                  <img src={icons.checkDarkBlue} alt="checkmark" />
                  <span className="text-xs">CONNECTED</span>
                </>
              ) : status === INTEGRATION_WARNING ? (
                <span className="text-xs">UPDATE</span>
              ) : status === INTEGRATION_CRITICAL ? (
                <span className="text-xs text-white">RECONNECT</span>
              ) : null}
            </div>
          </button>
        </div>
      </div>
      {accounts.map((account, idx) => (
        <div key={idx}>
          <ul>
            <li
              className={`py-2 ${idx !== accounts.length - 1 && 'border-b border-1 border-b-grey'}`}
            >
              <div className="flex justify-start max-md:flex-wrap max-md:gap-1 items-start w-full">
                <div className="flex flex-col md:w-4/12">
                  <div className="font-bold">Number:</div>
                  <div>{account.number ?? '...'}</div>
                </div>
                <div className="flex flex-col md:w-4/12">
                  <div className="font-bold">Name:</div>
                  <div>{account.name ?? '...'}</div>
                </div>
                <div className="flex flex-col md:w-2/12">
                  <div className="font-bold">Type:</div>
                  <div>{account.type ?? '...'}</div>
                </div>
                {account.currency && (
                  <div className="flex flex-col md:w-2/12">
                    <div className="font-bold">Currency:</div>
                    <div>{account.currency}</div>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      ))}
    </div>
  )
}

export default ConnectedBank
