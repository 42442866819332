import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import persistReducer from '../index'

// Middlewares for store
const middlewares = [thunk]

export const store = createStore(
  persistReducer,
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeWithDevTools(applyMiddleware(...middlewares))
)
export const persistor = persistStore(store)
