import * as Yup from 'yup'

const loginSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Email must be valid.')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  })
}

export default loginSchema
