export const getErrorMessage = (err) => {
  const genericMessage = 'Something went wrong, please try again later.'
  const data = err?.response?.data
  if (!data) return genericMessage

  const { message, email, uuid } = data
  if (message) return message
  if (email) return `Email ${email[0]}`
  if (uuid) return `${genericMessage} Error ${uuid}`

  return message
}
