export const UPDATE_PERCENTAGE = 'UPDATE_PERCENTAGE'
export const UPDATE_IS_CLICKED_INTEGRATIONS_DONE =
  'UPDATE_IS_CLICKED_INTEGRATIONS_DONE'
export const UPDATE_IS_REFETCH_INTEGRATIONS = 'UPDATE_IS_REFETCH_INTEGRATIONS'
export const UPDATE_ONBOARDING_CONFIG = 'UPDATE_ONBOARDING_CONFIG'
export const UPDATE_ONBOARDING_META = 'UPDATE_ONBOARDING_META'
export const UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS'
export const UPDATE_COMPANY_DIRECTOR = 'UPDATE_COMPANY_DIRECTOR'
export const UPDATE_COMPANY_DIRECTOR_DETAILS = 'UPDATE_COMPANY_DIRECTOR_DETAILS'
export const ADD_COMPANY_DIRECTOR_DETAILS = 'ADD_COMPANY_DIRECTOR_DETAILS'
export const REMOVE_COMPANY_DIRECTOR_DETAILS = 'REMOVE_COMPANY_DIRECTOR_DETAILS'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE'
export const UPDATE_DOCUSIGN_SIGNING_STATUS = 'UPDATE_DOCUSIGN_SIGNING_STATUS'
export const UPDATE_GOCARDLESS_STATUS = 'UPDATE_GOCARDLESS_STATUS'
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS'
export const SET_INTEGRATION_STATUS = 'SET_INTEGRATION_STATUS'
export const SET_OFFER_EXPIRATION = 'SET_OFFER_EXPIRATION'
