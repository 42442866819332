import axios from '../../services/axios'

const getBenchmarksData = async (dateRange) => {
  const response = await axios.get('/api/benchmarks', {
    params: {
      to: dateRange.startDate,
      from: dateRange.endDate
    }
  })

  return response.data
}

export default {
  getBenchmarksData
}
