import { always } from 'ramda'
import React from 'react'
import Card from 'components/Card'
import ErrorMessage from 'components/ErrorMessage'
import OutflowsRecurringTable from 'components/RecurringTable/OutFlows'
import OutflowsRegularTable from 'components/RegularTable/Outflows'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'
import SubNav from 'layouts/components/SubNav'
import formatCurrency from 'utils/formatCurrency'
import useOutflowsHook from './hook'

const AccountsAndBalancesOutflows = () => {
  const {
    totalOutflowsMonthly,
    recurringOutflows,
    regularOutflows,
    currentTab,
    handleOnTabClick,
    error,
    isLoading,
    loadMoreRegular,
    loadMoreRecurring
  } = useOutflowsHook()

  let regularClass = ''
  let recurringClass = ''
  if (currentTab === 'Recurring') {
    recurringClass =
      'rounded-lg bg-grey-blue-bloom text-indingo-bloom font-semibold'
    regularClass = 'text-text-color-grey-bloom font-normal'
  } else {
    regularClass =
      'rounded-lg bg-grey-blue-bloom text-indingo-bloom font-semibold'
    recurringClass = 'text-text-color-grey-bloom font-normal'
  }

  const TabAction = always(
    <div className="flex flex-row space-x-2">
      <button
        onClick={() => handleOnTabClick('Regular')}
        className={`py-2 px-4 ${regularClass}`}
      >
        Regular
      </button>
      <button
        onClick={() => handleOnTabClick('Recurring')}
        className={`py-2 px-4 ${recurringClass}`}
      >
        Recurring
      </button>
    </div>
  )

  if (isLoading) {
    return <AppLayoutSkeleton />
  }

  return (
    <>
      <SubNav />
      {Boolean(error) ? (
        <Card headerClassname="text-b-dark font-medium">
          <ErrorMessage error={error}></ErrorMessage>
        </Card>
      ) : (
        <div className="bg-body-grey space-y-4 mb-12 text-base">
          <div className="flex flex-col bg-white rounded-lg p-6">
            <span className="font-normal text-sm leading-24px text-text-color-grey-bloom">
              Total outflows predicted monthly
            </span>
            <span className="font-bold text-2xl text-green-bloom">
              {formatCurrency(totalOutflowsMonthly, 2)}
            </span>
          </div>

          <Card
            headerTitle={`${currentTab} outflows`}
            headerClassname="mb-2 mt-2 font-semibold"
            headerActionComponent={TabAction}
            children={
              <React.Fragment>
                {currentTab === 'Recurring' ? (
                  <OutflowsRecurringTable
                    dataGrouped={recurringOutflows.data.flows}
                    loadMore={loadMoreRecurring}
                    hasMore={recurringOutflows.hasMore}
                  />
                ) : (
                  <OutflowsRegularTable
                    data={regularOutflows.data.flows}
                    loadMore={loadMoreRegular}
                    hasMore={regularOutflows.hasMore}
                  />
                )}
              </React.Fragment>
            }
          />
        </div>
      )}
    </>
  )
}

export default AccountsAndBalancesOutflows
