import { OnboardingLayout } from 'layouts'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import useOnboardingHook from 'components/Onboarding/hook'
import { MERCHANT_ONBOARDING_ROUTES, ROOT_ROUTES } from 'config/configs/routes'
import { useAnalytics } from 'hooks/useAnalytics'
import OnboardingPage from 'pages/app/onboarding/OnboardingPage'
import OnboardingSuccess from 'pages/app/onboarding/OnboardingSuccess'
import OnboardingTransferFunds from 'pages/app/onboarding/OnboardingTransferFunds'

const { SUCCESS, TRANSFER_FUNDS } = MERCHANT_ONBOARDING_ROUTES
const { ONBOARDING } = ROOT_ROUTES

const MerchantOnboardingRouter = () => {
  const {
    onboardingSteps,
    onboardingMeta,
    offerExpiresAt,
    isOfferExpired,
    ...props
  } = useOnboardingHook()
  const analytics = useAnalytics()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== ONBOARDING) {
      analytics.page(location)
    }
  }, [location.pathname])

  return (
    <Switch>
      {/* We only want to redirect to success if the application is submitted and we have no offer yet */}
      {/* showInfoCard will be false on the second part of the onboarding, where we have an offer */}
      {onboardingSteps &&
        onboardingMeta?.applicationSubmitted &&
        !onboardingMeta?.offer && (
          <>
            <Route exact path={SUCCESS} component={OnboardingSuccess} />
            <Redirect to={SUCCESS} />
          </>
        )}

      <Route exact path={TRANSFER_FUNDS} component={OnboardingTransferFunds} />

      <OnboardingLayout shouldShowOnboardingSidebar={true} {...props}>
        {!!isOfferExpired && offerExpiresAt && onboardingMeta?.offer
          ? onboardingSteps.map((step, index) => (
              <Route
                key={index}
                path={step.url}
                render={() => (
                  <OnboardingPage key={0} index={0} type={'offer'} {...props} />
                )}
              />
            ))
          : onboardingSteps.map((step, index) => (
              <Route
                key={index}
                path={step.url}
                render={() => (
                  <OnboardingPage
                    key={index}
                    index={index}
                    type={step.type}
                    {...props}
                  />
                )}
              />
            ))}

        {onboardingSteps && onboardingSteps[0] && (
          <Redirect to={onboardingSteps[0].url} />
        )}
      </OnboardingLayout>
    </Switch>
  )
}

export default MerchantOnboardingRouter
