import _ from 'lodash'

const startCaseKey = (camelCaseKey) => {
  return _.startCase(camelCaseKey)
}

const startCaseKeys = (snake_case_object) => {
  const startCaseObject = {}
  _.forEach(snake_case_object, function (value, key) {
    if (_.isPlainObject(value)) {
      value = startCaseKey(value)
    } else if (_.isArray(value)) {
      value = value.map((v) => startCaseKey(v))
    }
    startCaseObject[startCaseKey(key)] = value
  })
  return startCaseObject
}

export { startCaseKey, startCaseKeys }
