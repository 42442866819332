import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import api from 'config/api/connections/xero'
// import type definfition
import 'config/api/connections/xero/typedef'
import { getErrorMessage } from 'utils/getErrorMessage'

/*
Step 1
Retrieve list of Xero connections(organizations)
endpoint: GET /inlr/xero/connections


Step 2
After connection select re-fetch Xero data
endpoint: GET /inlr/xero/connections/:tenantId/accounts

Step 3
Send data on form save
endpoint: POST /inlr/xero/writeback
*/

const twentyFourHoursInMs = 1000 * 60 * 60 * 24

export function useXeroData() {
  const [selectedOrganization, selectOrganization] = useState(
    /** @type {Connection} */ null
  )
  const [triggerRefetch, setTriggerRefetch] = useState(false)

  const {
    data: connections,
    refetch: refetchConnections,
    isLoading: areConnectionsLoading
  } = useQuery('xeroConnections', api.getConnections, {
    onError: (err) => {
      toast.error(getErrorMessage(err))
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: false,
    retry: false,
    retryOnMount: false,
    staleTime: twentyFourHoursInMs
  })

  const {
    data: currentWritebackData,
    refetch: refetchCurrentWriteback,
    isLoading: isWritebackLoading
  } = useQuery('xeroCurrentWriteback', api.getWriteback, {
    onError: (err) => {
      toast.error(getErrorMessage(err))
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: false,
    retry: false,
    retryOnMount: false,
    staleTime: twentyFourHoursInMs
  })

  const { data: currentConnection, refetch: refetchCurrentConnection } =
    useQuery(
      'xeroCurrentConnection',
      () => {
        if (!selectedOrganization?.tenantId) return
        return api.getConnection(selectedOrganization.tenantId)
      },
      {
        onError: (err) => {
          toast.error(getErrorMessage(err))
        },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        enabled: false,
        retry: false,
        retryOnMount: false,
        staleTime: twentyFourHoursInMs
      }
    )

  useEffect(() => {
    if (selectedOrganization) {
      refetchCurrentConnection(selectedOrganization.tenantId)
    }
  }, [selectedOrganization])

  const writebackMutation = useMutation(api.postWriteback, {
    onSuccess: (res) => {
      setTriggerRefetch(true)
      toast.success(res.message)
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  const disconnectMutation = useMutation(
    () => api.deleteConnection(selectedOrganization?.id),
    {
      onSuccess: (res) => {
        setTriggerRefetch(true)
        toast.success(res?.message)
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  /**
   * @param {Connection} tenant
   * @returns {void}
   */
  function onOrganizationSelect(tenant) {
    selectOrganization(tenant)
  }

  return {
    /**
     * @type {Connection}
     */
    selectedOrganization,
    selectOrganization: onOrganizationSelect,
    connections,
    refetchConnections,
    refetchCurrentWriteback,
    areConnectionsLoading,
    isWritebackLoading,
    currentWritebackData,
    currentConnection,
    refetchConnection: () => {
      !!selectedOrganization &&
        refetchCurrentConnection(selectedOrganization.tenantId)
    },
    postWriteback: writebackMutation,
    deleteConnection: disconnectMutation,
    triggerRefetch,
    setTriggerRefetch,
    isRefetchLoading:
      writebackMutation?.isFetching ||
      writebackMutation?.isLoading ||
      disconnectMutation?.isFetching ||
      disconnectMutation?.isLoading
  }
}
