import { HelpModal, HelpThankYouModal } from 'modals'
import React from 'react'
import { Link } from 'react-router-dom'
import configs from 'config/configs'
import useModal from 'hooks/useModal'
import useHeaderHook from '../useHeaderHook'

const HeaderDesktop = ({ screenName, backgroundColor, subdomainIcon }) => {
  const {
    showHeaderDropdown,
    handleFocusDropdown,
    handleBlurDropdown,
    user,
    isLoggedIn,
    companyName,
    submitEmail,
    changeMenuClass,
    ActionComponent,
    companyNameTextScale
  } = useHeaderHook({ screenName: screenName })
  const {
    isOpened: isOpenedHelpModal,
    open: openHelpModal,
    close: closeHelpModal
  } = useModal()
  const {
    isOpened: isOpenedHelpThankYouModal,
    open: openHelpThankYouModal,
    close: closeHelpThankYouModal
  } = useModal()

  return (
    <>
      <div id="zsiqwidget"></div>
      <div
        className={`hidden lg:block py-7 shadow-header-dark-grey ${backgroundColor}`}
      >
        <div className="w-10/12 mx-auto">
          <div className="flex flex-row flex-wrap justify-between mx-3 xl:mx-0">
            <Link to="/" className="flex items-center">
              <img
                src={subdomainIcon ?? configs.icons.logoHeaderDestkopDarkGrey}
                className="h-10"
              />
              {!subdomainIcon && isLoggedIn && companyName && (
                <React.Fragment>
                  <img
                    src={configs.icons.heartGreyHeader}
                    className="mx-2 pt-2.5"
                  />
                  <p
                    className={`pt-2.5 text-placeholder-grey font-bold ${companyNameTextScale}`}
                  >
                    {companyName}
                  </p>
                </React.Fragment>
              )}
            </Link>

            <div className="flex items-center">
              <div className="flex mr-6 hover:opacity-80 transition-opacity">
                <button onClick={openHelpModal}>
                  <span className="font-semibold text-dark-grey">Help</span>
                </button>
              </div>

              <HelpModal
                isOpened={isOpenedHelpModal}
                close={closeHelpModal}
                openHelpThankYouModal={openHelpThankYouModal}
                submitEmail={submitEmail}
                isLoggedIn={isLoggedIn}
              />
              <HelpThankYouModal
                isOpened={isOpenedHelpThankYouModal}
                close={closeHelpThankYouModal}
              />

              <ActionComponent
                showHeaderDropdown={showHeaderDropdown}
                handleFocusDropdown={handleFocusDropdown}
                handleBlurDropdown={handleBlurDropdown}
                user={user}
                changeMenuClass={changeMenuClass}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeaderDesktop
