import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import partners from 'config/api/partners'
import { PARTNERS_ROUTES, ROOT_ROUTES } from 'config/configs/routes'
import { updatePartnerData } from 'redux/actions/Partner'
import { getErrorMessage } from 'utils/getErrorMessage'

const { SIGNUP } = ROOT_ROUTES
const { MAINTENANCE } = PARTNERS_ROUTES

const usePartnersHook = (slug) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const partnerData = useSelector((state) => state.partnerReducer.partnerData)

  const getPartnerData = useQuery(
    'getPartnerData',
    () => partners.getPartnerData(slug),
    {
      onSuccess: (res) => {
        const logo = getPartnerLogo(slug)
        res.logo = logo
        dispatch(updatePartnerData(res))
      },
      onError: (err) => {
        dispatch(updatePartnerData({}))
        toast.error(getErrorMessage(err))
      },
      staleTime: Infinity,
      refetchInterval: 3600000
    }
  )

  const getPartnerLogo = (slug) => {
    return `https://bloom-partners.s3.eu-west-2.amazonaws.com/${slug}-logo.svg`
  }
  const handleApply = (data) => {
    const PARTNER_URL = `/partners/${data?.slug}/signup`
    return data?.disabled
      ? window.open(SIGNUP, '_blank')
      : data.maintenance
        ? history.push(MAINTENANCE)
        : history.push(PARTNER_URL)
  }
  return {
    partnerData,
    handleApply,
    getPartnerLogo,
    fetchingPartnerConfig: getPartnerData.isFetching
  }
}

export default usePartnersHook
