import debounce from 'lodash.debounce'
import React, { useMemo } from 'react'
import configs from 'config/configs'
import { useTransactionFiltersHook } from './hook'

const FilterAndSearch = ({ setAccount, setSearch, setCategory }) => {
  const { categories, categoriesLoading, accounts, accountsLoading } =
    useTransactionFiltersHook()

  const searchChangeDebouncer = useMemo(
    () =>
      debounce(
        (e) =>
          (e.target.value.length > 2 || e.target.value.length === 0) &&
          setSearch(e.target.value),
        300
      ),
    []
  )

  return (
    <div className="flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row">
      <div className="relative inline-flex w-full md:w-1/3">
        <img
          src={configs.icons.arrowDropdown}
          className="w-3 h-3 absolute top-0 right-0 m-22px pointer-events-none"
        ></img>

        <select
          onChange={(e) => setAccount(e.target.value)}
          class="border-1/2 border-grey-button rounded-lg text-gray-600 w-full h-14 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
        >
          <option value={''}>Account: All</option>
          {!accountsLoading
            ? accounts?.map((account) => {
                return (
                  <option value={account.number}>
                    {account.name}({account.number})
                  </option>
                )
              })
            : 'Loading...'}
        </select>
      </div>

      <div className="relative inline-flex w-full md:w-1/3">
        <img
          src={configs.icons.arrowDropdown}
          className="w-3 h-3 absolute top-0 right-0 m-22px pointer-events-none"
        ></img>

        <select
          onChange={(e) => setCategory(e.target.value)}
          class="border-1/2 border-grey-button rounded-lg text-gray-600 w-full h-14 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
        >
          <option value="">Type: All</option>
          {!categoriesLoading
            ? categories?.map((category) => {
                return <option value={category}>Type: {category}</option>
              })
            : 'Loading...'}
        </select>
      </div>

      <div className="flex relative justify-between align-center w-full md:w-1/3">
        <input
          className={`flex-grow font-normal flex flex-row items-center rounded-lg bg-white p-4 pl-10 focus:outline-none w-full bg border-1/2 border-grey-button`}
          type="text"
          placeholder="Search"
          onChange={searchChangeDebouncer}
        />
        <img
          className={`absolute top-23px left-4`}
          src={configs.icons.search}
        />
      </div>
    </div>
  )
}

export default FilterAndSearch
