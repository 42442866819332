import configs from 'config/configs'
import CommonModal from 'modals/components/CommonModal'

export const FacebookModal = ({ url, isOpened, close }) => {
  const onClick = () => {
    window.open(url, '', 'height=700,width=800')
    close()
  }

  return (
    <CommonModal
      title={'Connect your Meta for Business account'}
      isOpened={isOpened}
      close={close}
      noFooter
    >
      <div className="flex flex-col justify-center w-full">
        <p>Please make sure that you connect a Meta for Business account</p>
        <div className="flex flex-row w-full justify-end">
          <img
            src={configs.images.connectFb}
            width="250"
            onClick={onClick}
            className="cursor-pointer"
          />
        </div>
      </div>
    </CommonModal>
  )
}
