import React from 'react'
import Stepper from 'layouts/components/SidebarOnboarding/Stepper'

const YourBusinessProgressLinear = ({ title, doneSteps }) => {
  return (
    <div className="flex flex-col">
      <div className="h-8 px-7">
        <span className="lg:text-xl">{title}</span>
      </div>
      <div className="flex px-35px">
        <Stepper
          steps={doneSteps}
          completedClass="bg-teal-color"
          uncompletedClass="bg-white opacity-10"
        />
      </div>
    </div>
  )
}

export default YourBusinessProgressLinear
