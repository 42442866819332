import * as Yup from 'yup'

const SignatorySchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Account name is required'),
    position: Yup.string().required('Position is required'),
    email: Yup.string()
      .email('Email must be valid.')
      .required(`Email is required`),
    confirmPerson: Yup.boolean().oneOf(
      [true, 'true'],
      'You must confirm the above person is an authorised signatory.'
    )
  })
}

export default SignatorySchema
