import React, { useEffect } from 'react'
import { resolveComponent } from 'components/Onboarding/util'

const OnboardingPage = ({ index, setCurrentStep, onNext, onBack, type }) => {
  useEffect(() => {
    setCurrentStep(index)
  }, [])

  return <>{resolveComponent(type, onBack, onNext, index)}</>
}

export default OnboardingPage
