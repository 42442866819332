import * as actionTypes from '../../types/Onboarding'

const companyDirectorDetailsInit = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  address1: '',
  address2: '',
  flatNo: '',
  postalCode: '',
  years: '',
  months: '',
  authorize: true
}

const initialState = {
  currentStep: 0,
  percentage: 0,
  isClickedIntegrationsDone: false,
  isRefetchIntegrations: true,
  areDocumentsSigned: false,
  isGocardlessComplete: false,
  isAllConnectDone: false,
  config: [],
  meta: {},
  integrations: [],
  yourAvailableFunds: {},
  bankDetails: {
    accountName: '',
    accountHolder: '',
    accountNumber: '',
    bankName: '',
    sortCode: ''
  },
  companyDirectors: [],
  companyDirectorsDetails: [{ ...companyDirectorDetailsInit }],
  offerExpiration: {
    isOfferExpired: false,
    offerExpiresAt: null
  }
}

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.value
      }
    case actionTypes.UPDATE_DOCUSIGN_SIGNING_STATUS:
      return {
        ...state,
        areDocumentsSigned: action.payload.value
      }
    case actionTypes.UPDATE_GOCARDLESS_STATUS:
      return {
        ...state,
        isGocardlessComplete: action.payload.value
      }
    case actionTypes.UPDATE_PERCENTAGE:
      return {
        ...state,
        percentage: action.payload.value
      }
    case actionTypes.SET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload.value
      }
    case actionTypes.SET_INTEGRATION_STATUS:
      const newIntegrations = [...state.integrations]
      const integration = newIntegrations.find(
        (step) => step.label === action.payload.value
      )
      if (!integration) {
        return { ...state }
      }

      integration.connected = true
      return {
        ...state,
        integrations: [...newIntegrations]
      }
    case actionTypes.UPDATE_IS_CLICKED_INTEGRATIONS_DONE:
      return {
        ...state,
        isClickedIntegrationsDone: action.payload.value
      }
    case actionTypes.UPDATE_IS_REFETCH_INTEGRATIONS:
      return {
        ...state,
        isRefetchIntegrations: action.payload.value
      }
    case actionTypes.UPDATE_ONBOARDING_CONFIG:
      return {
        ...state,
        config: action.payload.value
      }
    case actionTypes.UPDATE_ONBOARDING_META:
      return {
        ...state,
        meta: action.payload.value
      }
    case actionTypes.UPDATE_BANK_DETAILS:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          [action.payload.field]: action.payload.value
        }
      }
    case actionTypes.UPDATE_COMPANY_DIRECTOR:
      return {
        ...state,
        companyDirectors: action.payload.value
      }
    case actionTypes.UPDATE_COMPANY_DIRECTOR_DETAILS:
      return {
        ...state,
        companyDirectorsDetails: Object.assign(
          [],
          state.companyDirectorsDetails,
          {
            [action.payload.formIndex]: action.payload.value
          }
        )
      }
    case actionTypes.ADD_COMPANY_DIRECTOR_DETAILS:
      return {
        ...state,
        companyDirectorsDetails: [
          ...state.companyDirectorsDetails,
          { ...companyDirectorDetailsInit }
        ]
      }
    case actionTypes.REMOVE_COMPANY_DIRECTOR_DETAILS:
      return {
        ...state,
        companyDirectorsDetails: [
          ...state.companyDirectorsDetails.slice(
            0,
            action.payload.formIndex + 1
          )
        ]
      }
    case actionTypes.SET_OFFER_EXPIRATION:
      return {
        ...state,
        offerExpiration: action.payload.value
      }

    case actionTypes.RESET_ONBOARDING_STATE:
      return initialState
    default: {
      return state
    }
  }
}
export default onboardingReducer
