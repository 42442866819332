import React, { useEffect } from 'react'

var rudder = {
  page: () => {},
  track: () => {},
  load: () => {}
}
try {
  rudder = require('rudder-sdk-js')
} catch (e) {
  window.rudderError = true
}

const AnalyticsContext = React.createContext({})

const AnalyticsProvider = ({ ...children }) => {
  useEffect(() => {
    if (window.rudderstack_write_key && window.rudderstack_data_plane_url) {
      rudder.load(
        window.rudderstack_write_key,
        window.rudderstack_data_plane_url
      )
    }
  }, [])

  const track = (userId, eventName, eventData = {}, callback = () => {}) => {
    rudder.track(
      eventName,
      {
        userId,
        ...eventData
      },
      callback
    )
  }

  const identify = (userId) => {
    rudder.identify(userId)
  }

  const page = ({ pathname, search }) => {
    rudder.page({
      path: pathname,
      search,
      url: window.location.href
    })
  }

  return (
    <AnalyticsContext.Provider
      value={{ identify, track, page }}
      {...children}
    />
  )
}

const useAnalytics = () => React.useContext(AnalyticsContext)

export { AnalyticsProvider, useAnalytics }
