import React from 'react'
import ResetPasswordForm from 'components/ResetPassword'
import { UnauthenticatedLayout } from '../../layouts'

const ResetPassword = ({ name }) => {
  return (
    <UnauthenticatedLayout
      formContent={<ResetPasswordForm />}
      screenName={name}
      hasHeader={true}
      hasFooter={false}
    />
  )
}

export default ResetPassword
