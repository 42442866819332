import Card from 'components/Card'
import ErrorMessage from 'components/ErrorMessage'
import LoadingIndicator from 'components/LoadingIndicator'
import FilterAndSearch from 'components/Transactions/FilterAndSearch'
import TransactionsTable from 'components/Transactions/TransactionsTable'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'
import SubNav from 'layouts/components/SubNav'
import useTransactionsHook from './hook'

const AccountsAndBalancesTransactions = () => {
  const {
    transactions,
    setAccount,
    setSearch,
    setCategory,
    error,
    isLoading,
    isRefetching,
    loadMore,
    totalTransactions,
    hasMore
  } = useTransactionsHook()

  if (isLoading) {
    return <AppLayoutSkeleton />
  }

  return (
    <>
      <SubNav />
      {Boolean(error) ? (
        <Card headerClassname="text-b-dark font-medium">
          <ErrorMessage error={error}></ErrorMessage>
        </Card>
      ) : (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 flex flex-col bg-white rounded-lg p-6">
            <FilterAndSearch
              setAccount={setAccount}
              setCategory={setCategory}
              setSearch={setSearch}
            />
          </div>

          <div className="col-span-12 flex flex-col bg-white mb-8">
            <Card
              headerTitle={`${totalTransactions} transactions`}
              headerClassname="text-text-color-bloom"
              children={<TransactionsTable transactions={transactions} />}
            />
            {isRefetching && (
              <div className="w-full text-center mb-2">
                <LoadingIndicator />
              </div>
            )}
            {!isRefetching && hasMore && (
              <div className="flex justify-center mb-6 items-center bg-white cursor-pointer text-header-table-gray font-medium">
                <button onClick={loadMore}>Load more</button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default AccountsAndBalancesTransactions
