import React from 'react'
import configs from 'config/configs'
import useHeaderHook from '../useHeaderHook'
import HeaderMobileExpanded from './Expanded'

const HeaderMobile = ({
  screenName,
  subdomainIcon,
  setCurrentStep,
  shouldShowOnboardingSidebar,
  shouldShowSaasDashboardSidebar,
  shouldShowEcommerceDashboardSidebar,
  bgColor = 'bg-light-grey-100'
}) => {
  const { user, toggleMobileExpand, mobileExpand } = useHeaderHook({
    screenName: screenName
  })

  return (
    <div className={`lg:hidden py-6 ${bgColor}`}>
      <div className="flex flex-row flex-wrap justify-between items-center mx-6 xl:mx-0">
        <button
          className="lg:hidden w-8 h-8 flex items-center justify-center"
          onClick={toggleMobileExpand}
        >
          <img src={configs.icons.hamburgerMobile} />
        </button>

        <a href="/">
          <img
            src={subdomainIcon ?? configs.icons.headerLogoMobile}
            className="h-10"
          />
        </a>
      </div>
      {mobileExpand && (
        <HeaderMobileExpanded
          user={user}
          screenName={screenName}
          setCurrentStep={setCurrentStep}
          toggleMobileExpand={toggleMobileExpand}
          shouldShowOnboardingSidebar={shouldShowOnboardingSidebar}
          shouldShowSaasDashboardSidebar={shouldShowSaasDashboardSidebar}
          shouldShowEcommerceDashboardSidebar={
            shouldShowEcommerceDashboardSidebar
          }
        />
      )}
    </div>
  )
}

export default HeaderMobile
