import configs from 'config/configs'

const FormikTextInput = ({
  placeholder,
  values,
  errors,
  field,
  handleChange,
  inputClassname,
  hasBorder = true,
  type,
  shouldValid,
  containerClassname,
  content,
  setFieldValue,
  redCloseClass = 'top-14px',
  disabled,
  autoComplete,
  autoFocus
}) => {
  const borderError = hasBorder && 'border-1/2 border-error-message'
  const inputErrorClass =
    shouldValid && errors[field] && 'border-error-message pr-14'

  let borderClassname =
    hasBorder && 'border-1/2 border-input-border-color-bloom'

  if (shouldValid && errors[field]) {
    borderClassname = borderError
  }

  return (
    <div
      className={`flex flex-col justify-center align-center w-full mx-auto ${containerClassname}`}
    >
      <div className="flex justify-between align-center max-w-400px relative">
        {content}
        <input
          id={field}
          className={`flex-grow font-normal flex flex-row items-center rounded-lg bg-white px-4 h-38px focus:outline-none w-full focus:border-b-dark ${inputErrorClass} ${inputClassname} ${borderClassname}`}
          value={values[field]}
          placeholder={placeholder}
          onChange={(e) => {
            if (handleChange) handleChange(field, e.target.value)
            if (setFieldValue) setFieldValue(field, e.target.value)
          }}
          type={type}
          disabled={!!disabled}
          autoComplete={autoComplete}
          autoFocus={!!autoFocus}
        />
        {shouldValid && errors[field] && !!values[field] && (
          <img
            className={`absolute cursor-pointer right-23px ${redCloseClass}`}
            src={configs.icons.redClose}
            onClick={() => {
              if (handleChange) handleChange(field, '')
              if (setFieldValue) setFieldValue(field, '')
              document.getElementById(field).focus()
            }}
          />
        )}
      </div>
      {shouldValid && errors[field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {errors[field]}
        </p>
      )}
    </div>
  )
}

export default FormikTextInput
