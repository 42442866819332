import React from 'react'
import configs from 'config/configs'
import Button from '../Button'
import LoadingIndicator from '../LoadingIndicator'
import useDirectDebitSectionHook from './hook'

const DirectDebitSection = ({ onBack, onNext }) => {
  const { actions, textButton, isIntegrationsDone, finalizeOnboarding } =
    useDirectDebitSectionHook({ onNext })
  const doneIcon = configs.images.doneCheck
  const undoneIcon = configs.icons.circleCheckGrey

  return (
    <div className="pt-6">
      <p className="text-4xl font-black leading-56px">View & sign documents</p>
      <p className="text-xl font-normal mt-3">
        Please read the sign the following documents to complete your
        application process and enable us to remit funds to you.
      </p>
      <div className="mt-8 flex w-full justify-between items-start">
        {actions.map((item, index) => {
          const borderColor = item.isDone
            ? 'border-teal-color'
            : 'border-b-grey'
          const borderWidth = item.isDone ? 'border-2' : 'border'
          return (
            <button
              className={`flex flex-col items-center justify-center rounded-xl ${borderWidth} ${borderColor} bg-white sm:p-6 sm:w-fit-content`}
              onClick={item.action}
              key={index}
              style={{ width: '49%' }}
              disabled={item.isDone || item.isLoading || item.disabled}
            >
              <img
                src={item.isDone ? doneIcon : undoneIcon}
                className={`${item.isDone && 'rounded-2xl bg-white'}`}
              />
              {item.isLoading ? (
                <LoadingIndicator />
              ) : (
                <p className="text-dark-grey">{item.title}</p>
              )}
              <img src={item.icon} style={item.iconStyle} />
            </button>
          )
        })}
      </div>

      <div className="mt-5 flex justify-between items-center">
        <Button variant="button-back-grey" onClick={onBack} children="Back" />
        <Button
          variant="button-next-pink"
          affixIcon={configs.icons.arrowForward}
          disabled={!isIntegrationsDone}
          onClick={() => finalizeOnboarding()}
          children={textButton}
        />
      </div>
    </div>
  )
}

export default DirectDebitSection
