import * as Yup from 'yup'
import { websiteRegex } from 'config/configs/constant'

const selectedCompanyEcommerceSchema = () => {
  return Yup.object().shape({
    avgMonthlyIncome: Yup.string().required('Monthly income is required'),
    website: Yup.string()
      .matches(websiteRegex, 'Website format is not valid')
      .notRequired()
  })
}

export default selectedCompanyEcommerceSchema
