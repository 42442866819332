import React from 'react'
import useCompanyDirectorHook from './hook'

const CompanyDirectors = (props) => {
  const { currentDirectorStep, directorSteps } = useCompanyDirectorHook(props)

  return (
    <div className="w-full mb-16">
      {directorSteps[currentDirectorStep].component}
    </div>
  )
}

export default CompanyDirectors
