import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const FormikPhoneInputGrey = ({
  placeholder,
  values,
  errors,
  field,
  inputClassname,
  iconSrc,
  enableLongNumbers = false,
  shouldValid,
  containerClassname,
  content,
  disabled,
  backgroundColor = 'bg-light-grey',
  disableCountryCode = false,
  disableCountryGuess = true,
  countryCodeEditable = false,
  autoComplete = 'tel-local',
  setValues
}) => {
  const handleOnChange = (phoneNumber, { countryCode }) => {
    setValues({
      ...values,
      phone: phoneNumber,
      countryCode: countryCode.toUpperCase()
    })
  }

  return (
    <div
      className={`flex flex-col justify-center w-full mx-auto ${containerClassname}`}
    >
      <div className="relative flex justify-between align-center w-full">
        {content}
        <PhoneInput
          country={'gb'}
          containerClass={`w-full font-normal flex flex-grow flex-row items-center h-14 pl-0 ${backgroundColor} 
                          !outline-none -transition-all placeholder:text-placeholder-grey`}
          dropdownClass={`rounded-lg ${inputClassname}`}
          inputClass="pr-10"
          buttonClass="flex justify-center items-center"
          value={values[field]}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disableCountryCode={disableCountryCode}
          disableCountryGuess={disableCountryGuess}
          countryCodeEditable={countryCodeEditable}
          enableLongNumbers={enableLongNumbers}
          onChange={handleOnChange}
          disabled={!!disabled}
        />
        {iconSrc && (
          <img
            className="absolute right-3 top-1/2 w-6 h-6 transform -translate-y-1/2"
            src={iconSrc}
            alt="phone-icon"
          />
        )}
      </div>
      {shouldValid && errors[field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {errors[field]}
        </p>
      )}
    </div>
  )
}

export default FormikPhoneInputGrey
