import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import ConnectionSuccessful from 'components/ConnectionSuccessful'
import FormikInputTextGrey from 'components/FormikInputTextGrey'
import api from 'config/api/connections/recurly'
import configs from 'config/configs'
import CommonModal from 'modals/components/CommonModal'
import { getErrorMessage } from 'utils/getErrorMessage'

const RecurlyModal = ({ image, isOpened, close }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const submitRecurlyDetails = useMutation(
    'submitRecurlyIntegrationData',
    api.install,
    {
      onSuccess: () => {
        setShowSuccess(true)
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
        close()
        setShowSuccess(false)
      }
    }
  )

  const formik = useFormik({
    initialValues: {
      api_key: ''
    },
    validationSchema: Yup.object().shape({
      api_key: Yup.string().required('Api Key is required.')
    }),
    onSubmit: (values, { resetForm }) => {
      submitRecurlyDetails.mutate(values)
      resetForm()
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  const onCloseSuccessModal = () => {
    setShowSuccess(false)
    close()
  }

  return (
    <CommonModal
      title={'Connect your Recurly account'}
      isOpened={isOpened}
      close={close}
    >
      {showSuccess ? (
        <ConnectionSuccessful
          integration="recurly"
          onClick={onCloseSuccessModal}
        />
      ) : (
        <>
          <div className="flex flex-col justify-center w-full border-light-grey-200 border-b mb-4">
            <div className="flex justify-center items-center">
              <img src={image} alt="recurly-logo" className="w-auto h-20" />
            </div>

            <div className="flex flex-col w-full text-left gap-y-2 mb-2">
              <p className="text-sm md:text-base text-black font-semibold">
                Api Key
              </p>
              <p className="text-sm md:text-base text-placeholder-grey">
                Please provide your Recurly API's key.
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left">
                  <FormikInputTextGrey
                    field="api_key"
                    id="api_key"
                    placeholder="126acxxxxxxxcxxxxxxxcxxxxxxx"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                    autoFocus
                  />
                </div>
              </form>
            </div>

            <div className="flex justify-between items-center mb-4">
              <Button
                type="submit"
                variant={'button-next-pink'}
                className="ml-auto normal-case"
                disabled={!(formik.isValid && formik.dirty)}
                loading={submitRecurlyDetails.isLoading}
                onClick={onSubmit}
              >
                Connect
              </Button>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full pt-2">
            <div className="flex justify-center gap-x-2 items-center">
              <img src={configs.icons.lockClosed} />
              <span className="text-sm text-placeholder-grey">
                Secured with 256-bit encryption
              </span>
            </div>
          </div>
        </>
      )}
    </CommonModal>
  )
}

export default RecurlyModal
