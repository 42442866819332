import PageTitle from 'components/PageTitle'
import useModal from 'hooks/useModal'
import SearchInputGrey from '../../SearchInputGrey'
import CantFindCompanyModal from '../CantFindCompanyModal'

const CantFindCompanyButton = ({ className }) => {
  const { isOpened, open, close } = useModal()
  return [
    <button
      className={`${className} absolute top-1/2 right-4 transform -translate-y-1/2 border-b border-placeholder-grey leading-none text-placeholder-grey`}
      // TODO: Replace with onCantFindCompany when manual company info is again enabled
      onClick={open}
      key="cantFindCompanyButton"
    >
      Can't find company?
    </button>,
    <CantFindCompanyModal
      key="cantFindCompanyModal"
      isOpened={isOpened}
      close={close}
    />
  ]
}

const CompanyInformationStep2 = ({
  StepTitle,
  StepSubtitle,
  companyName,
  setCompanyName,
  selectCompany,
  onCantFindCompany,
  companySearchResult,
  companyBusinessType,
  steps
}) => {
  return (
    <div className="flex flex-col w-full lg:h-full">
      <PageTitle
        stepper={steps}
        isSubtitleTop={true}
        title={<StepTitle companyBusinessType={companyBusinessType} />}
        subtitle={<StepSubtitle companyBusinessType={companyBusinessType} />}
      />

      <div className="relative">
        <SearchInputGrey
          inputProps={{
            value: companyName,
            autoFocus: true,
            placeholder: 'Enter your company name',
            onChange: (e) => setCompanyName(e.target.value)
          }}
          affixComponent={
            <CantFindCompanyButton onCantFindCompany={onCantFindCompany} />
          }
        />
      </div>
      {companySearchResult && !!companySearchResult.length && (
        <div className="mt-4 rounded-lg bg-light-grey-200 md:mt-2 max-h-80 overflow-y-scroll">
          {companySearchResult.map((item, index) => {
            return (
              <button
                key={index}
                className="pt-6 pb-4 px-4 flex flex-col md:flex-row md:justify-between hover:opacity-80 focus:opacity-80 w-full md:items-center border-b divide-grey"
                onClick={() => selectCompany(item?.company)}
              >
                <p className="text-left truncate">{item?.companyTitle}</p>
                <p className="text-sm text-placeholder-grey  text-right">
                  {item?.companyDetail}
                </p>
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CompanyInformationStep2
