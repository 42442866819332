import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import configs from 'config/configs'
import useWindowSize from 'hooks/useWindowSize'

const CustomSymbolShape = ({ x, y, fill, borderWidth, borderColor }) => {
  const xOffset = 8
  return (
    <rect
      x={x - xOffset}
      y={y}
      fill={fill}
      strokeWidth={borderWidth}
      stroke={borderColor}
      width={16}
      height={4}
      style={{ marginRight: 8 }}
    />
  )
}

const InflowsAndOutflowsChart = ({ data, min, max }) => {
  const mobileSize = 640
  const { width } = useWindowSize()

  return (
    <ResponsiveBar
      data={data}
      indexBy="id"
      groupMode="grouped"
      keys={['inflow', 'outflow']}
      colors={[configs.colors.lightBlueBloom, configs.colors.indingoBloom]}
      margin={{ top: 80, right: 32, bottom: 56, left: 56 }}
      animate={true}
      minValue={min}
      maxValue={max + (5 / 100) * max}
      innerPadding={38}
      enableGridX={false}
      enableLabel={false}
      yFormat={(value) => `${(Math.abs(value) / 1000).toFixed(2)}K`}
      valueFormat={(value) => `${(Math.abs(value) / 1000).toFixed(2)}K`}
      axisLeft={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        format: (value) => `${(Math.abs(value) / 1000).toFixed(2)}K`
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: width < mobileSize ? 90 : 0
      }}
      useMesh={true}
      legends={[
        {
          anchor: 'top-left',
          dataFrom: 'keys',
          direction: 'row',
          justify: false,
          translateX: -16,
          translateY: -48,
          itemsSpacing: 20,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 24,
          itemOpacity: 1,
          symbolSize: 4,
          symbolShape: CustomSymbolShape,
          symbolBorderColor: 'rgba(0, 0, 0, .5)'
        }
      ]}
    />
  )
}

export default InflowsAndOutflowsChart
