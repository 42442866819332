import React from 'react'

const SkeletonOnboardingLayout = () => {
  return (
    <>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
      <div className="flex w-155px h-64px lg:w-232px lg:h-24 animate-pulse flex bg-gray-200 rounded"></div>
    </>
  )
}

export default SkeletonOnboardingLayout
