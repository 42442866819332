import React from 'react'
import Select from 'react-select'
import Button from 'components/Button'
import 'config/api/connections/xero/typedef'
import { HELP_URL, XERO_URL } from 'config/configs/constant'
import { useXeroWritebackForm } from './useXeroWritebackForm'

const XERO_LINK =
  'https://developer.xero.com/documentation/guides/how-to-guides/general-lending-integration-guide/'

/**
 * @typedef {Object} Props
 * @property {WritebackItem} currentWritebackData
 * @property {ConnectionSettings} currentConnection
 * @property {Connection} selectedOrganization
 * @property {Function} refetch
 * @property {Function} handleBack
 * @property {Function} handleAfterActionRedirect
 * @property {Function} postWriteback
 */

/**
 *
 * @param {Props} props
 */
export function StepTwo(props) {
  const {
    handleAfterActionRedirect,
    selectedOrganization,
    handleBack,
    refetch,
    currentConnection,
    currentWritebackData,
    postWriteback
  } = props
  const { handleChange, formik, onFormSubmit } = useXeroWritebackForm({
    currentConnection,
    selectedOrganization,
    currentWritebackData,
    postWriteback
  })

  const handleSave = (e) => {
    onFormSubmit(e)
    handleAfterActionRedirect()
  }

  return (
    <>
      <form onSubmit={handleSave}>
        <div className="flex flex-col w-full mb-2">
          <div className="relative z-1 mb-6">
            <FormikBoolean
              {...formik}
              field="writeBack"
              label="Write back?"
              handleChange={handleChange}
            />
          </div>
          <p className="mb-3">
            Not sure what to choose? You can check this in{' '}
            <span className="sr-only">the Xero documentation</span>
            <a href={XERO_LINK} className="underline">
              the Xero documentation
            </a>
            .
          </p>
          <div className="relative z-1 mb-6">
            <FormikSelect
              {...formik}
              field="accounts"
              id="accounts"
              placeholder="Select an account"
              shouldValid={true}
              options={formatOptions(
                currentConnection?.originatingBankAccounts
              )}
              handleChange={handleChange}
              label="Bloom Facility Account"
            />
          </div>
          <div className="relative z-1 mb-6">
            <FormikSelect
              {...formik}
              field="bankAccounts"
              id="bankAccounts"
              placeholder="Select your an account for incoming payments"
              shouldValid={true}
              options={formatOptions(currentConnection?.receivingBankAccounts)}
              handleChange={handleChange}
              label="Select where youʼll be receiving the funds"
            />
          </div>
          <div className="relative z-1 mb-6">
            <FormikSelect
              {...formik}
              field="expenseAccounts"
              id="expenseAccounts"
              placeholder="Select an account for payouts"
              shouldValid={true}
              options={formatOptions(currentConnection?.feeBankAccounts)}
              handleChange={handleChange}
              label="Choose where to book the fee"
            />
          </div>
        </div>
        <div className="flex w-full mb-10 justify-between">
          <Button
            variant="button-back-grey"
            onClick={handleBack}
            children="Back"
            className="normal-case"
          />

          <Button
            type="submit"
            variant={'button-next-pink'}
            className="ml-auto normal-case"
          >
            Save
          </Button>
        </div>
      </form>
      <div className="block text-center mb-2">
        <span className="text-sm text-placeholder-grey">
          Don't see your account?
        </span>
        <a
          href={XERO_URL}
          className="text-sm text-placeholder-grey underline mx-1"
          target="_blank"
        >
          Click here to add a new one
        </a>
        &nbsp;
        <span className="text-sm text-placeholder-grey">or click here to</span>
        &nbsp;
        <span
          className="text-sm text-placeholder-grey underline cursor-pointer"
          onClick={refetch}
          role="button"
        >
          try to re-fetch data from Xero
        </span>
      </div>
      <div className="flex justify-center">
        <span className="text-sm text-placeholder-grey">
          Having trouble with this step?
          <a
            href={HELP_URL}
            className="text-sm text-placeholder-grey underline ml-1"
            target="_blank"
          >
            Please contact our support.
          </a>
        </span>
      </div>
    </>
  )
}

function formatOptions(items = []) {
  return items.map((item) => ({
    label: item.name,
    value: item.id
  }))
}

function FormikSelect(props) {
  const onChange = (option) => {
    props.handleChange(props.field, option || null)
  }
  const isWriteback = props?.initialValues?.writeBack
  return (
    <div className={`text-left dropdown w-full ${props.className ?? ''} z-10`}>
      <label htmlFor={props.field}>{props.label}</label>
      <div className="max-w-400px h-38px relative">
        <Select
          className="basic-single"
          classNamePrefix="select"
          id={props.field}
          isClearable="true"
          isSearchable="true"
          name={props.field}
          options={props.options}
          onChange={onChange}
          isDisabled={props.isDisabled}
          maxMenuHeight={150}
          defaultValue={isWriteback && props?.initialValues?.[props?.field]}
        />
      </div>
      {props.shouldValid && props.errors[props.field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {props.errors[props.field]}
        </p>
      )}
    </div>
  )
}

function FormikBoolean(props) {
  const onChange = (e) => {
    e.stopPropagation()
    e.persist()
    const value = e.target.checked
    props.handleChange(props.field, value)
  }

  return (
    <div className="flex flex-row">
      <label htmlFor={props.field} className="mr-4">
        {props.label ?? ''}
      </label>
      <input
        id={props.field}
        type="checkbox"
        checked={props?.values[props?.field]}
        onChange={onChange}
        className="w-6 h-6 border-2 bg-none"
      />
    </div>
  )
}
