import React from 'react'
import { getErrorMessage } from 'utils/getErrorMessage'

const ErrorMessage = ({ message, error }) => {
  const errorMessage = getErrorMessage(error)

  return <p className="py-6 text-pink-color">{message || errorMessage}</p>
}

export default ErrorMessage
