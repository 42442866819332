import React from 'react'
import Spinner from '../Spinner'

const variants = [
  {
    name: 'default',
    defaultClass:
      'py-4 px-8 border border-b-dark text-b-dark bg-white cursor-pointer hover:bg-gray-200',
    activeClass:
      'focus:outline-none focus:ring-2 focus:ring-b-dark focus:ring-opacity-50',
    disabledClass:
      'disabled:bg-green-button-disabled disabled:border-none disabled:text-white disabled:cursor-not-allowed'
  },
  {
    name: 'primary',
    defaultClass:
      'py-4 px-8 border border-input-border-color-bloom text-white bg-b-dark cursor-pointer hover:bg-gray-700',
    activeClass:
      'text-white focus:outline-none focus:ring-2 focus:ring-b-dark focus:ring-opacity-50'
  },
  {
    name: 'onboard',
    defaultClass:
      'py-4 px-8 bg-b-dark border-b-dark text-white cursor-pointer hover:bg-gray-700',
    activeClass:
      'text-white focus:outline-none focus:ring-2 focus:ring-b-dark focus:ring-opacity-50',
    disabledClass:
      'disabled:bg-green-button-disabled disabled:cursor-not-allowed'
  },
  {
    name: 'bordered',
    defaultClass: 'py-4 px-8 border-2 border-button-next cursor-pointer',
    activeClass: 'focus:outline-none',
    disabledClass: 'opacity-60 disabled:cursor-not-allowed'
  },
  {
    name: 'button-next',
    defaultClass:
      'py-3 px-8 text-white bg-button-next cursor-pointer hover:opacity-50',
    activeClass:
      'focus:outline-none focus:ring-2 focus:ring-button-next focus:ring-opacity-50',
    disabledClass:
      'disabled:bg-green-button-disabled disabled:border-none disabled:text-white disabled:cursor-not-allowed'
  },
  {
    name: 'button-apply',
    defaultClass:
      'py-3 px-16 text-white bg-button-next cursor-pointer hover:opacity-50',
    activeClass:
      'focus:outline-none focus:ring-2 focus:ring-button-next focus:ring-opacity-50',
    disabledClass:
      'disabled:bg-green-button-disabled disabled:border-none disabled:text-white disabled:cursor-not-allowed'
  },
  {
    name: 'button-back',
    defaultClass:
      'py-4 px-8 text-white bg-button-back cursor-pointer hover:bg-gray-400',
    activeClass:
      'focus:outline-none focus:ring-2 focus:ring-button-back focus:ring-opacity-50',
    disabledClass:
      'disabled:bg-green-button-disabled disabled:border-none disabled:text-white disabled:cursor-not-allowed'
  },
  {
    name: 'button-next-pink',
    defaultClass:
      'h-6 py-3 px-8 text-white font-bold border border-pink-color bg-pink-color box-content cursor-pointer transition hover:opacity-80',
    activeClass:
      'focus:outline-none focus:ring-1 focus:ring-pink-color focus:ring-opacity-80',
    disabledClass:
      'disabled:bg-pink-color opacity-30 disabled:border-transparent disabled:cursor-not-allowed'
  },
  {
    name: 'button-login-pink',
    defaultClass:
      'py-3 px-8 text-white font-bold border border-pink-color bg-pink-color cursor-pointer transition hover:opacity-80',
    activeClass:
      'focus:outline-none focus:ring-1 focus:ring-pink-color focus:ring-opacity-80',
    disabledClass:
      'disabled:bg-pink-color opacity-30 disabled:border-transparent disabled:cursor-not-allowed'
  },
  {
    name: 'button-back-grey',
    defaultClass:
      'py-3 px-8 text-placeholder-grey font-bold border border-placeholder-grey cursor-pointer transition hover:opacity-80',
    activeClass:
      'focus:outline-none focus:ring-1 focus:ring-placeholder-grey focus:ring-opacity-80',
    disabledClass: 'opacity-80 disabled:cursor-not-allowed'
  },
  {
    name: 'button-teal',
    defaultClass:
      'py-2 px-6 text-white bg-teal-color font-bold cursor-pointer transition hover:opacity-80',
    activeClass:
      'focus:outline-none focus:ring-1 focus:ring-teal-color focus:ring-opacity-80',
    disabledClass: 'opacity-80 disabled:cursor-not-allowed'
  },
  {
    name: 'button-pink-text',
    defaultClass:
      'text-pink-color bg-none font-bold cursor-pointer transition hover:opacity-80',
    activeClass: '',
    disabledClass: 'opacity-80 disabled:cursor-not-allowed'
  },
  {
    name: 'button-connect',
    defaultClass:
      'py-0.5 px-5 text-pink-color border border-pink-color text-sm leading-6 bg-white font-bold cursor-pointer transition hover:opacity-80'
  },
  {
    name: 'button-connected',
    defaultClass:
      'py-0.5 px-5 text-dark-blue bg-teal-color text-sm leading-6 bg-white font-bold cursor-pointer transition hover:opacity-80'
  },
  {
    name: 'button-group',
    defaultClass:
      'py-0.5 px-5px text-white text-13px leading-7 font-normal cursor-pointer transition hover:opacity-80',
    disabledClass: 'opacity-80 disabled:cursor-not-allowed'
  },
  {
    name: 'button-group-active',
    defaultClass:
      'py-0.5 px-5px bg-teal-color text-white text-13px leading-7 rounded-group font-normal cursor-pointer transition hover:opacity-80',
    disabledClass:
      'opacity-80 disabled:cursor-not-allowed disabled:bg-light-grey text-dark-blue'
  },
  {
    name: 'button-add-account',
    defaultClass:
      'py-3 px-8 text-placeholder-grey font-bold border border-placeholder-grey cursor-pointer transition hover:opacity-80',
    activeClass:
      'focus:outline-none focus:ring-1 focus:ring-placeholder-grey focus:ring-opacity-80',
    disabledClass: 'opacity-80 disabled:cursor-not-allowed'
  }
]

const Button = ({
  prefixIcon,
  affixIcon,
  children,
  variant,
  loading,
  disabled,
  prefixClassName,
  className = '',
  ...otherProps
}) => {
  const currentVariant =
    variants.find((item) => item.name === variant) || variants[0]
  const disabledClass = loading || disabled ? currentVariant.disabledClass : ''

  return (
    <button
      disabled={loading || disabled}
      className={`rounded-btn flex justify-center items-center uppercase ${currentVariant.defaultClass} ${currentVariant.activeClass} ${disabledClass} ${className}`}
      {...otherProps}
    >
      {prefixIcon && (
        <img src={prefixIcon} className={`mr-3 ${prefixClassName}`}></img>
      )}
      {children}
      {affixIcon && <img src={affixIcon} className="ml-3"></img>}
      {loading && <Spinner />}
    </button>
  )
}

export default Button
