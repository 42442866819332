import { validateDataPresence } from './validateDataPresenceUtil'

const formatNumberAsPercentageString = (
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  if (isNaN(value)) return validateDataPresence(value)

  const makePercentFormat = new Intl.NumberFormat('en-GB', {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits
  })

  return makePercentFormat.format(Number(value))
}

const formatDecimalValue = (
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  if (!value) return validateDataPresence(value)

  const makePercentFormat = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits
  })

  return makePercentFormat.format(Number(value))
}

export { formatNumberAsPercentageString, formatDecimalValue }
