import React from 'react'
import icons from 'config/configs/icons'
import { MERCHANT_ONBOARDING_ROUTES } from 'config/configs/routes'
import AvailableFunds from '../AvailableFunds'
import BankDetails from '../BankDetails'
import CompanyDirectors from '../CompanyDirectors'
import DirectDebitSection from '../DirectDebitSection'
import Integrations from '../Integrations'

const {
  CONNECT_SALES,
  CONNECT_MARKETING,
  CONNECT_ACCOUNTING,
  COMPANY_DIRECTORS,
  CONNECT_BANK,
  BANK_DETAILS,
  AVAILABLE_FUNDS,
  DIRECT_DEBIT,
  SUCCESS
} = MERCHANT_ONBOARDING_ROUTES

const resolveComponent = (type, onBack, onNext, currentStep) => {
  switch (type) {
    case 'sales':
      return (
        <Integrations
          type={type}
          accountType={'sales accounts'}
          onBack={onBack}
          onNext={onNext}
          currentStep={currentStep}
        />
      )
    case 'marketing':
      return (
        <Integrations
          type={type}
          accountType={'marketing accounts'}
          onBack={onBack}
          onNext={onNext}
          currentStep={currentStep}
        />
      )
    case 'accounting':
      return (
        <Integrations
          type={type}
          accountType={'accounting platforms'}
          onBack={onBack}
          onNext={onNext}
          currentStep={currentStep}
        />
      )
    case 'company_directors':
      return <CompanyDirectors onBack={onBack} onNext={onNext} />
    case 'banking':
      return (
        <Integrations
          type={type}
          accountType={'bank accounts'}
          onBack={onBack}
          onNext={onNext}
          currentStep={currentStep}
        />
      )
    case 'offer':
      return <AvailableFunds onBack={onBack} onNext={onNext} />
    case 'bank_details':
      return <BankDetails onBack={onBack} onNext={onNext} />
    case 'direct_debit':
      return <DirectDebitSection onBack={onBack} onNext={onNext} />
  }
}

const resolveUrl = (type) => {
  switch (type) {
    case 'sales':
      return CONNECT_SALES
    case 'marketing':
      return CONNECT_MARKETING
    case 'accounting':
      return CONNECT_ACCOUNTING
    case 'company_directors':
      return COMPANY_DIRECTORS
    case 'banking':
      return CONNECT_BANK
    case 'offer':
      return AVAILABLE_FUNDS
    case 'bank_details':
      return BANK_DETAILS
    case 'direct_debit':
      return DIRECT_DEBIT
    case 'success':
      return SUCCESS
    case 'finish':
      return '/'
  }
}

const resolveIcon = (type) => {
  switch (type) {
    case 'sales':
      return icons.cart
    case 'marketing':
      return icons.speakerphone
    case 'accounting':
      return icons.calculator
    case 'company_directors':
      return icons.calculator
    case 'banking':
      return icons.library
    case 'offer':
      return icons.funds
    case 'bank_details':
      return icons.library
    case 'direct_debit':
      return icons.debit
  }
}

export { resolveUrl, resolveIcon, resolveComponent }
