import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import authApi from 'config/api/auth'
import companyInformationApi from 'config/api/company'
import companyDirectorsApi from 'config/api/companyDirectors'
import { getErrorMessage } from 'utils/getErrorMessage'
import { companyDirectorsSchema } from './schema'

const useDirectorsFormHook = ({ onRedirect }) => {
  const getCompanyInfo = useQuery('getCompanyInfo', authApi.getCompanyInfo, {
    enabled: false,
    onSuccess: (res) => {
      const parentCompany = res.parentCompanyName
        ? {
            parent_company_name: res.parentCompanyName,
            parent_company_number: res.parentCompanyNumber,
            parent_company_shareholder_percentage:
              res.parentCompanyShareholderPercentage
          }
        : null
      formik.setValues({ ...formik.values, parent_company: parentCompany })
    },
    onError: () => {
      dispatch(companyRegistered(false))
      setCompanyLoading(false)
    }
  })

  const getCompanyDirectors = useQuery(
    'companyDirectors',
    companyDirectorsApi.getCompanyDirectors,
    {
      enabled: false,
      onSuccess: (res) => {
        const directors = res.directors.map((director) => ({
          id: director.id,
          first_name: director.firstName,
          last_name: director.lastName,
          date_of_birth: moment(new Date(director.dateOfBirth)).format(
            'DD/MM/yyyy'
          ),
          is_director: director.isDirector,
          shareholder_percentage: director.shareholderPercentage
        }))
        if (!formik.values.directors.length) {
          formik.setValues({ ...formik.values, directors: directors })
        }
        getCompanyInfo.refetch()
      }
    }
  )

  useEffect(() => {
    getCompanyDirectors.refetch()
  }, [])

  const updateDirectorsMutation = useMutation(
    companyDirectorsApi.updateCompanyDirectors,
    {
      onSuccess: () => {
        if (onRedirect) onRedirect()
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  const updateParentCompanyMutation = useMutation(
    companyInformationApi.updateCompanyInformation,
    {
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  const formik = useFormik({
    initialValues: {
      directors: [],
      parent_company: null
    },
    validateOnChange: false,
    validationSchema: companyDirectorsSchema(),
    validate: validateShareholders,
    onSubmit: (values) => {
      updateDirectorsMutation.mutate({
        directors: values.directors
      })
      const parentCompany = values.parent_company
        ? values.parent_company
        : {
            parent_company_name: null,
            parent_company_number: null,
            parent_company_shareholder_percentage: null
          }
      updateParentCompanyMutation.mutate(parentCompany)

      dispatch(updateCompanyDirector(values.directors))
    }
  })

  const addPerson = () => {
    formik.setValues({
      ...formik.values,
      directors: [
        ...formik.values.directors,
        {
          first_name: '',
          last_name: '',
          date_of_birth: '',
          is_director: false,
          shareholder_percentage: 0,
          destroyed: false
        }
      ]
    })
  }

  const addParentCompany = () => {
    formik.setValues({
      ...formik.values,
      parent_company: {
        company_name: '',
        company_registration_number: '',
        is_director: false,
        shareholder_percentage: 0
      }
    })
  }

  const onSubmitCompanyDirectors = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  return {
    addPerson,
    addParentCompany,
    onSubmitCompanyDirectors,
    formik,
    loading: getCompanyDirectors.isLoading,
    submitting: updateDirectorsMutation.isLoading
  }
}

/**
 * Director form item value type
 * @typedef {Object} DirectorForm
 * @property {string} id - internal id
 * @property {string} first_name - First name
 * @property {string} last_name - Last name
 * @property {string} date_of_birth - format "14/07/2022", cannot be in future
 * @property {boolean} is_director - Mark that person is director
 * @property {boolean} destroyed - Mark that the person data is "destroyed" and will not be visible
 * @property {number} shareholder_percentage - % of company share
 */
// TODO: Review data stored in this type, clarify necessary props
/**
 * Parent company form item value type
 * @typedef {Object} ParentCompanyForm
 * @property {string} company_name - usually empty
 * @property {string} company_registration_number - usually empty
 * @property {boolean} is_director - ? Mark that person is director
 * @property {number} shareholder_percentage - % of company share
 * @property {string} parent_company_name - Name of parent company
 * @property {string} parent_company_number - Name of parent company - Clarify allowed type
 * @property {number} parent_company_shareholder_percentage - % of company share
 */

/**
 * Form values type
 * @typedef {Object} DirectorsForm
 * @property {Array.<DirectorForm>} directors - Array of director form objects
 * @property {(Object<ParentCompanyForm>) | null} parent_company company info
 */

/**
 * Validates form for exceeded overall shareholders percentage
 * @param {DirectorsForm} values - Directors form data
 */
function validateShareholders(values) {
  const MAX_PERCENTAGE = 100
  const errorMessage = 'Overall shareholder percentage exceeds 100%'
  const directorErrors = []

  const directorsShare = values.directors.reduce((sum, director) => {
    if (!!director.destroyed) {
      directorErrors.push({})
      return sum
    }

    directorErrors.push({ shareholder_percentage: errorMessage })
    return sum + director.shareholder_percentage
  }, 0)
  const parentCompanyShare =
    values.parent_company?.parent_company_shareholder_percentage ?? 0

  const parentCompanyError = !!parentCompanyShare
    ? {
        parent_company: { parent_company_shareholder_percentage: errorMessage }
      }
    : {}

  if (directorsShare + parentCompanyShare > MAX_PERCENTAGE) {
    return { directors: directorErrors, ...parentCompanyError }
  }
  return {}
}

export default useDirectorsFormHook
