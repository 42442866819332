import { useFormik } from 'formik'
import { xeroFormSchema } from './xeroFormSchema'

export const useXeroWritebackForm = ({
  currentConnection,
  selectedOrganization,
  currentWritebackData,
  postWriteback
}) => {
  const isEdit =
    selectedOrganization.tenantId === currentWritebackData?.tenantId

  const currentAccount =
    currentConnection?.originatingBankAccounts?.find(
      (el) => el.id === currentWritebackData?.originatingBankAccount
    ) || null
  const currentBankAccount =
    currentConnection?.receivingBankAccounts?.find(
      (el) => el.id === currentWritebackData?.receivingBankAccount
    ) || null
  const currentFeeAccount =
    currentConnection?.feeBankAccounts?.find(
      (el) => el.id === currentWritebackData?.feeBankAccount
    ) || null

  const renameObjectKeys = (obj) => ({
    value: obj?.id,
    label: obj?.name
  })

  const formik = useFormik({
    initialValues: {
      writeBack: isEdit && !!currentWritebackData?.enabled,
      accounts: isEdit && renameObjectKeys(currentAccount),
      bankAccounts: isEdit && renameObjectKeys(currentBankAccount),
      expenseAccounts: isEdit && renameObjectKeys(currentFeeAccount)
    },
    validationSchema: xeroFormSchema(),
    onSubmit: (data) => {
      const { accounts, bankAccounts, expenseAccounts, writeBack } = data

      const payload = {
        enabled: writeBack
      }

      if (writeBack) {
        payload.tenantId = selectedOrganization.tenantId
        payload.originating_bank_account = accounts.value
        payload.receiving_bank_account = bankAccounts.value
        payload.fee_bank_account = expenseAccounts.value
      }

      postWriteback.mutate(payload)
    }
  })

  const requestUpdate = () => {
    getData(getMockedXeroConfigData())
  }

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const onFormSubmit = (e) => {
    formik.handleSubmit(e)
  }

  return {
    onFormSubmit,
    formik,
    handleChange,
    refetch: requestUpdate
  }
}
