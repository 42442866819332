import { ErrorMessage, Field, FieldArray, FormikProvider } from 'formik'
import styled from 'styled-components'
import configs from 'config/configs'
import Button from '../../Button'
import Skeleton from '../../Skeleton'
import { BirthdayDatePicker } from './BirthdayDatePicker'
import useDirectorsFormHook from './hook'

const PercentInput = styled.input`
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
`

const ParentDirectorForm = ({ formik }) => {
  return (
    <div className="rounded-2xl xs:bg-none lg:bg-white mt-8 lg:p-6">
      <div className="flex row gap-2 sm:gap-4 flex-col sm:flex-row">
        <div className="flex-1">
          <div className="flex flex-col gap-3">
            <Field name={`parent_company.parent_company_name`}>
              {({ field, meta }) => (
                <div className="relative">
                  <input
                    id={`parent_company.parent_company_name`}
                    placeholder="Company Name"
                    type="text"
                    {...field}
                    className={`flex-grow font-normal flex flex-row items-center rounded-lg
                    bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                    ${meta.touched && meta.error ? 'border-b-pink' : 'border-b-placeholder'}`}
                  />
                  <ErrorMessage
                    name="parent_company.parent_company_name"
                    component="p"
                    className="text-b-pink text-sm mt-2 font-normal pl-4"
                  />
                </div>
              )}
            </Field>

            <Field name={`parent_company.parent_company_number`}>
              {({ field, meta }) => (
                <div className="relative">
                  <input
                    id="parent_company.parent_company_number"
                    type="text"
                    placeholder="Company Registration Number"
                    {...field}
                    className={`flex-grow font-normal flex flex-row items-center rounded-lg
                    bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                    ${meta.touched && meta.error ? 'border-b-pink' : 'border-b-placeholder'}`}
                  />
                  <ErrorMessage
                    name="parent_company.parent_company_number"
                    component="p"
                    className="text-b-pink text-sm mt-2 font-normal pl-4"
                  />
                </div>
              )}
            </Field>
          </div>
        </div>
        <div className="flex-1">
          <Field name={`parent_company.parent_company_shareholder_percentage`}>
            {({ field, meta }) => {
              return (
                <div className="rounded-2xl bg-gray-200 p-6 flex flex-col justify-center align-center h-full">
                  <div className="flex justify-between items-center flex-grow flex-row">
                    <label>Shareholder percentage</label>
                    <div className="relative items-center">
                      <span className="absolute right-1.5 align-middle inset-y-27.08% ">
                        %
                      </span>
                      <PercentInput
                        {...field}
                        id={`parent_company.parent_company_shareholder_percentage`}
                        type="number"
                        min={0}
                        max={100}
                        value={field.value ?? 0}
                        className={`rounded-lg w-80px border focus
                      border-b-dark h-50px p-3 pr-5 ${meta.touched && meta.error && 'border-b-pink'}`}
                      />
                    </div>
                  </div>
                  <ErrorMessage
                    name="parent_company.parent_company_shareholder_percentage"
                    component="p"
                    className="text-b-pink text-sm mt-2 font-normal"
                  />
                </div>
              )
            }}
          </Field>
        </div>
      </div>
      <div className="text-pink-color fond-bold mt-4">
        <Button
          type="button"
          variant="button-pink-text"
          onClick={() => formik.setFieldValue(`parent_company`, null)}
        >
          <img src={configs.icons.minus} className="mr-2"></img>
          REMOVE THIS PARENT COMPANY
        </Button>
      </div>
    </div>
  )
}

const DirectorsForm = ({ onBack, onRedirect }) => {
  const {
    formik,
    onSubmitCompanyDirectors,
    loading,
    addPerson,
    addParentCompany,
    submitting
  } = useDirectorsFormHook({
    onRedirect
  })
  const noDirectors = formik?.values?.directors?.every(
    (director) => director?.destroyed
  )

  return (
    <div>
      <p className="font-bold text-4xl">Director and shareholder details</p>
      <p className="text-xl mt-2">
        Please enter the name and date of birth for each director of your
        company as recorded at companies house. Please also provide details for
        every shareholder who owns 25% or more of the company. Make sure to use
        full legal names.
      </p>
      <form onSubmit={onSubmitCompanyDirectors}>
        {!loading ? (
          <FormikProvider value={formik}>
            <FieldArray
              name="directors"
              render={() => (
                <div>
                  {formik.values.directors.map(
                    (director, index) =>
                      !director.destroyed && (
                        <div
                          key={index}
                          className="rounded-2xl xs:bg-none lg:bg-white mt-8 lg:p-6"
                        >
                          <div className="flex row gap-2 sm:gap-4 flex-col sm:flex-row">
                            <div className="flex-1">
                              <div className="flex flex-col gap-3">
                                <Field name={`directors.${index}.first_name`}>
                                  {({ field, meta }) => (
                                    <div className="relative">
                                      <input
                                        id={`directors.${index}.first_name`}
                                        placeholder="First Name"
                                        type="text"
                                        {...field}
                                        className={`flex-grow font-normal flex flex-row items-center rounded-lg
                                    bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                                    ${meta.touched && meta.error ? 'border-b-pink' : 'border-b-placeholder'}`}
                                      />
                                      <ErrorMessage
                                        name={`directors.${index}.first_name`}
                                        component="p"
                                        className="text-b-pink text-sm mt-2 font-normal pl-4"
                                      />
                                    </div>
                                  )}
                                </Field>
                                <Field name={`directors.${index}.last_name`}>
                                  {({ field, meta }) => (
                                    <div className="relative">
                                      <input
                                        id={`directors.${index}.last_name`}
                                        type="text"
                                        placeholder="Last Name"
                                        {...field}
                                        className={`flex-grow font-normal flex flex-row items-center rounded-lg
                                    bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                                    ${meta.touched && meta.error ? 'border-b-pink' : 'border-b-placeholder'}`}
                                      />
                                      <ErrorMessage
                                        name={`directors.${index}.last_name`}
                                        component="p"
                                        className="text-b-pink text-sm mt-2 font-normal pl-4"
                                      />
                                    </div>
                                  )}
                                </Field>
                                <Field
                                  name={`directors.${index}.date_of_birth`}
                                >
                                  {({ meta }) => (
                                    <div className="relative">
                                      <BirthdayDatePicker
                                        onChange={formik.setFieldValue}
                                        name={`directors.${index}.date_of_birth`}
                                        setTouched={formik.setFieldTouched}
                                        meta={meta}
                                      />
                                      <ErrorMessage
                                        name={`directors.${index}.date_of_birth`}
                                        component="p"
                                        className="text-b-pink text-sm mt-2 font-normal pl-4"
                                      />
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="flex-1">
                              <div className="rounded-2xl bg-gray-200 p-6 flex flex-col justify-center align-center h-full">
                                <Field name={`directors.${index}.is_director`}>
                                  {({ field, meta }) => (
                                    <div className="flex justify-between flex-grow flex-row items-center mb-2">
                                      <label>Is director?</label>
                                      <input
                                        id={`directors.${index}.is_director`}
                                        type="checkbox"
                                        checked={meta.value}
                                        onChange={(e) =>
                                          formik.setFieldValue(
                                            field.name,
                                            e.target.checked
                                          )
                                        }
                                        {...field}
                                        className="w-6 h-6 border-2 bg-none"
                                      />
                                    </div>
                                  )}
                                </Field>
                                <Field
                                  name={`directors.${index}.shareholder_percentage`}
                                >
                                  {({ field, meta }) => {
                                    return (
                                      <>
                                        <div className="flex justify-between items-center flex-grow flex-row">
                                          <label>Shareholder percentage</label>
                                          <div className="relative items-center">
                                            <span className="absolute right-1.5 align-middle inset-y-27.08% ">
                                              %
                                            </span>
                                            <PercentInput
                                              id={`directors.${index}.shareholder_percentage`}
                                              type="number"
                                              min={0}
                                              max={100}
                                              {...field}
                                              className={`rounded-lg w-80px border focus
                                              border-b-dark h-50px p-3 pr-5 ${
                                                meta.touched &&
                                                meta.error &&
                                                'border-b-pink'
                                              }`}
                                            />
                                          </div>
                                        </div>
                                        <ErrorMessage
                                          name={`directors.${index}.shareholder_percentage`}
                                          component="p"
                                          className="text-b-pink text-sm mt-2 font-normal"
                                        />
                                      </>
                                    )
                                  }}
                                </Field>
                              </div>
                            </div>
                          </div>
                          <div className="text-pink-color fond-bold mt-4">
                            <Button
                              type="button"
                              variant="button-pink-text"
                              onClick={() => {
                                formik.setFieldValue(
                                  `directors.${index}.destroyed`,
                                  true
                                )
                              }}
                            >
                              <img
                                src={configs.icons.minus}
                                className="mr-2"
                              ></img>
                              REMOVE THIS PERSON
                            </Button>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            />
            {formik.values.parent_company && (
              <ParentDirectorForm formik={formik} />
            )}
          </FormikProvider>
        ) : (
          <Skeleton height="48" />
        )}

        <div className="mt-35px flex flex-row-reverse gap-4">
          <Button
            type="button"
            variant="button-pink-text"
            onClick={addParentCompany}
          >
            <img src={configs.icons.plus} className="mr-2"></img>
            Add parent company
          </Button>
          <Button type="button" variant="button-pink-text" onClick={addPerson}>
            <img src={configs.icons.plus} className="mr-2"></img>
            {noDirectors ? 'Add a person' : 'Add another person'}
          </Button>
        </div>

        <div className="mt-35px flex justify-between items-center">
          <Button
            variant="button-back-grey"
            type="button"
            onClick={onBack}
            children="Back"
          />
          <Button
            variant="button-next-pink"
            affixIcon={configs.icons.arrowForward}
            type="submit"
            children={submitting ? 'Loading...' : 'Next'}
            disabled={
              !formik.values.directors.filter((d) => !d.destroyed).length ||
              submitting
            }
          />
        </div>
      </form>
    </div>
  )
}

export default DirectorsForm
