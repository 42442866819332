import { useFormik } from 'formik'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import authApi from 'config/api/auth'
import { getErrorMessage } from 'utils/getErrorMessage'
import { makePasswordStrengthBar } from 'utils/passwordBarUtil'
import { jwtDecode } from '../../../src/utils/jwtDecode'
import resetPasswordSchema from './schema'

const useResetPasswordHook = () => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const token = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true
  }).token
  const decodedToken = jwtDecode(token)
  const history = useHistory()
  const [passwordBar, setPasswordBar] = useState({
    message: '',
    items: [
      {
        1: 'bg-light-grey-200'
      },
      {
        2: 'bg-light-grey-200'
      },
      {
        3: 'bg-light-grey-200'
      },
      {
        4: 'bg-light-grey-200'
      }
    ]
  })

  useEffect(() => {
    if (!decodedToken) {
      return history.push('/login')
    }
    const expiresAt = decodedToken.exp
    const now = new Date()
    const expireDate = new Date(expiresAt * 1000)
    if (now > expireDate) {
      toast.error('Your password reset link has expired')
      history.push('/login')
    }
  }, [])

  const handleResetPassword = useMutation(authApi.resetPassword, {
    onSuccess: (res) => {
      toast.success(res.message)
      history.push('/login')
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  //form
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: resetPasswordSchema(),
    onSubmit: (values) => {
      handleResetPassword.mutate({ ...values, token })
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
    if (field === 'password') {
      makePasswordStrengthBar(value, passwordBar.items).then((it) =>
        setPasswordBar(it)
      )
    }
  }

  const onResetPassword = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  return {
    formik,
    shouldShowError,
    passwordBar,
    handleChange,
    onResetPassword,
    loading: handleResetPassword.isLoading
  }
}

export default useResetPasswordHook
