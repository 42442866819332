import * as Yup from 'yup'

export const digitRegExp = /\d/
export const specialRegExp = /[!~<>,;:_=?*+#."&§%°()|\[\]\-$^@]+/
export const lowercaseRegExp = /(?=.*[a-z])/
export const uppercaseRegExp = /(?=.*[A-Z])/

const passwordMessageMin = 'Use 8 or more characters for your password'
const validationPasswordSchema = () => {
  customPasswordValidationFunction()
  return {
    password: Yup.string()
      .min(8, passwordMessageMin)
      .required('New password is required'),
    passwordConfirmation: Yup.string()
      .min(8, passwordMessageMin)
      .required('Password is required')
      .password()
      .when('password', (password, schema) => {
        return schema.test({
          test: (passwordConfirmation) =>
            !!password && passwordConfirmation === password,
          message: 'Confirm password and new password do not match.'
        })
      })
  }
}

const customPasswordValidationFunction = () => {
  Yup.addMethod(Yup.string, 'password', function (errorMessage) {
    return this.test(`test-password`, errorMessage, function (value) {
      const { path, createError } = this

      if (!digitRegExp.test(value))
        return createError({
          path,
          message: 'At least one digit is required'
        })
      if (!specialRegExp.test(value))
        return createError({
          path,
          message: 'At least one special character is required'
        })
      if (!lowercaseRegExp.test(value))
        return createError({
          path,
          message: 'At least one lowercase character is required'
        })
      if (!uppercaseRegExp.test(value))
        return createError({
          path,
          message: 'At least one uppercase character is required'
        })
      return value
    })
  })
}

export default {
  passwordMessageMin,
  validationPasswordSchema,
  customPasswordValidationFunction
}
