import * as actionTypes from '../../types/Register'

const initialState = {
  currentStep: 0,
  selectedCompany: null,
  countryDefault: 'United Kingdom',
  investmentPlan: '',
  saveCompanyError: false,
  selectedCompanyForm: {
    website: '',
    avgMonthlyIncome: '',
    estimatedArr: ''
  },
  newCompanyForm: {
    companyName: '',
    companyNumber: '',
    incorporatedOn: '',
    country: '',
    companyType: '',
    website: '',
    avgMonthlyIncome: '',
    manually: true
  },
  isNewCompany: false,
  completedStep1: false,
  personalInfo: {
    countryCode: 'GB',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    privacyPolicy: false,
    keepInformed: false,
    minFunding: 100000,
    maxFunding: 250000
  }
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.value
      }
    case actionTypes.SAVE_COMPANY_ERROR:
      return {
        ...state,
        saveCompanyError: action.payload.value
      }
    case actionTypes.SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload.company
      }
    case actionTypes.REMOVE_SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: null
      }
    case actionTypes.UPDATE_SELECT_COMPANY_FORM:
      return {
        ...state,
        selectedCompanyForm: {
          ...state.selectedCompanyForm,
          [action.payload.field]: action.payload.value
        }
      }
    case actionTypes.UPDATE_NEW_COMPANY_FORM:
      return {
        ...state,
        newCompanyForm: {
          ...state.newCompanyForm,
          [action.payload.field]: action.payload.value
        }
      }
    case actionTypes.UPDATE_PERSONAL_INFO_VALUES:
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          ...action.payload
        }
      }
    case actionTypes.COMPLETED_STEP_1:
      return {
        ...state,
        completedStep1: true
      }
    case actionTypes.SET_IS_NEW_COMPANY:
      return {
        ...state,
        isNewCompany: action.payload.value
      }
    case actionTypes.RESET_REGISTER_STATE:
      return initialState
    default: {
      return state
    }
  }
}
export default registerReducer
