import axios from '../../services/axios'

const getCompanyInfo = async () => {
  const response = await axios.get('/api/company-information')

  return response.data
}

const getCurrentBankAccount = async () => {
  const response = await axios.get(
    '/api/company-information/current_bank_account'
  )

  return response.data
}

export default {
  getCompanyInfo,
  getCurrentBankAccount
}
