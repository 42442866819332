import * as Yup from 'yup'
import 'yup-phone-lite'
import passwordValidation from 'utils/passwordValidation'

const personalSchema = (countryCode) => {
  passwordValidation.customPasswordValidationFunction()

  Yup.addMethod(Yup.string, 'countryPhone', function (errorMessage) {
    return this.test(`test-country-phone`, errorMessage, function (value) {
      const { path, createError } = this

      const phoneSchema = Yup.string().phone(countryCode)

      try {
        return phoneSchema.validateSync(value)
      } catch (error) {
        return createError({ path, message: errorMessage })
      }
    })
  })

  return Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required('First name is required.')
      .max(255, 'Max. 255 characters.'),
    lastName: Yup.string()
      .trim()
      .required('Last name is required.')
      .max(255, 'Max. 255 characters.'),
    email: Yup.string()
      .trim()
      .required('Work email is required.')
      .email('Work email is not valid.'),
    phone: Yup.string()
      .required('Contact number is not valid.')
      .countryPhone('Contact number is not valid.'),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must have at least 8 characters.')
      .password(),
    privacyPolicy: Yup.boolean().oneOf(
      [true, 'true'],
      "Please accept Bloom's Privacy Policy."
    )
  })
}

export default personalSchema
