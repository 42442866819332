import React from 'react'
import Skeleton from 'components/Skeleton'

const SkeletonOnboardingLayout = () => {
  return (
    <div className="flex flex-col w-full">
      <Skeleton height="24"></Skeleton>
      <Skeleton height="32"></Skeleton>
      <Skeleton height="32"></Skeleton>
      <Skeleton height="24"></Skeleton>
    </div>
  )
}

export default SkeletonOnboardingLayout
