import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import usePartnersHook from 'components/Partners/hook'
import { PARTNERS_ROUTES } from 'config/configs/routes'
import NotFoundPage from 'pages/NotFoundPage'
import PartnersApply from 'pages/app/Partners/PartnersApply'
import PartnersIncognito from 'pages/app/Partners/PartnersIncognito'
import PartnersMaintenance from 'pages/app/Partners/PartnersMaintenance/PartnersMaintenance'
import PartnersSignUp from 'pages/app/Partners/PartnersSignUp'

const { APPLY, MAINTENANCE, INCOGNITO, SIGNUP } = PARTNERS_ROUTES

const PartnersRouter = () => {
  const location = useLocation()
  const allow = ['apply', 'signup']
  const slug = location?.pathname.split('/')?.[2]
  const triggerHook = allow.some((el) => location?.pathname?.includes(el))
  const { partnerData, fetchingPartnerConfig } =
    triggerHook && usePartnersHook(slug)

  if (fetchingPartnerConfig) {
    return <LoadingScreen />
  }
  if (window.rudderError) {
    const INCOGNITO_URL = INCOGNITO.replace(':slug', slug)
    return (
      <Switch>
        <Route
          path={INCOGNITO_URL}
          render={() => <PartnersIncognito slug={slug} />}
        />
        <Redirect to={INCOGNITO_URL} />
      </Switch>
    )
  }
  if (partnerData?.slug && partnerData?.url && partnerData?.logo) {
    return (
      <Switch>
        <Route
          path={APPLY}
          render={() => <PartnersApply partnerData={partnerData} />}
        ></Route>
        <Route
          path={SIGNUP}
          render={() => <PartnersSignUp partnerData={partnerData} />}
        ></Route>
      </Switch>
    )
  }
  return (
    <Switch>
      <Route path={MAINTENANCE} component={PartnersMaintenance} />
      <Route path={'*'} component={NotFoundPage} />
    </Switch>
  )
}

export default PartnersRouter
