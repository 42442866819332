import axios from '../../services/axios'

const getDocuments = async () => {
  const response = await axios.get('/api/documents')

  return response.data
}

const downloadDocument = async (document_id) => {
  const response = await axios.get(`/api/documents/${document_id}/download`)

  return response.data
}

export default {
  getDocuments,
  downloadDocument
}
