import _ from 'lodash'

const camelCaseKeys = (snake_case_object) => {
  var camelCaseObject = {}
  _.forEach(snake_case_object, function (value, key) {
    // checks that a value is a plain object or an array - for recursive key conversion
    if (_.isPlainObject(value)) {
      value = camelCaseKeys(value) // recursively update keys of any values that are also objects
    } else if (_.isArray(value)) {
      value = value.map((v) => camelCaseKeys(v))
    }
    camelCaseObject[_.camelCase(key)] = value
  })
  return camelCaseObject
}

export default camelCaseKeys
