import React, { useState } from 'react'
import configs from 'config/configs'
import SalesChart from '../Charts/SalesChart'

const SalesBody = ({ salesData }) => {
  const [tabActive, setTabActive] = useState('Sales')

  if (!salesData) {
    return <div>No sales data</div>
  }

  return (
    <div className="flex flex-col">
      <div className="flex mt-6 bg-b-lite rounded-lg overflow-x-auto">
        {salesData.saleTabs?.map((item, index) => {
          const isActive = item.label === tabActive
          const isGrowth = parseInt(item.compared_to_last_year) > 0

          return (
            <div
              className={`p-4 flex cursor-pointer flex-col w-1/4 min-w-fit-content rounded-l-lg ${
                isActive && 'border-t-4 border-indingo-bloom bg-white'
              }`}
              key={index}
              onClick={() => setTabActive(item.label)}
            >
              <div className="mb-1 text-header-table-gray">{item.label}</div>
              <div className="font-bold text-xl text-text-color-bloom">
                {item.value}
              </div>
              {item.compared_to_last_year && (
                <div
                  className={`mb-1 flex row text-sm ${isGrowth ? 'text-green-bloom' : 'text-faded-strawberry-bloom'}`}
                >
                  {item.compared_to_last_year !== 0 && (
                    <img
                      src={
                        isGrowth
                          ? configs.images.arrowCompareUp
                          : configs.images.arrowCompareDown
                      }
                      className="mr-0.5"
                    />
                  )}
                  <div className="mr-1.5">{item.compared_to_last_year}</div>
                </div>
              )}
            </div>
          )
        })}
      </div>
      <SalesChart data={salesData.chart} tabActive={tabActive} />
      <div className="flex flex-row space-x-3 md:space-x-6 font-normal text-base mt-2 md:mt-0">
        <div className="flex flex-row space-x-2">
          <div>
            <img src={configs.icons.circleCheckPurple} />
          </div>
          <span>This year</span>
        </div>
        <div className="flex flex-row space-x-2">
          <div>
            <img src={configs.icons.circleCheckPink} />
          </div>
          <span>Last year</span>
        </div>
      </div>
    </div>
  )
}
export default SalesBody
