import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from 'layouts/components/Sidebar'
import { options, steps } from './config'

const SidebarDashboardSaaS = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname) {
      const foundStep = steps.find((s) => s.routeMatch === pathname)
      const index = foundStep && steps.indexOf(foundStep)
      index && setCurrentStep(index)
    }
  }, [pathname])

  return (
    <div className={`flex flex-col w-full bg-dark-blue text-white rounded-lg`}>
      <Sidebar
        steps={steps}
        options={options}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    </div>
  )
}

export default SidebarDashboardSaaS
