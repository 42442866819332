import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { closeHeader } from 'redux/actions/App'
import useSidebarHook from './hook'
import sidebarConfig from './sidebarConfig'

const SidebarDashboardEcommerce = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useSidebarHook()

  return (
    <React.Fragment>
      <div className="flex flex-col divide-y divide-divider-dark-blue bg-dark-blue lg:rounded-lg lg:h-auto lg:relative">
        {sidebarConfig.map((route, index) => {
          let isActive = pathname.includes(route.routeMatch) && route.to !== '/'
          if (pathname === '/') {
            isActive = route.to === pathname
          }
          let activeClassName = isActive
            ? 'opacity-100 font-bold'
            : 'opacity-60 font-normal'

          return (
            <NavLink
              key={index}
              to={route.to}
              className={`flex items-center w-full py-6 px-8 text-white transition hover:opacity-100 focus:opacity-100 ${activeClassName}`}
              onClick={() => dispatch(closeHeader())}
            >
              <span
                className={`flex h-6 w-6 mr-4 justify-center items-center transition-all bg-transparent duration-150`}
              >
                <img
                  src={route.icon}
                  className={`h-6 w-6 object-cover transition-opacity duration-150`}
                />
              </span>
              <span className="flex">{route.name}</span>
            </NavLink>
          )
        })}
      </div>
      {children}
    </React.Fragment>
  )
}

export default SidebarDashboardEcommerce
