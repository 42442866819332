import { isEmpty } from 'ramda'
import BenchmarkCategory from 'components/BenchmarkCategory'
import Card from 'components/Card'
import DateSectionTitle from 'components/DateSectionTitle'
import ErrorMessage from 'components/ErrorMessage'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'
import { startCaseKey } from 'utils/startCaseKeys'
import useBenchmarksHook from './hook'

const Benchmarks = () => {
  const {
    benchmarksData,
    descriptions,
    dateRange,
    handleChangeDate,
    error,
    isLoading
  } = useBenchmarksHook()

  if (isLoading) {
    return <AppLayoutSkeleton />
  }

  return (
    <div className="col-span-12 md:row-span-1 lg:col-span-12">
      <div className="col-span-6 lg:col-span-5 flex flex-col space-y-4 mb-12">
        {Boolean(error) || isEmpty(benchmarksData) ? (
          <Card headerClassname="text-b-dark font-medium">
            <ErrorMessage error={error}></ErrorMessage>
          </Card>
        ) : (
          <>
            <DateSectionTitle
              title="Industry benchmarks"
              subtitle="Updated every month."
              handleChangeDate={handleChangeDate}
              dateRange={dateRange}
            />
            {benchmarksData &&
              Object.entries(benchmarksData).map((entry, index) => {
                const [key, data] = entry
                const titleName = startCaseKey(key)
                return (
                  <BenchmarkCategory
                    key={index}
                    titleName={titleName}
                    description={descriptions[key]}
                    data={data}
                  />
                )
              })}
          </>
        )}
      </div>
    </div>
  )
}

export default Benchmarks
