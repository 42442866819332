import React from 'react'
import Button from '../Button'
import FormikTextInput from '../FormikInputText'
import useForgotPasswordFormHook from './hook'

const ForgotPasswordForm = () => {
  const { formik, shouldShowError, onForgotPassword, loading } =
    useForgotPasswordFormHook()

  return (
    <div className="flex flex-col justify-between items-start w-full md:w-596px xl:w-624px xl:ml-103px z-10 shadow-login-form">
      <form
        className="flex flex-col justify-end space-y-6 w-full rounded-2xl p-12 bg-white"
        onSubmit={onForgotPassword}
      >
        <span className="text-32px md:text-2xl font-semibold static">
          Forgot Your Password?
        </span>
        <span className="text-base font-normal">
          We get it, stuff happens. Just enter your email address below and
          we'll send you a link to reset your password!
        </span>
        <div className="flex flex-col">
          <FormikTextInput
            autoFocus
            field="email"
            id="email"
            placeholder="Email"
            shouldValid={shouldShowError}
            {...formik}
          />
        </div>
        <div className="flex flex-col items-end">
          <Button
            type="submit"
            variant="onboard"
            className="w-full md:w-auto"
            loading={loading}
          >
            Forgot Password
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ForgotPasswordForm
