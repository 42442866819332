import { useEffect, useState } from 'react'

const useModal = () => {
  const [isOpened, setIsOpened] = useState(false)
  const [isEscapePressed, setIsEscapePressed] = useState(false)

  const open = () => {
    setIsOpened(true)
    setIsEscapePressed(false)
  }

  const close = () => {
    setIsOpened(false)
  }

  const handleCloseOnEscape = (e) => {
    if (e.keyCode === 27) {
      setIsEscapePressed(true)
      close()
    }
    return null
  }

  useEffect(() => {
    if (isOpened) {
      window.addEventListener('keydown', handleCloseOnEscape)
      return () => window.removeEventListener('keydown', handleCloseOnEscape)
    }
  }, [isOpened])

  return {
    isOpened,
    open,
    close,
    isEscapePressed
  }
}

export default useModal
