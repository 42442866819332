import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import configs from 'config/configs'
import arrData from 'config/data/EstimatedARR'
import useModal from 'hooks/useModal'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import formatCurrency from 'utils/formatCurrency'
import Button from '../../../Button'
import FormikCurrencyInputGrey from '../../../FormikCurrencyInputGrey'
import FormikInputTextGrey from '../../../FormikInputTextGrey'
import FormikSelectGrey from '../../../FormikSelectGrey'
import SearchInputGrey from '../../../SearchInputGrey'
import CantFindCompanyModal from '../../CantFindCompanyModal'
import useSelectedCompanyFormHook from './hook'

const CantFindCompanyButton = () => {
  const { isOpened, open, close } = useModal()

  return [
    <button
      className="absolute top-1/2 right-4 transform -translate-y-1/2 border-b border-placeholder-grey leading-none text-placeholder-grey"
      // TODO: Replace with setSelectedCompany(null) when manual company info is again enabled
      onClick={open}
      key="cantFindCompanyButton"
    >
      Can't find company?
    </button>,
    <CantFindCompanyModal
      key="cantFindCompanyModal"
      isOpened={isOpened}
      close={close}
    />
  ]
}

const SelectedCompanyForm = ({
  companySearchResult,
  selectCompany,
  onCompanyChange,
  setSelectedCompany,
  handleCreateCompanyInfo
}) => {
  const {
    formik,
    shouldShowError,
    onSubmitCompanyForm,
    handleChange,
    isSaaSUser,
    isEcommerceUser
  } = useSelectedCompanyFormHook({
    handleCreateCompanyInfo
  })
  const dataRegister = useSelector((state) => state.dataRegister)
  const dataCompany = useSelector((state) => state.dataCompany)
  const { selectedCompanyForm, selectedCompany, countryDefault } = dataRegister
  const { registerCompanyLoading } = dataCompany

  return (
    <React.Fragment>
      <div>
        <div className="relative bg-white rounded-2xl">
          <div className="p-5">
            <div className="relative mb-5">
              <SearchInputGrey
                inputClasses={
                  'outline-none focus:border-dark-grey focus:border-2'
                }
                inputProps={{
                  placeholder: 'Enter your company name',
                  onChange: (e) => onCompanyChange(e.target.value),
                  autoFocus: true,
                  value: capitalizeFirstLetter(selectedCompany.title),
                  disabled: false,
                  style: {
                    backgroundColor: 'white'
                  }
                }}
                affixComponent={
                  <CantFindCompanyButton
                    setSelectedCompany={setSelectedCompany}
                  />
                }
              />
              {companySearchResult && !!companySearchResult.length && (
                <div className="absolute top-full left-0 w-full mt-4 z-20 bg-light-grey-200 max-h-80 rounded-lg overflow-y-scroll">
                  {companySearchResult.map((item, index) => {
                    return (
                      <button
                        key={index}
                        className="pt-6 pb-4 px-4 flex flex-col w-full md:flex-row md:justify-between transition-opacity hover:opacity-80 focus:opacity-80 md:items-center border-b border-divide-grey"
                        onClick={() => selectCompany(item?.company)}
                      >
                        <p className="text-left truncate">
                          {item?.companyTitle}
                        </p>
                        <p className="text-sm text-placeholder-grey text-right">
                          {item?.companyDetail}
                        </p>
                      </button>
                    )
                  })}
                </div>
              )}
            </div>
            <div className="rounded-lg bg-splitter-grey">
              <div className="grid grid-cols-2 md:grid-cols-4 mb-7 bg-light-grey-200 rounded-lg">
                <div className="flex flex-col items-center mb-4 md:mb-0 border-r border-light-grey-100 py-3">
                  <p className="text-sm text-placeholder-grey mb-1">
                    Company no.
                  </p>
                  <p className="font-bold">
                    {selectedCompany?.company_number?.toUpperCase()}
                  </p>
                </div>
                <div className="flex flex-col items-center mb-4 md:mb-0 border-r border-light-grey-100 py-3">
                  <p className="text-sm text-placeholder-grey mb-1">
                    Incorporated
                  </p>
                  <p className="font-bold">
                    {moment(selectedCompany?.date_of_creation).format(
                      'DD MMM YYYY'
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-center mb-4 md:mb-0 border-r border-light-grey-100 py-3">
                  <p className="text-sm text-placeholder-grey mb-1">Country</p>
                  <p className="font-bold">
                    {selectedCompany?.address?.country || countryDefault}
                  </p>
                </div>
                <div className="flex flex-col items-center mb-4 md:mb-0 py-3">
                  <p className="text-sm text-placeholder-grey mb-1">
                    Company type
                  </p>
                  <p className="font-bold">{selectedCompany?.company_type}</p>
                </div>
              </div>
            </div>
            {isEcommerceUser && (
              <div className="mb-8">
                <FormikCurrencyInputGrey
                  field="avgMonthlyIncome"
                  id="avgMonthlyIncome"
                  placeholder="Monthly revenue"
                  shouldValid={shouldShowError}
                  containerClassname={'mb-3'}
                  {...formik}
                  handleChange={handleChange}
                  autoFocus
                />
                <FormikInputTextGrey
                  field="website"
                  id="website"
                  backgroundColor={'bg-white'}
                  placeholder="Company website"
                  iconPrefixSrc={configs.icons.globe}
                  shouldValid={shouldShowError}
                  {...formik}
                  handleChange={handleChange}
                />
              </div>
            )}
            {isSaaSUser && (
              <div className="mb-8">
                <FormikSelectGrey
                  field="estimatedArr"
                  id="estimatedArr"
                  placeholder="Estimated Annual Recurring Revenue (ARR)"
                  shouldValid={shouldShowError}
                  options={arrData.estimatedARR}
                  {...formik}
                  handleChange={handleChange}
                  containerClassname={'mb-3'}
                  hasCurrencyIcon={true}
                />
                {selectedCompanyForm?.estimatedArr > 0 && (
                  <div
                    className="bg-teal-color flex flex-col md:flex-row justify-center rounded-lg min-h-10 items-center mb-4 p-2"
                    id="arrWrapper"
                  >
                    <div>{`Your initial trade limit could be up to `}</div>
                    <div className="text-xl leading-6 text-black font-bold ml-2">
                      {formatCurrency(
                        selectedCompanyForm?.estimatedArr *
                          (selectedCompanyForm?.estimatedArr > 10000000
                            ? 1
                            : 0.7)
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="my-8 3xl:my-12 flex justify-between items-center">
          <Button
            type="submit"
            variant={'button-next-pink'}
            className="ml-auto"
            affixIcon={configs.icons.arrowForward}
            loading={registerCompanyLoading}
            onClick={onSubmitCompanyForm}
          >
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SelectedCompanyForm
