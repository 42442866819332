import React from 'react'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import formatCurrency from 'utils/formatCurrency'

const RecurringRepayCard = ({
  repayAmount,
  isActive,
  onClick,
  repaymentOption
}) => {
  return (
    <div
      className={`flex flex-col overflow-hidden w-full lg:w-240px cursor-pointer`}
      onClick={onClick}
    >
      <div
        className={`h-150px rounded-lg border ${
          isActive ? 'border-teal-color' : 'border-dark-blue'
        }  transition-all ease-out duration-200 bg-white pb-5 overflow-hidden`}
      >
        <div
          className={`h-60px ${
            isActive
              ? 'bg-teal-color text-dark-grey '
              : 'bg-dark-grey text-white'
          } flex justify-center items-center text-2xl font-bold relative`}
        >
          {formatCurrency(repayAmount)}
        </div>
        <div className={`py-2.5 px-5`}>
          <>
            <div className="flex justify-center text-base text-dark-grey opacity-80 mb-1">
              Repay
            </div>
            <div className="flex justify-center text-3xl font-black leading-6 text-dark-grey opacity-80 mb-2">
              {repaymentOption && capitalizeFirstLetter(repaymentOption)}
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default RecurringRepayCard
