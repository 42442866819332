import React from 'react'
import ConnectedBank from 'components/BankDetails/ConnectedBank'
import { LaunchLink } from 'components/Integrations/Plaid/LaunchLink'
import { usePlaid } from 'components/Integrations/Plaid/usePlaid'
import configs from 'config/configs'

const ContentList = [
  'Plaid is FCA regulated',
  'Open Banking is backed by the UK Government',
  'We will never share or sell your data',
  'We pay the costs for this service, not you',
  'You are in control. Your bank login details will never be seen by Plaid or Bloom',
  'This will not affect your credit score'
]

const Description =
  'Bloom recommends sharing financial information safety through Open Banking – its like sharing your bank statements but faster, easier and safer. Our trusted Open Banking partner is Plaid. The more data we have about your business, the more capital we can provide.'

const OpenBankingSkeleton = (
  <div className="flex w-full mb-8 h-64px lg:h-32 animate-pulse flex bg-gray-200 rounded"></div>
)

const OpenBanking = ({ isLoading, accountList }) => {
  const {
    selectedBankToken,
    institutions,
    onSuccess,
    onExit,
    requestToken,
    loading: loadingPlaidAccounts
  } = usePlaid()

  return (
    <div className="text-base font-normal">
      <p className="mb-8 text-xl leading-26px">{Description}</p>
      <div
        className={`flex flex-col space-y-4 rounded-2xl w-full ${Object.keys(institutions).length ? 'mb-4' : ''}`}
      >
        {(isLoading || loadingPlaidAccounts) && (
          <div className="flex justify-center items-center mb-2">
            {OpenBankingSkeleton}
          </div>
        )}
        {!isLoading &&
          !loadingPlaidAccounts &&
          Object.entries(institutions)
            .filter(([institutionId]) => institutionId !== 'default')
            .map(([institutionId, institution]) => (
              <ConnectedBank
                key={institutionId}
                institutionId={institutionId}
                accounts={institution.accounts}
                bankName={institution.bankName}
                sortCode={institution.sortCode}
                accountHolder={institution.accountHolder}
                status={institution.status}
                requestToken={requestToken}
              />
            ))}
      </div>
      {!isLoading &&
        !loadingPlaidAccounts &&
        accountList?.map((item, index) => (
          <div
            key={index}
            className={`mb-8 p-27px w-full bg-white flex flex-col justify-between items-center rounded-lg sm:flex-row sm:space-x-8 lg:px-4 border border-1 border-b-grey`}
          >
            <div className="flex flex-col justify-between items-center sm:flex-row sm:h-45px">
              <img src={configs.images.plaidLogo} className="h-83px" />
              <img src={configs.images.fca} className="my-4 sm:ml-8" />
            </div>
            <button onClick={() => requestToken(null)}>
              <div
                className="py-0.5 px-5 text-pink-color rounded-full border border-pink-color text-sm leading-6 bg-white font-bold cursor-pointer transition hover:opacity-80"
                children={
                  Object.keys(institutions).length > 1
                    ? 'Connect Another'
                    : 'Connect'
                }
              />
            </button>
          </div>
        ))}
      {ContentList.map((content, index) => (
        <div key={index} className="flex flex-row mb-0.5 relative">
          <img src={configs.images.check} className="absolute bottom-26_43%" />
          <span className="ml-7">{content}</span>
        </div>
      ))}
      {selectedBankToken && (
        <LaunchLink
          token={selectedBankToken}
          onSuccess={onSuccess}
          onExit={onExit}
        />
      )}
    </div>
  )
}

export default OpenBanking
