import * as actionTypes from '../../types/Company'

export const companyRegistered = (value) => {
  return {
    type: actionTypes.COMPANY_REGISTERED,
    payload: {
      value
    }
  }
}

export const companyConfirming = (value) => {
  return {
    type: actionTypes.COMPANY_CONFIRMING,
    payload: {
      value
    }
  }
}

export const registerCompanyLoading = (value) => {
  return {
    type: actionTypes.REGISTER_COMPANY_LOADING,
    payload: {
      value
    }
  }
}
