import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import api from 'config/api/onboarding'
import { setOnboardingConnections } from 'redux/actions/App'
import { getErrorMessage } from 'utils/getErrorMessage'

const useConnectionsHook = () => {
  const { connections: steps } = useSelector((state) => state.dataApp)
  const dispatch = useDispatch()

  const stepsQuery = useQuery('connections', api.getOnboardingConnections, {
    onSuccess: (res) => {
      dispatch(setOnboardingConnections(res))
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  return {
    steps,
    isLoading: stepsQuery.isLoading
  }
}

export { useConnectionsHook }
