import React from 'react'
import Logo from 'components/Logo'
import configs from 'config/configs'

const PartnersLayout = ({ logo, children }) => {
  return (
    <div className={`min-h-screen text-dark-grey flex flex-col`}>
      <div className="bg-light-grey-100" style={{ flexGrow: '1' }}>
        <div className="flex justify-center mt-8">
          <img
            src={configs.images.bloomLogoWithHeart}
            className={'m-2 w-32 md:w-auto'}
            alt="bloomLogo"
          />
          <Logo
            className={'w-32 md:w-48 h-16 ml-2 bg-no-repeat bg-center'}
            logo={`url(${logo})`}
          />
        </div>
        {children}
      </div>
    </div>
  )
}

export default PartnersLayout
