import React from 'react'
import Card from 'components/Card'
import SalesBody from 'components/SalesBody'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'
import useSalesHook from './hook'

const Sales = () => {
  const { salesData, isLoading } = useSalesHook()

  if (isLoading) {
    return <AppLayoutSkeleton />
  }

  return (
    <Card headerTitle="Sales" cardClassname={'col-span-6 lg:col-span-5'}>
      <p className="mt-2">Comparison between the last two full months</p>
      <SalesBody salesData={salesData} />
    </Card>
  )
}

export default Sales
