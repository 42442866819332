import React from 'react'
import images from 'config/configs/images'

const LoadingScreen = () => {
  return (
    <div className="h-screen flex justify-center items-center relative">
      <img src={images.logoSingleO} className="h-350px"></img>
      <img
        src={images.logoDot}
        className="animate-spinAround h-52px absolute left-0 right-0 mx-auto"
      ></img>
    </div>
  )
}

export default LoadingScreen
