import axios from '../../services/axios'

const getUser = async () => {
  const response = await axios.get(`/api/user`)

  return response.data
}

export default {
  getUser
}
