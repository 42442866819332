import React from 'react'
import ChangePassword from 'components/ChangePassword'
import CompanyDetails from 'components/CompanyDetails'
import PaymentDetails from 'components/PaymentDetails'

const Settings = () => {
  return (
    <>
      <div className="text-3xl font-bold">Settings</div>
      <div className="rounded-lg p-6 bg-white mt-5">
        <div className="h-14 shadow-card-header-white">
          <span className="text-xl font-semibold py-2">Company details</span>
        </div>
        <CompanyDetails />
      </div>

      <div className="rounded-lg p-6 bg-white mt-5">
        <div className="h-14 shadow-card-header-white">
          <span className="text-xl font-semibold py-2">Payment account</span>
        </div>
        <PaymentDetails />
      </div>

      <div className="rounded-lg p-6 bg-white mt-5">
        <div className="h-14 shadow-card-header-white">
          <span className="text-xl font-semibold py-2">Change password</span>
        </div>
        <ChangePassword />
      </div>
    </>
  )
}

export default Settings
