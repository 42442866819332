import React from 'react'
import { useSelector } from 'react-redux'
import Footer from 'layouts/components/Footer'
import Header from 'layouts/components/Header'
import SidebarOnboarding from 'layouts/components/SidebarOnboarding'
import useMediaQuery from '../../hooks/useMediaQuery'
import SkeletonOnboardingLayout from './SkeletonOnboardingLayout'

const OnboardingLayout = ({
  screenName,
  shouldShowOnboardingSidebar,
  isLoading,
  steps,
  children,
  headerBackground = 'bg-white',
  ...props
}) => {
  // If header is expanded, disable scroll on body
  const { headerExpanded } = useSelector((state) => state.dataApp)
  const headerExpandedClass = headerExpanded ? 'h-screen' : ''
  const isTablet = useMediaQuery('(max-width: 1024px)')

  return (
    <div className={`text-dark-grey  ${headerExpandedClass}`}>
      <Header
        screenName={screenName}
        backgroundColor={headerBackground}
        shouldShowOnboardingSidebar={shouldShowOnboardingSidebar}
        {...props}
      />
      <div className="container mx-auto px-6 lg:px-2 xl:px-0">
        <div className="flex flex-col lg:flex-row items-start my-12">
          {shouldShowOnboardingSidebar && !isTablet && (
            <SidebarOnboarding
              steps={steps}
              shouldShowOnboardingSidebar={shouldShowOnboardingSidebar}
              {...props}
            />
          )}

          <div className="flex w-full lg:w-2/3 lg:ml-73px">
            <div className="flex-col w-full">
              {!isLoading ? children : <SkeletonOnboardingLayout />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default OnboardingLayout
