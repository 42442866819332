import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import bigCommerceApi from 'config/api/connections/bigCommerce'
import CommonModal from 'modals/components/CommonModal'
import { getErrorMessage } from 'utils/getErrorMessage'

const BigCommerceModal = ({ rutter, isOpened, close }) => {
  const accountsQuery = useQuery(
    'bigCommerceShops',
    () => bigCommerceApi.shops(),
    {
      onError: (err) => {
        toast.error(getErrorMessage(err))
      },
      enabled: false,
      retry: false
    }
  )

  useEffect(() => {
    if (isOpened) {
      accountsQuery.refetch()
    }
  }, [isOpened])

  const onConnect = () => {
    //close modal
    close()
    //open pop-up
    rutter.open({ platform: 'BIG_COMMERCE' })
  }

  return (
    <CommonModal
      title={'Connect your BigCommerce store'}
      isOpened={isOpened}
      close={close}
    >
      <div className="flex flex-col justify-center w-full">
        <div>
          <div className="w-full">
            <div className="flex flex-col w-full mb-6">
              <div className="flex flex-col w-full mb-3 pb-3 border-light-grey border-b">
                <p className="pb-3">Connected stores:</p>
                {accountsQuery.isLoading && (
                  <div className="flex flex-col self-center w-full">
                    <Spinner />
                  </div>
                )}
                {accountsQuery.data?.map((account) => (
                  <div key={account} className="flex">
                    {account}
                  </div>
                ))}
                {!accountsQuery.isLoading && !accountsQuery.data?.length && (
                  <div>No stores yet.</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full mb-10 pb-6 border-light-grey border-b text-center">
          <p className="text-sm md:text-base text-placeholder-grey">
            To connect your BigCommerce store URL, login to your BigCommerce
            admin. <br />
          </p>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex justify-between items-center mb-12">
            <Button
              variant={'button-next-pink'}
              className="ml-auto normal-case"
              onClick={onConnect}
            >
              Connect BigCommerce
            </Button>
          </div>
        </div>

        <div className="flex justify-center mb-10">
          <span className="text-sm text-placeholder-grey">
            Having trouble with this step? Please contact our support.
          </span>
        </div>
      </div>
    </CommonModal>
  )
}

export default BigCommerceModal
