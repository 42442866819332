import * as Yup from 'yup'
import { useFormik } from 'formik'
import React from 'react'
import Button from 'components/Button'
import FormikInputTextGrey from 'components/FormikInputTextGrey'
import FormikTextareaGrey from 'components/FormikTextareaGrey'
import CommonModal from 'modals/components/CommonModal'

const HelpModalFormValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Name is required')
    .max(50, 'Max 50 symbols')
    .required('Name is required'),
  subject: Yup.string()
    .trim()
    .min(2, 'Subject is required')
    .max(50, 'Max 50 symbols')
    .required('Subject is required'),
  details: Yup.string()
    .trim()
    .min(2, 'Details is required')
    .max(500, 'Max 500 symbols')
    .required('Details are required')
})
const HelpModalUnauthenticatedValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Name is required')
    .max(50, 'Max 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .trim()
    .email('Email must be valid.')
    .required(`Email is required`),
  subject: Yup.string()
    .trim()
    .min(2, 'Subject is required')
    .max(50, 'Max 50 symbols')
    .required('Subject is required'),
  details: Yup.string()
    .trim()
    .min(2, 'Details is required')
    .max(500, 'Max 500 symbols')
    .required('Details are required')
})

const HelpModal = ({
  isOpened,
  close,
  openHelpThankYouModal,
  submitEmail,
  isLoggedIn
}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      details: ''
    },
    validationSchema: isLoggedIn
      ? HelpModalFormValidation
      : HelpModalUnauthenticatedValidation,
    validateOnChange: false,
    onSubmit: (values, { resetForm }) => {
      const castValues = isLoggedIn
        ? HelpModalFormValidation.cast(values)
        : HelpModalUnauthenticatedValidation.cast(values)

      if (castValues) {
        submitEmail(castValues)
        resetForm()
        close()
        openHelpThankYouModal()
      }
    }
  })

  return (
    <CommonModal title={'How can we help?'} isOpened={isOpened} close={close}>
      <div>
        <div className="mb-6">
          <p className="text-xl">
            Create a support ticket and we will have someone look into it right
            away.
          </p>
        </div>

        <form onSubmit={formik.handleSubmit} className="flex flex-col w-full">
          <div className="flex flex-col">
            <FormikInputTextGrey
              shouldValid
              field="name"
              id="name"
              placeholder="Name"
              type="text"
              containerClassname={'mb-4'}
              {...formik}
              autoFocus
            />

            {!isLoggedIn && (
              <FormikInputTextGrey
                shouldValid
                field="email"
                id="email"
                placeholder="Email"
                type="email"
                containerClassname={'mb-4'}
                {...formik}
              />
            )}
            <div className="w-full">
              <FormikInputTextGrey
                shouldValid
                field="subject"
                id="subject"
                placeholder="Subject"
                type="text"
                containerClassname={'mb-4'}
                {...formik}
              />
            </div>

            <div className="w-full">
              <FormikTextareaGrey
                shouldValid
                field="details"
                id="details"
                placeholder="Write details..."
                containerClassname={'mb-4'}
                {...formik}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <Button
              type="submit"
              variant="button-next"
              disabled={!formik.dirty}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </CommonModal>
  )
}

export default HelpModal
