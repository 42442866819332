import React from 'react'
import configs from 'config/configs'
import formatCurrency from 'utils/formatCurrency'
import { formatNumberAsPercentageString } from 'utils/formatNumberUtil'
import { maybePluralize } from 'utils/textUtil'
import Button from '../Button'

const BestLabel = () => {
  return (
    <div className="absolute top-2.5 z-50 w-full flex justify-center">
      <div className="text-sm bg-purple-bloom text-white px-5 rounded-best font-bold">
        BEST
      </div>
    </div>
  )
}

const EcommerceOfferCard = ({
  offer,
  index,
  isActive,
  onClick,
  onNext,
  submitOfferForm,
  isAccepted,
  activeCard,
  isLoading
}) => {
  const handlePosition = (i, isActive) => {
    switch (i) {
      case 0:
        return `left-0 ${isActive ? '-top-43px z-40' : 'top-0 z-0'}`
      case 1:
        return `left-50% lg:-ml-165px ${isActive ? '-top-43px z-40' : 'top-0 z-30'}`
      case 2:
        return `right-0 ${isActive ? '-top-43px z-40' : 'top-0 z-20'}`
      default:
        break
    }
  }
  const handleHeaderBackground = (i, isActive) => {
    const isBestSelected = activeCard === 2 || activeCard === null
    switch (i) {
      case 0:
        return `${isActive ? 'bg-teal-color text-dark-grey' : 'bg-dark-grey text-white'}`
      case 1:
        return `${
          isActive
            ? 'bg-teal-color text-dark-grey'
            : isBestSelected
              ? 'bg-black text-white'
              : 'bg-dark-grey text-white'
        }`
      case 2:
        return `${isActive ? 'bg-teal-color text-dark-grey' : 'bg-black text-white'}`
      default:
        'bg-dark-grey text-white'
        break
    }
  }
  return (
    <div
      className={`lg:absolute ${handlePosition(
        index,
        isActive
      )} flex flex-col overflow-hidden w-full lg:w-330px cursor-pointer ${offer?.isBest && isActive && 'pt-5'}`}
      onClick={() => onClick(index)}
      onMouseOver={() => onClick(index)}
      //onClick is used on mobile
      style={{
        transition: 'top 0.2s ease-in-out 0s',
        filter: `${isActive || index === 1 ? 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2))' : ''}`
      }}
    >
      <div>
        {offer?.isBest && isActive && <BestLabel />}
        <div
          className={`${isActive ? 'h-240px' : 'h-155px'} ${(!offer?.isBest || (offer?.isBest && !isActive)) && 'mt-4'} lg:mt-0 rounded-lg border ${
            isActive ? 'border-teal-color' : 'border-dark-blue'
          }  transition-all ease-out duration-200 bg-white pb-5 overflow-hidden`}
        >
          <div
            className={`${handleHeaderBackground(
              index,
              isActive
            )} flex justify-center items-center h-60px text-4xl font-black relative`}
          >
            {formatCurrency(offer?.amount)}
          </div>
          <div className={`${isActive ? ' py-18px' : 'py-3'} px-5`}>
            <div className={'flex justify-between'}>
              <div className="opacity-80">Repayment</div>
              <div>{`${formatNumberAsPercentageString(offer?.holdbackRate)} of sales`}</div>
            </div>
            <div className={'flex justify-between'}>
              <div className="opacity-80">Period</div>
              <div>{maybePluralize(offer?.months, 'month')}</div>
            </div>
            <div className={`flex justify-between`}>
              <div className="opacity-80">Daily fee</div>
              <div>{formatCurrency(offer?.dailyFee, 2)} per day</div>
            </div>
            {/* uncomment if you wish to add again calculated total fee and total to repay */}
            {/* <div className={'flex justify-between'}>
              <div className="opacity-80">Calculated total fees</div>
              <div>{formatCurrency(offer?.totalFee?.totalFee)}</div>
            </div> */}
            {/* <div className={'flex justify-between font-black'}>
              <div className="opacity-80">Total to repay</div>
              <div>{formatCurrency(offer?.totalRepayment)}</div>
            </div> */}
          </div>
          <div className={`flex justify-center`}>
            <Button
              type="button"
              loading={isLoading}
              variant="button-next"
              className="font-medium"
              affixIcon={configs.icons.arrowForward}
              children={isAccepted ? 'Next' : 'Accept'}
              onClick={isAccepted ? onNext : submitOfferForm}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EcommerceOfferCard
