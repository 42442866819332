import { always } from 'ramda'
import React from 'react'
import Card from 'components/Card'
import ErrorMessage from 'components/ErrorMessage'
import RecurringTable from 'components/RecurringTable'
import RegularTable from 'components/RegularTable'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'
import SubNav from 'layouts/components/SubNav'
import formatCurrency from 'utils/formatCurrency'
import useInflowsHook from './hook'

const AccountsAndBalancesInflows = () => {
  const {
    totalInflowsMonthly,
    recurringInflows,
    regularInflows,
    currentTab,
    handleOnTabClick,
    error,
    isLoading,
    loadMoreRecurring,
    loadMoreRegular
  } = useInflowsHook()
  let regularClass = ''
  let recurringClass = ''
  if (currentTab === 'Recurring') {
    recurringClass =
      'rounded-lg bg-grey-blue-bloom text-indingo-bloom font-semibold'
    regularClass = 'text-text-color-grey-bloom font-normal'
  } else {
    regularClass =
      'rounded-lg bg-grey-blue-bloom text-indingo-bloom font-semibold'
    recurringClass = 'text-text-color-grey-bloom font-normal'
  }

  const TabAction = always(
    <div className="flex flex-row space-x-2">
      <button
        onClick={() => handleOnTabClick('Regular')}
        className={`py-2 px-4 ${regularClass}`}
      >
        Regular
      </button>
      <button
        onClick={() => handleOnTabClick('Recurring')}
        className={`py-2 px-4 ${recurringClass}`}
      >
        Recurring
      </button>
    </div>
  )

  if (isLoading) {
    return <AppLayoutSkeleton />
  }

  return (
    <>
      <SubNav />
      {Boolean(error) ? (
        <Card headerClassname="text-b-dark font-medium">
          <ErrorMessage error={error}></ErrorMessage>
        </Card>
      ) : (
        <div className="bg-body-grey space-y-4 mb-12 text-base">
          <div className="flex flex-col bg-white rounded-lg p-6">
            <span className="font-normal text-sm leading-24px text-text-color-grey-bloom">
              Total inflows predicted monthly
            </span>
            <span className="font-bold text-2xl text-green-bloom">
              {formatCurrency(totalInflowsMonthly, 2)}
            </span>
          </div>

          <Card
            headerTitle={`${currentTab} inflows`}
            headerClassname="mb-2 mt-2 font-semibold"
            headerActionComponent={TabAction}
            children={
              <React.Fragment>
                {currentTab === 'Recurring' ? (
                  <RecurringTable
                    dataGrouped={recurringInflows.data.flows}
                    loadMore={loadMoreRecurring}
                    hasMore={recurringInflows.hasMore}
                  />
                ) : (
                  <RegularTable
                    data={regularInflows.data.flows}
                    loadMore={loadMoreRegular}
                    hasMore={regularInflows.hasMore}
                  />
                )}
              </React.Fragment>
            }
          />
        </div>
      )}
    </>
  )
}

export default AccountsAndBalancesInflows
