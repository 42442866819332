import React from 'react'

const RegistrationStepper = ({ steps }) => {
  return (
    <div className="flex justify-center my-8 gap-4">
      {steps.map((step, i) => (
        <div
          key={i}
          className={`${step ? 'bg-teal-color' : 'bg-dark-blue opacity-10'} h-1 w-1/3 rounded`}
        ></div>
      ))}
    </div>
  )
}

export default RegistrationStepper
