import React from 'react'

const InfoCard = ({
  image,
  description,
  imageClassName,
  descriptionClassName
}) => {
  return (
    <div className={`flex overflow-hidden my-2.5 rounded-lg bg-dark-blue/5`}>
      <img className={`mr-1 ${imageClassName}`} src={image} />
      <div
        className={`leading-5 text-dark-grey mr-14px text-sm ${descriptionClassName}`}
      >
        {description}
      </div>
    </div>
  )
}

export default InfoCard
