const customReactSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F7F7F7' : 'transparent',
    color: '#000'
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
    backgroundColor: 'white',
    borderWidth: '0.5px',
    borderColor: '#A9A7B2',
    borderRadius: '8px',
    transition: 'all .15s',
    overview: 'hidden',

    '&:hover': {
      cursor: 'pointer'
    },

    '&:focus': {
      borderWidth: '2px',
      borderColor: '#374151'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px'
  }),
  control: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    width: '100%',
    padding: 0,
    paddingLeft: '16px',
    paddingRight: '8px',
    outlineColor: 'transparent',
    outline: 'none',
    fontSize: '16px',
    transition: 'all 0.15s'
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: '0',
    fontSize: '16px',
    color: '#939296'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#374151'
  })
}

export default customReactSelectStyles
