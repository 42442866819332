import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import configs from 'config/configs'
import useOnClickOutside from '../../../hooks/useClickOutsideRef'

const ModalPortal = ({ children }) => {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector('#react-app-root')
    setMounted(true)
  }, [])

  return mounted ? createPortal(children, ref.current) : null
}

const CommonModal = ({
  title,
  isOpened,
  close,
  children,
  noHeader,
  customStyle,
  customAddAccountModal,
  noFooter
}) => {
  const ref = useRef()

  useOnClickOutside(ref, close)

  return (
    <>
      {isOpened && (
        <ModalPortal>
          <div
            className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center backdrop-blur-sm bg-dark-blue bg-opacity-75 z-50 overflow-auto`}
          >
            <div
              ref={ref}
              className={`flex flex-col w-full mx-4 md:w-1/2 lg:max-w-464px p-8 bg-white rounded-md max-h-90screen overflow-auto ${customStyle}`}
            >
              {noHeader ?? (
                <div
                  className={cn('flex w-full text-xl font-black', {
                    'border-b pb-6': !customAddAccountModal,
                    'justify-between mb-6 border-light-grey-200': title,
                    'justify-end': !title
                  })}
                >
                  {title && <p className="font-bold text-xl">{title}</p>}

                  <button className="focus:outline-none" onClick={close}>
                    <img src={configs.icons.closeDarkGrey} className="h-3" />
                  </button>
                </div>
              )}

              <div
                className={cn({
                  'overflow-auto -mx-5': customAddAccountModal,
                  'mb-12': !noFooter
                })}
              >
                {children}
              </div>
            </div>
          </div>
        </ModalPortal>
      )}
    </>
  )
}

export default CommonModal
