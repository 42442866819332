import * as Yup from 'yup'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import ConnectionSuccessful from 'components/ConnectionSuccessful'
import FormikInputTextGrey from 'components/FormikInputTextGrey'
import api from 'config/api/onboarding'
import configs from 'config/configs'
import { websiteRegex } from 'config/configs/constant'
import CommonModal from 'modals/components/CommonModal'
import { getErrorMessage } from 'utils/getErrorMessage'

const PrestaShopModal = ({ image, isOpened, close }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const submitPrestashopDetails = useMutation(
    'submitRutterIntegrationData',
    api.submitRutterIntegrationData,
    {
      onSuccess: () => {
        setShowSuccess(true)
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
        close()
        setShowSuccess(false)
      }
    }
  )

  const formik = useFormik({
    initialValues: {
      store_url: '',
      api_key: ''
    },
    validationSchema: Yup.object().shape({
      store_url: Yup.string()
        .matches(websiteRegex, 'Please enter a valid store URL.')
        .required('Store name is required.'),
      api_key: Yup.string().required('PrestaShop Acess Key is required.')
    }),
    onSubmit: (values, { resetForm }) => {
      submitPrestashopDetails.mutate({
        platform: 'PRESTASHOP',
        connection_parameters: values
      })
      resetForm()
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  const onCloseSuccessModal = () => {
    setShowSuccess(false)
    close()
  }

  return (
    <CommonModal
      title={'Connect your PrestaShop store'}
      isOpened={isOpened}
      close={close}
    >
      {showSuccess ? (
        <ConnectionSuccessful
          integration="prestashop"
          onClick={onCloseSuccessModal}
        />
      ) : (
        <>
          <div className="flex flex-col justify-center w-full border-light-grey-200 border-b mb-4">
            <div className="flex justify-center items-center">
              <img src={image} alt="prestashop-logo" className="w-auto h-20" />
            </div>

            <div className="flex flex-col w-full text-left gap-y-2 mb-2">
              <p className="text-sm md:text-base text-black font-semibold">
                Full Domain
              </p>
              <p className="text-sm md:text-base text-placeholder-grey">
                Please provide the full URL domain to your website's API (e.g.
                https://myshop.com/api). Make sure to include the /api ending.
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left">
                  <FormikInputTextGrey
                    field="store_url"
                    id="store_url"
                    placeholder="https://example.com/api"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                    autoFocus
                  />
                </div>
              </form>
            </div>

            <div className="flex flex-col w-full text-left gap-y-2">
              <p className="text-sm md:text-base text-black font-semibold">
                Auth Key / Access Key
              </p>
              <p className="text-sm md:text-base text-placeholder-grey">
                Please provide an Auth Key from the PrestaShop admin portal.
                Click
                <a
                  className="text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://devdocs.prestashop.com/1.7/webservice/tutorials/creating-access/"
                >
                  {' here '}
                </a>
                to find instructions on how to make an Auth Key. We need at
                least the permissions to{' '}
                <span className="font-bold text-black"> read and write</span>:
                shops, products, orders, order_payments, customers, tags,
                stock_availables, combinations, product_options,
                product_option_values, carries, order_states, order_carriers,
                order_details, order_slip, addresses, states, countries,
                configurations, currencies, languages.
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left mb-2">
                  <FormikInputTextGrey
                    field="api_key"
                    id="api_key"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                  />
                </div>
              </form>
            </div>

            <div className="flex justify-between items-center mb-4">
              <Button
                type="submit"
                variant={'button-next-pink'}
                className="ml-auto normal-case"
                disabled={!(formik.isValid && formik.dirty)}
                loading={submitPrestashopDetails.isLoading}
                onClick={onSubmit}
              >
                Connect PrestaShop
              </Button>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full pt-2">
            <div className="flex justify-center gap-x-2 items-center">
              <img src={configs.icons.lockClosed} />
              <span className="text-sm text-placeholder-grey">
                Secured with 256-bit encryption
              </span>
            </div>
          </div>
        </>
      )}
    </CommonModal>
  )
}

export default PrestaShopModal
