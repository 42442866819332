import React, { useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'

export const LaunchLink = ({ token, onSuccess, onExit, onEvent }) => {
  const { open, ready } = usePlaidLink({
    onSuccess,
    onExit,
    onEvent,
    token
  })

  useEffect(() => {
    ready && open()
  }, [ready, open])

  return <></>
}
