const mergeRecurringFlows = (existingFlows, newFlows) => {
  if (existingFlows.length === 0) return newFlows
  if (newFlows.length === 0) return existingFlows

  const result = []
  existingFlows.forEach((flow) => {
    const values = Object.values(flow)
    newFlows.forEach((newFlow) => {
      const newFlowValues = Object.values(newFlow)
      if (
        newFlowValues.length > 0 &&
        values.length > 0 &&
        newFlowValues[0].description === values[0].description
      ) {
        result.push([...values, ...newFlowValues])
      }
    })
  })

  return result
}

export { mergeRecurringFlows }
