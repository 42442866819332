import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import api from 'config/api/auth'
import { getErrorMessage } from 'utils/getErrorMessage'

const useEmailVerification = ({ email, confirmationSentAt = '' }) => {
  const [timerWaiting, setTimerWaiting] = useState(0)
  const [timerShowButton, setTimerShowButton] = useState(0)
  const [shouldResend, setShouldResend] = useState(true)
  const [showResend, setShowResend] = useState(false)
  const [waitingTimeRemainingSec, setWaitingTimeRemainingSec] = useState(120)

  const resendEmail = useMutation('resendEmail', api.resendVerificationEmail, {
    onSuccess: () => {
      const timerId = setInterval(() => {
        if (waitingTimeRemainingSec > 0) {
          setWaitingTimeRemainingSec(
            (waitingTimeRemainingSec) => waitingTimeRemainingSec - 1
          )
        }
      }, 1000)

      setShouldResend(false)
      setTimerWaiting(timerId)
    },
    onError: (err) => {
      const errorMsg = getErrorMessage(err)
      toast.error(errorMsg)
    }
  })

  useEffect(() => {
    if (waitingTimeRemainingSec === 0) {
      clearTimeout(timerWaiting)
      setShouldResend(true)
      setWaitingTimeRemainingSec(120)
    }
  }, [waitingTimeRemainingSec])

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const confirmationDate = new Date(confirmationSentAt)
      confirmationDate.setMinutes(confirmationDate.getMinutes() + 2)

      if (now > confirmationDate) {
        setShowResend(true)
      }
    }, 1000)

    setTimerShowButton(timer)
  }, [])

  useEffect(() => {
    if (showResend) {
      clearTimeout(timerShowButton)
    }
  }, [showResend])

  const onClick = () => {
    resendEmail.mutate(email)
  }

  return {
    onClick,
    showResend,
    shouldResend,
    waitingTimeRemainingSec
  }
}

export { useEmailVerification }
