import axios from '../../services/axios'

const getPartnerData = async (slug) => {
  const response = await axios.get(`/api/partners/${slug}.json`)

  return response.data
}

export default {
  getPartnerData
}
