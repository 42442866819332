import React from 'react'
import formatCurrency from 'utils/formatCurrency'

const OutflowsRegularTable = ({ data, loadMore, hasMore }) => {
  return (
    <div className="mt-6">
      <table className="w-full text-sm leading-24px text-text-color-bloom">
        <thead>
          <tr className="shadow-card-header-white text-header-table-gray">
            <td className="pb-4 pr-6">Date</td>
            <td className="pb-4 pr-6">Description</td>
            <td className="pb-4 pr-12 text-right">Amount</td>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((row, index) => (
              <tr key={index} className={`shadow-card-header-white`}>
                <td className="py-4 pr-6">
                  {new Date(row.date).toLocaleDateString('en-UK')}
                </td>
                <td className="py-4 pr-6">{row.description}</td>
                <td className="py-4 pr-12 text-right">
                  {formatCurrency(row.amount, 2)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {hasMore && (
        <div className="flex items-center justify-center mt-6 text-header-table-gray font-medium text-base leading-48px">
          <button onClick={loadMore}>Load more</button>
        </div>
      )}
    </div>
  )
}

export default OutflowsRegularTable
