import axios from '../../../services/axios'
import './typedef'

/**
 * Retrieves connections
 * @returns {Promise<Connection[]>} - array of possible connections
 */
const getConnections = async () => {
  const response = await axios.get(`/inlr/xero/connections`)
  return response.data
}

/**
 * Returns existed Writeback config
 * @returns {Promise<WritebackItem>} - existed writeback config
 */
const getWriteback = async () => {
  const response = await axios.get(`/inlr/xero/writeback`)

  return response.data
}

/**
 * Request tenant specific config for writeback configuration (Step 2 on modal)
 * @param {string} tenantId
 * @returns {Promise<ConnectionSettings>}
 */
const getConnection = async (tenantId) => {
  if (!tenantId) return
  const response = await axios.get(
    `/inlr/xero/connections/${tenantId}/accounts`
  )

  return response.data
}

/**
 * Writeback config form submit
 * @param {WritebackItem} data
 * @returns
 */
const postWriteback = async (data) => {
  const response = await axios.post(`/inlr/xero/writeback`, data)

  return response.data
}
const deleteConnection = async (connectionId) => {
  if (!connectionId) return
  const response = await axios.delete(`/inlr/xero/connections/${connectionId}`)

  return response.data
}

export default {
  getConnections,
  getWriteback,
  getConnection,
  postWriteback,
  deleteConnection
}
