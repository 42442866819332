import React, { useMemo } from 'react'

const PasswordStrengthBar = ({ passwordBar }) => {
  const passwordStrengthText = useMemo(
    () =>
      passwordBar.score === 0 || passwordBar.score === 1
        ? 'Low'
        : passwordBar.score === 2 || passwordBar.score === 3
          ? 'Medium'
          : 'High',
    [passwordBar.score]
  )

  return (
    <>
      {passwordBar.items && (
        <div className={`flex items-center w-full space-x-1`}>
          {passwordBar.items.map((it, i) => (
            <div key={i} className={`flex w-1/4 h-1 ${it[i + 1]}`}></div>
          ))}
        </div>
      )}

      <span className="inline-block pt-1 text-right text-placeholder-grey text-sm">
        {passwordStrengthText} Password Strength
      </span>

      {passwordBar.message && (
        <p className={`inline-block mt-3 mb-4 w-full text-right`}>
          {passwordBar.message}
        </p>
      )}
    </>
  )
}

export default PasswordStrengthBar
