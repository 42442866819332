import dayjs from 'dayjs'
import React from 'react'
import { Tooltip } from 'react-tooltip'
import { maybePluralize } from 'utils/textUtil'
import useCountdown from './hook'

const ShowCounter = ({ days, hours, minutes, seconds, target }) => {
  const { twoDigitTimeFormat } = useCountdown()
  return (
    <>
      <div
        id="offer-element"
        className="flex justify-center mb-4"
        data-tooltip="test"
      >
        Offer expires in
        <span className="font-bold ml-1">
          {days
            ? maybePluralize(days, 'day')
            : `${twoDigitTimeFormat(hours)}:${twoDigitTimeFormat(minutes)}:${twoDigitTimeFormat(seconds)}`}
        </span>
        <Tooltip
          anchorId="offer-element"
          content={`offer expires at: ${dayjs(target).format('DD MMM YYYY HH:mm')}`}
        />
      </div>
    </>
  )
}

export default ShowCounter
