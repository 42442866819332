import FormikPasswordInput from 'components/FormikPasswordInput'
import Button from '../Button'
import FormikInputTextGrey from '../FormikInputTextGrey'
import PasswordStrengthBar from '../PasswordStrengthBar'
import useChangePasswordHook from './hook'

const ChangePassword = () => {
  const {
    formik,
    shouldShowError,
    passwordBar,
    handleChange,
    handleSubmit,
    loading
  } = useChangePasswordHook()

  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col mb-8">
        <FormikInputTextGrey
          field="currentPassword"
          id="currentPassword"
          placeholder="Current password"
          type="password"
          containerClassname={'mb-3'}
          shouldValid={shouldShowError}
          {...formik}
          handleChange={handleChange}
        />
        <div className="relative flex flex-col w-full">
          <FormikPasswordInput
            field="password"
            id="password"
            placeholder="New password"
            inputClassname="w-full"
            containerClassname="mb-3"
            shouldValid={shouldShowError}
            type="password"
            {...formik}
            handleChange={handleChange}
          />
          <PasswordStrengthBar passwordBar={passwordBar} />
        </div>
        <div className="w-full relative">
          <FormikInputTextGrey
            field="passwordConfirmation"
            id="passwordConfirmation"
            placeholder="Confirm new password"
            inputClassname="w-full"
            shouldValid={shouldShowError}
            type="password"
            {...formik}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="flex flex-col items-end">
        <Button type="submit" variant="button-next" loading={loading}>
          Submit
        </Button>
      </div>
    </form>
  )
}

export default ChangePassword
