import moment from 'moment'
import { validateDataPresence } from '../utils/validateDataPresenceUtil'

const DateFormats = {
  ShortYears: 'DD-MM-YY',
  MonthNames: 'DD MMM YYYY'
}

const formatIsoToDate = (date, format = DateFormats.MonthNames) => {
  if (!date) return validateDataPresence(date)

  return moment(date).format(format)
}

const toMonthName = (monthNumber) => {
  const date = new Date()
  date.setMonth(monthNumber - 1)

  return date.toLocaleString('en-US', {
    month: 'short'
  })
}

export { formatIsoToDate, toMonthName, DateFormats }
