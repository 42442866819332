import React from 'react'
import { formatValueAndSymbol } from 'utils/formatValueAndSymbol'
import PerformanceGradient from './PerformaceGradient'

const BenchmarkCategory = ({ titleName, description, data }) => {
  return (
    <div className="px-4 md:px-6 pt-6 pb-4 space-y-6 bg-white rounded-lg">
      <div
        className={`${description && 'shadow-card-header-white'} md:flex md:flex-row justify-between pb-4`}
      >
        <span className="font-bold text-base">{titleName}</span>
        <div className="flex flex-row space-x-3 md:space-x-6 font-normal text-base mt-2 md:mt-0">
          <div className="flex flex-row space-x-2">
            <div className="bg-faded-strawberry-bloom h-4 w-4 my-1 rounded"></div>
            <span>Bottom 25%</span>
          </div>
          <div className="flex flex-row space-x-2">
            <div className="bg-orange-bloom h-4 w-4 my-1 rounded"></div>
            <span>Average</span>
          </div>
          <div className="flex flex-row space-x-2">
            <div className="bg-green-bloom h-4 w-4 my-1 rounded"></div>
            <span>Top 25%</span>
          </div>
        </div>
      </div>

      <div className="font-medium text-base text-text-color-grey-bloom">
        {description}
      </div>

      <div className="w-full font-medium text-base overflow-x-auto">
        <table className="min-w-full w-max">
          <thead>
            <tr className="shadow-row-table text-header-table-gray">
              <td className="py-4 pr-6" style={{ width: '20%' }}>
                Performance
              </td>
              <td className="py-4 pr-6" style={{ width: '32%' }}>
                Metric
              </td>
              <td className="py-4" style={{ width: '12%' }}>
                You
              </td>
              <td className="py-4" style={{ width: '12%' }}>
                Top 25%
              </td>
              <td className="py-4" style={{ width: '12%' }}>
                Average
              </td>
              <td className="py-4" style={{ width: '12%' }}>
                Bottom 25%
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              const isSignDirectionPositive = row.direction === 1
              const userNum = Number(row.customerValue)
              const bottomNum = Number(row.bottom25)
              const topNum = Number(row.top25)

              let performance
              if (isSignDirectionPositive) {
                const isAverage = userNum > bottomNum && userNum < topNum
                const isBottom = Number(userNum) < Number(bottomNum)
                const isTop = userNum > topNum

                if (isAverage) {
                  performance = 50
                } else if (isBottom) {
                  performance = 20
                } else if (isTop) {
                  performance = 80
                }
              } else {
                const isAverage = userNum < bottomNum && userNum > topNum
                const isBottom = userNum > bottomNum
                const isTop = userNum < topNum

                if (isAverage) {
                  performance = 50
                } else if (isBottom) {
                  performance = 20
                } else if (isTop) {
                  performance = 80
                }
              }

              const colorClass =
                performance <= 25
                  ? 'faded-strawberry-bloom'
                  : performance >= 75
                    ? 'green-bloom'
                    : 'orange-bloom'

              return (
                <tr key={index} className="shadow-row-table space-x-6 py-4">
                  <td className="py-4 pr-6" style={{ width: '20%' }}>
                    <PerformanceGradient
                      percentage={performance}
                      colorClass={colorClass}
                    />
                  </td>
                  <td className="py-4 pr-6" style={{ width: '32%' }}>
                    {row.field}
                  </td>
                  <td
                    className={`py-4  text-${colorClass}`}
                    style={{ width: '12%' }}
                  >
                    {formatValueAndSymbol(userNum ?? 0, row.symbol)}
                  </td>
                  <td className="py-4" style={{ width: '12%' }}>{`${
                    isSignDirectionPositive ? '>' : '<'
                  } ${formatValueAndSymbol(topNum ?? 0, row.symbol)}`}</td>
                  <td className="py-4" style={{ width: '12%' }}>
                    {formatValueAndSymbol(row.averagevalue ?? 0, row.symbol)}
                  </td>
                  <td className="py-4" style={{ width: '12%' }}>{`${
                    isSignDirectionPositive ? '<' : '>'
                  } ${formatValueAndSymbol(bottomNum ?? 0, row.symbol)}`}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BenchmarkCategory
