import React from 'react'
import configs from 'config/configs'
import AuthorisationLayout from '../../../../layouts/AuthorisationLayout'
import GoBackButton from './GoBackButton'

const OnboardingSuccess = () => {
  return (
    <AuthorisationLayout hasFooter={false}>
      <div className="flex justify-center mx-auto bg-light-grey-100 text-dark-grey font-lato min-h-content-fit-desktop">
        <div className="flex flex-col justify-center md:flex-row gap-y-4 py-8 mx-8">
          <div className="flex justify-center items-center w-full mr-8 xl:max-w-5xl">
            <img
              src={configs.images.onboardingSuccess}
              className={`max-w-xs lg:max-w-xl`}
            />
          </div>

          <div className="flex flex-col justify-center items-start text-center md:text-left md:mt-0 w-full gap-y-6">
            <div className="font-lato text-dark-grey font-black text-5xl xl:max-w-75%">
              Thank you for your application.
            </div>
            <div className="font-lato text-dark-grey font-normal text-xl">
              <span>
                We will review your application within 24 hours, and a Bloom
                representative will be in touch.{' '}
                <a
                  href="https://hello.letsbloom.com/c/sam/t/1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-facebook"
                >
                  Book your discovery call.
                </a>
              </span>
            </div>
            <GoBackButton />
          </div>
        </div>
      </div>
    </AuthorisationLayout>
  )
}

export default OnboardingSuccess
