import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useRutterLink } from 'react-rutter-link'
import { toast } from 'react-toastify'
import onboardingApi from 'config/api/onboarding'
import {
  INTEGRATION_CONNECTED,
  INTEGRATION_CRITICAL,
  INTEGRATION_INACTIVE,
  INTEGRATION_WARNING
} from 'enums/Integrations'
import { setIntegrations } from 'redux/actions/Onboarding'
import { getErrorMessage } from 'utils/getErrorMessage'

const useIntegrationsHook = (type) => {
  const [publicKey, setPublicKey] = useState('')
  const dispatch = useDispatch()
  const { integrations } = useSelector((state) => state.dataOnboarding)
  const { companyBusinessType } = useSelector(
    (state) => state.persistedDataRegister
  )

  const getPublicKeyRutter = useQuery(
    'getPublicKeyRutter',
    onboardingApi.getPublicKeyRutter,
    {
      onSuccess: (res) => {
        setPublicKey(res.key)
      },
      retry: false,
      enabled: false
    }
  )

  const integrationsQuery = useQuery(
    ['integrations', type],
    () => onboardingApi.getIntegrations(type),
    {
      onSuccess: (res) => {
        dispatch(setIntegrations(res))
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
      },
      cacheTime: 0
    }
  )

  useEffect(() => {
    getPublicKeyRutter.refetch()
  }, [])

  const integrationsRutter = useMutation(onboardingApi.integrationsRutter)

  const onSuccess = (publicToken) => {
    integrationsRutter.mutate({
      user_token: publicToken
    })
  }

  const rutterConfig = {
    token: publicKey,
    publicKey,
    onSuccess
  }

  const rutter = useRutterLink(rutterConfig)

  const extraAccountList = () => {
    return type === 'sales accounts'
      ? integrations?.filter(
          (account) =>
            !account?.show_for?.includes(companyBusinessType) &&
            !account?.connected &&
            account?.status === INTEGRATION_INACTIVE
        )
      : integrations
  }

  const showIntegration = (account) => {
    return type === 'sales accounts'
      ? account?.show_for?.includes(companyBusinessType) ||
          account?.connected ||
          [
            INTEGRATION_CONNECTED,
            INTEGRATION_WARNING,
            INTEGRATION_CRITICAL
          ].includes(account?.status)
      : true
  }

  return {
    rutter,
    accountList: integrations,
    extraAccountList: extraAccountList(),
    integrationsError: integrationsQuery.error,
    isLoading: integrationsRutter.isLoading || integrationsQuery.isLoading,
    showIntegration
  }
}

export default useIntegrationsHook
