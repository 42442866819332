import axios from '../../services/axios'

const getCompanyDirectors = async () => {
  const response = await axios.get('/api/company-information/company_directors')
  return response.data
}

const updateSignatoryDesignation = async (data) => {
  const response = await axios.put(
    `/api/company-information/company_directors/${data['selected_director']}`,
    data
  )
  return response.data
}

const updateCompanyDirectors = async (data) => {
  const response = await axios.post(
    '/api/company-information/company_directors',
    data
  )
  return response.data
}

export default {
  getCompanyDirectors,
  updateSignatoryDesignation,
  updateCompanyDirectors
}
