import * as Yup from 'yup'

const forgotPasswordSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Email must be valid.')
      .required('Email is required')
  })
}

export default forgotPasswordSchema
