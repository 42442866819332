import { keys } from 'ramda'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import configs from 'config/configs'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import formatCurrency from 'utils/formatCurrency'
import { formatNumberAsPercentageString } from 'utils/formatNumberUtil'
import Button from '../Button'
import ButtonGroup from '../ButtonGroup'
import Card from '../Card'
import useCountdown from '../CountdownTimer/hook'
import ErrorMessage from '../ErrorMessage'
import EcommerceOfferCard from './EcommerceOfferCard'
import SkeletonAvailableFunds from './SkeletonAvailableFunds'
import useAvailableFundsHook from './hook'

const EcommerceAvailableFunds = ({ onNext, target }) => {
  const {
    availableFundsData,
    submitOfferForm,
    offerData,
    setOfferData,
    isAccepted,
    error,
    isLoading,
    trackOfferAdjusted
  } = useAvailableFundsHook({ onNext })

  const [viewPage, setViewPage] = useState('offerCards')
  const [activeCard, setActiveCard] = useState(null)
  const [activeOffer, setActiveOffer] = useState(null)
  const [lastAmount, setLastAmount] = useState()
  const [lastMonths, setLastMonths] = useState()

  const { expired } = useCountdown(target)
  const user = useSelector((state) => state.dataUser)

  useEffect(() => {
    //set best offer as initial offer
    const bestOffer = availableFundsData?.defaults?.find(
      (offer) => offer.isBest
    )
    setActiveOffer(bestOffer)
  }, [availableFundsData])

  useEffect(() => {
    target && expired && setViewPage('offerCards')
  }, [expired, target])

  const repayOptions = offerData?.repayMonthOptions?.slice(-3)
  const threeRepayOptions = repayOptions?.length === 3

  const saveLastValues = () => {
    setLastAmount(offerData.currentAmount)
    setLastMonths(offerData.currentMonth)
  }

  const handleAmountChange = (value) => {
    let data = {}

    if (availableFundsData?.amountDetails[value]) {
      data.currentAmount = value
      data.currentPricing = availableFundsData.amountDetails[value]
      data.repayMonthOptions = keys(data?.currentPricing?.months)
      data.currentMonth = parseInt(keys(data?.currentPricing?.months)[0])

      setOfferData({ ...offerData, ...data })
    }
  }

  const handleMonthChange = (value) => {
    setOfferData({ ...offerData, currentMonth: value })
  }

  const handleTrackEvent = () => {
    if (
      offerData.currentAmount !== lastAmount ||
      offerData.currentMonth !== lastMonths
    ) {
      trackOfferAdjusted(offerData.currentAmount, offerData.currentMonth)
    }
  }

  const handleBackButton = () => {
    setViewPage('offerCards')
    let data = {}
    data.currentAmount = activeOffer?.amount
    data.repayMonthOptions = keys(offerData?.currentPricing?.months)
    data.currentPricing =
      availableFundsData?.amountDetails?.[activeOffer?.amount]
    data.currentMonth = activeOffer?.months
    //set last active offer as current offer
    setOfferData({ ...offerData, ...data })
  }

  const handleOfferCardClick = (i, offer) => {
    setActiveCard(i)
    setActiveOffer(offer)
    let data = {}

    data.currentAmount = offer?.amount
    data.repayMonthOptions = keys(offerData?.currentPricing?.months)
    data.currentPricing = availableFundsData?.amountDetails?.[offer?.amount]
    data.currentMonth = offer?.months
    //set active offer as current offer
    setOfferData({ ...offerData, ...data })
  }
  if (isLoading || !availableFundsData || !offerData) {
    return <SkeletonAvailableFunds />
  }

  if (error) {
    return (
      <div className="flex flex-col justify-between container pt-6 mb-16">
        <Card headerClassname="text-b-dark font-medium">
          <ErrorMessage error={error}></ErrorMessage>
        </Card>
      </div>
    )
  }
  const renderCustomizeOffer = () => {
    return (
      <>
        <div className="flex flex-col items-center">
          <div className="flex text-xl mb-2 lg:text-40px lg:leading-56px font-black">
            <img src={configs.images.partyPopper} className={'mr-2'} />
            {target && !!expired
              ? `${capitalizeFirstLetter(user?.firstName)}, your offer expired! `
              : `You have been approved for ${formatCurrency(
                  availableFundsData?.maxAmount
                    ? availableFundsData.maxAmount
                    : '0'
                )}`}
          </div>
        </div>
        {target && !!expired ? (
          <></>
        ) : (
          //   Removed as per https://bloomfin.atlassian.net/browse/EN-60
          // <div className="flex justify-center mb-2 text-xl leading-26px">
          //   Call us on
          //   <a href={`tel:${getPhoneNumber().href}`} className="hover:opacity-80 transition-opacity font-semibold ml-1">
          //     {getPhoneNumber().text}
          //   </a>
          // </div>
          <>
            <div className="mb-8 text-xl leading-26px">
              Great news! To gain access to these funds, simply accept the offer
              below. As simple and easy as that!
            </div>
          </>
        )}

        {offerData ? (
          <>
            <div
              className={`flex flex-col rounded-lg overflow-hidden mb-2 ${
                target && !!expired && 'pointer-events-none'
              }`}
              style={
                target && !!expired
                  ? { filter: 'grayscale(1)' }
                  : { filter: 'grayscale(0)' }
              }
            >
              <div className="flex flex-col lg:flex-row bg-dark-grey text-white py-22px pl-8 pr-6">
                <div className="flex flex-1 flex-col">
                  <div className="flex items-center leading-6">
                    I WANT TO WITHDRAW
                    <span className="text-32px leading-10 font-black ml-2.5 ">
                      {formatCurrency(
                        offerData.currentAmount &&
                          parseInt(offerData.currentAmount)
                      )}
                    </span>
                  </div>
                  <div className="my-3 lg:w-11/12">
                    {offerData.pricingSteps && offerData.currentAmount ? (
                      <Slider
                        style={{
                          background: isAccepted && '#374151',
                          opacity: isAccepted && '0.8'
                        }}
                        railStyle={{ backgroundColor: '#5D5E6E', height: 4 }}
                        trackStyle={{
                          backgroundColor: isAccepted ? '#FAFAFA' : '#00FFC2',
                          height: 4
                        }}
                        disabled={isAccepted}
                        handleStyle={{
                          width: '24px',
                          height: '24px',
                          top: 0,
                          background: isAccepted ? '#FAFAFA' : '#44D7B6',
                          border: '2px solid #374151',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)'
                        }}
                        value={offerData.currentAmount}
                        min={parseInt(offerData.pricingSteps[0])}
                        max={parseInt(
                          offerData.pricingSteps[
                            offerData.pricingSteps.length - 1
                          ]
                        )}
                        step={
                          offerData?.pricingSteps?.length > 1
                            ? parseInt(
                                offerData.pricingSteps[1] -
                                  offerData.pricingSteps[0]
                              )
                            : 0
                        }
                        dotStyle={{ display: 'none' }}
                        onBeforeChange={saveLastValues}
                        onChange={(e) => handleAmountChange(e)}
                        onAfterChange={(e) => handleTrackEvent(e)}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  className={`flex flex-1 justify-start lg:${
                    threeRepayOptions ? 'justify-between' : 'justify-end'
                  } items-end`}
                >
                  <div
                    className={`mr-4 ${!threeRepayOptions && 'pr-5'} mb-2 lg:mr-0`}
                  >
                    REPAY OVER
                  </div>
                  <ButtonGroup
                    value={offerData.currentMonth}
                    options={repayOptions}
                    onChange={(e) => !isAccepted && handleMonthChange(e)}
                    disabled={isAccepted}
                  />
                </div>
              </div>
              <div className="bg-white px-8 pb-6 pt-14px">
                {!isAccepted && (
                  <div className="font-bold mb-1.5">YOUR OFFER SUMMARY</div>
                )}
                {offerData &&
                  offerData.currentPricing &&
                  offerData.currentMonth && (
                    <>
                      <div className={'flex justify-between pb-3px'}>
                        <div>Finance amount</div>
                        <div>{formatCurrency(offerData.currentAmount)}</div>
                      </div>
                      <div className={'flex justify-between pb-3px'}>
                        <div>Repayment period</div>
                        <div>{offerData.currentMonth} months</div>
                      </div>
                      <div className={'flex justify-between pb-3px'}>
                        <div>
                          Daily Fee{' '}
                          {formatNumberAsPercentageString(
                            offerData?.currentPricing?.months?.[
                              offerData?.currentMonth
                            ]?.dailyPercentage
                          )}{' '}
                        </div>
                        <div>
                          <span>
                            {formatCurrency(
                              offerData?.currentPricing?.months?.[
                                offerData?.currentMonth
                              ]?.dailyFee,
                              2
                            )}
                          </span>
                          <span> per day</span>
                        </div>
                      </div>
                      <div className={'flex justify-between pb-3px'}>
                        <div>Repayment</div>
                        <div>
                          <span>
                            {formatNumberAsPercentageString(
                              offerData?.currentPricing?.months?.[
                                offerData?.currentMonth
                              ]?.holdbackRate
                            )}
                          </span>
                          <span>&nbsp;of sales</span>
                        </div>
                      </div>
                      {/* uncomment if you wish to add again calculated total fee and total to repay */}
                      {/* <div className={'flex justify-between pb-3px'}>
                      <div>Calculated total fees</div>
                      <div>{formatCurrency(offerData?.currentPricing?.totalFee)}</div>
                    </div>
                    <div className={'flex justify-between pb-3px'}>
                      <div className="font-bold">Total to repay</div>
                      <div className="font-bold">{formatCurrency(offerData?.currentPricing?.totalRepayment)}</div>
                    </div> */}
                    </>
                  )}
                <div className="flex justify-between flex-col-reverse space-y-4 space-y-reverse mt-8 md:space-x-4 md:flex-row  md:space-y-0">
                  <Button
                    disabled={isAccepted}
                    variant="button-back-grey"
                    onClick={handleBackButton}
                    children="Back"
                    className="font-medium"
                  />
                  <Button
                    variant="button-next"
                    affixIcon={configs.icons.arrowForward}
                    children={isAccepted ? 'Next' : 'Accept'}
                    className="font-medium"
                    type="button"
                    onClick={isAccepted ? onNext : submitOfferForm}
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    )
  }
  const renderOfferCards = () => {
    const arraySortedByAmount = availableFundsData?.defaults?.sort(
      (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
    )

    return (
      <>
        <div className="flex flex-col">
          <div className="flex justify-center text-xl mb-2 lg:text-40px lg:leading-56px font-black">
            {target && !!expired
              ? `${capitalizeFirstLetter(user?.firstName)}, your offer expired! `
              : 'You have been approved!'}
          </div>
        </div>

        {!isAccepted && (
          <>
            {target && !!expired ? (
              <></>
            ) : (
              // Removed as per https://bloomfin.atlassian.net/browse/EN-60
              // <div className="flex justify-center mb-2 text-xl leading-26px">
              //   Call us on
              //   <a
              //     href={`tel:${getPhoneNumber().href}`}
              //     className="hover:opacity-80 transition-opacity font-semibold ml-1"
              //   >
              //     {getPhoneNumber().text}
              //   </a>
              // </div>
              <>
                <div className="flex justify-center mb-2 text-xl leading-26px">
                  Great news! Simply accept an offer below to get started.
                </div>
                <div className="flex justify-center">
                  <div className="mb-5 text-sm leading-26px lext-left lg:text-center">
                    <span
                      className="mb-8 mr-1.5 text-sm text-pink-color underline underline-offset-4 leading-26px hover:cursor-pointer"
                      onClick={() => {
                        setViewPage('customizeOffer')
                      }}
                    >
                      Click here{' '}
                    </span>{' '}
                    if you want more control over your offer & repayment
                    options.
                  </div>
                </div>
              </>
            )}
            {arraySortedByAmount && (
              <div
                className={`flex relative mb-64 mt-4 lg:mt-14 flex-col lg:flex-row ${
                  target && !!expired && 'pointer-events-none'
                }`}
                style={
                  target && !!expired
                    ? { filter: 'grayscale(1)' }
                    : { filter: 'grayscale(0)' }
                }
              >
                {arraySortedByAmount.map((offer, index) => {
                  return (
                    <EcommerceOfferCard
                      key={index}
                      offer={offer}
                      index={index}
                      isActive={
                        activeCard === index ||
                        (activeCard === null && offer.isBest)
                      }
                      onClick={(i) => handleOfferCardClick(i, offer)}
                      onNext={onNext}
                      submitOfferForm={submitOfferForm}
                      isLoading={isLoading}
                      isAccepted={isAccepted}
                      activeCard={activeCard}
                    />
                  )
                })}
              </div>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <div className="flex flex-col justify-between container mb-10">
      <div className={`flex flex-col`}>
        {viewPage === 'customizeOffer' || isAccepted
          ? renderCustomizeOffer()
          : renderOfferCards()}
      </div>
    </div>
  )
}
export default EcommerceAvailableFunds
