import React from 'react'
import Button from 'components/Button'
import 'config/api/connections/xero/typedef'

export function StepThree(props) {
  const { handleBack, handleDisconnect, selectedOrganization } = props

  return (
    <>
      <div className="flex justify-center mb-10">
        <span className="text-lg font-bold mt-2">
          {`Are you sure you want to disconnect ${selectedOrganization?.tenantName}?`}
        </span>
      </div>
      <div className="flex w-full mt-2 justify-between">
        <Button
          variant="button-back-grey"
          onClick={handleBack}
          children="Back"
          className="normal-case"
        />

        <Button
          variant={'button-next-pink'}
          className="ml-auto normal-case"
          onClick={handleDisconnect}
        >
          Disconnect
        </Button>
      </div>
    </>
  )
}
