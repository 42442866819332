import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useWindowSize from 'hooks/useWindowSize'
import { closeSidebar, openSidebar } from 'redux/actions/App'

const minifiedSidebarScreenwidth = 1024

const useSidebarHook = () => {
  const dataApp = useSelector((state) => state.dataApp)
  const location = useLocation()
  const pathname = location.pathname

  // True when screen width is below *minifiedSidebarScreenwidth and user toggled to show sidebar
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)
  // True when screen width is below *minifiedSidebarScreenwidth pixel
  const [isSidebarMinified, setIsSidebarMinified] = useState(false)

  const { sidebarExpanded } = dataApp
  const { width } = useWindowSize()

  const dispatch = useDispatch()

  const handleFocusSidebar = () => {
    dispatch(openSidebar())
  }

  const handleOnblurSidebar = () => {
    setTimeout(() => {
      dispatch(closeSidebar())
    }, 200)
  }

  useEffect(() => {
    if (isSidebarMinified && sidebarExpanded) {
      return setIsSidebarExpanded(true)
    }

    setIsSidebarExpanded(false)
  }, [sidebarExpanded, isSidebarMinified])

  useEffect(() => {
    if (width < minifiedSidebarScreenwidth) {
      return setIsSidebarMinified(true)
    }

    return setIsSidebarMinified(false)
  }, [width])

  return {
    isSidebarExpanded,
    handleFocusSidebar,
    handleOnblurSidebar,
    isSidebarMinified,
    pathname
  }
}

export default useSidebarHook
