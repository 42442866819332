import React from 'react'
import useCompanyInformationHook from './hook'

const CompanyInformation = (props) => {
  const { companyCurrentStep } = props
  const { companySteps } = useCompanyInformationHook(props)

  return (
    <div className="flex flex-col items-start w-full mt-8 3xl:mt-12 lg:h-full">
      {companySteps[companyCurrentStep] &&
        companySteps[companyCurrentStep].component}
    </div>
  )
}

export default CompanyInformation
