import React, { useState } from 'react'
import useModal from 'hooks/useModal'
import { XeroConnectedModal } from './connectedModals'

export function useConnectedActions() {
  const [integrations, setIntegrations] = useState([])
  const {
    isOpened: isXeroOpened,
    open: openXeroModal,
    close: closeXeroModal
  } = useModal()

  function track(accounts) {
    setIntegrations(accounts)
  }

  function getComponent(name) {
    const interationProps = integrations.find((el) =>
      el.label.toLowerCase().includes(name)
    )
    return [
      openXeroModal,
      closeXeroModal,
      isXeroOpened,
      () => {
        return (
          <XeroConnectedModal
            isOpened={isXeroOpened}
            close={closeXeroModal}
            {...interationProps}
          />
        )
      }
    ]
  }

  return {
    track,
    getComponent
  }
}
