import React from 'react'
import configs from 'config/configs'
import fakeData from 'config/data'
import Button from '../../../Button'
import FormikCurrencyInputGrey from '../../../FormikCurrencyInputGrey'
import FormikDatePickerGrey from '../../../FormikDatePickerGrey'
import FormikInputTextGrey from '../../../FormikInputTextGrey'
import FormikSelectGrey from '../../../FormikSelectGrey'
import useNewCompanyFormHook from './hook'

const NewCompanyForm = ({ onBackCompanyStep, handleCreateCompanyInfo }) => {
  const {
    formik,
    shouldShowError,
    onSubmitCompanyForm,
    handleChange,
    countryOptions,
    saveCompanyError
  } = useNewCompanyFormHook({
    handleCreateCompanyInfo
  })

  return (
    <form onSubmit={onSubmitCompanyForm}>
      <div className="flex flex-col w-full">
        <div className="flex items-start">
          <FormikInputTextGrey
            field="companyName"
            id="companyName"
            placeholder="Enter company name"
            backgroundColor="bg-white"
            shouldValid={shouldShowError}
            {...formik}
            handleChange={handleChange}
            containerClassname={'mb-3'}
            autoFocus
          />
        </div>

        <div className="flex flex-col md:flex-row items-start md:space-x-3">
          <FormikInputTextGrey
            field="companyNumber"
            id="companyNumber"
            backgroundColor="bg-white"
            placeholder="Company number"
            shouldValid={shouldShowError}
            {...formik}
            handleChange={handleChange}
            containerClassname={'mb-3'}
          />
          <FormikDatePickerGrey
            field="incorporatedOn"
            id="incorporatedOn"
            placeholder="Incorporated on"
            shouldValid={shouldShowError}
            {...formik}
            handleChange={handleChange}
            containerClassname={'mb-3'}
          />
        </div>

        <div className="flex flex-col md:flex-row items-start md:space-x-3">
          <FormikSelectGrey
            field="country"
            id="country"
            placeholder="Country"
            shouldValid={shouldShowError}
            options={countryOptions}
            saveError={saveCompanyError}
            {...formik}
            handleChange={handleChange}
            containerClassname={'mb-3'}
          />

          <FormikSelectGrey
            field="companyType"
            id="companyType"
            placeholder="Company type"
            shouldValid={shouldShowError}
            options={fakeData.companyData.companyTypeOptions}
            saveError={saveCompanyError}
            {...formik}
            handleChange={handleChange}
            containerClassname={'mb-3'}
          />
        </div>

        <div className="flex flex-col md:flex-row items-start md:space-x-3 mb-6">
          <FormikInputTextGrey
            field="website"
            id="website"
            placeholder="Website"
            backgroundColor="bg-white"
            iconPrefixSrc={configs.icons.globe}
            shouldValid={shouldShowError}
            {...formik}
            handleChange={handleChange}
            containerClassname={'mb-3'}
          />
          <FormikCurrencyInputGrey
            field="avgMonthlyIncome"
            id="avgMonthlyIncome"
            placeholder="Monthly revenue"
            shouldValid={shouldShowError}
            type="number"
            {...formik}
            handleChange={handleChange}
            containerClassname={'mb-3'}
          />
        </div>
      </div>

      <div className="flex justify-between items-center mb-12">
        <Button
          type="button"
          variant="button-back-grey"
          onClick={onBackCompanyStep}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant={'button-next-pink'}
          className="ml-auto"
          affixIcon={configs.icons.arrowForward}
          onClick={onSubmitCompanyForm}
          loading={formik.isSubmitting}
        >
          Next
        </Button>
      </div>
    </form>
  )
}

export default NewCompanyForm
