import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import configs from 'config/configs'
import { loginSuccess, logout } from 'redux/actions/Auth'
import { store } from 'redux/store'
import camelCaseKeys from 'utils/camelCaseKeys'
import { jwtDecode } from 'utils/jwtDecode'

// import consumer from "../../channels/consumer";

const baseURL = configs?.apiConfig.endpoint

export const instance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json'
  }
})

instance.interceptors.request.use(function (config) {
  const originalRequest = config
  const state = store.getState()
  const { accessToken, expiresAt } = state?.dataAuth

  // Check if token expires
  if (expiresAt) {
    const now = new Date()
    const expireDate = new Date(expiresAt * 1000)
    if (now > expireDate) {
      // Handle refresh token in background when token expired
      return axios
        .get('/api/refresh-token')
        .then((res) => {
          // If res token exist then it's successful
          if (res.data.token) {
            // Update new token and info to redux store
            const { token } = res.data
            const decodedToken = jwtDecode(token)
            store.dispatch(
              loginSuccess({
                token,
                expiresAt: decodedToken.exp,
                impersonating: decodedToken.impersonating
              })
            )

            // Reconnect to websocket

            // TODO this was commented for SPLIT
            // consumer.subscriptions.consumer.connect();

            // Resolve originalRequest after refresh new token
            originalRequest.headers.Authorization = `Bearer ${token}`
            return Promise.resolve(originalRequest)
          }
        })
        .catch((err) => {
          console.log(`axios err logout =>`, err)
          // If request for new token fails, remove existing token in memory as well as any other authentication
          store.dispatch(logout())
        })
    }
  }

  // Intercept api calls and add token in authorization header
  config.headers.Authorization = `Bearer ${accessToken}`

  // Transform data to snake_case format for BE to read
  let transformedData = config.data
  if (transformedData) {
    transformedData = snakecaseKeys(transformedData)
    config.data = transformedData
  }

  return config
})

// Transform data to camelCase format for FE to read
instance.interceptors.response.use((response) => {
  let transformedData = response.data

  if (
    transformedData &&
    typeof transformedData === 'object' &&
    !Array.isArray(transformedData)
  ) {
    transformedData = camelCaseKeys(transformedData)
    response.data = transformedData
  }

  return response
})

// Intercept response on token expired requests and request for new tokens
instance.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    // If response returns 401 - Unauthorized, remove existing token in memory as well as any other authentication
    if (error?.response?.status === 401) {
      store.dispatch(logout())
    }

    // return Error object with Promise
    return Promise.reject(error)
  }
)

export default instance
