import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import documentsApi from 'config/api/documents'
import configs from 'config/configs'
import { formatIsoToDate } from 'utils/datesUtil'
import formatCurrency from 'utils/formatCurrency'

const DocumentDetails = ({ document }) => {
  const downloadDocumentMut = useMutation(
    (id) => documentsApi.downloadDocument(id),
    {
      onSuccess: (res) => {
        const linkSource = `data:application/pdf;base64,${res.body}`
        const downloadLink = window.document.createElement('a')
        const fileName = 'Bloom DocuSign.pdf'

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  const downloadDocument = () => {
    downloadDocumentMut.mutate(document?.envelope_id)
  }

  return (
    <div className="rounded-lg p-6 bg-white mt-5">
      <div className={`flex justify-between pb-4 shadow-card-header-white`}>
        <span className="text-xl font-semibold py-2">
          {formatIsoToDate(document?.created_at)}
        </span>
        <span className="text-xl font-semibold py-2">
          {formatCurrency(document?.amount ?? '0')}
        </span>
      </div>
      <div className="mt-5">
        <button
          disabled={downloadDocumentMut.isLoading}
          className="mb-6 w-auto font-lato text-16px text-dark-grey border border-solid border-dark-grey rounded-lg"
        >
          <div
            className="flex flex-row items-center py-4 mx-auto w-full pr-4"
            onClick={downloadDocument}
          >
            <img src={configs.icons.download} className="w-6 h-6 mx-4" />
            {downloadDocumentMut.isLoading
              ? 'Downloading...'
              : 'Loan Agreement & Terms and Conditions'}
          </div>
        </button>
      </div>
    </div>
  )
}

export default DocumentDetails
