import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import {
  GOCARDLESS,
  SAAS_BUSINESS_TYPE,
  SEKO,
  XAPIX
} from 'config/configs/constant'
import { ROOT_ROUTES } from 'config/configs/routes'
import { useAnalytics } from 'hooks/useAnalytics'
import ResetPassword from 'pages/ResetPassword'
import { ForgotPassword, Login, SignUp } from 'pages/authorisation'
import CompanyBusinessType from 'pages/authorisation/CompanyBusinessType'
import {
  updateBusinessType,
  updateEmbeddedPartner
} from 'redux/actions/PersistRegister'
import useRouterHook from 'routes/hooks/useRouter'
import PartnersRouter from './partners/PartnersRouter'

const {
  SIGNUP,
  LOGIN,
  FORGOT_PASSWORD,
  PARTNERS,
  RESET_PASSWORD,
  SIGNUP_ECOMMERCE,
  SIGNUP_RECURRING
} = ROOT_ROUTES

const AuthRouter = () => {
  const analytics = useAnalytics()
  const location = useLocation()
  const dispatch = useDispatch()
  const { getSubdomain, isSubdomainPermitted, isSubdomainWithoutBusinessType } =
    useRouterHook()

  const subdomain = getSubdomain()
  const subdomainPermitted = isSubdomainPermitted()

  useEffect(() => {
    analytics.page(location)
  }, [location.pathname])

  useEffect(() => {
    if (subdomainPermitted) {
      switch (subdomain) {
        case GOCARDLESS:
          dispatch(updateEmbeddedPartner(GOCARDLESS))
          dispatch(updateBusinessType(SAAS_BUSINESS_TYPE))
          return
        case SEKO:
          dispatch(updateEmbeddedPartner(SEKO))
          dispatch(updateBusinessType(SAAS_BUSINESS_TYPE))
          return
        case XAPIX:
          dispatch(updateEmbeddedPartner(XAPIX))
          return
        default:
          dispatch(updateEmbeddedPartner(''))
      }
    }
  }, [subdomain])

  return (
    <Switch>
      <Redirect exact path="/" to={SIGNUP} />
      {subdomainPermitted && (
        <Route
          exact
          path={SIGNUP}
          component={
            isSubdomainWithoutBusinessType() ? CompanyBusinessType : SignUp
          }
        />
      )}

      <Route exact path={LOGIN} component={Login} />
      <Route exact path={SIGNUP} component={CompanyBusinessType} />
      <Route exact path={SIGNUP_ECOMMERCE} component={SignUp} />
      <Route exact path={SIGNUP_RECURRING} component={SignUp} />
      <Route path={PARTNERS} component={PartnersRouter} />
      <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={RESET_PASSWORD} component={ResetPassword} />

      <Redirect path={LOGIN} />
    </Switch>
  )
}

export default AuthRouter
