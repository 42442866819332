import React, { useRef } from 'react';
import HeaderDropdown from '../HeaderDropdown';

const LoggedInAction = ({ responsiveMenuClass, handleFocusDropdown, handleBlurDropdown, user, showHeaderDropdown }) => {
  const userFirstLetter = user?.firstName?.charAt(0);
  const userSecondLetter = user?.lastName?.charAt(0);
  const buttonElement = useRef();

  return (
    <div className={`hidden md:flex md:items-center md:flex-row p-1 md:p-0 ${responsiveMenuClass ?? ''}`}>
      {user && (
        <div className="relative">
          <button
            ref={buttonElement}
            onFocus={handleFocusDropdown}
            onClick={() => buttonElement.current.focus()}
            className="flex justify-center items-center bg-dark-blue w-8 h-8 rounded-full font-normal"
            onBlur={handleBlurDropdown}
          >
            <span className="font-bold text-white uppercase">
              {userFirstLetter}
              {userSecondLetter}
            </span>
          </button>
          <HeaderDropdown show={showHeaderDropdown} offsetY="49px"></HeaderDropdown>
        </div>
      )}
    </div>
  );
};

export default LoggedInAction;
