import { useMemo } from 'react'
import configs from 'config/configs'
import {
  INTEGRATION_CONNECTED,
  INTEGRATION_CRITICAL,
  INTEGRATION_INACTIVE,
  INTEGRATION_WARNING
} from 'enums/Integrations'

export const useConnectionStatus = (status) => {
  const bgColorClass = useMemo(() => {
    switch (status) {
      case true:
      case INTEGRATION_CONNECTED:
        return 'bg-teal-color'

      case INTEGRATION_WARNING:
        return 'bg-orange-warning'

      case INTEGRATION_CRITICAL:
        return 'bg-red-600'

      case false:
      default:
        return ''
    }
  }, [status])

  const borderColorClass = useMemo(() => {
    switch (status) {
      case true:
      case INTEGRATION_CONNECTED:
        return 'border-teal-color'

      case INTEGRATION_WARNING:
        return 'border-orange-warning'

      case INTEGRATION_CRITICAL:
        return 'border-red-600'

      case false:
      default:
        return ''
    }
  }, [status])

  const isConnected = useMemo(
    () =>
      !!status ||
      status === INTEGRATION_CONNECTED ||
      status === INTEGRATION_WARNING,
    [status]
  )

  const isInactive = useMemo(
    () => !status || status === INTEGRATION_INACTIVE,
    [status]
  )

  const statusIcon = useMemo(() => {
    const isFailed = status === INTEGRATION_CRITICAL
    const isExpiring = status === INTEGRATION_WARNING
    const isActive = status === INTEGRATION_CONNECTED
    return isFailed
      ? configs.icons.circleCrossRed
      : isExpiring
        ? configs.icons.circleCheckOrange
        : isActive
          ? configs.images.doneCheck
          : configs.images.emptyCheck
  }, [status])

  return {
    borderColorClass,
    bgColorClass,
    statusIcon,
    isInactive,
    isConnected
  }
}
