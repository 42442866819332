import { AppLayout } from 'layouts'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ROOT_ROUTES } from 'config/configs/routes'
import { useAnalytics } from 'hooks/useAnalytics'
import Benchmarks from 'pages/app/Benchmarks'
import Documents from 'pages/app/Documents'
import Connections from 'pages/app/GetConnected'
import Sales from 'pages/app/Sales/Sales'
import Settings from 'pages/app/Settings'
import { FeatureDashboardRouter } from 'routes/AppRouter/dashboard/FeatureDashboardRouter'
import AccountsBalancesRouter from './AccountsBalancesRouter'

const {
  DASHBOARD,
  SALES,
  ACCOUNTS_BALANCES,
  BENCHMARKS,
  CONNECTIONS,
  DOCUMENTS,
  SETTINGS
} = ROOT_ROUTES

const MerchantDashboardRouter = () => {
  const analytics = useAnalytics()
  const location = useLocation()

  const { isSaasUser } = useSelector((state) => state.dataUser)

  useEffect(() => {
    analytics.page(location)
  }, [location.pathname])

  return (
    <AppLayout>
      <Switch>
        <Route exact path={DASHBOARD} component={FeatureDashboardRouter} />

        {!isSaasUser && <Route exact path={SALES} component={Sales} />}
        {!isSaasUser && (
          <Route exact path={BENCHMARKS} component={Benchmarks} />
        )}
        {!isSaasUser && (
          <Route path={ACCOUNTS_BALANCES} component={AccountsBalancesRouter} />
        )}

        <Route exact path={CONNECTIONS} component={Connections} />
        <Route exact path={DOCUMENTS} component={Documents} />
        <Route exact path={SETTINGS} component={Settings} />
        <Redirect to={DASHBOARD} />
      </Switch>
    </AppLayout>
  )
}

export default MerchantDashboardRouter
