import { always, cond, equals } from 'ramda'
import { useMemo, useState } from 'react'
import Integrations from 'components/Integrations'
import PageTitle from 'components/PageTitle'
import configs from 'config/configs'
import {
  INTEGRATION_CONNECTED,
  INTEGRATION_CRITICAL,
  INTEGRATION_WARNING
} from 'enums/Integrations'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'
import { useConnectionsHook } from 'pages/app/GetConnected/hook'

const ConnectionTitle = cond([
  [equals('Sales'), always('Connect your sales accounts')],
  [equals('Marketing'), always('Connect your marketing accounts')],
  [equals('Accounting platforms'), always('Finance accounts')],

  [equals('Bank accounts'), always('Open Banking')]
])

const GetConnected = () => {
  const { steps: integrations, isLoading } = useConnectionsHook()
  const [currentTab, setCurrentTab] = useState('')

  const tabs = useMemo(
    () => [
      {
        title: 'Sales',
        status: integrations.find((step) => step.kind === 'sales')?.status
      },
      {
        title: 'Marketing',
        isAnyIntegrationConnected: integrations.find(
          (step) => step.kind === 'marketing'
        )?.is_done
      },
      {
        title: 'Accounting platforms',
        status: integrations.find((step) => step.kind === 'accounting')?.status
      },
      {
        title: 'Bank accounts',
        status: integrations.find((step) => step.kind === 'banking')?.status
      }
    ],
    [integrations]
  )

  const handleClickTab = (tabTitle) => {
    if (currentTab === tabTitle) {
      setCurrentTab('')
      return null
    }

    setCurrentTab(tabTitle)
  }

  if (isLoading) {
    return <AppLayoutSkeleton />
  }

  return (
    <div className="flex flex-col w-full">
      <PageTitle
        title={'Connections'}
        subtitle={
          'Help us understand your business better by connecting your data. The more data you provide the more capital we can provide.'
        }
      />

      <div className="flex flex-col mb-4">
        {tabs.map((item, index) => {
          const isActive = currentTab === item.title

          return (
            <div
              key={index}
              className="flex flex-col w-full mb-8 bg-white rounded-lg overflow-hidden"
            >
              <div
                onClick={() => handleClickTab(item.title)}
                className={`flex items-center justify-between m-6 mb-0 pb-6 cursor-pointer ${
                  isActive ? 'border-b border-light-grey-400' : ''
                }`}
              >
                <div className="flex items-center">
                  {item.isAnyIntegrationConnected ||
                  item.status === INTEGRATION_CONNECTED ? (
                    <img
                      src={configs.icons.circleCheckGreen}
                      className="w-6 h-6 mr-3 border border-transparent"
                    />
                  ) : item.status === INTEGRATION_WARNING ? (
                    <img
                      src={configs.icons.circleCheckOrange}
                      className="w-6 h-6 mr-3 border border-transparent"
                    />
                  ) : item.status === INTEGRATION_CRITICAL ? (
                    <img
                      src={configs.icons.circleCrossRed}
                      className="w-6 h-6 mr-3 border border-transparent"
                    />
                  ) : (
                    <span className="w-6 h-6 mr-3 border border-light-grey-300 rounded-full"></span>
                  )}

                  <span className="font-bold text-xl">
                    {ConnectionTitle(item.title)}
                  </span>
                </div>

                {isActive ? (
                  <img src={configs.icons.arrowDarkGrey} className="w-6 h-6" />
                ) : (
                  <img
                    src={configs.icons.arrowDarkGrey}
                    className="w-6 h-6 transform rotate-180"
                  />
                )}
              </div>
              <div className={`w-full ${isActive ? 'h-auto' : 'h-0'}`}>
                <div className="rounded-lg p-6">
                  {integrations && (
                    <>
                      {item.title === 'Sales' && isActive && (
                        <Integrations
                          accountType="sales accounts"
                          currentStep={0}
                          hasTitle={false}
                          hasContent={false}
                          xlWidth="auto"
                          lgHeight="auto"
                          hasNext={false}
                        />
                      )}
                      {item.title === 'Marketing' && isActive && (
                        <Integrations
                          accountType="marketing accounts"
                          currentStep={0}
                          hasTitle={false}
                          xlWidth="auto"
                          lgHeight="auto"
                          hasNext={false}
                          hasContent={false}
                        />
                      )}
                      {item.title === 'Accounting platforms' && isActive && (
                        <Integrations
                          accountType="accounting platforms"
                          currentStep={0}
                          hasTitle={false}
                          xlWidth="auto"
                          lgHeight="auto"
                          hasNext={false}
                          hasContent={false}
                        />
                      )}
                      {item.title === 'Bank accounts' && isActive && (
                        <Integrations
                          accountType="bank accounts"
                          currentStep={0}
                          hasTitle={false}
                          hasContent={false}
                          xlWidth="auto"
                          lgHeight="auto"
                          hasNext={false}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GetConnected
