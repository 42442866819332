import moment from 'moment'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import DatePicker from 'react-datepicker'
import icons from 'config/configs/icons'
import Button from '../../../Button'

const DAY_FIELD = 'day'
const MONTH_FIELD = 'month'
const YEAR_FIELD = 'year'

const initialState = {
  [DAY_FIELD]: '',
  [MONTH_FIELD]: '',
  [YEAR_FIELD]: ''
}

const MAX_DATE_RANGE = moment().subtract(18, 'years').toDate()
const MIN_DATE_RANGE = moment().subtract(110, 'years').toDate()

const CHANGE_VALUE = 'change_value'
const SET_DATE = 'set_date'

const DATE_FORMAT = 'DD/MM/YYYY'

function reducer(state, action) {
  switch (action.type) {
    case CHANGE_VALUE:
      return { ...state, [action.field]: action.value }
    case SET_DATE:
      return { ...state, ...action.value }
    default:
      throw new Error('unknown action')
  }
}

export function BirthdayDatePicker({ name, onChange, meta, setTouched }) {
  const [isOpen, setIsOpen] = useState(false)
  const [{ day, month, year }, dispatch] = useReducer(reducer, initialState)

  const closeDatepicker = () => setIsOpen(false)
  const openDatepicker = () => setIsOpen(true)

  /**
   * Sets only one field at the moment
   */
  const onFieldChange = (fieldName) => (event) => {
    dispatch({
      type: CHANGE_VALUE,
      value: event.target.value,
      field: fieldName
    })
  }

  /**
   * Sets all fields at the same time
   * @param {Date} value
   */
  const onSetDate = (value) => {
    const momentDate = moment(value)
    dispatch({
      type: SET_DATE,
      value: {
        [DAY_FIELD]: momentDate.date(),
        [MONTH_FIELD]: momentDate.month() + 1,
        [YEAR_FIELD]: momentDate.year()
      }
    })
  }

  // Memoized setDate to pass into the datepicker component
  const onDatePickerChange = useCallback((value) => {
    onSetDate(value)
  }, [])

  useEffect(() => {
    // Set initial value if exist
    if (meta.value) {
      const inputDate = moment(meta.value, DATE_FORMAT)
      onSetDate(inputDate)
    }
  }, [])

  useEffect(() => {
    if (!day || !month || !year) return
    setTouched(name, true)
    // Note.The date could be invalid.It checked with the YUP form validation.
    onChange(name, `${day}/${month}/${year}`, true)
  }, [day, month, year])

  const inputClasses = `flex-grow font-normal flex flex-row items-center rounded-lg
                        bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                        ${meta.touched && meta.error ? 'border-b-pink' : 'border-b-placeholder'}
                        `

  // TODO: Refactor UI with designer for better UX. At this moment date format is not clear and datepicker button could look better
  return (
    <div className="relative">
      <div className="flex flex-col">
        <fieldset className="flex flex-col">
          <legend className="mb-2">Date of birth</legend>
          <div className="flex flex-row">
            <div className="mr-2 w-full">
              <label className="sr-only" htmlFor="birth-day">
                Day
              </label>
              <input
                className={inputClasses}
                value={day}
                placeholder="Day"
                onChange={onFieldChange(DAY_FIELD)}
                id="birth-day"
                name="birth-day"
                type="number"
                inputMode="numeric"
                min="1"
                max="31"
              />
            </div>
            <div className="mr-2 w-full">
              <label className="sr-only" htmlFor="birth-month">
                Month
              </label>
              <input
                className={inputClasses}
                value={month}
                placeholder="Month"
                onChange={onFieldChange(MONTH_FIELD)}
                id="birth-month"
                name="birth-month"
                type="number"
                inputMode="numeric"
                min="1"
                max="12"
              />
            </div>
            <div className="w-full">
              <label className="sr-only" htmlFor="birth-year">
                Year
              </label>
              <input
                className={inputClasses}
                value={year}
                placeholder="Year"
                onChange={onFieldChange(YEAR_FIELD)}
                id="birth-year"
                name="birth-year"
                type="number"
                inputMode="numeric"
                min={moment(MIN_DATE_RANGE).year()}
                max={moment(MAX_DATE_RANGE).year()}
              />
            </div>
          </div>
        </fieldset>
        <Button
          className="mt-3"
          variant="button-back-grey"
          type="button"
          prefixIcon={icons.calendarGrey}
          onClick={isOpen ? closeDatepicker : openDatepicker}
        >
          <span>{isOpen ? 'Close' : 'Open'} date picker</span>
        </Button>
      </div>
      <div className="relative datepicker_wrapper ">
        {isOpen && (
          <DatePicker
            value={getDateFromState({ day, month, year })}
            placeholderText="Date of birth"
            className="absolute"
            showMonthDropdown
            showYearDropdown
            inline
            withPortal
            portalId="root-portal"
            openToDate={
              !!meta.value
                ? getDateFromState(parseDateFormat(meta.value))
                : MAX_DATE_RANGE
            }
            onChange={onDatePickerChange}
            minDate={MIN_DATE_RANGE}
            maxDate={MAX_DATE_RANGE}
            customInput={<input hidden />}
          />
        )}
      </div>
    </div>
  )
}

/**
 * Validate entered day value
 * @param {string} value string representation of number
 * @param {number | string} month
 */
function isDayFieldValid(value, month) {
  const parsedValue = Math.abs(parseInt(value))

  if (Number.isNaN(parsedValue)) return false

  const parsedMonth = Math.abs(parseInt(month))

  if (!month || Number.isNaN(parsedMonth)) {
    return parsedValue <= 31
  }

  return (
    moment()
      .set('month', parsedMonth - 1)
      .date() >= parsedValue
  )
}

function isMonthFieldValid(value) {
  const parsedMonth = Math.abs(parseInt(value))
  if (Number.isNaN(parsedMonth)) return false

  return parsedMonth > 0 && parsedMonth <= 12
}

function getDateFromState(state) {
  let fixedDay = state.day
  let fixedMonth = state.month

  if (!isDayFieldValid(state.day)) {
    fixedDay = 1
  }

  if (!isMonthFieldValid(state.month)) {
    fixedMonth = 1
  }
  return moment(`${fixedDay}/${fixedMonth}/${state.year}`, DATE_FORMAT).toDate()
}

function parseDateFormat(dateString) {
  const [day, month, year] = dateString.split('/').map((el) => parseInt(el))
  return { day, month, year }
}
