import React from 'react'
import icons from 'config/configs/icons'

const BankAccountSection = ({
  bankName,
  sortCode,
  accountNumber,
  accountName,
  accountType,
  accountId,
  isSelected,
  onClick
}) => {
  const backgroundColor = isSelected ? 'bg-teal-color' : 'bg-white'
  const textColor = isSelected ? 'text-dark-blue' : 'text-pink-color'
  const borderConfigurationButton = isSelected
    ? ''
    : 'border border-1 border-pink-color'
  const borderConfiguration = isSelected
    ? 'max-md:border-2 max-md:border-teal-color'
    : ''
  const buttonText = isSelected ? 'SELECTED' : 'SELECT'

  return (
    <div
      className={`flex items-center justify-between py-4 px-6 bg-white rounded-lg border border-1 border-b-grey ${borderConfiguration} hover:cursor-pointer`}
      onClick={() => onClick(accountId)}
    >
      <div className="flex flex-col justify-start text-lg font-bold w-5/12">
        <span>{accountName}</span>
        <span>
          {accountType}: {accountNumber}
        </span>
      </div>
      <div className="w-3/12">{bankName}</div>
      <div className="w-2/12">Sort code: {sortCode}</div>
      <div className="w-2/12 max-md:hidden">
        <button
          className={`flex items-center justify-center gap-x-2 ${backgroundColor} ${borderConfigurationButton} ${textColor} font-lato font-medium rounded-2xl md:w-32 h-8 text-sm`}
        >
          {isSelected && <img src={icons.checkDarkBlue} alt="checkmark" />}
          {buttonText}
        </button>
      </div>
    </div>
  )
}

export default BankAccountSection
