import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import onboardingApi from 'config/api/onboarding'
import { getErrorMessage } from 'utils/getErrorMessage'

const GoBackButton = () => {
  const rollbackMutation = useMutation(onboardingApi.rollback, {
    onSuccess: () => {
      window.location.reload()
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  const confirm = () => {
    rollbackMutation.mutate()
  }

  return (
    // MPP-1323: Removed ButtonWithConfirmation component, since the modal is not needed anymore
    <Button
      variant="button-next-pink"
      type="submit"
      children={'Submit More Information'}
      onClick={confirm}
    />
  )
}

export default GoBackButton
