import React from 'react'
import Card from '../Card'
import ErrorMessage from '../ErrorMessage'
import Skeleton from '../Skeleton'
import useCompanyDetails from './useCompanyDetails'

const CompanyDetails = () => {
  const { companyDetails, user, isLoading, error } = useCompanyDetails()

  if (error) {
    return (
      <Card headerClassname="text-b-dark font-medium">
        <ErrorMessage error={error}></ErrorMessage>
      </Card>
    )
  }

  if (isLoading) {
    return <Skeleton height="274px" />
  }

  return (
    companyDetails &&
    user && (
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <div className="mt-5">
            <p className="text-l text-text-color-grey-bloom font-normal">
              Company name
            </p>
            <p className="mt-1 text-l font-normal">
              {companyDetails.companyName || 'Not available'}
            </p>
          </div>
          <div className="mt-8">
            <p className="text-l text-text-color-grey-bloom font-normal">
              Address
            </p>
            <p className="mt-1 text-l font-normal">
              {companyDetails.addressLine1 || 'Not available'}
            </p>
            {companyDetails.addressLine2 && (
              <p className="mt-1 text-l font-normal">
                {companyDetails.addressLine2}
              </p>
            )}
          </div>
          <div className="mt-8">
            <p className="text-l text-text-color-grey-bloom font-normal">
              Website
            </p>
            <p className="mt-1 text-l font-normal">
              {companyDetails.website || 'Not available'}
            </p>
          </div>
        </div>
        <div>
          <div className="mt-5">
            <p className="text-l text-text-color-grey-bloom font-normal">
              Contact name
            </p>
            <p className="mt-1 text-l font-normal">
              {`${user.firstName} ${user.lastName}` || 'Not available'}
            </p>
          </div>
          <div className="mt-8">
            <p className="text-l text-text-color-grey-bloom font-normal">
              Contact email
            </p>
            <p className="mt-1 text-l font-normal">
              {user.email || 'Not available'}
            </p>
          </div>
          <div className="mt-8">
            <p className="text-l text-text-color-grey-bloom font-normal">
              Contact phone number
            </p>
            <p className="mt-1 text-l font-normal">
              {user.phone || 'Not available'}
            </p>
          </div>
        </div>
      </div>
    )
  )
}

export default CompanyDetails
