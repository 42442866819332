import React from 'react'
import { useSelector } from 'react-redux'
import configs from 'config/configs'
import Card from '../Card'
import CountdownTimer from '../CountdownTimer'
import ErrorMessage from '../ErrorMessage'
import InfoCard from '../InfoCard'
import EcommerceAvailableFunds from './EcommerceAvailableFunds'
import RecurringAvailableFunds from './RecurringAvailableFunds'
import SkeletonAvailableFunds from './SkeletonAvailableFunds'
import useAvailableFundsHook from './hook'

const DESCRIPTION =
  'Your Calculated Fees and Calculated Repayment are based on your chosen repayment period. With Bloom you only pay for what you use so if you repay early your fees will reduce - we only charge you for the days you hold the funds.'

const AvailableFunds = ({ onBack, onNext }) => {
  const { availableFundsData, offerData, error, isLoading, offerExpiresAt } =
    useAvailableFundsHook({ onNext })
  const persistedDataRegister = useSelector(
    (state) => state.persistedDataRegister
  )
  const { isSaaSUser } = persistedDataRegister
  if (isLoading || !availableFundsData || !offerData) {
    return <SkeletonAvailableFunds />
  }
  if (error) {
    return (
      <div className="flex flex-col justify-between container pt-6 mb-16">
        <Card headerClassname="text-b-dark font-medium">
          <ErrorMessage error={error}></ErrorMessage>
        </Card>
      </div>
    )
  }

  return isSaaSUser ? (
    <>
      <RecurringAvailableFunds
        onBack={onBack}
        onNext={onNext}
        target={offerExpiresAt}
      />
      {offerExpiresAt && <CountdownTimer target={offerExpiresAt} />}
    </>
  ) : (
    <>
      <EcommerceAvailableFunds
        onBack={onBack}
        onNext={onNext}
        target={offerExpiresAt}
      />
      {offerExpiresAt && <CountdownTimer target={offerExpiresAt} />}
      <InfoCard
        imageClassName={'mr-3'}
        description={DESCRIPTION}
        descriptionClassName={'my-1.5'}
        image={configs.icons.logoDotAndO}
      />
    </>
  )
}
export default AvailableFunds
