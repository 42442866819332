import { useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import inflowsApi from 'config/api/accountsAndBalances'
import { getErrorMessage } from 'utils/getErrorMessage'
import { mergeRecurringFlows } from '../utils'

const useInflowsHook = () => {
  const [totalInflowsMonthly, setTotalInflowsMonthly] = useState(0)
  const [recurringInflows, setRecurringInflows] = useState({
    data: { flows: [] },
    hasMore: false,
    lastDate: null
  })
  const [regularInflows, setRegularInflows] = useState({
    data: { flows: [] },
    hasMore: false,
    lastDate: null
  })
  const [currentTab, setCurrentTab] = useState('Recurring')

  const totalInflowsMonthlyQuery = useQuery(
    'totalInflowsMonthly',
    () => inflowsApi.getTotalInflowsMonthly(),
    {
      onSuccess: (res) => {
        setTotalInflowsMonthly(res.totalPredictedMonthly)
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
      }
    }
  )

  const recurringInflowsQuery = useQuery(
    'recurringInflows',
    () => inflowsApi.getRecurringInflows(recurringInflows.lastDate),
    {
      onSuccess: (res) => {
        setRecurringInflows({
          ...recurringInflows,
          hasMore: res.hasMore,
          lastDate: res.lastDate,
          data: {
            flows: mergeRecurringFlows(
              recurringInflows.data.flows,
              res.data.flows
            )
          }
        })
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
      }
    }
  )

  const regularInflowsQuery = useQuery(
    'regularInflows',
    () => inflowsApi.getRegularInflows(regularInflows.lastDate),
    {
      onSuccess: (res) => {
        setRegularInflows({
          ...regularInflows,
          hasMore: res.hasMore,
          lastDate: res.lastDate,
          data: {
            flows: [...res.data.flows, ...regularInflows.data.flows]
          }
        })
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
      }
    }
  )

  const loadMoreRegular = () => {
    regularInflowsQuery.refetch()
  }

  const loadMoreRecurring = () => {
    console.log('load more recurring')
  }

  const handleOnTabClick = (value) => {
    setCurrentTab(value)
  }

  return {
    totalInflowsMonthly,
    recurringInflows,
    regularInflows,
    currentTab,
    handleOnTabClick,
    loadMoreRecurring,
    loadMoreRegular,
    error:
      totalInflowsMonthlyQuery.error ||
      recurringInflowsQuery.error ||
      regularInflowsQuery.error,
    isLoading:
      totalInflowsMonthlyQuery.isLoading ||
      recurringInflowsQuery.isLoading ||
      regularInflowsQuery.isLoading
  }
}

export default useInflowsHook
