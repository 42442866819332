import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import queryClient from 'config/services/queryClient'
import { persistor, store } from 'redux/store'
import RootRouter from 'routes/RootRouter'
import { AnalyticsProvider } from './hooks/useAnalytics'
import './index.css'
import reportWebVitals from './reportWebVitals'

if (window.sentry_dsn) {
  Sentry.init({
    dsn: window.sentry_dsn,
    integrations: [new BrowserTracing()],
    environment: window.environment,
    enabled_environments: ['staging', 'production'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: function () {
      let trace_rate = window.sentry_traces_rate || 0
      switch (true) {
        case /\/healthcheck/.test(window.location.href):
          trace_rate = 0
          break
        case /\/cable/.test(window.location.href):
          trace_rate = 0.1
          break
        default:
          trace_rate = 0.5
      }
      return trace_rate
    }
  })
}

const channels = require.context('config/channels', true, /_channel\.js$/)
channels.keys().forEach(channels)

const root = ReactDOM.createRoot(document.getElementById('react-app-root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AnalyticsProvider>
            <BrowserRouter>
              <RootRouter />
            </BrowserRouter>
          </AnalyticsProvider>
          <ToastContainer limit={3} transition={Slide} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
