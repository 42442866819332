import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Select, { components } from 'react-select'
import Button from 'components/Button'
import { HELP_URL } from 'config/configs/constant'

const WritebackLabel = ({ label, writeBack }) => {
  return (
    <div className="flex justify-between">
      <span>{label}</span>
      {!!writeBack && (
        <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
          Writeback
        </span>
      )}
    </div>
  )
}

const Option = (props) => {
  const { label, writeBack } = props.data
  return (
    <components.Option {...props}>
      <WritebackLabel label={label} writeBack={writeBack} />
    </components.Option>
  )
}

const SingleValue = ({ ...props }) => {
  const { label, writeBack } = props.selectProps.value
  return (
    <components.SingleValue {...props}>
      <WritebackLabel label={label} writeBack={writeBack} />
    </components.SingleValue>
  )
}

export function StepOne(props) {
  const {
    handleReconnect,
    handleNextStep,
    handleDisconnectStep,
    selectedOrganization,
    selectOrganization,
    isLoading,
    organizations = [],
    currentWritebackData
  } = props

  const [options, setOptions] = useState()

  useEffect(() => {
    const opt =
      organizations?.map((item) => {
        const writeBack =
          item.tenantId === currentWritebackData?.tenantId &&
          currentWritebackData?.enabled
        return { label: item.tenantName, value: item.tenantId, writeBack }
      }) ?? []
    setOptions(opt)
  }, [organizations, currentWritebackData])

  const [selected, setSelected] = useState(
    selectedOrganization
      ? options?.find((item) => item.value === selectedOrganization.id)
      : null
  )

  useEffect(() => {
    selectOrganization({})
  }, [])

  useEffect(() => {
    if (!selectedOrganization) {
      const defaultOption = options?.find((el) => el?.writeBack) ?? {}
      selectOrganization(defaultOption)
    }
  }, [organizations, selectedOrganization])

  const onChange = (event) => {
    setSelected(event)
    const selectedOrganization = organizations.find(
      (item) => item.tenantId === event.value
    )
    selectOrganization(selectedOrganization)
  }

  return (
    <>
      {!!organizations?.length && (
        <div className="w-full">
          <div className="flex flex-col w-full mb-6">
            <div className="flex flex-col w-full mb-2 border-light-grey border-b">
              <div className="pb-3">
                <label>
                  <span>Connected organisations</span>
                  <Select
                    id="organizations"
                    className="basic-single pt-3"
                    classNamePrefix="select"
                    name="organizations_selector"
                    components={{ Option, SingleValue }}
                    options={options}
                    onChange={onChange}
                    isDisabled={isLoading}
                    value={selected}
                    maxMenuHeight={200} // limits dropdown height
                    isSearchable
                  />
                </label>
              </div>
              <p className="text-sm text-placeholder-grey">
                Note: Writeback could be enabled only for one account
              </p>
            </div>
          </div>
        </div>
      )}
      {selectedOrganization?.id && (
        <>
          <span>Available actions:</span>
          <div className="flex w-full pb-6 justify-between">
            <div
              className={`flex justify-start mt-4 ml-2 cursor-pointer`}
              onClick={selectedOrganization?.id && handleDisconnectStep}
            >
              <span className="text-sm font-bold text-placeholder-grey underline">
                Disconnect
              </span>
            </div>
            <div>
              <Button
                type="button"
                variant="button-back-grey"
                className="ml-auto normal-case"
                onClick={handleNextStep}
              >
                Writeback
              </Button>
            </div>
          </div>
        </>
      )}
      {organizations?.length ? <hr /> : null}
      <div className="flex w-full py-6  justify-end">
        <div>
          <Button
            type="button"
            variant={'button-next-pink'}
            className="ml-auto normal-case"
            onClick={handleReconnect}
          >
            Connect new organisation
          </Button>
        </div>
      </div>
      <div className="flex justify-center">
        <span className="text-sm text-placeholder-grey">
          Having trouble with this step?
          <a
            href={HELP_URL}
            className="text-sm text-placeholder-grey underline ml-1"
            target="_blank"
          >
            Please contact our support.
          </a>
        </span>
      </div>
    </>
  )
}
