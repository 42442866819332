import React from 'react'

const FormikTextareaGrey = ({
  placeholder,
  values,
  errors,
  field,
  handleChange,
  containerClassname,
  inputClassname,
  content,
  setFieldValue,
  disabled,
  autoComplete,
  autoFocus
}) => {
  const borderError = errors[field] ? 'border-error-message border-2' : ''

  return (
    <div
      className={`flex flex-col justify-center align-center w-full mx-auto ${containerClassname}`}
    >
      <div className="relative flex justify-between align-center">
        {content}
        <textarea
          id={field}
          className={`flex-grow flex flex-row items-center w-full min-h-56 px-4 py-4 rounded-lg text-base bg-light-grey placeholder:text-base placeholder:text-color-dark-grey focus:outline-none focus:border-[#92aadb] focus:border-2 ${inputClassname} ${borderError}`}
          value={values[field]}
          placeholder={placeholder}
          onChange={(e) => {
            if (handleChange) handleChange(field, e.target.value)
            if (setFieldValue) setFieldValue(field, e.target.value)
          }}
          disabled={!!disabled}
          autoComplete={autoComplete}
          autoFocus={!!autoFocus}
        />
      </div>
      {errors[field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {errors[field]}
        </p>
      )}
    </div>
  )
}

export default FormikTextareaGrey
