import axios from '../../services/axios'

const searchCompanies = async (query = '') => {
  const params = {}
  if (query) params.q = query

  const response = await axios.get('/company-search', {
    params
  })
  return response.data
}

export default {
  searchCompanies
}
