import React from 'react'
import configs from 'config/configs'

const SearchInputGrey = ({
  inputClasses,
  inputProps,
  affixComponent,
  iconRight
}) => {
  return (
    <div className={`relative`}>
      <div
        className={`absolute ${iconRight ? 'right-3' : 'left-3'} top-1/2 transform -translate-y-1/2`}
      >
        <img src={configs.icons.searchGrey} className="w-6 h-6" />
      </div>
      <input
        className={`flex-grow flex flex-row items-center w-full h-14 pl-11 ${
          iconRight ? 'pr-20' : 'pr-40'
        }  rounded-lg text-base border-1/2 border-b-placeholder transition-all placeholder:text-base placeholder:text-placeholder-grey ${inputClasses}`}
        {...inputProps}
      />
      {affixComponent && affixComponent}
    </div>
  )
}

export default SearchInputGrey
