// Company types based on
// https://www.toppr.com/guides/principles-and-practices-of-accounting/intro-to-company-accounts/types-of-companies/
const companyTypeOptions = [
  {
    title: 'Sole trader',
    value: 'sole_trader'
  },
  {
    title: 'Partnership',
    value: 'partnership'
  },
  {
    title: 'Limited liability partnership ',
    value: 'limited_liability_partnership'
  },
  {
    title: 'Limited company',
    value: 'limited_company'
  }
]

export default { companyTypeOptions }
