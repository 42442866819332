import * as Yup from 'yup'

export const requestIntegrationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Platform name is required')
      .max(255, 'Max. 255 characters'),

    website: Yup.string().required('Platform URL is required').trim().url()
  })
}
