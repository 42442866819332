import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import configs from 'config/configs'
import integrations from 'config/data/Integrations'
import Button from '../Button'
import LazyBackgroundImage from '../LazyBackgroundImage'

const ConnectionSuccessful = ({ integration, onClick }) => {
  const title = integrations[integration].title
  const logo = `https://bloom-integrations-logos.s3.eu-west-2.amazonaws.com/${integrations[integration].img}`

  const [isLoaderFinished, setIsLoaderFinished] = useState(false)

  const defaultOnClick = () => {
    window.close()
  }

  useEffect(() => {
    const loader = setTimeout(() => {
      setIsLoaderFinished(true)
    }, 6000)

    return () => {
      clearTimeout(loader)
    }
  }, [])

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center`}
    >
      <div className="flex flex-col xs:w-full xs:mx-6 md:w-1/2 lg:max-w-464px p-8 bg-white rounded-md">
        <div className="flex w-full justify-between items-center mb-6">
          <div className="flex items-center">
            <span className="w-3 h-3 mr-3 bg-green-bloom rounded-full"></span>
            <p className="text-sm text-light-grey-300 leading-none">
              {isLoaderFinished
                ? 'Connection Successful'
                : 'Establishing data connection'}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full mb-10 pb-6 border-light-grey border-b">
          <p className="mb-4 font-bold text-xl">{title}</p>
          <p className="text-sm text-placeholder-grey">
            We are currently pulling data from your {title.toLowerCase()}{' '}
            account
          </p>
        </div>

        <div className="flex items-center justify-center h-16 space-x-4 px-9 mb-24">
          <span className="relative flex w-full h-full">
            <LazyBackgroundImage
              src={logo}
              backgroundClass="transform scale-150 bg-contain"
            />
          </span>

          <span className="flex items-center justify-center w-10 max-h-10">
            {!isLoaderFinished ? (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: configs.lottie.loadingConnectionLottie,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                isStopped={isLoaderFinished}
              />
            ) : (
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: configs.lottie.successConnectionLottie,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            )}
          </span>
          <span className="flex w-1/2 items-center">
            <img
              src={configs.icons.logoBloomSmall}
              className="h-16 w-16 object-contain"
            />
          </span>
        </div>
        <div className="flex">
          <img
            src={configs.icons.lockClosedTeal}
            className="w-3 h-3 mr-1"
            style={{ marginTop: '2px' }}
          />
          <span className="text-sm text-placeholder-grey">
            Bloom used 256-bit encryption is a data/file encryption technique to
            ensure your data is always safe
          </span>
        </div>
        <Button
          variant="button-next-pink"
          className="w-full md:w-auto mt-8 mx-10"
          type="button"
          onClick={onClick ? onClick : defaultOnClick}
        >
          Close window
        </Button>
      </div>
    </div>
  )
}

export default ConnectionSuccessful
