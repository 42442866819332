import React from 'react'

const DateSectionTitle = (props) => {
  const { title, subtitle } = props

  return (
    <div className="flex justify-between">
      <div className="flex flex-col mb-2">
        <p className="font-bold text-2xl">{title}</p>
        <p className="mt-2 font-base text-base text-text-color-grey-bloom">
          {subtitle}
        </p>
      </div>
    </div>
  )
}

export default DateSectionTitle
