import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import accountsAndBalancesApi from 'config/api/accountsAndBalances'

const useOverviewHook = () => {
  const [accountMeta, setAccountMeta] = useState()
  const [balances, setBalances] = useState([])
  const [balancesPeriod, setBalancesPeriod] = useState('12m')
  const [inflowsVsOutflows, setInflowsVsOutflows] = useState()
  const [inflowsVsOutflowsPeriod, setInflowsVsOutflowsPeriod] = useState('12m')
  const [breakdownData, setBreakdownData] = useState([])
  const [error, setError] = useState(null)

  const getBalancesOverview = useQuery(
    'accountMetaOverview',
    () => accountsAndBalancesApi.getBalancesOverview(),
    {
      onSuccess: (res) => {
        setAccountMeta(res)
      },
      retry: false
    }
  )

  const getBalancesOverviewByPeriod = useQuery(
    'balancesOverview',
    () => accountsAndBalancesApi.getBalancesOverviewByPeriod(balancesPeriod),
    {
      onSuccess: (res) => {
        if (res.error) {
          setError(res.error)
        } else {
          setBalances(res)
        }
      },
      onError: (error) => {
        setError(error)
      },
      enabled: false,
      retry: false
    }
  )

  const getInfowsVsOutflows = useQuery(
    'inflowsVsOutflowsOverview',
    () => accountsAndBalancesApi.getInflowsVsOutflows(inflowsVsOutflowsPeriod),
    {
      onSuccess: (res) => {
        if (res.error) {
          setError(res.error)
        } else {
          setInflowsVsOutflows(inflowsVsOutflowsFromBalances(res))
          setBreakdownData(breakdownDataFromBalances(res))
        }
      },
      onError: (error) => {
        setError(error)
      },
      enabled: false,
      retry: false
    }
  )

  useEffect(() => {
    getBalancesOverviewByPeriod.refetch()
  }, [balancesPeriod])

  useEffect(() => {
    getInfowsVsOutflows.refetch()
  }, [inflowsVsOutflowsPeriod])

  return {
    error,
    accountMeta,
    balances,
    inflowsVsOutflows,
    breakdownData,
    isLoading:
      getBalancesOverview.isLoading ||
      getBalancesOverviewByPeriod.isLoading ||
      getInfowsVsOutflows.isLoading,
    isFetching:
      getBalancesOverview.isFetching ||
      getBalancesOverviewByPeriod.isFetching ||
      getInfowsVsOutflows.isFetching,
    setBalancesPeriod,
    setInflowsVsOutflowsPeriod
  }
}

const inflowsVsOutflowsFromBalances = (balances) => {
  return balances.map((dataPoint) => {
    const month = new Date(dataPoint.date).toLocaleString('default', {
      month: 'short'
    })
    return {
      id: month,
      month: month,
      date: dataPoint.date,
      inflow: dataPoint.in,
      outflow: Math.abs(dataPoint.out)
    }
  })
}

const breakdownDataFromBalances = (balances) => {
  const highestInflow = balances.reduce((max, point) => {
    return max.in > point.in ? max : point
  })
  const highestOutflow = balances.reduce((max, point) => {
    return Math.abs(max.out) > Math.abs(point.out) ? max : point
  })
  const lowestInflow = balances.reduce((min, point) => {
    return min.in < point.in ? min : point
  }, Number.MAX_VALUE)
  const lowestOutflow = balances.reduce((min, point) => {
    return Math.abs(min.out) < Math.abs(point.out) ? min : point
  }, Number.MAX_VALUE)
  const lastMonth = balances[0]
  return {
    inflow: [
      {
        label: 'Highest',
        value: highestInflow.in,
        date: new Date(highestInflow.date).toLocaleString('default', {
          day: 'numeric',
          month: 'short'
        })
      },
      {
        label: 'Lowest',
        value: lowestInflow.in,
        date: new Date(lowestInflow.date).toLocaleString('default', {
          day: 'numeric',
          month: 'short'
        })
      },
      {
        label: 'Last month',
        value: lastMonth.in,
        date: new Date(lastMonth.date).toLocaleString('default', {
          day: 'numeric',
          month: 'short'
        })
      }
    ],
    outflow: [
      {
        label: 'Highest',
        value: Math.abs(highestOutflow.out),
        date: new Date(highestOutflow.date).toLocaleString('default', {
          day: 'numeric',
          month: 'short'
        })
      },
      {
        label: 'Lowest',
        value: Math.abs(lowestOutflow.out),
        date: new Date(lowestOutflow.date).toLocaleString('default', {
          day: 'numeric',
          month: 'short'
        })
      },
      {
        label: 'Last month',
        value: Math.abs(lastMonth.out),
        date: new Date(lastMonth.date).toLocaleString('default', {
          day: 'numeric',
          month: 'short'
        })
      }
    ]
  }
}

export default useOverviewHook
