import * as Yup from 'yup'
import { useFormik } from 'formik'
import React from 'react'
import Button from 'components/Button'
import FormikCurrencyInputGrey from 'components/FormikCurrencyInputGrey'
import CommonModal from 'modals/components/CommonModal'

const MakeOneOffPaymentFormValidation = Yup.object().shape({
  amount: Yup.number()
    .required('One-off payment amount required')
    .test('Is positive?', 'The amount must be positive', (value) => value > 0)
})

const MakeOneOffPayment = ({
  isOpened,
  close,
  openMakeOneOffPaymentThankYouModal,
  handleSubmit
}) => {
  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    validationSchema: MakeOneOffPaymentFormValidation,
    validateOnChange: true,
    onSubmit: (values, { resetForm }) => {
      if (values) {
        handleSubmit(values)
        resetForm()
        close()
        openMakeOneOffPaymentThankYouModal()
      }
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  return (
    <CommonModal
      title={'Make a one-off payment'}
      isOpened={isOpened}
      close={close}
    >
      <div>
        <div className="flex flex-col w-full">
          <div className="flex w-full mb-6">
            <FormikCurrencyInputGrey
              field="amount"
              id="amount"
              placeholder="How much would you like to pay?"
              shouldValid={true}
              containerClassname={'mb-3'}
              {...formik}
              handleChange={handleChange}
              autoFocus
            />
          </div>
          <div className="flex flex-col">
            <Button
              type="submit"
              variant="button-next"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </CommonModal>
  )
}

export default MakeOneOffPayment
