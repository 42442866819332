import React from 'react'

const PerformanceGradient = ({ percentage, colorClass }) => {
  return (
    <div className="flex flex-row h-6 items-center relative">
      <div className="rounded bg-gradient-to-r from-faded-strawberry-bloom via-orange-bloom to-green-bloom h-1 w-full"></div>
      <div
        className={`flex flex-row items-center absolute bg-white`}
        style={{ width: 100 - percentage + '%', left: percentage + '%' }}
      >
        <div className="flex flex-row items-center relative w-full">
          <div className="w-3 p-0.5 items-center absolute left-0">
            <div className={`rounded-xl bg-${colorClass} h-6`}></div>
          </div>
          <div className="rounded bg-gradient-gray h-1 w-full"></div>
        </div>
      </div>
    </div>
  )
}

export default PerformanceGradient
