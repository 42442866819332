import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import companyDirectorsApi from 'config/api/companyDirectors'
import signatoryDesignationSchema from './schema'

const useSignatoryDesignationHook = ({ onRedirect }) => {
  const [options, setOptions] = useState([])
  const [selectedDirector, setSelectedDirector] = useState(null)

  const getCompanyDirectors = useQuery(
    'signatoryDesignationOptions',
    () => companyDirectorsApi.getCompanyDirectors(),
    {
      enabled: false,
      onSuccess: (res) => {
        const directors = res.directors.filter(
          (director) => director.isDirector
        )
        const directorOptions = directors.map((director) => ({
          value: director.id,
          title: `${director.firstName} ${director.lastName}`
        }))
        setOptions(directorOptions)
        const selected = directors.find((option) => option.authorizedSignatory)
        if (selected) {
          setSelectedDirector({
            value: selected.id,
            label: `${selected.firstName} ${selected.lastName}`
          })
          formik.setValues({
            selected_director: selected.id,
            line1: selected.directorAddress.line1,
            line2: selected.directorAddress.line2,
            flat_no: selected.directorAddress.flatNo,
            postal_code: selected.directorAddress.postalCode
          })
        }
      }
    }
  )

  useEffect(() => {
    getCompanyDirectors.refetch()
  }, [])

  const updateSignatoryDesignation = useMutation(
    companyDirectorsApi.updateSignatoryDesignation,
    {
      onSuccess: () => {
        if (onRedirect) onRedirect()
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  const formik = useFormik({
    initialValues: {
      selected_director: null
    },
    validateOnChange: false,
    validationSchema: signatoryDesignationSchema(),
    onSubmit: (values) => {
      updateSignatoryDesignation.mutate(values)
    }
  })

  const onSubmit = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  return {
    formik,
    onSubmit,
    options,
    selectedDirector,
    loading: getCompanyDirectors.isLoading
  }
}

export default useSignatoryDesignationHook
