import React from 'react'

const CardSelector = ({ title, subtitle, type, selected, onClick }) => {
  const isSelected = selected === type
  return (
    <div
      className={`flex flex-col justify-center items-center bg-white w-full lg:w-1/2 h-28 rounded-lg cursor-pointer text-center ${
        isSelected ? 'border-pink-color border-2' : 'border-b-grey border'
      }`}
      onClick={() => onClick(type)}
    >
      <div className={`${isSelected && 'text-pink-color'} text-3xl font-black`}>
        {title}
      </div>
      {subtitle && (
        <div
          className={`${isSelected && 'text-pink-color'} text-xl  font-black"`}
        >
          {subtitle}
        </div>
      )}
    </div>
  )
}

export default CardSelector
