import * as actionTypes from '../../types/PersistRegister'

export const updateBusinessType = (value) => {
  return {
    type: actionTypes.UPDATE_PERSIST_DATA,
    payload: {
      value
    }
  }
}
export const updateEmbeddedPartner = (value) => {
  return {
    type: actionTypes.UPDATE_EMBEDDED_PARTNER_DATA,
    payload: {
      value
    }
  }
}

export const resetPersistState = () => {
  return {
    type: actionTypes.RESET_PERSIST_DATA
  }
}
