import FormikPasswordInput from 'components/FormikPasswordInput'
import configs from 'config/configs'
import { ROOT_URL } from 'config/configs/constant'
import FormikInputCheckbox from '../FormikInputCheckbox'
import FormikInputTextGrey from '../FormikInputTextGrey'
import FormikPhoneInputGrey from '../FormikPhoneInputGrey'
import PasswordStrengthBar from '../PasswordStrengthBar'

const FormAndTerms = ({
  formik,
  shouldShowError,
  passwordBar,
  handleChange
}) => {
  return (
    <div className="rounded-2xl bg-white">
      <div className="flex flex-col w-full mt-4 3xl:mt-8 p-5">
        <div className="flex items-start space-x-3 mb-3">
          <FormikInputTextGrey
            field="firstName"
            id="firstName"
            placeholder="First name"
            shouldValid={shouldShowError}
            autoComplete={'given-name'}
            backgroundColor="bg-white"
            iconPostfixSrc={configs.icons.user}
            {...formik}
            handleChange={handleChange}
            autoFocus
          />

          <FormikInputTextGrey
            field="lastName"
            id="lastName"
            placeholder="Last name"
            shouldValid={shouldShowError}
            autoComplete={'family-name'}
            backgroundColor="bg-white"
            iconPostfixSrc={configs.icons.user}
            {...formik}
            handleChange={handleChange}
          />
        </div>
        <FormikPhoneInputGrey
          field="phone"
          id="phone"
          placeholder="Phone"
          backgroundColor={'bg-white'}
          autoComplete={'tel-local'}
          shouldValid={shouldShowError}
          enableLongNumbers={true}
          {...formik}
          containerClassname={'mb-3'}
          iconSrc={configs.icons.phone}
        />

        <FormikInputTextGrey
          field="email"
          id="email"
          placeholder="Work email"
          shouldValid={shouldShowError}
          autoComplete={'email'}
          containerClassname={'mb-3'}
          backgroundColor="bg-white"
          iconPostfixSrc={configs.icons.email}
          {...formik}
          handleChange={handleChange}
        />

        <div className="relative flex flex-col mb-8">
          <FormikPasswordInput
            field="password"
            id="password"
            type="password"
            autoComplete={'new-password'}
            placeholder="Password (minimum 8 characters)"
            shouldValid={shouldShowError}
            backgroundColor="bg-white"
            containerClassname={'mb-3'}
            iconPostfixSrc={configs.icons.lockClosed}
            {...formik}
            handleChange={handleChange}
          />
          <PasswordStrengthBar passwordBar={passwordBar} />
        </div>

        <div className="flex flex-col px-4 py-2 space-y-4">
          <FormikInputCheckbox
            field="privacyPolicy"
            id="privacyPolicy"
            shouldValid={shouldShowError}
            {...formik}
            handleChange={handleChange}
            content={
              <div>
                <span className="font-normal text-dark-grey">I agree to </span>
                <a href={ROOT_URL + '/privacy-policy'} target="_blank">
                  <span className="text-pink-color border-pink-color border-b hover:opacity-80 transition-opacity">
                    Bloom’s Privacy Policy
                  </span>
                </a>
              </div>
            }
          />
          <FormikInputCheckbox
            field="keepInformed"
            id="keepInformed"
            {...formik}
            handleChange={handleChange}
            content={
              <span className="font-normal text-dark-grey">
                Keep me informed
              </span>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default FormAndTerms
