import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import accountsAndBalancesApi from 'config/api/accountsAndBalances'
import { getErrorMessage } from 'utils/getErrorMessage'

const useTransactionsHook = () => {
  const [account, setAccount] = useState('')
  const [category, setCategory] = useState('')
  const [search, setSearch] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [totalTransactions, setTotalTransactions] = useState('')
  const lastDate = useRef('')

  const getTransactions = useQuery(
    'getTransactions',
    () =>
      accountsAndBalancesApi.getTransactions(
        account,
        encodeURIComponent(category),
        search,
        lastDate.current
      ),
    {
      onSuccess: (res) => {
        setTransactions([...transactions, ...res.data.transactions])
        setHasMore(res.hasMore)
        setTotalTransactions(res.meta.totalCount)
        lastDate.current = res.lastDate || ''
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
      },
      enabled: false,
      cacheTime: 0
    }
  )

  const loadMore = () => {
    getTransactions.refetch()
  }

  useEffect(() => {
    setTransactions([])
    lastDate.current = ''
    getTransactions.refetch()
  }, [account, category, search])

  return {
    transactions,
    setAccount,
    setCategory,
    setSearch,
    loadMore,
    hasMore,
    totalTransactions,
    error: getTransactions.error,
    isLoading: getTransactions.isLoading,
    isRefetching: getTransactions.isFetching
  }
}

export default useTransactionsHook
