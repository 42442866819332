import { useQuery } from 'react-query'
import salesApi from 'config/api/sales'
import formatCurrency from 'utils/formatCurrency'
import { formatNumberAsPercentageString } from 'utils/formatNumberUtil'

const percentageChange = (v1, v2) => {
  if (!v1 || !v2) {
    return null
  }
  return formatNumberAsPercentageString((v1 - v2) / v2)
}

const prevMonth = (date) => {
  var res = new Date(date)

  if (res.getMonth() > 0) {
    res.setMonth(res.getMonth() - 1, 1)
  } else {
    res.setYear(res.getFullYear() - 1)
    res.setMonth(11, 1)
  }

  return res
}

const processData = (data) => {
  if (!data) {
    return {}
  }

  const currentDate = new Date()
  const lastMonthDate = prevMonth(currentDate)
  const beforeLastMonthDate = prevMonth(lastMonthDate)

  const currentDataPoint = data.find(
    ({ year, month }) =>
      year === lastMonthDate.getFullYear() &&
      month === lastMonthDate.getMonth() + 1
  )
  const previousDataPoint = data.find(
    ({ year, month }) =>
      year === beforeLastMonthDate.getFullYear() &&
      month === beforeLastMonthDate.getMonth() + 1
  )

  const oneYearAgo = new Date(currentDate)
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
  const thisYearPoints = data.filter(
    ({ year, month }) => new Date(year, month) > oneYearAgo
  )
  const previousYearPoints = data.filter(
    ({ year, month }) => new Date(year, month) < oneYearAgo
  )

  return {
    saleTabs: [
      {
        label: 'Sales',
        value: currentDataPoint?.value
          ? formatCurrency(currentDataPoint.value, 2)
          : 'N/A',
        compared_to_last_year: percentageChange(
          currentDataPoint?.value,
          previousDataPoint?.value
        )
      },
      {
        label: 'Marketing spend',
        value: currentDataPoint?.market_spending
          ? formatCurrency(currentDataPoint.market_spending, 2)
          : 'N/A',
        compared_to_last_year: percentageChange(
          currentDataPoint?.market_spending,
          previousDataPoint?.market_spending
        )
      },
      {
        label: 'New customers',
        value: currentDataPoint?.new_customers,
        compared_to_last_year: percentageChange(
          currentDataPoint?.new_customers,
          previousDataPoint?.new_customers
        )
      },
      {
        label: 'Transactions',
        value: currentDataPoint?.count ? currentDataPoint.count : 'N/A',
        compared_to_last_year: percentageChange(
          currentDataPoint?.count,
          previousDataPoint?.count
        )
      }
    ],
    chart: [
      {
        id: 'this_year',
        data: thisYearPoints
      },
      {
        id: 'last_year',
        data: previousYearPoints
      }
    ]
  }
}

const useSalesHook = () => {
  const getSalesQuery = useQuery('getSales', salesApi.getSales, {
    retry: false
  })

  return {
    salesData: processData(getSalesQuery.data),
    isLoading: getSalesQuery.isLoading
  }
}

export default useSalesHook
