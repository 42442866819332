import { useSelector } from 'react-redux'
import useCountdown from 'components/CountdownTimer/hook'
import configs from 'config/configs'
import {
  INTEGRATION_CONNECTED,
  INTEGRATION_CRITICAL,
  INTEGRATION_WARNING
} from 'enums/Integrations'

const useSidebarHook = () => {
  const dataOnboarding = useSelector((state) => state.dataOnboarding)
  const { offerExpiration } = dataOnboarding
  const { expired } = useCountdown(offerExpiration?.offerExpiresAt)

  const handleTick = (active, isDone, status) => {
    const isCritical = status === INTEGRATION_CRITICAL
    const isWarning = status === INTEGRATION_WARNING
    const isConnected = isDone || status === INTEGRATION_CONNECTED
    return isCritical
      ? configs.icons.circleCrossRed
      : isWarning
        ? configs.icons.circleCheckOrange
        : active && isConnected
          ? configs.images.doneCheckActive
          : active && !isConnected
            ? configs.images.emptyCheckActive
            : !active && isConnected
              ? configs.images.doneCheck
              : configs.images.emptyCheck
  }

  return {
    handleTick,
    isOfferExpired: !!expired,
    offerExpiresAt: offerExpiration?.offerExpiresAt
  }
}

export default useSidebarHook
