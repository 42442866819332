import React from 'react'
import formatCurrency from 'utils/formatCurrency'

const Accordion = ({ data }) => {
  return data.map((row, idx) => (
    <tr
      key={idx}
      className={`shadow-card-header-white text-header-table-gray font-normal`}
    >
      <td className="py-4 pr-6">
        {new Date(row.date).toLocaleDateString('en-UK')}
      </td>
      <td className="py-4 pr-6">{row.description}</td>
      <td className="py-4 pr-6">{row.category}</td>
      <td className="py-4 pr-6">{row.type}</td>
      <td className="py-4 pr-12 text-right">{formatCurrency(row.amount, 2)}</td>
    </tr>
  ))
}

export default Accordion
