import * as actionTypes from '../../types/App'

const initialState = {
  isAppReady: false,
  sidebarExpanded: false,
  headerExpanded: false,
  connections: []
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
    case actionTypes.INITIALIZE_APP_SUCCESS:
      return {
        ...state,
        isAppReady: true
      }
    case actionTypes.EXPAND_SIDEBAR:
      return { ...state, sidebarExpanded: true }
    case actionTypes.CLOSE_SIDEBAR:
      return { ...state, sidebarExpanded: false }
    case actionTypes.EXPAND_HEADER:
      return { ...state, headerExpanded: true }
    case actionTypes.CLOSE_HEADER:
      return { ...state, headerExpanded: false }
    case actionTypes.SET_ONBOARDING_CONNECTIONS:
      return { ...state, connections: action.payload }
    default: {
      return state
    }
  }
}
export default appReducer
