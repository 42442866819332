import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fakeData from 'config/data'
import {
  errorSavingCompany,
  setIsNewCompany,
  updateNewCompanyForm
} from 'redux/actions/Register'
import selectedCompanySchema from './schema'

const useNewCompanyFormHook = ({ handleCreateCompanyInfo }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const dataRegister = useSelector((state) => state.dataRegister)
  const dispatch = useDispatch()
  const { newCompanyForm, investmentPlan, saveCompanyError } = dataRegister
  const countryOptions = fakeData.countryData.countries.map((country) => {
    country.value = country.code
    country.title = country.name
    return country
  })
  //form
  const formik = useFormik({
    initialValues: {
      ...newCompanyForm
    },
    validationSchema: selectedCompanySchema(),
    onSubmit: () => {
      dispatch(errorSavingCompany(false))
      handleCreateCompanyInfo({ ...newCompanyForm, investmentPlan })
      dispatch(setIsNewCompany(true))
    }
  })

  useEffect(() => {
    if (saveCompanyError === true) {
      formik.resetForm()
    }
  }, [saveCompanyError])

  const handleChange = (field, value) => {
    dispatch(updateNewCompanyForm(field, value))
    formik.setFieldValue(field, value)
  }

  const onSubmitCompanyForm = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  return {
    shouldShowError,
    onSubmitCompanyForm,
    formik,
    saveCompanyError,
    handleChange,
    countryOptions
  }
}

export default useNewCompanyFormHook
