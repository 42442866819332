import { useHistory } from 'react-router-dom'
import useBankDetailsHook from 'components/BankDetails/hook'
import Button from 'components/Button'
import configs from 'config/configs'
import AuthorisationLayout from '../../../../layouts/AuthorisationLayout'

const OnboardingTransferFunds = () => {
  const history = useHistory()
  const { options, isLoading } = useBankDetailsHook({})
  const selectedAccount = options?.find((o) => o.selected)

  return (
    <AuthorisationLayout hasFooter={false}>
      <div className="flex justify-center mx-auto min-h-content-fit-desktop">
        <div className="flex flex-col justify-center md:flex-row gap-y-4 py-8 mx-8">
          <div className="flex justify-center items-center w-full mr-8 xl:max-w-5xl">
            <img
              src={configs.images.onboardingSuccess}
              className={`max-w-xs lg:max-w-xl`}
            />
          </div>

          <div className="flex flex-col justify-center items-start text-center md:text-left md:mt-0 w-full gap-y-6">
            <div className="font-black text-5xl">You’re all set!</div>
            <div className="font-normal text-xl">
              We will send funding to the bank account listed below.
            </div>
            <div className="bg-white w-full md:w-2/3 p-2 rounded-lg text-sm mb-2">
              <div className="flex justify-between mb-1">
                {isLoading ? (
                  <div className="flex w-full h-5 animate-pulse bg-gray-200 rounded" />
                ) : (
                  <>
                    <div className="font-bold">Account number:</div>
                    <div>{selectedAccount?.accountNumber}</div>
                  </>
                )}
              </div>
              <div className="flex justify-between mb-1">
                {isLoading ? (
                  <div className="flex w-full h-5 animate-pulse bg-gray-200 rounded" />
                ) : (
                  <>
                    <div className="font-bold">Account holder:</div>
                    <div>{selectedAccount?.accountHolder}</div>
                  </>
                )}
              </div>
              <div className="flex justify-between mb-1">
                {isLoading ? (
                  <div className="flex w-full h-5 animate-pulse bg-gray-200 rounded" />
                ) : (
                  <>
                    <div className="font-bold">Sort code:</div>
                    <div>{selectedAccount?.sortCode}</div>
                  </>
                )}
              </div>
              <div className="text-xs mt-2">
                * Please note exact transfer times depend on the date and time
                the transfer is initiated and your banking organisation’s
                processing times.
              </div>
            </div>
            <Button
              variant="button-next"
              affixIcon={configs.icons.arrowForward}
              children={'Go to Bloom Portal'}
              className="font-medium"
              type="button"
              onClick={() => history.go(0)}
            />
          </div>
        </div>
      </div>
    </AuthorisationLayout>
  )
}

export default OnboardingTransferFunds
