import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ACCOUNTS_BALANCES_ROUTES } from 'config/configs/routes'
import AccountsBalancesCategories from 'pages/app/AccountsBalances/Categories'
import AccountsBalancesInflows from 'pages/app/AccountsBalances/Inflows'
import AccountsBalancesOutflows from 'pages/app/AccountsBalances/Outflows'
import AccountsBalancesOverview from 'pages/app/AccountsBalances/Overview'
import AccountsBalancesTransactions from 'pages/app/AccountsBalances/Transactions'

const { OVERVIEW, INFLOWS, OUTFLOWS, CATEGORIES, TRANSACTIONS } =
  ACCOUNTS_BALANCES_ROUTES

const AccountsBalancesRouter = () => {
  return (
    <Switch>
      <Route exact path={OVERVIEW} component={AccountsBalancesOverview} />
      <Route exact path={INFLOWS} component={AccountsBalancesInflows} />
      <Route exact path={OUTFLOWS} component={AccountsBalancesOutflows} />
      <Route exact path={CATEGORIES} component={AccountsBalancesCategories} />
      <Route
        exact
        path={TRANSACTIONS}
        component={AccountsBalancesTransactions}
      />
      <Redirect to={OVERVIEW} />
    </Switch>
  )
}

export default AccountsBalancesRouter
