import apiConfig from './api'
import colors from './colors'
import icons from './icons'
import images from './images'
import lottie from './lottie'

export default {
  colors,
  icons,
  images,
  lottie,
  apiConfig
}
