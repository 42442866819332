import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { REGISTRATION_ROUTES } from 'config/configs/routes'
import { useAnalytics } from 'hooks/useAnalytics'
import CompanyBusinessType from 'pages/authorisation/CompanyBusinessType'
import CompanyRegistration from 'pages/authorisation/CompanyRegistration/CompanyRegistration'

const { COMPANY_REGISTRATION, COMPANY_BUSINESS_TYPE } = REGISTRATION_ROUTES

const CompanyAuthRouter = () => {
  const analytics = useAnalytics()
  const location = useLocation()

  const persistedDataRegister = useSelector(
    (state) => state.persistedDataRegister
  )
  const companyBusinessTypeData = persistedDataRegister?.companyBusinessType

  useEffect(() => {
    analytics.page(location)
  }, [location.pathname])

  return (
    <Switch>
      <Route
        exact
        path={
          companyBusinessTypeData ? COMPANY_REGISTRATION : COMPANY_BUSINESS_TYPE
        }
        component={
          companyBusinessTypeData ? CompanyRegistration : CompanyBusinessType
        }
      />
      <Redirect
        to={
          companyBusinessTypeData ? COMPANY_REGISTRATION : COMPANY_BUSINESS_TYPE
        }
      />
    </Switch>
  )
}
export default CompanyAuthRouter
