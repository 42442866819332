import React from 'react'
import PageTitle from 'components/PageTitle'
import NewCompanyForm from './NewCompanyForm'
import SelectedCompanyForm from './SelectedCompanyForm'

const CompanyInformationStep3 = ({
  StepTitle,
  StepSubtitle,
  companySearchResult,
  onCompanyChange,
  setCompanyName,
  selectCompany,
  selectedCompany,
  onBackCompanyStep,
  setSelectedCompany,
  handleCreateCompanyInfo,
  companyBusinessType,
  steps
}) => {
  return (
    <div className="flex flex-col w-full lg:h-full">
      <PageTitle
        stepper={steps}
        isSubtitleTop={true}
        title={<StepTitle companyBusinessType={companyBusinessType} />}
        subtitle={<StepSubtitle companyBusinessType={companyBusinessType} />}
      />

      {selectedCompany && (
        <SelectedCompanyForm
          selectCompany={selectCompany}
          companySearchResult={companySearchResult}
          onCompanyChange={onCompanyChange}
          setCompanyName={setCompanyName}
          selectedCompany={selectedCompany}
          onBackCompanyStep={onBackCompanyStep}
          setSelectedCompany={setSelectedCompany}
          handleCreateCompanyInfo={handleCreateCompanyInfo}
          companyBusinessType={companyBusinessType}
        />
      )}
      {!selectedCompany && (
        <NewCompanyForm
          onBackCompanyStep={onBackCompanyStep}
          handleCreateCompanyInfo={handleCreateCompanyInfo}
        />
      )}
    </div>
  )
}

export default CompanyInformationStep3
