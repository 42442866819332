import { batch } from 'react-redux'
import api from 'config/api/onboarding'
import { setOnboardingConnections } from 'redux/actions/App'
import {
  setIntegrationStatus,
  updateDocusignSigningStatus,
  updateGocardlessStatus,
  updateOnboardingConfig,
  updatePercentage
} from 'redux/actions/Onboarding'
import { store } from 'redux/store/index'
import consumer from './consumer'
import { INTEGRATIONS_CHANNEL } from './names'

consumer.subscriptions.create(INTEGRATIONS_CHANNEL, {
  received(data) {
    // Only call the API if we have an access token
    // For some reason, without this check, we would make an unauthenticated call and
    // risk getting logged out of the app.
    if (store.getState().dataAuth.accessToken) {
      // Refetching the steps to update the percentage and step status
      api.getOnboardingConfig().then((res) => {
        const steps = store.getState().dataOnboarding.config.map((step) => ({
          ...step,
          isDone: res.steps.find((el) => el.kind === step.type).isDone,
          status: res.steps.find((el) => el.kind === step.type).status
        }))

        batch(() => {
          store.dispatch(updateOnboardingConfig(steps))
          store.dispatch(updatePercentage(res.percentage))
        })
      })

      if (data.status) {
        api
          .getOnboardingConnections()
          .then((res) => store.dispatch(setOnboardingConnections(res)))
      }

      // Update the integration card status
      if (data.integration === 'docusign') {
        store.dispatch(updateDocusignSigningStatus(data.connected))
      } else if (data.integration === 'gocardless') {
        store.dispatch(updateGocardlessStatus(data.connected))
      } else {
        store.dispatch(setIntegrationStatus(data.integration))
      }
    }
  }
})
