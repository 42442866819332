import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import LoadingScreen from '../components/LoadingScreen'
import AppRouter from './AppRouter/AppRouter'
import AuthRouter from './AuthRouter/AuthRouter'
import useAuth from './hooks/useAuth'

const RootRouter = () => {
  const { isLoggedIn } = useSelector((state) => state.dataAuth)
  const { isAuthLoading, handleGetRefreshToken } = useAuth()

  useEffect(() => {
    handleGetRefreshToken()
  }, [])

  if (isAuthLoading) {
    return <LoadingScreen />
  }

  if (isLoggedIn) {
    return <AppRouter />
  }
  return <AuthRouter />
}

export default RootRouter
