import React from 'react'
import BankAccountSection from 'components/BankDetails/BankAccountSection'
import configs from 'config/configs'
import Button from '../Button'
import useBankDetailsHook from './hook'

const BankDetails = ({ onBack, onNext }) => {
  const {
    formik,
    handleChange,
    onBankDetailsNext,
    options,
    textButton,
    isLoading
  } = useBankDetailsHook({
    onNext
  })

  return (
    <div className="pt-6">
      <p className="font-black text-2xl md:text-4xl">
        Select preferred bank account
      </p>
      <p className="mt-3 text-xl">
        This bank account will be used to deposit your funds and for all direct
        debits.
      </p>

      <div className="flex flex-col space-y-4 rounded-2xl pt-2 w-full lg:pt-6">
        {isLoading ? (
          <div className="flex w-full h-36 animate-pulse bg-gray-200 rounded" />
        ) : (
          options.map((option, idx) => (
            <BankAccountSection
              key={idx}
              bankName={option.bankName}
              sortCode={option.sortCode}
              accountName={option.accountName}
              accountNumber={option.accountNumber}
              accountType={option.accountType}
              accountId={option.id}
              isSelected={option.selected}
              onClick={(accountId) => handleChange('id', accountId)}
            />
          ))
        )}
      </div>

      <div className="flex lex-row justify-between w-full mt-35px">
        <Button variant="button-back-grey" onClick={onBack} children="Back" />
        <Button
          variant="button-next-pink"
          affixIcon={configs.icons.arrowForward}
          onClick={onBankDetailsNext}
          type="submit"
          children={textButton}
          disabled={!formik.isValid}
        />
      </div>
    </div>
  )
}

export default BankDetails
