import { ResponsiveLine } from '@nivo/line'
import React from 'react'
import configs from 'config/configs'
import useWindowSize from '../../hooks/useWindowSize'

const tabValue = {
  Sales: 'value',
  Transactions: 'count',
  'New customers': 'new_customers',
  'Marketing spend': 'market_spending'
}

const SalesChart = ({ data, tabActive }) => {
  if (!data) {
    return null
  }

  const mobileSize = 640
  const { width } = useWindowSize()
  var hasData = false

  const xyData = data.map((graphData) => {
    return {
      ...graphData,
      data: graphData.data.map(({ month, [tabValue[tabActive]]: value }) => {
        if (value || value === 0) {
          hasData = true
        }
        return { x: month, y: value }
      })
    }
  })

  if (!hasData) {
    return <div className="mt-6">No {tabActive} data.</div>
  }

  return (
    <div className="mt-6 h-96 bg-body-grey">
      <ResponsiveLine
        data={xyData}
        colors={[configs.colors.indingoBloom, configs.colors.pinkBloom]}
        curve="catmullRom"
        enablePoints={false}
        enableGridX={false}
        yFormat=" >-.2f"
        margin={{ top: 32, right: 32, bottom: 56, left: 56 }}
        animate={true}
        axisLeft={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          format: (value) =>
            value > 1000 ? `${(Math.abs(value) / 1000).toFixed(2)}K` : value
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 10,
          tickRotation: width < mobileSize ? 90 : 0,
          format: (value) =>
            Intl.DateTimeFormat('en', { month: 'long' }).format(
              new Date(2020, value - 1, 1)
            )
        }}
        yScale={{ type: 'linear', stacked: false, min: 0 }}
        useMesh={true}
      />
      <div className="flex flex-row space-x-3 md:space-x-6 font-normal text-base mt-2 md:mt-0">
        <div className="flex flex-row space-x-2">
          <div>
            <img src={configs.icons.circleCheckPurple} />
          </div>
          <span>This year</span>
        </div>
        <div className="flex flex-row space-x-2">
          <div>
            <img src={configs.icons.circleCheckPink} />
          </div>
          <span>Last year</span>
        </div>
      </div>
    </div>
  )
}

export default SalesChart
