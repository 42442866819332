import React, { useMemo } from 'react'
import configs from 'config/configs'
import useRouterHook from 'routes/hooks/useRouter'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

const Header = ({
  screenName,
  shouldShowOnboardingSidebar,
  shouldShowSaasDashboardSidebar,
  shouldShowEcommerceDashboardSidebar,
  setCurrentStep,
  backgroundColor
}) => {
  const { getSubdomain } = useRouterHook()
  const subdomain = getSubdomain()

  const subdomainIcon = useMemo(() => {
    switch (subdomain) {
      case 'gocardless':
        return configs.icons.logoBloomAndGoCardless
      case 'seko':
        return configs.icons.logoBloomAndSeko
      case 'xapix':
        return configs.icons.logoBloomAndXapix
      default:
        return null
    }
  }, [subdomain])

  return (
    <React.Fragment>
      <HeaderMobile
        screenName={screenName}
        shouldShowOnboardingSidebar={shouldShowOnboardingSidebar}
        shouldShowSaasDashboardSidebar={shouldShowSaasDashboardSidebar}
        shouldShowEcommerceDashboardSidebar={
          shouldShowEcommerceDashboardSidebar
        }
        setCurrentStep={setCurrentStep}
        bgColor={backgroundColor}
        subdomainIcon={subdomainIcon}
      ></HeaderMobile>
      <HeaderDesktop
        screenName={screenName}
        backgroundColor={backgroundColor}
        subdomainIcon={subdomainIcon}
      ></HeaderDesktop>
    </React.Fragment>
  )
}

export default Header
