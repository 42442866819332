export const RESET_REGISTER_STATE = 'RESET_REGISTER_STATE'
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP'
export const SAVE_COMPANY_ERROR = 'SAVE_COMPANY_ERROR'
export const SELECT_COMPANY = 'SELECT_COMPANY'
export const REMOVE_SELECT_COMPANY = 'REMOVE_SELECT_COMPANY'
export const UPDATE_SELECT_COMPANY_FORM = 'UPDATE_SELECT_COMPANY_FORM'
export const UPDATE_NEW_COMPANY_FORM = 'UPDATE_NEW_COMPANY_FORM'
export const COMPLETED_STEP_1 = 'COMPLETED_STEP_1'
export const UPDATE_PERSONAL_INFO_VALUES = 'UPDATE_PERSONAL_INFO_VALUES'
export const SET_IS_NEW_COMPANY = 'SET_IS_NEW_COMPANY'
