import React from 'react'
import LoginForm from 'components/LoginForm'
import { UnauthenticatedLayout } from '../../../layouts'

const Login = ({ name }) => {
  return (
    <UnauthenticatedLayout
      formContent={<LoginForm />}
      screenName={name}
      hasHeader={true}
      hasFooter={false}
    />
  )
}

export default Login
