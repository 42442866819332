import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import benchmarksApi from 'config/api/benchmarks'
import { getErrorMessage } from 'utils/getErrorMessage'

const useBenchmarksHook = () => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [benchmarksData, setBenchmarksData] = useState({
    overallPerformance: [],
    growth: []
  })

  const descriptions = {
    overallPerformance:
      'Understand how your business is performing and benchmark your most important growth metrics against the competition.',
    growth:
      'Understand how your business is performing and benchmark your most important growth metrics against the competition.'
  }

  const benchmarksQuery = useQuery(
    'benchmarks',
    () => benchmarksApi.getBenchmarksData(dateRange[0]),
    {
      enabled: false,
      onSuccess: (res) => {
        setBenchmarksData(res)
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
      }
    }
  )

  const handleChangeDate = (item) => {
    setDateRange([item.selection])
  }

  useEffect(() => {
    benchmarksQuery.refetch()
  }, [dateRange])

  return {
    benchmarksData,
    descriptions,
    dateRange,
    handleChangeDate,
    error: benchmarksQuery.error,
    isLoading: benchmarksQuery.isLoading
  }
}

export default useBenchmarksHook
