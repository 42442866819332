import * as Yup from 'yup'
import moment from 'moment'

// Add custom `optional` method to object types
// Source: https://objectpartners.com/2020/06/04/validating-optional-objects-with-yup/
Yup.addMethod(
  Yup.object,
  'optional',
  function (isOptional = true, defaultValue = undefined) {
    return this.transform(function (value) {
      if (!isOptional) return value
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === '')
        )
      ) {
        return value
      }

      return defaultValue
    }).default(defaultValue)
  }
)

export const companyDirectorsSchema = () => {
  return Yup.object().shape({
    directors: Yup.array().of(
      Yup.lazy((obj) => {
        if (!!obj.destroyed) {
          return Yup.object().shape()
        }

        return Yup.object().shape({
          first_name: Yup.string()
            .trim()
            .max(255, 'First Name cannot be longer than 255 characters')
            .required('First Name is required'),

          last_name: Yup.string()
            .trim()
            .max(255, 'Last Name cannot be longer than 255 characters')
            .required('Last Name is required'),

          date_of_birth: Yup.string()
            .required('Date of Birth is required')
            .test('is-date-value-valid', 'Invalid date', (value) => {
              // Just to be sure that data from component is correct
              return moment(value, 'DD/MM/YYYY').isValid()
            })
            .test(
              'is-director-an-adult',
              'A director should be older than 18 years',
              (value) => {
                return moment(value, 'DD/MM/YYYY').isBefore(
                  moment().subtract(18, 'years'),
                  'day'
                )
              }
            )
            .test(
              'is-future-date',
              'Please pick a date in the past',
              (value) => {
                return moment(value, 'DD/MM/YYYY').isBefore(
                  moment(new Date()),
                  'day'
                )
              }
            )
        })
      })
    ),
    parent_company: Yup.object({
      parent_company_name: Yup.string()
        .trim()
        .max(255, 'Company name cannot be longer than 255 characters')
        .required('Company name is required'),

      parent_company_number: Yup.string()
        .trim()
        .max(255, 'Company number cannot be longer than 255 characters')
        .required('Company number is required')
    })
      .nullable()
      .optional()
      .default(null)
  })
}
