import React from 'react'
import {
  INTEGRATION_CONNECTED,
  INTEGRATION_CRITICAL,
  INTEGRATION_INACTIVE,
  INTEGRATION_WARNING
} from 'enums/Integrations'

const Stepper = ({ steps, completedClass, uncompletedClass }) => {
  // Add the statuses in the order in which they'll appear in the stepper
  const statuses = {
    true: 0,
    [INTEGRATION_CONNECTED]: 0,
    [INTEGRATION_WARNING]: 1,
    [INTEGRATION_CRITICAL]: 2,
    [INTEGRATION_INACTIVE]: 3,
    false: 3
  }

  const countDone = steps
    .sort((left, right) => statuses[left] - statuses[right])
    .filter((el) => el !== false).length

  return steps?.map((s, index) => {
    const isLast = index === steps.length - 1

    let statusClass = s ? completedClass : uncompletedClass
    if (typeof s !== 'boolean') {
      switch (s) {
        case INTEGRATION_INACTIVE:
          statusClass = uncompletedClass
          break
        case INTEGRATION_CONNECTED:
          statusClass = completedClass
          break
        case INTEGRATION_WARNING:
          statusClass = 'bg-orange-warning'
          break
        case INTEGRATION_CRITICAL:
        default:
          statusClass = 'bg-red-600'
          break
      }
    }

    return (
      <div
        key={index}
        className={`flex-1 h-1 rounded-sm my-27px
              ${countDone <= index ? uncompletedClass : statusClass} 
              ${!isLast && 'mr-2.5'}`}
      />
    )
  })
}

export default Stepper
