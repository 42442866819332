import configs from 'config/configs'
import { ROOT_ROUTES } from 'config/configs/routes'

const { DASHBOARD, CONNECTIONS, DOCUMENTS, SETTINGS } = ROOT_ROUTES

const sidebarConfig = [
  {
    name: 'Dashboard',
    to: DASHBOARD,
    routeMatch: DASHBOARD,
    icon: configs.icons.home
  },
  {
    name: 'Connections',
    to: CONNECTIONS,
    routeMatch: CONNECTIONS,
    icon: configs.icons.connections
  },
  {
    name: 'Documents',
    to: DOCUMENTS,
    routeMatch: DOCUMENTS,
    icon: configs.icons.document
  },
  {
    name: 'Settings',
    to: SETTINGS,
    routeMatch: SETTINGS,
    icon: configs.icons.settings
  }
]

export default sidebarConfig
