import { ErrorMessage, Field, FormikProvider } from 'formik'
import React from 'react'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import CommonModal from 'modals/components/CommonModal'
import { useRequestIntegrationFormHook } from './hook'

export function RequestIntegrationModal({ isOpened, close }) {
  const { formik, submitting } = useRequestIntegrationFormHook({ close })

  const handleSubmit = () => {
    formik.submitForm()
  }

  return (
    <CommonModal
      isOpened={isOpened}
      close={close}
      customAddAccountModal
      noFooter
    >
      <div className="flex flex-col mt-4">
        <div className="border rounded-lg p-5">
          <h3 className="font-bold text-xl mb-3">Request integration</h3>
          <p className="mb-3">
            Tell us more about the sales account you would like us to add.
          </p>
          <div className="min-h-full relative">
            {submitting && (
              <div className="absolute flex justify-center items-center w-full h-full bg-white/75">
                <Spinner color="black" />
              </div>
            )}
            <FormikProvider value={formik}>
              <Field name="name">
                {({ field, meta }) => (
                  <div className="mb-3">
                    <input
                      id="name"
                      placeholder="Name of sales platform"
                      type="text"
                      {...field}
                      className={`flex-grow font-normal flex flex-row items-center rounded-lg
                                    bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                                    ${meta.touched && meta.error ? 'border-b-pink' : 'border-b-placeholder'}`}
                    />
                    <ErrorMessage
                      name="name"
                      component="p"
                      className="text-b-pink text-sm mt-2 font-normal pl-4"
                    />
                  </div>
                )}
              </Field>
              <Field name="website">
                {({ field, meta }) => (
                  <div className="mb-3">
                    <input
                      id="website"
                      type="text"
                      placeholder="Website URL"
                      {...field}
                      className={`flex-grow font-normal flex flex-row items-center rounded-lg
                                    bg-white p-4 focus:outline-none w-full h-50px focus:border-b-dark border
                                    ${meta.touched && meta.error ? 'border-b-pink' : 'border-b-placeholder'}`}
                    />
                    <ErrorMessage
                      name="website"
                      component="p"
                      className="text-b-pink text-sm mt-2 font-normal pl-4"
                    />
                  </div>
                )}
              </Field>
            </FormikProvider>
          </div>
          <div className="flex justify-end bg-white mt-3">
            <Button
              onClick={handleSubmit}
              variant="button-next-pink"
              children={'Submit'}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </CommonModal>
  )
}
