import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import useAuthHook from 'components/Auth/hook'
import icons from 'config/configs/icons'
import SidebarDashboardSaaS from 'layouts/components/SidebarDashboardSaaS'
import { closeHeader } from 'redux/actions/App'
import SidebarDashboardEcommerce from '../../SidebarDashboardEcommerce'
import SidebarOnboarding from '../../SidebarOnboarding'

const HeaderMobileExpanded = ({
  user,
  screenName,
  setCurrentStep,
  toggleMobileExpand,
  shouldShowOnboardingSidebar,
  shouldShowSaasDashboardSidebar,
  shouldShowEcommerceDashboardSidebar
}) => {
  const { handleLogout, logoutLoading } = useAuthHook()
  const dispatch = useDispatch()

  const handleClickLogout = () => {
    handleLogout()
    dispatch(closeHeader())
  }

  return (
    <div className="flex flex-col bg-b-lite px-6 fixed overflow-y-auto top-0 left-0 h-screen w-screen z-50 animate-sidebarAppear origin-left divide-y-1/2 divide-white divide-light-grey-100">
      <div className="py-8 flex justify-between items-center">
        <Link to="#" className="flex font-bold items-center">
          <p className="ml-2">{user?.fullName}</p>
        </Link>

        <button onClick={toggleMobileExpand}>
          <img src={icons.headerClose}></img>
        </button>
      </div>

      {/* sidebar for mobile view */}
      {shouldShowOnboardingSidebar && (
        <div className="mb-6">
          <SidebarOnboarding
            shouldShowOnboardingSidebar={true}
            setCurrentStep={setCurrentStep}
          />
        </div>
      )}
      {shouldShowEcommerceDashboardSidebar && (
        <div className="mb-6">
          <SidebarDashboardEcommerce setCurrentStep={setCurrentStep} />
        </div>
      )}
      {shouldShowSaasDashboardSidebar && (
        <div className="mb-6">
          <SidebarDashboardSaaS setCurrentStep={setCurrentStep} />
        </div>
      )}

      {user && (
        <button
          disabled={logoutLoading}
          className="pb-6 font-bold flex"
          onClick={handleClickLogout}
        >
          Logout
        </button>
      )}
      {!user &&
        (screenName === 'Signup' ? (
          <Link
            className="pb-6 font-bold"
            to="/login"
            onClick={toggleMobileExpand}
          >
            Login
          </Link>
        ) : (
          <Link
            className="pb-6 font-bold"
            to="/signup"
            onClick={toggleMobileExpand}
          >
            Sign up
          </Link>
        ))}
    </div>
  )
}

export default HeaderMobileExpanded
