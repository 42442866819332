import { useQuery } from 'react-query'
import companyApi from 'config/api/company'
import userApi from 'config/api/user'

const useCompanyDetails = () => {
  const companyDetailsQuery = useQuery(
    'companyDetails',
    companyApi.getCompanyInfo
  )
  const userQuery = useQuery('user', userApi.getUser)

  return {
    companyDetails: companyDetailsQuery.data,
    user: userQuery.data,
    error: companyDetailsQuery.error || userQuery.error,
    isLoading: companyDetailsQuery.isLoading || userQuery.isLoading
  }
}

export default useCompanyDetails
