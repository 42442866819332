import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateBusinessType } from 'redux/actions/PersistRegister'
import {
  setIsNewCompany,
  updateSelectedCompanyForm
} from 'redux/actions/Register'
import selectedCompanyEcommerceSchema from './ecommerceSchema'
import selectedCompanyRecurrignSchema from './recurringSchema'

const useSelectedCompanyFormHook = ({ handleCreateCompanyInfo }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const dataRegister = useSelector((state) => state.dataRegister)
  const persistedDataRegister = useSelector(
    (state) => state.persistedDataRegister
  )
  const { companyBusinessType, isSaaSUser, isEcommerceUser } =
    persistedDataRegister
  const dispatch = useDispatch()
  const {
    selectedCompanyForm,
    selectedCompany,
    countryDefault,
    investmentPlan
  } = dataRegister
  //form
  const formik = useFormik({
    initialValues: {
      ...selectedCompanyForm
    },
    validationSchema: isSaaSUser
      ? () =>
          Yup.lazy(() => {
            return selectedCompanyRecurrignSchema()
          })
      : selectedCompanyEcommerceSchema(),
    onSubmit: () => {
      const company = {
        companyName: selectedCompany.title,
        companyNumber: selectedCompany.company_number,
        incorporatedOn: selectedCompany.date_of_creation,
        country: selectedCompany.address.country || countryDefault,
        companyType: selectedCompany.company_type,
        website: selectedCompanyForm.website,
        addressLine_1: selectedCompany.address.address_line_1,
        addressLine_2: selectedCompany.address.address_line_2 || null,
        locality: selectedCompany.address.locality,
        premises: selectedCompany.address.premises,
        postalCode: selectedCompany.address.postal_code,
        avgMonthlyIncome: parseFloat(selectedCompanyForm.avgMonthlyIncome),
        manually: false,
        investmentPlan,
        businessType: companyBusinessType,
        estimatedArr: selectedCompanyForm.estimatedArr
      }
      handleCreateCompanyInfo(company)
      dispatch(setIsNewCompany(false))
    }
  })

  const handleChange = (field, value) => {
    dispatch(updateSelectedCompanyForm(field, value))
    formik.setFieldValue(field, value)
  }
  const handleBusinessType = (field, value) => {
    dispatch(updateBusinessType(value))
    formik.setFieldValue(field, value)
  }

  const onSubmitCompanyForm = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  return {
    formik,
    isSaaSUser,
    handleChange,
    isEcommerceUser,
    shouldShowError,
    handleBusinessType,
    onSubmitCompanyForm
  }
}

export default useSelectedCompanyFormHook
