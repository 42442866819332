import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import appReducer from './reducers/App'
import authReducer from './reducers/Auth'
import companyReducer from './reducers/Company'
import onboardingReducer from './reducers/Onboarding'
import partnerReducer from './reducers/Partner'
import persistedRegisterReducer from './reducers/PersistedRegister'
import registerReducer from './reducers/Register'
import userReducer from './reducers/User'

// Configs for persist
// Add reducers that you do not want to persist in blacklist. E.g: blacklist: ['dataApp']
const rootPersistConfig = {
  timeout: 0,
  key: 'root',
  storage,
  blacklist: [
    'dataAuth',
    'dataRegister',
    'dataOnboarding',
    'dataCompany',
    'dataUser'
  ]
}

// Combine reducers
const rootReducer = combineReducers({
  dataApp: appReducer,
  dataRegister: registerReducer,
  persistedDataRegister: persistedRegisterReducer,
  dataAuth: authReducer,
  dataOnboarding: onboardingReducer,
  dataCompany: companyReducer,
  dataUser: userReducer,
  partnerReducer: partnerReducer
})

// Persist reducers by their configs
export default persistReducer(
  {
    ...rootPersistConfig
  },
  rootReducer
)
