import { useEffect, useState } from 'react'
import Flag from 'react-flagkit'
import Select, { components, createFilter } from 'react-select'
import configs from 'config/configs'
import customReactSelectStyles from './styles'
import customReactCurrencySelectStyles from './stylesCurrency'

const EmptyOptions = () => {
  return (
    <div className="relative text-left dropdown w-full h-14">
      <span className="rounded-md shadow-sm">
        <button
          disabled
          className="flex justify-between items-center h-14 w-full pl-4 py-4 pr-22px font-medium transition duration-150 ease-in-out bg-white border-1/2 border-input-border-color-bloom rounded-lg text-text-color-grey-bloom focus:outline-none"
          type="button"
          aria-controls="headlessui-menu-items-117"
        >
          <span>No options available</span>
          <img src={configs.icons.arrowDropdown}></img>
        </button>
      </span>
    </div>
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={configs.icons.arrowDarkGrey}></img>
    </components.DropdownIndicator>
  )
}

const NormalOption = (props) => {
  return <components.Option {...props}>{props.data.title}</components.Option>
}

const IconOption = (props) => {
  return (
    <components.Option {...props}>
      <Flag country={props.data.value} className="inline mr-2" />
      {props.data.title}
    </components.Option>
  )
}

const FormikSelectGrey = ({
  initialValue,
  options,
  placeholder,
  errors,
  field,
  shouldValid,
  id,
  handleChange,
  containerClassname,
  saveError,
  hasCurrencyIcon
}) => {
  const [formattedOptions, setFormattedOptions] = useState(options)
  const [currentValue, setCurrentValue] = useState()

  useEffect(() => {
    setCurrentValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    options &&
      options.map((option) => {
        option.label = option.title
        return option
      })
    setFormattedOptions(options)
  }, [options])

  useEffect(() => {
    if (saveError === true) {
      setCurrentValue(null)
    }
  }, [saveError])

  const onChange = (item) => {
    handleChange(field, item.value)
    setCurrentValue(item)
  }

  if (!options) {
    return <EmptyOptions />
  }

  return (
    <div className={`flex flex-col w-full z-100 ${containerClassname}`}>
      <div className="relative flex w-full">
        {hasCurrencyIcon && (
          <img
            src={configs.icons.currencyPound}
            className="w-6 h-6 absolute z-10 left-4 top-4"
          />
        )}
        <Select
          key={id}
          placeholder={placeholder}
          value={currentValue}
          options={formattedOptions}
          components={{
            Option: field === 'country' ? IconOption : NormalOption,
            DropdownIndicator
          }}
          onChange={onChange}
          filterOption={createFilter({ matchFrom: 'start' })}
          styles={
            field === 'estimatedArr'
              ? customReactCurrencySelectStyles
              : customReactSelectStyles
          }
        />
      </div>

      {shouldValid && errors[field] && (
        <p className="text-error-message text-sm mt-2 font-normal pl-4">
          {errors[field]}
        </p>
      )}
    </div>
  )
}

export default FormikSelectGrey
