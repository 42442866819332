import { ResponsiveLine } from '@nivo/line'
import React from 'react'
import configs from 'config/configs'
import useWindowSize from 'hooks/useWindowSize'

const BalancesOverviewChart = ({ data }) => {
  const mobileSize = 640
  const { width } = useWindowSize()
  const processedData = data?.map((dataPoint) => {
    return {
      x: new Date(dataPoint['date']).toLocaleString('default', {
        month: 'short'
      }),
      y: dataPoint['balance']
    }
  })
  const maxBalance = Math.max.apply(
    Math,
    processedData.map(function (o) {
      return o.y
    })
  )
  const minBalance = Math.min.apply(
    Math,
    processedData.map(function (o) {
      return o.y
    })
  )
  return (
    <ResponsiveLine
      data={[{ id: 'balances', data: processedData }]}
      colors={[configs.colors.indingoBloom]}
      curve="catmullRom"
      enablePoints={false}
      enableGridX={false}
      yFormat=" >-.2f"
      margin={{ top: 32, right: 32, bottom: 56, left: 56 }}
      animate={true}
      axisLeft={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: width < mobileSize ? 90 : 0
      }}
      yScale={{
        type: 'linear',
        stacked: false,
        min: minBalance,
        max: maxBalance + maxBalance / 10
      }}
      useMesh={true}
    />
  )
}

export default BalancesOverviewChart
