import * as Yup from 'yup'

const selectedCompanyRecurrignSchema = () => {
  return Yup.object().shape({
    estimatedArr: Yup.string().required(
      'Annual Recurring Revenue income is required'
    )
  })
}

export default selectedCompanyRecurrignSchema
