import {
  ECOMMERCE_BUSINESS_TYPE,
  SAAS_BUSINESS_TYPE
} from 'config/configs/constant'
import * as actionTypes from '../../types/PersistRegister'

const initialState = {
  companyBusinessType: '',
  isSaaSUser: false,
  isEcommerceUser: false,
  embeddedPartner: ''
}

const persistedRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PERSIST_DATA:
      return {
        ...state,
        companyBusinessType: action.payload.value,
        isSaaSUser: action.payload.value === SAAS_BUSINESS_TYPE,
        isEcommerceUser: action.payload.value === ECOMMERCE_BUSINESS_TYPE
      }
    case actionTypes.UPDATE_EMBEDDED_PARTNER_DATA:
      return {
        ...state,
        embeddedPartner: action.payload.value
      }
    case actionTypes.RESET_PERSIST_DATA:
      return initialState
    default: {
      return state
    }
  }
}

export default persistedRegisterReducer
