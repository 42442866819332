import React from 'react';
import { Link } from 'react-router-dom';

const SigninAction = () => {
  return (
    <div className="flex items-center">
      <span className="text-dark-grey font-bold">Already have an account? &nbsp;</span>
      <Link to="/login">
        <span className="text-pink-color font-bold border-pink-color border-b hover:opacity-80 transition-opacity">
          Sign in
        </span>
      </Link>
    </div>
  );
};

export default SigninAction;
