import * as Yup from 'yup'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import ConnectionSuccessful from 'components/ConnectionSuccessful'
import FormikInputTextGrey from 'components/FormikInputTextGrey'
import api from 'config/api/onboarding'
import configs from 'config/configs'
import CommonModal from 'modals/components/CommonModal'
import { getErrorMessage } from 'utils/getErrorMessage'

const ZuoraModal = ({ image, isOpened, close }) => {
  const [shouldShowError, setShouldShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const submitZuoraDetails = useMutation(
    'submitZuoraDetails',
    api.submitZuoraData,
    {
      onSuccess: () => {
        setShowSuccess(true)
      },
      onError: (err) => {
        const errorMsg = getErrorMessage(err)
        toast.error(errorMsg)
        setShowSuccess(false)
        close()
      }
    }
  )

  const formik = useFormik({
    initialValues: {
      client_id: '',
      client_secret: ''
    },
    validationSchema: Yup.object().shape({
      client_id: Yup.string().required('Zuora Client ID is required.'),
      client_secret: Yup.string().required('Zuora Client Secret is required.')
    }),
    onSubmit: (values, { resetForm }) => {
      submitZuoraDetails.mutate(values)
      resetForm()
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  const onCloseSuccessModal = () => {
    setShowSuccess(false)
    close()
  }

  const openTabWithLink = () => {
    const documentationLink =
      'https://knowledgecenter.zuora.com/CF_Users_and_Administrators/A_Administrator_Settings/Manage_Users?_gl=1*5twf41*_ga*NDU0NzI3MDk0LjE2NTc1NTAyMzg.*_ga_MY8CQ650DH*MTY1NzYwOTU3NC4yLjEuMTY1NzYwOTYwNC4w&_ga=2.182091367.1715345169.1657550238-454727094.1657550238#Create_an_OAuth_Client_for_a_User'
    window.open(documentationLink, '_blank')
  }

  return (
    <CommonModal
      title={'Connect your Zuora account'}
      isOpened={isOpened}
      close={close}
    >
      {showSuccess ? (
        <ConnectionSuccessful
          integration="zuora"
          onClick={onCloseSuccessModal}
        />
      ) : (
        <>
          <div className="flex flex-col justify-center w-full border-light-grey-200 border-b mb-4">
            <div className="flex justify-center items-center">
              <img src={image} alt="magento-logo" className="w-auto h-20" />
            </div>

            <div className="w-full text-left mb-2">
              You must first{' '}
              <span
                className="cursor-pointer text-blue-500"
                onClick={openTabWithLink}
              >
                create an OAuth client{' '}
              </span>
              in the Zuora UI. To do this, you must be an administrator of your
              Zuora tenant. This is a one-time operation. You will be provided
              with a Client ID and a Client Secret.
            </div>

            <div className="flex flex-col w-full text-left gap-y-2">
              <p className="text-sm md:text-base text-black font-semibold">
                <span className="text-red-400">* </span>Client ID
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left mb-2">
                  <FormikInputTextGrey
                    field="client_id"
                    id="client_id"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                  />
                </div>
              </form>
            </div>

            <div className="flex flex-col w-full text-left gap-y-2">
              <p className="text-sm md:text-base text-black font-semibold">
                <span className="text-red-400">* </span>Client Secret
              </p>
              <form className="w-full">
                <div className="flex w-full items-start text-left mb-2">
                  <FormikInputTextGrey
                    field="client_secret"
                    id="client_secret"
                    type="password"
                    shouldValid={shouldShowError}
                    {...formik}
                    handleChange={handleChange}
                    containerClassname={'mb-3 overflow-hidden'}
                  />
                </div>
              </form>
            </div>

            <div className="flex justify-between items-center mb-4">
              <Button
                type="submit"
                variant={'button-next-pink'}
                className="ml-auto normal-case"
                disabled={!(formik.isValid && formik.dirty)}
                loading={submitZuoraDetails.isLoading}
                onClick={onSubmit}
              >
                Connect Zuora
              </Button>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full pt-2">
            <div className="flex justify-center gap-x-2 items-center">
              <img src={configs.icons.lockClosed} />
              <span className="text-sm text-placeholder-grey">
                Secured with 256-bit encryption
              </span>
            </div>
          </div>
        </>
      )}
    </CommonModal>
  )
}

export default ZuoraModal
