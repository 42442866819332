import React from 'react'
import Button from 'components/Button'
import configs from 'config/configs'
import { PARTNERS_ROUTES } from 'config/configs/routes'

const PartnersIncognito = ({ slug }) => {
  const { SIGNUP } = PARTNERS_ROUTES
  const partnerSignUp = SIGNUP.replace(':slug', slug)
  return (
    <div className="flex justify-center mx-auto min-h-content-fit-desktop">
      <div className="flex flex-col justify-center md:flex-row gap-y-4 py-8 mx-8">
        <div className="flex justify-center items-center w-full mr-8 xl:max-w-5xl">
          <img
            src={configs.images.onboardingSuccess}
            className={`max-w-xs lg:max-w-xl`}
          />
        </div>

        <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left md:mt-0 w-full gap-y-6">
          <div className="font-black text-5xl">Oops!</div>
          <div className="font-normal text-xl">
            It appears your browser is running in Incognito. Please disable it
            or click the button below to open this form in a new tab.
          </div>
          <Button
            variant="button-next"
            affixIcon={configs.icons.arrowForward}
            children={'Go to Bloom partner signup'}
            className="font-medium"
            type="button"
            onClick={() => window.open(partnerSignUp, '_blank')}
          />
        </div>
      </div>
    </div>
  )
}

export default PartnersIncognito
